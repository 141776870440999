import { StakingData } from '@hooks/useStake'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { FC, Suspense } from 'react'
import useCoingecko from '@hooks/useCoingecko'
import stakingUtils from '@utils/stake'
import {
  useGetTotalUserRewards,
  useGetTotalUserStakedAmount,
  useStakingTotalStaked
} from '@hooks/useStaking'
import { binancePools } from 'src/data/Staking/Binance/contracts'
import { useWallet } from '@hooks/useWallet'

import './BannerItem.sass'

type StakeKey = keyof StakingData | 'usd'

interface BannerItemProps {
  address: string
  title: string
  stakeKey: StakeKey
}

interface BannerItemComponentProps {
  value: number
}

const { pool1, pool2, pool3, pool4 } = binancePools

const BannerItemUSD = () => {
  const pool1Data = useStakingTotalStaked(pool1.address)
  const pool2Data = useStakingTotalStaked(pool2.address)
  const pool3Data = useStakingTotalStaked(pool3.address)
  const pool4Data = useStakingTotalStaked(pool4.address)
  const totalStaked = pool1Data + pool2Data + pool3Data + pool4Data
  const data = useCoingecko()
  const lay3rData = stakingUtils.getLay3rData(data)

  const { current_price: currentPrice } = lay3rData

  return (
    <FormattedNumber
      value={totalStaked * currentPrice}
      style="currency"
      maximumFractionDigits={0}
      currency="USD"
    />
  )
}

const BannerItemTotal: FC<BannerItemComponentProps> = () => {
  const pool1Data = useStakingTotalStaked(pool1.address)
  const pool2Data = useStakingTotalStaked(pool2.address)
  const pool3Data = useStakingTotalStaked(pool3.address)
  const pool4Data = useStakingTotalStaked(pool4.address)
  const totalStaked = pool1Data + pool2Data + pool3Data + pool4Data
  return (
    <FormattedNumber
      value={totalStaked}
      style="decimal"
      maximumFractionDigits={0}
    />
  )
}

const BannerItemUserStake = () => {
  const address = useWallet()
  const pool1Data = useGetTotalUserStakedAmount(address, pool1.address)
  const pool2Data = useGetTotalUserStakedAmount(address, pool2.address)
  const pool3Data = useGetTotalUserStakedAmount(address, pool4.address)
  const pool4Data = useGetTotalUserStakedAmount(address, pool4.address)
  const totalUserStaked: number = pool1Data + pool2Data + pool3Data + pool4Data
  return (
    <FormattedNumber
      value={totalUserStaked}
      style="decimal"
      maximumFractionDigits={2}
    />
  )
}

const BannerItemRewards = () => {
  const address = useWallet()
  const pool1Data = useGetTotalUserRewards(address, pool1.address)
  const pool2Data = useGetTotalUserRewards(address, pool2.address)
  const pool3Data = useGetTotalUserRewards(address, pool3.address)
  const pool4Data = useGetTotalUserRewards(address, pool4.address)
  const totalUserRewards = pool1Data + pool2Data + pool3Data + pool4Data
  return (
    <FormattedNumber
      value={totalUserRewards}
      style="decimal"
      maximumFractionDigits={2}
    />
  )
}

const dataComponents: Record<StakeKey, FC> = {
  rewards: BannerItemRewards,
  totalStakedAmount: BannerItemTotal,
  userStakedAmount: BannerItemUserStake,
  usd: BannerItemUSD
}

export default function BannerItem({ title, stakeKey }: BannerItemProps) {
  const DataComponent = dataComponents[stakeKey]

  return (
    <div className="banner-item">
      <span className="title">
        <FormattedMessage id={title} />
      </span>
      <span className="value">
        <Suspense fallback={<span className="fallback" />}>
          <DataComponent />
        </Suspense>
      </span>
    </div>
  )
}
