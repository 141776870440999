import { FormattedMessage } from 'react-intl'
import Spinner from 'src/components/Loading/Spinner/Spinner'
import Loading from 'src/components/Loading/Loading'

import './AvsSection.sass'

const AvsSectionLoading = () => {
  return (
    <section id="avs-section">
      <h2>
        <span>
          <FormattedMessage id="avs-section.title" />{' '}
        </span>
        <Spinner />
      </h2>
      <Loading />
    </section>
  )
}

export default AvsSectionLoading
