import { useState } from 'react'

export const useCopy = () => {
  const [showCopied, setShowCopied] = useState<boolean>(false)

  const copyClipBoard = (e: { stopPropagation: () => void }, text: string) => {
    e.stopPropagation()
    navigator?.clipboard
      ?.writeText(text)
      .then(() => setShowCopied(true))
      .catch((err) => console.error(err.message))
    setTimeout(() => setShowCopied(false), 1500)
  }
  return { copyClipBoard, showCopied }
}
