import { useModal } from '@gluedigital/modal'
import { getTokenImage } from 'src/utils/getTokenImage'
import { useNavigate } from 'react-router-dom'
import { useAssetsPortfolio } from 'src/hooks/usePortfolio'
import { PortfolioData, PortfolioTokens } from 'src/types/portfolioTypes'
import { Networks } from 'src/utils/networkHelper'

import './AssetBodyTable.sass'

const AssetRow = ({ card }: { card: PortfolioTokens }) => {
  const { balance, id, name, usdValue, type, priceVariation, networkName } =
    card
  const modal = useModal()
  const navigate = useNavigate()
  const handleRow = () => {
    navigate(`/info/${type.toLowerCase()}/${networkName}/${id.toLowerCase()}`)
    modal.hide()
  }
  return (
    <tr
      className="asset-row port-folio-grid-row"
      id={`asset-row-${id}`}
      key={id + networkName}
      onClick={() => handleRow()}
    >
      <td className="row-token">
        <img title={id} src={getTokenImage(id.toLowerCase())} alt={id} />
      </td>
      <td className="row-title">
        <h2 title={name}>{name}</h2>
        <span title={id} className="token-id">
          <strong>{id}</strong> - {networkName}
        </span>
      </td>
      <td className="row-amount">
        <strong title={balance.toString()}>{(+balance).toFixed(5)}</strong>
      </td>
      <td className="row-usd-value">
        <span className="amount" title={'$' + usdValue.toString()}>
          <span className="dollar-symbol">$</span>
          <strong>{usdValue.toFixed(2)}</strong>
        </span>
        <span
          title={priceVariation.toString() + '%'}
          className={priceVariation > 0 ? 'variation' : 'variation red'}
        >
          ({(priceVariation > 0 ? '+' : '') + priceVariation?.toFixed(2)}%)
        </span>
      </td>
    </tr>
  )
}

const AssetBodyTable = () => {
  const arbData: PortfolioData = useAssetsPortfolio(Networks.arbitrum)
  const bnbData: PortfolioData = useAssetsPortfolio(Networks.binance)
  const baseAssets: PortfolioData = useAssetsPortfolio(Networks.base)
  const optimismAssets: PortfolioData = useAssetsPortfolio(Networks.optimism)
  const modeAssets: PortfolioData = useAssetsPortfolio(Networks.mode)
  const scrollAssets: PortfolioData = useAssetsPortfolio(Networks.scroll)
  const ethData: PortfolioData = useAssetsPortfolio(Networks.ethereum)
  //  const lineaAssets: PortfolioData = useAssetsPortfolio(Networks.linea)
  const tokens = [
    ...arbData.tokens,
    ...ethData.tokens,
    ...bnbData.tokens,
    ...baseAssets.tokens,
    ...optimismAssets.tokens,
    ...modeAssets.tokens,
    ...scrollAssets.tokens
  ]
 


  const assets: PortfolioTokens[] = tokens.filter(
    (d) => d.balance > 0 || d.usdValue > 0
  )
  return (
    <table className="portfolio-table" id="asset-table" cellSpacing={0}>
      <colgroup>
        <col className="col-token" />
        <col className="col-title" />
        <col className="col-amount" />
        <col className="col-usd-value" />
      </colgroup>
      <thead>
        <tr>
          <th className="row-token">Token</th>
          <th className="row-title">Title</th>
          <th className="row-amount">Amount</th>
          <th className="row-usd-value">USD Value</th>
        </tr>
      </thead>
      <tbody>
        {assets.map((d) => (
          <AssetRow key={`${d.id}-${d.networkName}`} card={d} />
        ))}
      </tbody>
    </table>
  )
}

export default AssetBodyTable
