import { DialogTypes } from '@components/Stake/StakeDialog/StakeDialog'
import { CoingeckoDataSummary } from '@hooks/useCoingecko'

function getLay3rData(data: CoingeckoDataSummary[]) {
  const autolayer = data.find((item) => item.symbol === 'lay3r')
  return autolayer
}

function getAbbreviatedNumber(num: number) {
  if (num >= 1 && num < 10000) {
    return num.toFixed(3)
  } else if (num < 1) {
    return num.toFixed(5)
  } else if (num >= 10000 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'K'
  } else if (num >= 1000000 && num < 1000000000) {
    return (num / 1000000).toFixed(1) + 'M'
  } else {
    return (num / 1000000000).toFixed(1) + 'B'
  }
}

function getPendingDate(startTime: number, lockTime: number) {
  const dateDifference = Math.floor(Date.now() / 1000 - startTime)

  const day = Math.floor(dateDifference / 86400)

  return lockTime - day
}

function chooseDialogLabel(type: DialogTypes) {
  switch (type) {
    case 'claim':
      return 'Claim Rewards'
    case 'rewards':
      return 'Stake Rewards'
    case 'stake':
      return 'Stake'
    case 'unstake':
      return 'Unstake'
  }
}

const stakeUtils = {
  getLay3rData,
  getAbbreviatedNumber,
  chooseDialogLabel,
  getPendingDate
}

export default stakeUtils
