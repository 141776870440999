export enum ExecutionSteps {
  notStarted = 0,
  gotRoute = 1,
  approvedToken = 2,
  sentToken = 3
}

export enum FailExecutionSteps {
  failGotRoute = 1,
  failApprovedToken = 2,
  failSentToken = 3
}

export enum RemoveLiquiditySteps {
  notStarted = 0,
  approveBpt = 1,
  sendBptLiquidity = 2,
  approveToken = 3,
  sendToken = 4
}

export enum FailRemoveLiquiditySteps {
  failBptApprove = 1,
  failSendBptLiquidity = 2,
  failApproveToken = 3,
  failSendToken = 4
}
