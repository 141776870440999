import { useFetch } from '@gluedigital/ruse-fetch-extras'
import { dataMicroserviceUrl } from 'src/constants'
import { Multipliers } from 'src/types'
import { NetworkIds } from 'src/utils/networkHelper'

export interface TokensInfo {
  id: string
  address: string
  name: string
  decimals: string
  network: NetworkIds
  type: 'LRT' | 'LST'
  description: string
  apr?: string
  image?: string
  opengraph?: string
  multipliers: Multipliers
}

export type TokensInfoWithoutMultiplier = Omit<TokensInfo, 'multipliers'>

const useTokensLst = (): TokensInfo[] => {
  const route = '/lst'
  const tokens = useFetch<TokensInfo[]>(dataMicroserviceUrl + route)
  return tokens
}

export default useTokensLst
