import { Suspense, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import BalanceButtons from './BalanceButtons/BalanceButtons'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import TokenSearch from './TokenSearch/TokenSearch'
import Loading from '../Loading/Loading'
import TokenSelectorList from './TokenSelectorList/TokenSelectorList'
import { getTokenImage } from 'src/utils/getTokenImage'
import { useParams } from 'react-router-dom'
import getTokenDecimals from 'src/contracts/tokenDecimals/getTokenDecimals'
import useNetworkFromRoute from 'src/hooks/useNetworkFromRoute'
import { NetworkData, networkData } from 'src/data'

import './TokenSelector.sass'

const MINIMUN_VALUE: number = 0

interface TokenSelectorProps {
  previousData?: { outputCoin: string; amount: number }
  onChange?: ({
    amount,
    outputCoin
  }: {
    outputCoin: string
    amount: number
  }) => void
  searchMode?: boolean
  setSearchMode?: (value: boolean) => void | null
  blocked?: boolean
  darkMode?: boolean
  setRealWeiAmount?: (value: bigint) => void
}

const TokenSelector = ({
  previousData = { outputCoin: 'WETH', amount: 0 },
  onChange,
  searchMode = false,
  setSearchMode,
  setRealWeiAmount,
  blocked = true,
  darkMode = false
}: TokenSelectorProps) => {
  const [search, setSearch] = useState<string>('')
  const { amount, outputCoin } = previousData
  const { chainId } = useNetworkFromRoute()
  const network: NetworkData = networkData(chainId)
  const setCoin = (coin: string) => {
    !!onChange && onChange({ ...previousData, outputCoin: coin })
  }
  const { productName } = useParams<{ productName: string }>()
  const inputTokenDecimals: string = getTokenDecimals(
    previousData.outputCoin.toLowerCase(),
    chainId
  )

  return (
    <div
      className={`token-selector ${blocked ? 'blocked' : ''} ${darkMode ? 'dark-theme' : 'light-theme'}`}
    >
      <section
        className="token-selector-info"
        onClick={() => setSearchMode?.(false)}
      >
        <label className="input-container">
          <h2>Restake</h2>
          <input
            readOnly={blocked}
            className={`amount-input ${('' + amount).length < 9 ? 'big' : ''}`}
            disabled={amount < MINIMUN_VALUE}
            type="number"
            inputMode="decimal"
            onFocus={() => {
              setSearchMode?.(false)
            }}
            onBlur={() => {
              const factor: number = Math.pow(10, Number(inputTokenDecimals))
              setRealWeiAmount?.(BigInt(amount * factor))
            }}
            onChange={(e) => {
              e.preventDefault()
              const value: string = e.target.value
              const decimals: string = value.split(',')[1]
              if (decimals && decimals.length > 6) return
              onChange({ ...previousData, amount: +e.target.value })
            }}
            placeholder="0"
            value={amount === 0 || !amount ? 0 : amount}
            min={MINIMUN_VALUE}
            step="any"
          />
        </label>
        <h1
          className={
            outputCoin?.length > 4
              ? outputCoin?.length > 6
                ? 'micro'
                : 'mini'
              : ''
          }
          onClick={(e) => {
            e.stopPropagation()
            if (!blocked) {
              setSearchMode?.(!searchMode)
            }
          }}
        >
          <img title={outputCoin} src={getTokenImage(outputCoin)} />
          <span>{outputCoin || network.tokens[0].id}</span>
          <span
            className={`icon icon-angle_${searchMode ? 'right' : 'down'}`}
          />
        </h1>
      </section>
      <BalanceButtons
        disabled={blocked}
        previousData={previousData}
        onChange={onChange}
        setRealWeiAmount={setRealWeiAmount}
      />
      <section className={`token-selector-select ${searchMode ? 'open' : ''}`}>
        <TokenSearch search={search} setSearch={setSearch} />
        <section className="token-selector-list-wrapper">
          <ul
            className="token-selector-list"
            onClick={() => setSearchMode?.(false)}
          >
            <ErrorBoundary
              fallback={
                <>
                  <FormattedMessage id="error-loading-tokens-balance" />
                </>
              }
            >
              <Suspense fallback={<Loading colored />}>
                {!!onChange && (
                  <TokenSelectorList
                    coins={network.tokens}
                    search={search}
                    outputCoin={outputCoin}
                    setCoin={setCoin}
                    inputCoin={productName}
                  />
                )}
              </Suspense>
            </ErrorBoundary>
          </ul>
        </section>
      </section>
    </div>
  )
}

export default TokenSelector
