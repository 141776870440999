import {
  FailRemoveLiquiditySteps,
  RemoveLiquiditySteps
} from 'src/types/DepositTypes'
import { createMessageListForPoolsWithdrawal } from './messageList'
import MarkdownMessage from '@gluedigital/markdown-message'
import Spinner from 'src/components/Loading/Spinner/Spinner'
import ScanLinkMessage from './ScanLinkMessage'
import { FormattedMessage } from 'react-intl'

import './DepositStepMessage.sass'

interface DepositStepMessageForPoolsProps {
  failExecutionStep: FailRemoveLiquiditySteps
  executionStep: RemoveLiquiditySteps
  darkMode?: boolean
  txHash?: string
  txPoints?: string
  avoidSwap?: boolean
  withdrawalAmount?: { token0: string; token1: string }
  camelotLpName?: string
}
const DepositStepMessageForPoolsWithdrawal = ({
  failExecutionStep,
  executionStep,
  darkMode = false,
  txHash,
  txPoints,
  avoidSwap,
  withdrawalAmount,
  camelotLpName
}: DepositStepMessageForPoolsProps) => {
  //* messages has in the first element the step and in second the status
  const messages: string[][] = createMessageListForPoolsWithdrawal(
    failExecutionStep,
    executionStep
  )
  let filteredMessages: string[][] = []
  if (avoidSwap && executionStep === RemoveLiquiditySteps.sendBptLiquidity) {
    filteredMessages = [messages[0], messages[1]]
  } else {
    filteredMessages = messages
  }
  return (
    <div
      className={`deposit-step-list ${darkMode ? 'dark-theme' : 'light-theme'}`}
    >
      {filteredMessages?.map((message: string[], index: number) => {
        return (
          <div className="row" key={index}>
            {message[1] === 'done' && <div className={'circle done'} />}
            {failExecutionStep && message[1] === 'fail' && (
              <div className={'circle failed'} />
            )}
            {failExecutionStep && message[1] === 'running' && (
              <div className={'circle failed'} />
            )}
            {!failExecutionStep && message[1] === 'running' && <Spinner />}
            <span>
              <MarkdownMessage
                id={`deposit-step-${message[0]}.${message[1]}`}
              />
              {index === 0 || index === 1 ? ' (1 / 2)' : ' (2 / 2) '}
            </span>
          </div>
        )
      })}
      {txHash && <ScanLinkMessage txHash={txHash} />}
      {txHash && (
        <p className="funds-wallet">
          <FormattedMessage id="funds-wallet" />
        </p>
      )}
      {withdrawalAmount &&
        withdrawalAmount?.token0 &&
        withdrawalAmount?.token1 && (
          <div className="amount-withdraw">
            <span>
              {parseFloat(withdrawalAmount?.token0).toFixed(6)}{' '}
              <strong>{camelotLpName.split('-')[0]}</strong>
            </span>
            <span>
              {parseFloat(withdrawalAmount?.token1).toFixed(6)}{' '}
              <strong>{camelotLpName.split('-')[1]}</strong>
            </span>
          </div>
        )}
      {txHash && txPoints && parseFloat(txPoints) > 0 && (
        <>
          <p className="funds-wallet">
            <FormattedMessage id="deposit.tx-points-earned" />
          </p>
          <span className="tx-points">
            {parseFloat(txPoints).toFixed(4)}{' '}
            <FormattedMessage id="dashboard.autolayer-points" />
          </span>
        </>
      )}
    </div>
  )
}

export default DepositStepMessageForPoolsWithdrawal
