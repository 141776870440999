import { useEffect, useState } from 'react'
import SwapSelector from '../TokenSelector/SwapSelector'
import { useParams } from 'react-router-dom'
import NotConnectedDeposit from '../Deposit/NotConnectedDeposit'
import approveToken from 'src/contracts/approveToken'
import { getWeiNumberAmountDependOnDecimals } from 'src/contracts/tokenDecimals/getEtherNumberAmount'
import getParaswapRoute from 'src/contracts/Paraswap/getParaswapRoute'
import { OptimalRate } from '@paraswap/sdk'
import buildParaswapTx from 'src/contracts/Paraswap/buildParaswapTx'
import {
  FailRemoveLiquiditySteps,
  RemoveLiquiditySteps
} from 'src/types/DepositTypes'
import { DepositProps } from '../Deposit/Deposit'
import removeLiquidityBalancerPool from 'src/contracts/removeLiquidityBalancerPool'
import { useSinglePool } from 'src/hooks/usePools'
import sendSwapTransaction from 'src/contracts/sendSwapTransaction'
import tokenNameToAddress from 'src/utils/tokenNameToAddress'
import DepositStepMessageForPoolsWithdrawal from '../Deposit/DepositStepMessage/DepositStepMessageForPoolsWithdrawal'
import { getAutolayerPointsAfterTx } from 'src/contracts/getAutoLayerPoints'
import useCoingecko, { CoingeckoDataSummary } from 'src/hooks/useCoingecko'
import { calculatePoolTokenOutputAmount } from './swapHelper'
import useEnoughBalance from 'src/hooks/useEnoughBalance'
import { useCommonDeposit } from 'src/hooks/useCommonDeposit'
import useIsConnected from 'src/hooks/useIsConnected'
import ContinueButton from '../Deposit/ContinueButton'
import type { SwapData } from 'src/types'
import Slippage from '../Slippage/Slippage'

import './Swap.sass'

const SwapPools = ({
  darkMode = false,
  refreshAutoLayerPoints
}: DepositProps) => {
  const { productAddress } = useParams<{ productAddress: string }>()
  const [avoidSwap, setAvoidSwap] = useState<boolean>(false)
  const [swapPreviewAmount, setSwapPreviewAmount] = useState<string>('0')
  // In this case we cannot get executionStep and failExecutionStep from useDeposit because we need different types.
  const isConnected: boolean = useIsConnected()
  const {
    searchMode,
    setSearchMode,
    txHash,
    setTxHash,
    txPoints,
    setTxPoints,
    executionStep,
    failExecutionStep,
    setExecutionStep,
    setFailExecutionStep,
    realWeiAmount,
    setRealWeiAmount,
    data: swapData,
    setData: setSwapData,
    wallet,
    chainId,
    slippage,
    setSlippage
  } = useCommonDeposit<
    SwapData,
    RemoveLiquiditySteps,
    FailRemoveLiquiditySteps
  >({ outputCoin: 'USDT', amount: 0, inputCoin: productAddress })

  const blocked = executionStep !== undefined && failExecutionStep === undefined
  const thisPool = useSinglePool(productAddress)
  const poolTokensAddress = thisPool.filteredTokens.map(
    (token) => token.address
  )
  poolTokensAddress.sort((a, b) => Number(a) - Number(b))

  const hasEnoughBalance: boolean = useEnoughBalance(
    realWeiAmount,
    swapData?.inputCoin
  )

  const coingeckoData: CoingeckoDataSummary[] = useCoingecko()

  useEffect(() => {
    const calculateSwapPreview = setTimeout(async () => {
      try {
        if (swapData.amount <= 0) return
        const outputPoolTokenAmount: number = calculatePoolTokenOutputAmount(
          thisPool,
          coingeckoData,
          swapData.amount,
          poolTokensAddress[0],
          chainId
        )
        const weiAmount: string = await getWeiNumberAmountDependOnDecimals(
          poolTokensAddress[0],
          +outputPoolTokenAmount.toFixed(5),
          chainId
        )
        const priceRoute: OptimalRate = await getParaswapRoute(
          poolTokensAddress[0],
          swapData.outputCoin,
          weiAmount,
          chainId
        )
        const outputTokenAmountEth =
          parseFloat(priceRoute.destAmount) / 10 ** priceRoute.destDecimals
        setSwapPreviewAmount(outputTokenAmountEth?.toFixed(5))
      } catch (error) {
        console.error('Error calculating swap preview', error.message)
        setSwapPreviewAmount('0')
      }
    }, 900)

    return () => clearTimeout(calculateSwapPreview)
  }, [thisPool, swapData, coingeckoData, poolTokensAddress, chainId])

  const handleChange = (data: SwapData) => {
    if (executionStep || failExecutionStep) {
      setExecutionStep(undefined)
      setFailExecutionStep(undefined)
    }
    setSwapData(data)
  }

  const handleSwap = async () => {
    if (
      (poolTokensAddress[0]?.toLowerCase() ===
        tokenNameToAddress(swapData?.outputCoin, chainId)?.toLowerCase(),
      chainId)
    ) {
      setAvoidSwap(true)
    }
    setSearchMode(false) // to close the list
    setExecutionStep(RemoveLiquiditySteps.notStarted)
    try {
      await approveToken(
        swapData.inputCoin,
        realWeiAmount,
        wallet?.address,
        chainId
      )
      setExecutionStep(RemoveLiquiditySteps.approveBpt)
    } catch (error) {
      console.error('Error approving token', error.message)
      setFailExecutionStep(FailRemoveLiquiditySteps.failBptApprove)
      return
    }
    let weiReceivedAmount
    try {
      const { transactionHash, amountWei } = await removeLiquidityBalancerPool(
        thisPool,
        realWeiAmount.toString(),
        wallet?.address,
        productAddress,
        chainId
      )
      weiReceivedAmount = amountWei
      setExecutionStep(RemoveLiquiditySteps.sendBptLiquidity)
      if (
        poolTokensAddress[0]?.toLowerCase() ===
        tokenNameToAddress(swapData?.outputCoin, chainId)?.toLowerCase()
      ) {
        setTxHash(transactionHash)
      }
    } catch (error) {
      console.error('Error sending swap transaction', error.message)
      setFailExecutionStep(FailRemoveLiquiditySteps.failSendBptLiquidity)
      return
    }

    //* In case the first token pool is the same than the output token, we don't need to swap.
    if (
      poolTokensAddress[0]?.toLowerCase() ===
      tokenNameToAddress(swapData?.outputCoin, chainId)?.toLowerCase()
    ) {
      return
    }

    let priceRoute: OptimalRate
    try {
      priceRoute = await getParaswapRoute(
        poolTokensAddress[0],
        swapData.outputCoin,
        weiReceivedAmount,
        chainId
      )
    } catch (error) {
      console.error('Error getting swap info', error.message)
    }

    let txParams
    try {
      txParams = await buildParaswapTx(
        priceRoute,
        poolTokensAddress[0],
        swapData.outputCoin,
        weiReceivedAmount,
        chainId,
        slippage
      )
    } catch (error) {
      console.error('Error getting swap info', error.message)
      setFailExecutionStep(FailRemoveLiquiditySteps.failApproveToken)
      return
    }

    try {
      await approveToken(
        poolTokensAddress[0],
        BigInt(weiReceivedAmount),
        wallet?.address,
        chainId
      )
      setExecutionStep(RemoveLiquiditySteps.approveToken)
    } catch (error) {
      console.error('Error approving token', error.message)
      setFailExecutionStep(FailRemoveLiquiditySteps.failApproveToken)
      return
    }

    let sendSwapTx
    try {
      sendSwapTx = await sendSwapTransaction(
        txParams,
        swapData.outputCoin,
        weiReceivedAmount,
        wallet?.address,
        chainId
      )
    } catch (error) {
      console.error('Error sending swap transaction', error.message)
      setFailExecutionStep(FailRemoveLiquiditySteps.failSendToken)
      return
    }
    try {
      const { points, transactionHash } = getAutolayerPointsAfterTx(
        sendSwapTx,
        wallet?.address,
        false,
        tokenNameToAddress(swapData.outputCoin, chainId)
      )
      setExecutionStep(RemoveLiquiditySteps.sendToken)
      setTxHash(transactionHash)
      setTxPoints(points)
      refreshAutoLayerPoints()
    } catch (error) {
      console.error('Error getting transaction Points', error.message)
    }
  }

  if (!isConnected) {
    return (
      <NotConnectedDeposit
        darkMode={darkMode}
        searchMode={searchMode}
        depositData={swapData}
        setDepositData={setSwapData}
        setSearchMode={setSearchMode}
      />
    )
  }

  return (
    <div id="swap" className={darkMode ? 'dark-theme' : 'light-theme'}>
      <div className="wrapper-selector">
        <SwapSelector
          darkMode={darkMode}
          searchMode={searchMode}
          previousData={swapData}
          blocked={blocked}
          onChange={handleChange}
          setSearchMode={setSearchMode}
          swapPreviewAmount={swapPreviewAmount}
          setRealWeiAmount={setRealWeiAmount}
        />
        <Slippage
          blocked={blocked}
          slippage={slippage}
          setSlippage={setSlippage}
        />
      </div>
      {executionStep === undefined ? (
        <div>
          <ContinueButton
            disabled={swapData?.amount <= 0 || !hasEnoughBalance}
            handleDeposit={handleSwap}
            darkMode={darkMode}
          />
        </div>
      ) : (
        <DepositStepMessageForPoolsWithdrawal
          executionStep={executionStep}
          failExecutionStep={failExecutionStep}
          txHash={txHash}
          txPoints={txPoints}
          avoidSwap={avoidSwap}
        />
      )}
    </div>
  )
}

export default SwapPools
