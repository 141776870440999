import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import ExternalHeader from 'src/components/ExternalHeader/ExternalHeader'
import Footer from 'src/components/Footer/Footer'
import Loading from 'src/components/Loading/Loading'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import ReferralsMeta from 'src/components/Meta/ReferralsMeta'
import ReferralsButtons from './ReferralsButtons/ReferralsButtons'

import './Referrals.sass'

const Referrals = () => {
  const learnMoreUrl =
    'https://docs.autolayer.io/autolayer/latest-app-infos/rewards-and-programs/autolayer-referral-program'

  return (
    <>
      <ReferralsMeta />
      <div id="referrals" className="page">
        <ExternalHeader name="referrals" learnMoreUrl={learnMoreUrl} />
        <div className="wrapper">
          <section className="referrals-top">
            <div className="container">
              <h1>
                <FormattedMessage id="referrals.title" />
              </h1>
              <p>
                <FormattedMessage id="referrals.text" />
              </p>
            </div>
          </section>
          <div className="container">
            <section className="referrals-bottom">
              <ReferralsButtons />
              <ErrorBoundary>
                <Suspense fallback={<Loading colored />}>
                  <Outlet />
                </Suspense>
              </ErrorBoundary>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Referrals
