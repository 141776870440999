import { ReactNode } from 'react'

export default function PointsDashboardWrapper({
  children,
  type
}: {
  children: ReactNode
  type?: string
}) {
  return (
    <article className={`points-item-dashboard points-item-${type}`}>
      {children}
    </article>
  )
}
