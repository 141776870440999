import { Suspense, useState } from 'react'
import { DataProps } from '../Data/Mobile'
import { FormattedMessage } from 'react-intl'
import StakingButtons from '../StakigInfo/StakingButtons/StakingButtons'
import ProgressBar, {
  ProgressWrapper
} from '@components/ProgressBar/ProgressBar'
import { StakeDialogMethods } from '../StakeDialog/StakeDialog'
import stakingUtils from '@utils/stake'
import {
  useGetPendingRewardsInPosition,
  useGetTotalUserRewards,
  useGetTotalUserStakedAmount,
  useStakingPosition,
  useStakingPositionNumber,
  useStakingTotalStaked
} from '@hooks/useStaking'
import { useWallet } from '@hooks/useWallet'
import useWalletChainId from '@hooks/useWalletChainId'

type StakingDataDesktopProps = Pick<StakeDialogMethods, 'open'>

function StakingContentTotalRewards({ poolAddress }: { poolAddress: string }) {
  const address = useWallet()
  const rewards = useGetTotalUserRewards(address, poolAddress)

  return (
    <span title={rewards.toString()}>
      {stakingUtils.getAbbreviatedNumber(rewards)}
    </span>
  )
}

function StakingContentTotalStaked({ poolAddress }: { poolAddress: string }) {
  const address = useWallet()
  const stakedAmount = useGetTotalUserStakedAmount(address, poolAddress)

  return (
    <span title={stakedAmount.toString()}>
      {stakingUtils.getAbbreviatedNumber(stakedAmount)}
    </span>
  )
}

function StakingContentRow({
  poolAddress,
  positionId,
  lockTime,
  open
}: {
  positionId: number
  poolAddress: string
  lockTime: number
} & StakingDataDesktopProps) {
  const address = useWallet()
  const positionData = useStakingPosition(address, positionId, poolAddress)
  const rewards = useGetPendingRewardsInPosition(
    address,
    positionId,
    poolAddress
  )

  if (!positionData) return null
  const { startTime, currentAmount, active } = positionData

  const pendingDate = stakingUtils.getPendingDate(startTime, lockTime)

  if (!active) return null

  return (
    <div className="staking-entry">
      <div className="lock-term">
        <span>{pendingDate}</span>
      </div>
      <div className="my-stake" title={`${currentAmount}`}>
        {stakingUtils.getAbbreviatedNumber(currentAmount)}
      </div>
      <div className="my-rewards" title={`${rewards}`}>
        {stakingUtils.getAbbreviatedNumber(rewards)}
      </div>
      <StakingButtons
        open={open}
        rewards={rewards}
        poolAddress={poolAddress}
        positionId={positionId}
        pendingDate={pendingDate}
      />
    </div>
  )
}

export default function StakingContentDesktop({
  apy,
  image,
  name,
  lockTime,
  maxCapacity,
  poolAddress,
  refreshKey,
  open: openDialog
}: DataProps & StakingDataDesktopProps) {
  const address = useWallet()
  const positionsNumber = useStakingPositionNumber(
    address,
    poolAddress,
    refreshKey
  )

  const entries = Array.from({ length: positionsNumber })
  const [open, setOpen] = useState<boolean>(false)

  const userWalletChainId = useWalletChainId()
  const totalStaked = useStakingTotalStaked(poolAddress)
  const disableStake: boolean = totalStaked >= maxCapacity

  return (
    <article className={open ? 'open' : ''}>
      <header onClick={() => setOpen((prev) => !prev)}>
        <div className="pools">
          <img src={image} />
          <span>{name}</span>
        </div>
        <div className="apy">
          <span>{apy}%</span>
          <span>
            <FormattedMessage id="stake.staking-data.compounding" />
          </span>
        </div>
        <div className="lock-term">
          <span>{lockTime}</span>
          <span>
            <FormattedMessage id="stake.staking-data.days" />
          </span>
        </div>
        <div className="capacity">
          <Suspense
            fallback={
              <ProgressBar
                label={stakingUtils.getAbbreviatedNumber(maxCapacity)}
                value={0}
              />
            }
          >
            <ProgressWrapper
              maxCapacity={maxCapacity}
              poolAddress={poolAddress}
            />
          </Suspense>
        </div>
        <div className="my-stake">
          <Suspense fallback={<span />}>
            <StakingContentTotalStaked poolAddress={poolAddress} />
          </Suspense>
        </div>
        <div className="my-rewards">
          <Suspense fallback={<span />}>
            <StakingContentTotalRewards poolAddress={poolAddress} />
          </Suspense>
        </div>
        <div className="buttons">
          <button
            onClick={(e) => {
              openDialog({ type: 'stake', poolAddress })
              e.stopPropagation()
            }}
            disabled={userWalletChainId !== '0x38' || disableStake}
          >
            Stake
          </button>
          <span
            className="icon icon-angle_right"
            role="button"
            title="Expand"
          />
        </div>
      </header>
      {entries.map((_, index) => (
        <Suspense fallback={<div />} key={index}>
          <StakingContentRow
            lockTime={lockTime}
            open={openDialog}
            poolAddress={poolAddress}
            positionId={index}
          />
        </Suspense>
      ))}
    </article>
  )
}
