import { AutolayerLogos } from '@static/logos/autolayer'

interface StakingPool {
  name: string
  logo: string
  lockTerm: number
  maxCapacity: number
  apy: number
  address: string
}

const pool1: StakingPool = {
  lockTerm: 45,
  logo: AutolayerLogos.autolayerIconWhite,
  maxCapacity: 300000,
  name: '$LAY3R',
  apy: 35,
  address: '0x99Af1822516a85eF1a9d27ef1F1C0d30676C4f8d'
}

const pool2: StakingPool = {
  lockTerm: 45,
  logo: AutolayerLogos.autolayerIconWhite,
  maxCapacity: 750000,
  name: '$LAY3R',
  apy: 10,
  address: '0xC4EF9eBB5992EAe9756DeA2447D15211cE345121'
}

const pool3: StakingPool = {
  lockTerm: 45,
  logo: AutolayerLogos.autolayerIconWhite,
  maxCapacity: 600000,
  name: '$LAY3R',
  apy: 30,
  address: '0xb16C4dB422594D18e051D06CD75792a433D01610'
}

const pool4: StakingPool = {
  lockTerm: 45,
  logo: AutolayerLogos.autolayerIconWhite,
  maxCapacity: 750000,
  name: '$LAY3R',
  apy: 25,
  address: '0x6f0466636A612Cf3D816fDd3D837A2c6eb2A9DaE'
}

export const binancePools = {
  pool1,
  pool2,
  pool3,
  pool4
}
