import AssetsCard from 'src/components/ProductCard/AssetsCard'
import { TokensInfo } from 'src/hooks/useTokensLst'
import { getNetworkName } from 'src/utils/networkHelper'
import { Network } from 'src/types'

import './AssetsGrid.sass'

interface AssetsGridGridProps {
  tokens: TokensInfo[]
  showNetwork?: boolean
  selectedNetwork?: Network
}

const AssetsGrid = ({
  tokens,
  showNetwork,
  selectedNetwork = 'all networks'
}: AssetsGridGridProps) => {
  const filteredTokens =
    selectedNetwork === 'all networks'
      ? tokens
      : tokens.filter(
          (token) => getNetworkName(token.network) === selectedNetwork
        )

  return (
    <div
      id="assets-grid"
      className={`product-grid ${showNetwork ? 'lrt-grid' : 'lst-grid'}`}
    >
      {(filteredTokens || []).map((t) => (
        <AssetsCard
          key={`${t?.id}${t.network}`}
          token={t}
          showNetwork={showNetwork}
        />
      ))}
    </div>
  )
}

export default AssetsGrid
