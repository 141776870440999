import { WalletData } from 'src/types'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { SmallIcons } from 'src/static/images/iconsSmall'

import './GoToAirdrops.sass'

const GoToAirdrops = () => {
  const wallet = useSelector((s: any) => s.wallet as WalletData)
  const navigate = useNavigate()
  const clickButton = async () => {
    import(/* webpackChunkName: "wallet" */ 'src/utils/wallet')
      .then((m) => m.connect())
      .then(() => navigate('/airdrop'))
  }
  const content = (
    <>
      <img src={SmallIcons.gift} alt="present" />
      <span>
        <FormattedMessage id="airdrop-header.title" />
      </span>
    </>
  )

  if (!wallet) {
    return (
      <button onClick={clickButton} id="go-to-airdrops" className="button">
        {content}
      </button>
    )
  }

  return (
    <Link to="/airdrop" id="go-to-airdrops" className="button">
      {content}
    </Link>
  )
}

export default GoToAirdrops
