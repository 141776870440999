import { useParams } from 'react-router'
import { useGetLpAmountForNft, useGetNftIdsCamelot } from 'src/hooks/useCamelot'
import { NetworkData, networkData } from 'src/data'
import usePromise from 'src/hooks/usePromise'
import CamelotPositionItem from './CamelotPositionItem'
import useCoingecko, { CoingeckoDataSummary } from 'src/hooks/useCoingecko'
import { getLpUSDPriceForNftPosition } from 'src/contracts/Camelot/getCamelotLpValue'
import useNetworkFromRoute from 'src/hooks/useNetworkFromRoute'

import './CamelotPositionsList.sass'
import { CamelotPool } from 'src/types/pools'

interface CamelotPositionListProps {
  thisPool: CamelotPool
  onChange?: ({
    amount,
    outputCoin
  }: {
    outputCoin: string
    amount: number
  }) => void
  previousData?: any
  setSeletedPositionData?: ({
    balance,
    usdValue
  }: {
    balance: number
    usdValue: number
  }) => void
  refreshKey?: number
}

const CamelotPositionsList = ({
  thisPool,
  onChange,
  previousData,
  setSeletedPositionData,
  refreshKey
}: CamelotPositionListProps) => {
  const { productAddress } = useParams<{ productAddress: string }>()
  const { chainId } = useNetworkFromRoute()
  const networkInfo: NetworkData = networkData(chainId)
  const nftPoolsAddress: string = networkInfo.camelotPools.find(
    (pool) => pool.pos.toLowerCase() === productAddress?.toLowerCase()
  )?.nftAddress
  const nftsIds: number[] = useGetNftIdsCamelot(refreshKey, nftPoolsAddress)

  const idsBalances: number[] = useGetLpAmountForNft(
    refreshKey,
    nftPoolsAddress,
    nftsIds
  )
  const completeInfo = nftsIds.map((id, index) => ({
    id,
    balance: idsBalances[index]
  }))
  const coingeckoData: CoingeckoDataSummary[] = useCoingecko()
  const lpPrice: number = usePromise(
    getLpUSDPriceForNftPosition,
    thisPool.pos,
    coingeckoData
  )
  const completeInfoWithUsdValue = completeInfo.map((c) => ({
    ...c,
    usdValue: c.balance * lpPrice
  }))

  const setCoin = (coin: string, balance: number, usdValue: number) => {
    !!onChange && onChange({ ...previousData, outputCoin: coin })
    setSeletedPositionData({ balance, usdValue })
  }
  return (
    <ul id="camelot-position-list">
      {completeInfoWithUsdValue.map((c) => (
        <CamelotPositionItem
          key={c.id}
          camelotPosition={c}
          onClick={setCoin}
          thisPool={thisPool}
        />
      ))}
    </ul>
  )
}

export default CamelotPositionsList
