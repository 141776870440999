import { FormattedMessage } from 'react-intl'
import BalanceButtons from './BalanceButtons/BalanceButtons'

// import { getTokenImage } from "src/utils/getTokenImage"

import './TokenSelector.sass'

const MINIMUN_VALUE: number = 0

interface TokenSelectorProps {
  previousData?: { outputCoin: string; amount: number }
  onChange?: ({
    amount,
    outputCoin
  }: {
    outputCoin: string
    amount: number
  }) => void
  searchMode?: boolean
  setSearchMode?: (value: boolean) => void | null
  blocked?: boolean
  darkMode?: boolean
  setRealWeiAmount?: (value: bigint) => void
}

const NftCamelotSelector = ({
  previousData,
  onChange,
  searchMode = false,
  setSearchMode,
  setRealWeiAmount,
  blocked = true,
  darkMode = false
}: TokenSelectorProps) => {
  const { amount, outputCoin } = previousData

  // For camelot all LP tokens has 18 decimals
  const inputTokenDecimals: string = '18'

  return (
    <div
      className={`token-selector ${blocked ? 'blocked' : ''} ${darkMode ? 'dark-theme' : 'light-theme'}`}
    >
      <section
        className="token-selector-info"
        onClick={() => setSearchMode?.(false)}
      >
        <label className="input-container">
          <h2>
            <FormattedMessage id="token-selector.withdraw" />
          </h2>
          <input
            readOnly={blocked}
            className={`amount-input ${('' + amount).length < 9 ? 'big' : ''}`}
            disabled={amount < MINIMUN_VALUE}
            type="number"
            inputMode="decimal"
            onFocus={() => {
              setSearchMode?.(false)
            }}
            onBlur={() => {
              const factor: number = Math.pow(10, Number(inputTokenDecimals))
              setRealWeiAmount?.(BigInt(amount * factor))
            }}
            onChange={(e) => {
              e.preventDefault()
              const value: string = e.target.value
              const decimals: string = value.split(',')[1]
              if (decimals && decimals.length > 6) return
              onChange({ ...previousData, amount: +e.target.value })
            }}
            placeholder="0"
            value={amount === 0 || !amount ? 0 : amount}
            min={MINIMUN_VALUE}
            step="any"
          />
        </label>
        <h1
          className={
            outputCoin?.length > 4
              ? outputCoin?.length > 6
                ? 'micro'
                : 'mini'
              : ''
          }
          onClick={(e) => {
            e.stopPropagation()
            if (!blocked) {
              setSearchMode?.(!searchMode)
            }
          }}
        >
          <span> Position #{outputCoin}</span>
        </h1>
      </section>
      <BalanceButtons
        disabled={blocked}
        previousData={previousData}
        onChange={onChange}
        setRealWeiAmount={setRealWeiAmount}
        isSwap={true}
      />
    </div>
  )
}

export default NftCamelotSelector
