import { useFetch } from '@gluedigital/ruse-fetch-extras'
import { dataMicroserviceUrl } from 'src/constants'

export interface CoingeckoDataSummary {
  symbol: string
  price_change_percentage_24h: number
  current_price: number
}

//* Returns an array with the percentage change of the price of the tokens in the last 24 hours
//* The values are updated each hour at 00:00

const useCoingecko = (): CoingeckoDataSummary[] => {
  const route = '/prices/variation'
  const pricesVariation: CoingeckoDataSummary[] = useFetch(
    dataMicroserviceUrl + route
  )
  return pricesVariation
}

export default useCoingecko
