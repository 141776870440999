import MarkdownMessage from '@gluedigital/markdown-message'
import { FormattedMessage } from 'react-intl'
import { getItemByNetwork } from 'src/utils/networkHelper'
import { getNetworkParams } from 'src/utils/walletHelpers'
import { useCopy } from 'src/hooks/useCopy'
import { useSelector } from 'react-redux'
import { WalletData } from 'src/types'

import copy from 'src/static/images/settings-menu/copy.svg'
import arbiscan from 'src/static/images/settings-menu/arbiscan.svg'

import './AccountDataWallet.sass'

interface AccountDataProps {
  wallet: WalletData
}

const AccountDataWallet = ({ wallet }: AccountDataProps) => {
  const { showCopied, copyClipBoard } = useCopy()
  const networkId = useSelector((s: any) => s.network)
  const { address } = wallet

  const networkParams = getNetworkParams(networkId)
  const { scanName } = getItemByNetwork(networkId)

  const handleDisconnect = () => {
    import(/* webpackChunkName: "wallet" */ 'src/utils/wallet').then((m) =>
      m.disconnect()
    )
  }

  return (
    <div id="account-actions">
      <button
        className="account-number"
        onClick={(e) => copyClipBoard(e, address)}
      >
        {showCopied ? (
          <span>
            <FormattedMessage id="copied-bubble.text" />
          </span>
        ) : (
          <span className="account-wrapper">
            <img loading="lazy" src={copy} alt="copy symbol" />
            <span>{address.slice(0, 6) + '...' + address.slice(-4)}</span>
          </span>
        )}
      </button>
      <a
        href={`${networkParams.blockExplorerUrls}/address/${address}`}
        target="_blank"
        rel="noopener noreferrer"
        className="button"
      >
        <img loading="lazy" src={arbiscan} alt="moon icon" />
        <MarkdownMessage id="account-data.view" values={{ scan: scanName }} />
      </a>
      <button id="disconnect" onClick={handleDisconnect}>
        <FormattedMessage id="disconnect" />
      </button>
    </div>
  )
}

export default AccountDataWallet
