import { BigNumber } from 'ethers'
import tokenNameToAddress from 'src/utils/tokenNameToAddress'
import getTokenDecimals from './getTokenDecimals'
import { NetworkIds, getNetworkName } from 'src/utils/networkHelper'
import getWeb3 from '../web3'

// Must receive a token address and an amount as a string
export const getEtherNumberAmount = async (
  tokenAddress: string,
  amount: string,
  chainId: NetworkIds
): Promise<string> => {
  if (!amount || !tokenAddress) return '0'
  const networkName = getNetworkName(chainId)
  const web3 = getWeb3(networkName)
  const decimals: string = getTokenDecimals(tokenAddress, chainId)
  const FINAL_DECIMALS = 10 ** 6
  let convertedAmount: any
  if (decimals !== '18') {
    const valueDifference: any = BigNumber.from(10).pow(decimals)
    convertedAmount = BigNumber.from(amount)
      .mul(FINAL_DECIMALS)
      .div(valueDifference)
    convertedAmount = Number(convertedAmount) / FINAL_DECIMALS
    return parseFloat(convertedAmount).toFixed(8)
  }
  const bnAmount: BigNumber = BigNumber.from(amount)
  convertedAmount = web3.utils.fromWei(bnAmount.toString(), 'ether')
  return parseFloat(convertedAmount).toFixed(15)
}

export const numberScientificNotationToString = (amount: number): string => {
  if (Math.abs(amount) < 1.0) {
    const e: number = parseInt(amount.toString().split('e-')[1])
    if (e) {
      let correctAmount: string = String(amount * Math.pow(10, e - 1))
      correctAmount =
        '0.' + new Array(e).join('0') + correctAmount.toString().substring(2)
      return correctAmount
    } else {
      return String(amount)
    }
  } else {
    let e: number = parseInt(amount.toString().split('+')[1])
    if (e > 20) {
      e -= 20
      let correctAmount: string = String(amount / Math.pow(10, e))
      correctAmount += new Array(e + 1).join('0')
      return correctAmount
    }
    return String(amount)
  }
}

export const getWeiNumberAmountDependOnDecimals = async (
  tokenName: string,
  amount: number,
  chainId
): Promise<string> => {
  const tokenAddress: string =
    tokenName.length === 42
      ? tokenName.toLowerCase()
      : tokenNameToAddress(tokenName, chainId)
  const decimals: string = getTokenDecimals(tokenAddress, chainId)
  let convertedAmount: any
  const networkName = getNetworkName(chainId)
  const web3 = getWeb3(networkName)
  if (decimals?.toString() !== '18') {
    const FINAL_DECIMALS = 10 ** Number(decimals) // 6 is the number of decimals that we permit use in the addFunds Modal
    const valueDifference: BigNumber = BigNumber.from(10).pow(decimals)
    let bnAmount: BigNumber
    if (!Number.isInteger(amount)) {
      const amountWithDecimals: string = (amount * FINAL_DECIMALS)?.toFixed(0)
      bnAmount = BigNumber.from(amountWithDecimals)
    } else {
      bnAmount = BigNumber.from(amount)
      bnAmount = bnAmount.mul(FINAL_DECIMALS)
    }
    convertedAmount = BigNumber.from(bnAmount.mul(valueDifference))
    convertedAmount = BigNumber.from(convertedAmount.div(FINAL_DECIMALS))
  } else {
    const stringAmount: string = numberScientificNotationToString(amount)
    convertedAmount = web3.utils.toWei(stringAmount, 'ether')
  }
  return convertedAmount.toString()
}
