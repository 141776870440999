import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import networkChoice from '../networkChoice.json'
import { networkVectors, networkVectorsWhite } from 'src/static/logos/networks'
import { useNetworkName } from 'src/hooks/useNetworkName'
import { NetworkIds, NetworkName } from 'src/utils/networkHelper'
import { changeChain } from 'src/utils/walletHelpers'
import { useSelector } from 'react-redux'

import './NetworkSelectorDialog.sass'

interface NetworkSelectorDialogProps {
  showDialog: boolean
  handleShow: (show: boolean) => void
}

const NetworkSelectorDialog = ({
  handleShow,
  showDialog
}: NetworkSelectorDialogProps) => {
  const networkId = useSelector((s: any) => s.network)

  const networks = Object.entries(networkChoice) as [
    NetworkName,
    { label: string; id: NetworkIds }
  ][]
  const filteredNetworks = networks.filter(
    ([key]) => key !== 'linea' && key !== 'mode' && key !== 'scroll'
  )

  const selectedNetwork = useNetworkName()

  const handleNetwork = async (id: NetworkIds) => {
    try {
      await changeChain(id)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    setTimeout(() => handleShow(false), 300)
  }, [networkId])

  return (
    <dialog className="network-selector-dialog" open={showDialog}>
      <header>
        <h1>
          <FormattedMessage id="network-selector.title" />
        </h1>
        <span className="icon icon-close" onClick={() => handleShow(false)} />
      </header>
      <ul className="language-selector-list">
        {filteredNetworks.map(([key, { id, label }]) => {
          return (
            <li
              key={label}
              className={`network-item ${selectedNetwork === key ? 'selected' : ''}`}
              onClick={() => handleNetwork(id)}
            >
              <img
                loading="lazy"
                src={
                  selectedNetwork === key
                    ? networkVectorsWhite[key]
                    : networkVectors[key]
                }
                alt={`${label} icon`}
              />
              <span className="text">{label}</span>
            </li>
          )
        })}
      </ul>
    </dialog>
  )
}

export default NetworkSelectorDialog
