import { Helmet } from 'react-helmet-async'

interface ProductMetaProps {
  title: string
  description: string
  image: string
  url: string
}

export default function ProductMeta({
  description,
  image,
  title,
  url
}: ProductMetaProps) {
  return (
    <Helmet>
      <title>{title}</title>

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:description" content={description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}
