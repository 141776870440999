import { Suspense } from 'react'
import PointsBox from 'src/components/Points/PointsBox/PointsBox'
import DefaultPointsBox from 'src/components/Points/PointsBox/DefaultPointsBox'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import { Points, ProductIcons } from 'src/types'

interface PointsPortfolioProps {
  useFetchPoints: () => Points
  type: ProductIcons
}

function PointsPortfolio({ type, useFetchPoints }: PointsPortfolioProps) {
  const { points } = useFetchPoints()
  return <PointsBox points={+points} type={type} />
}

export default function PointsPortfolioWrapper({
  type,
  useFetchPoints
}: PointsPortfolioProps) {
  return (
    <ErrorBoundary fallback={<DefaultPointsBox type="error" />}>
      <Suspense fallback={<DefaultPointsBox type={type} />}>
        <PointsPortfolio type={type} useFetchPoints={useFetchPoints} />
      </Suspense>
    </ErrorBoundary>
  )
}
