import useCoingecko, { CoingeckoDataSummary } from './useCoingecko'
import addTokensWeightForPools from 'src/helpers/addTokensWeightForPools'
import { BalancerPool } from 'src/types/pools'
import { PortfolioStrategies } from 'src/types/portfolioTypes'

const useTokensWeightForPools = (pool: BalancerPool): PortfolioStrategies[] => {
  const coingeckoData: CoingeckoDataSummary[] = useCoingecko()
  const poolWithTokenWeights: PortfolioStrategies[] = addTokensWeightForPools(
    [pool],
    coingeckoData
  )
  return poolWithTokenWeights
}

export default useTokensWeightForPools
