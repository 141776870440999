import { useState } from 'react'

import gear from 'src/static/images/connectwallet/gears.svg'
import './Slippage.sass'

interface SlippageProps {
  slippage: number
  setSlippage: (s: number) => void
  blocked: boolean
}
const Slippage = ({ slippage, setSlippage, blocked }: SlippageProps) => {
  const radioOptions = ['1', '2']
  const [open, setOpen] = useState(false)

  const handleChange = (e) => {
    const value = +e.target.value * 100
    setSlippage(value <= 500 ? value : 500)
  }

  return (
    <>
      <div id="slippage" className={blocked ? 'blocked' : ''}>
        <section onClick={() => setOpen(!open)}>
          <span>Slippage </span>
          <strong>{slippage / 100}%</strong>
          <img loading="lazy" src={gear} alt="gear icon" />
        </section>
        <div className={`${open ? 'open' : ''} slippage-bubble`}>
          <strong>Slippage Tolerance</strong>
          <section className="row">
            {radioOptions.map((option) => (
              <label key={option}>
                <input
                  type="radio"
                  name="slippageOption"
                  value={option}
                  checked={slippage === +option * 100}
                  onChange={handleChange}
                />
                <span>{option} %</span>
              </label>
            ))}
            <label>
              <input
                type="number"
                name="customValue"
                value={
                  slippage && slippage > 0 && slippage <= 500
                    ? slippage / 100
                    : ''
                }
                min="1"
                max="50"
                step={0.1}
                onChange={handleChange}
              />
              %
            </label>
          </section>
        </div>
      </div>
      {open && <div className="veil" onClick={() => setOpen(false)} />}
    </>
  )
}

export default Slippage
