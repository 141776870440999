import { ExecutionSteps, FailExecutionSteps } from 'src/types/DepositTypes'
import tokenNameToAddress from 'src/utils/tokenNameToAddress'
import { OptimalRate, TransactionParams } from '@paraswap/sdk'
import approveToken from 'src/contracts/approveToken'
import { getAutolayerPointsAfterTx } from 'src/contracts/getAutoLayerPoints'
import useEnoughBalance from './useEnoughBalance'
import { useCommonDeposit } from './useCommonDeposit'
import type { DepositData } from 'src/types'
import getParaswapRoute from 'src/contracts/Paraswap/getParaswapRoute'
import buildParaswapTx from 'src/contracts/Paraswap/buildParaswapTx'
import useNetworkFromRoute from './useNetworkFromRoute'
import { getNativeCurrencySymbol } from 'src/utils/networkHelper'

interface useDepositArgs {
  refreshAutoLayerPoints?: () => void
}

const useDeposit = ({ refreshAutoLayerPoints }: useDepositArgs) => {
  const { networkName: network } = useNetworkFromRoute()
  const initialTokenSymbol: string = network === 'binance' ? 'BNB' : 'ETH'
  const {
    data: depositData,
    executionStep,
    failExecutionStep,
    realWeiAmount,
    searchMode,
    setData: setDepositData,
    setExecutionStep,
    setFailExecutionStep,
    setRealWeiAmount,
    setSearchMode,
    setTxHash,
    setTxPoints,
    txHash,
    txPoints,
    wallet,
    isConnected,
    chainId,
    setSlippage,
    slippage
  } = useCommonDeposit<DepositData, ExecutionSteps, FailExecutionSteps>({
    outputCoin: initialTokenSymbol,
    amount: 0
  })

  const hasEnoughBalance: boolean = useEnoughBalance(
    realWeiAmount,
    depositData?.outputCoin
  )

  const retrieveTokenInAdress = () => {
    const tokenIn: string = depositData.outputCoin
    const tokenInAddress: string =
      tokenIn.length === 42
        ? tokenIn.toLowerCase()
        : tokenNameToAddress(tokenIn, chainId)

    return tokenInAddress
  }

  const changeExecutionStep = (executionStep: ExecutionSteps) =>
    setExecutionStep(executionStep)

  const retrieveTxParams = async (
    tokenInAddress: string,
    output: string,
    amount: string,
    slippage: number
  ): Promise<{ txParams: TransactionParams; priceRoute: OptimalRate }> => {
    const priceRoute: OptimalRate = await getParaswapRoute(
      tokenInAddress,
      output,
      amount,
      chainId
    )
    const txParams: TransactionParams = await buildParaswapTx(
      priceRoute,
      tokenInAddress,
      output,
      amount,
      chainId,
      slippage
    )
    return { txParams, priceRoute }
  }

  const handleRetrieveTxParamsError = (error: Error) => {
    console.error('Error getting swap info', error.message)
    setFailExecutionStep(FailExecutionSteps.failGotRoute)
  }

  const tokenApprove = async (tokenInAddress: string): Promise<void> => {
    // Only approve in case of ERC20 tokens
    const nativeCrypto: string = getNativeCurrencySymbol(chainId)
    if (tokenInAddress === tokenNameToAddress(nativeCrypto, chainId)) return
    await approveToken(tokenInAddress, realWeiAmount, wallet?.address, chainId)
  }

  const handleApproveError = (error: Error) => {
    console.error('Error approving token', error.message)
    setFailExecutionStep(FailExecutionSteps.failApprovedToken)
  }

  const finishTransaction = (sendSwapTx: any) => {
    const { points, transactionHash } = getAutolayerPointsAfterTx(
      sendSwapTx,
      wallet?.address
    )
    setTxHash(transactionHash)
    setTxPoints(points)
    refreshAutoLayerPoints()
  }

  const handleSendError = (error: any) => {
    console.error('Error sending swap transaction', error.message)
    setFailExecutionStep(FailExecutionSteps.failSentToken)
  }

  const handleTokenChange = (data: DepositData) => {
    if (executionStep || failExecutionStep) {
      setExecutionStep(undefined)
      setFailExecutionStep(undefined)
    }
    setDepositData(data)
  }

  return {
    searchMode,
    executionStep,
    failExecutionStep,
    txHash,
    txPoints,
    isConnected,
    hasEnoughBalance,
    depositData,
    realWeiAmount,
    wallet,
    chainId,
    setDepositData,
    setRealWeiAmount,
    handleTokenChange,
    retrieveTokenInAdress,
    retrieveTxParams,
    handleRetrieveTxParamsError,
    handleSendError,
    finishTransaction,
    handleApproveError,
    tokenApprove,
    setSearchMode,
    changeExecutionStep,
    slippage,
    setSlippage
  }
}

export default useDeposit
