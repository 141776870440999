import web3 from '@utils/web3'

import { aubnbManager } from '../abis/AutoLayerAbis/AuBnbManagerAbi'
import { aubnbClaimRewardsAbi } from '../abis/AutoLayerAbis/AuBnbClaimRewardsAbi'
import { BinanceData } from 'src/data/Binance'
import { AubnbTokenAbi } from '../abis/AutoLayerAbis/AubnbTokenAbi'

const AUBNB_MANAGER_ADDRESS = '0xdf9b36286f87b7f237764e5140289e8608776a2b'
const AUBNB_CLAIM_REWARDS_ADDRESS = '0x5a1edcf742118348d36d978750020a46b30eaa38'
const AUBNB_TOKEN = BinanceData.auBnb.address

export const approveAuBnb = async (
  userAddress: string,
  amount: bigint
): Promise<string> => {
  const aubnbContract = new web3.eth.Contract(AubnbTokenAbi, AUBNB_TOKEN)
  const tx = await aubnbContract.methods
    .approve(AUBNB_MANAGER_ADDRESS, amount)
    .send({ from: userAddress })
  const txHash = tx.transactionHash
  return txHash
}
export const getAubnbRatio = async (): Promise<{
  ratio: bigint
  ratioEth: number
}> => {
  const aubnbContract = new web3.eth.Contract(
    aubnbManager,
    AUBNB_MANAGER_ADDRESS
  )
  const ratio: bigint = await aubnbContract.methods.getRate().call()
  const ratioEth = parseFloat(ratio.toString()) / 10 ** 18
  return { ratioEth, ratio }
}

// amountMin and amountBnb are in wei
export const depositBnbToAuBnb = async (
  userAddress: string,
  amountMin: bigint,
  amountBnb: bigint
): Promise<string> => {
  const deadline = Math.floor(Date.now() / 1000) + 60 * 20
  const aubnbContract = new web3.eth.Contract(
    aubnbManager,
    AUBNB_MANAGER_ADDRESS
  )
  console.log('depositParams', amountBnb, deadline)
  const estimatedGas = await aubnbContract.methods
    .deposit(amountBnb, deadline)
    .estimateGas({
      from: userAddress,
      value: amountBnb.toString()
    })
  const tx = await aubnbContract.methods
    .deposit(amountBnb, deadline)
    .send({ from: userAddress, value: amountBnb.toString() })
  const txHash = tx.transactionHash
  return txHash
}

export const withdrawAuBnbToBnb = async (
  userAddress: string,
  amountMin: bigint,
  amountAubnb: bigint
): Promise<string> => {
  const deadline = Math.floor(Date.now() / 1000) + 60 * 20
  const aubnbContract = new web3.eth.Contract(
    aubnbManager,
    AUBNB_MANAGER_ADDRESS
  )
  console.log('withdrawParams', amountAubnb, amountMin, deadline)
  const tx = await aubnbContract.methods
    .withdraw(amountAubnb, amountMin, deadline)
    .send({ from: userAddress })
  const txHash = tx.transactionHash
  return txHash
}

export const claimAubnbRewards = async (
  signature: string,
  userAddress: string,
  amountWei: string,
  blockNumber: number
): Promise<string> => {
  const aubnbContract = new web3.eth.Contract(
    aubnbClaimRewardsAbi,
    AUBNB_CLAIM_REWARDS_ADDRESS
  )
  const estimatedGas = await aubnbContract.methods
    .claimRewards(signature, amountWei, blockNumber)
    .estimateGas({ from: userAddress })
  console.log({ estimatedGas })
  const tx = await aubnbContract.methods
    .claimRewards(signature, amountWei, blockNumber)
    .send({ from: userAddress })
  const txHash = tx.transactionHash
  return txHash
}

export const getUserClaimAubnbRewardsInfo = async (
  userAddress: string
): Promise<{
  rewardsAmountClaimed: bigint
  lastBlockNumberClaimed: bigint
}> => {
  const contract = new web3.eth.Contract(
    aubnbClaimRewardsAbi,
    AUBNB_CLAIM_REWARDS_ADDRESS
  )
  const userInfo = await contract.methods.usersInfo(userAddress).call()
  const rewardsAmountClaimed: bigint = userInfo[0]
  const lastBlockNumberClaimed: bigint = userInfo[1]
  return { rewardsAmountClaimed, lastBlockNumberClaimed }
}
