import { Suspense, lazy } from 'react'
import { RiskChartProps } from './RiskChart'
import Loading from 'src/components/Loading/Loading'

const RiskChart = lazy(
  () => import(/* webpackChunkName: "risk-chart" */ './RiskChart')
)

export default function RiskChartWrapper(props: RiskChartProps) {
  return (
    <Suspense fallback={<Loading colored />}>
      <RiskChart {...props} />
    </Suspense>
  )
}
