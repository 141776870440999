import { useState, ReactNode } from 'react'

import './Tooltip.sass'

interface TooltipProps {
  children: ReactNode
  buttonComponent?: ReactNode
}

const Tooltip = ({ children, buttonComponent }: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className={`tooltip-container ${isOpen ? 'tooltip-open' : ''}`}>
        <div onClick={() => setIsOpen(!isOpen)}>
          {buttonComponent || <button className="tooltip-button">+</button>}
        </div>
        {isOpen && <div className="tooltip">{children}</div>}
      </div>
      <div className="veil" onClick={() => setIsOpen(false)} />
    </>
  )
}

export default Tooltip
