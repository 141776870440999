import { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import ExternalHeader from 'src/components/ExternalHeader/ExternalHeader'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import RewardsGrid from './RewardsGrid/RewardsGrid'
import Footer from 'src/components/Footer/Footer'
import DefaultRewardsGrid from './RewardsGrid/DefaultRewardsGrid'
import AirdropMeta from 'src/components/Meta/AirdropMeta'

import './Airdrop.sass'

const Airdrop = () => {
  return (
    <>
      <AirdropMeta />
      <div id="airdrop" className="page">
        <ExternalHeader name="airdrop" />
        <div className="container">
          <h1>
            <FormattedMessage id="airdrop.title" />
          </h1>
          <p className="mono-space">
            <FormattedMessage id="airdrop.text" />
          </p>
          <ErrorBoundary fallback={'error'}>
            <Suspense fallback={<DefaultRewardsGrid />}>
              <RewardsGrid />
            </Suspense>
          </ErrorBoundary>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Airdrop
