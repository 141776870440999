import { ChangeEvent } from 'react'
import type { ChartTimeSpan } from 'src/types'

import './ChartTimeSelector.sass'

interface ChartTimeSelectorProps {
  time: ChartTimeSpan
  handleTime: (time: ChartTimeSpan) => void
}

const timeSpanArray: ChartTimeSpan[] = ['1d', '7d', '1m', '1y', 'all']

const checkTimeSpan = (time: string): time is ChartTimeSpan =>
  timeSpanArray.some((item) => item === time)

const ChartTimeSelector = ({ handleTime, time }: ChartTimeSelectorProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const time = event.target.value
    const isCorrect = checkTimeSpan(time)
    handleTime(isCorrect ? time : '1d')
  }

  return (
    <div id="chart-time-selector">
      {timeSpanArray.map((timeSpan) => (
        <label key={timeSpan}>
          <input
            type="radio"
            value={timeSpan}
            checked={time === timeSpan}
            className="time-pnl-radio"
            onChange={handleChange}
          />
          <span>{timeSpan}</span>
        </label>
      ))}
    </div>
  )
}

export default ChartTimeSelector
