import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import SettingsMenu from 'src/components/SettingsMenu/SettingsMenu'
import ShareLstOptions from 'src/components/ShareDialog/Options/ShareLstOptions/ShareLstOptions'
import NonModalLayout from 'src/components/NonModalLayout/NonModalLayout'
import { TokensInfo } from 'src/hooks/useTokensLst'
import { SmallIcons } from 'src/static/images/iconsSmall'
import { Pools } from 'src/types/pools'

import './HeaderProductInfo.sass'

interface HeaderProductInfoProps {
  productInfo: TokensInfo | Pools
}

const HeaderProductInfo = ({ productInfo }: HeaderProductInfoProps) => {
  const assetType =
    productInfo?.type.toLocaleLowerCase() === 'lrt' ||
    productInfo?.type.toLocaleLowerCase() === 'lst'
  const backUrl = assetType ? productInfo?.type : 'defi-strategies'
  const name = productInfo.name
  return (
    <header id="masthead-product-info">
      <div className="container">
        <Link to={`/${backUrl?.toLowerCase()}`} className="button">
          <span className="icon icon-angle_left" />
          <span className="back-text">
            <FormattedMessage id="back-to-dashboard" />
          </span>
        </Link>
        <h1 title={name}>
          <span>{name}</span>
        </h1>
        <div className="right-buttons">
          <NonModalLayout image={{ src: SmallIcons.share, alt: 'share icon' }}>
            <ShareLstOptions productInfo={productInfo} />
          </NonModalLayout>
          <SettingsMenu />
        </div>
      </div>
    </header>
  )
}

export default HeaderProductInfo
