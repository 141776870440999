import { getTokenBalanceList } from 'src/contracts/getBalance/getBalanceList'
import usePromise from './usePromise'
import { useSelector } from 'react-redux'
import { WalletData } from 'src/types'
import useNetworkFromRoute from './useNetworkFromRoute'

export function useTokenBalanceList<T>(tokens: any) {
  const wallet = useSelector((s: any) => s.wallet as WalletData)
  const address = wallet?.address ? wallet.address : null
  const { chainId } = useNetworkFromRoute()
  const result: T[] = usePromise(getTokenBalanceList, tokens, address, chainId)

  return result
}
