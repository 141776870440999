import { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import CompleteAprChart from 'src/components/Charts/AprChart/CompleteAprChart/CompleteAprChart'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import { protocolsIcons } from 'src/static/logos/protocols'
import InfoBox from './InfoBox/InfoBox'
import Loading from 'src/components/Loading/Loading'
import PoolTokenWrapper from './PoolTokenTable/PoolTokenTable'
import TokensWeight from './TokensWeight'
import ListMultiplier from 'src/components/MultiplierInfo/ListMultiplier/ListMultiplier'
import { Pools } from 'src/types/pools'

import './MiddleProductInfo.sass'

interface MiddleDefiInfoProps {
  productInfo: Pools
}

const MiddleDefiInfo = ({ productInfo }: MiddleDefiInfoProps) => {
  const { address, name, apy, provider, liquidity, volume, multipliers } =
    productInfo
  const tvl = +liquidity

  return (
    <section id="defi-middle-section" className="middle-section">
      <section className="top-info">
        <span className={`badge ${provider?.toLowerCase()} provider`}>
          <img
            loading="lazy"
            src={protocolsIcons?.[provider.toLowerCase()]}
            alt={provider}
            className="provider-image"
          />
          {provider}
        </span>
      </section>
      <section>
        <h1 title={name}>{name}</h1>
        <ErrorBoundary fallback={<div />}>
          <Suspense fallback={<Loading mini colored />}>
            <TokensWeight productInfo={productInfo} />
          </Suspense>
        </ErrorBoundary>
        <div className="info-cards">
          <InfoBox type="apr" amount={+apy} />
          <InfoBox type="volume" amount={+volume} />
          <InfoBox type="tvl" amount={tvl} />
        </div>
      </section>
      {!!productInfo?.description && (
        <section>
          <p>{productInfo?.description}</p>
        </section>
      )}
      <section className="multipliers-info">
        <h2>
          <FormattedMessage id="middle-section.multipliers" />
        </h2>
        <ListMultiplier multipliers={multipliers} />
      </section>
      <ErrorBoundary fallback={<div />}>
        <CompleteAprChart productName={address} />
      </ErrorBoundary>
      <section className="pool-info">
        <h2>
          <FormattedMessage id="middle-section.pool-composition" />
        </h2>
        <ErrorBoundary fallback={<div />}>
          <Suspense fallback={<Loading colored />}>
            <PoolTokenWrapper productInfo={productInfo} />
          </Suspense>
        </ErrorBoundary>
      </section>
    </section>
  )
}

export default MiddleDefiInfo
