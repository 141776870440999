import { SmallIcons } from '@static/images/iconsSmall'
import { getNetworkParams } from '@utils/walletHelpers'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { getItemByNetwork } from '@utils/networkHelper'
import Spinner from '@components/Loading/Spinner/Spinner'
import { FC, ReactNode } from 'react'

import './StakeDialogSteps.sass'

export type StakeDialogReducedStatus = 'error' | 'loading' | 'success' | 'done'

export interface StakeDialogStep {
  type: StakeDialogReducedStatus
  text: string
  extra?: { [key: string]: any }
}

interface StepsProps {
  text: string
  txHash: string
  [key: string]: any
}

const DoneStatus: FC<StepsProps> = ({ text, txHash }: StepsProps) => {
  const networkParams = getNetworkParams('0x38')
  const networkScan = networkParams.blockExplorerUrls[0]
  const scanLink: string = networkScan + 'tx/' + txHash
  const { scanName } = getItemByNetwork('0x38')
  return (
    <div className="done">
      <a href={scanLink} target="_blank" className="ftm-link" rel="noreferrer">
        <img src={SmallIcons.linkGrey} alt="link icon" />
        <span>
          <MarkdownMessage
            id="deposit-step-scan.link"
            values={{ scan: scanName }}
          />
        </span>
      </a>
      <p>
        <FormattedMessage id="stake.staking-dialog.steps.done-staking" />
      </p>
    </div>
  )
}

const SuccessStatus: FC<StepsProps> = ({ text }) => {
  return (
    <div className="success">
      <div className="circle success" />
      <span>
        <FormattedMessage id={`${text}.success`} />
      </span>
    </div>
  )
}

const ErrorStatus: FC<StepsProps> = ({ text }) => {
  return (
    <div className="error">
      <div className="circle failed" />
      <span>
        <FormattedMessage id={`${text}.error`} />
      </span>
    </div>
  )
}

const LoadingStatus: FC<StepsProps> = ({ text }) => {
  return (
    <div className="loading">
      <Spinner />
      <span>
        <FormattedMessage id={`${text}.loading`} />
      </span>
    </div>
  )
}

function StakeDialogSteps({
  children,
  steps
}: {
  children: ReactNode
  steps: StakeDialogStep[]
}) {
  const statusRecord: Record<StakeDialogReducedStatus, FC<StepsProps>> = {
    success: SuccessStatus,
    error: ErrorStatus,
    loading: LoadingStatus,
    done: DoneStatus
  }

  if (!steps.length) {
    return children
  }

  return (
    <section className="stake-dialog-steps">
      {steps.map((step, index) => {
        const Component = statusRecord[step.type]

        return (
          <Component
            text={step.text}
            key={index}
            txHash={step.extra?.['txHash']}
          />
        )
      })}
    </section>
  )
}

export default StakeDialogSteps
