import { useParams } from 'react-router-dom'
import usePromise from 'src/hooks/usePromise'
import { getSingleTokenBalance } from 'src/contracts/getBalance/getSingleTokenBalance'
import camelotUtils from 'src/utils/camelot'
import ProductLeftUsdValue from './LeftUsdValues/ProductLeftUsdValue'
import CamelotLeftUsdValue from './LeftUsdValues/CamelotLeftUsdValue'
import DefiLeftUsdValue from './LeftUsdValues/DefiLeftUsdValue'
import { NetworkIds } from 'src/utils/networkHelper'
import useNetworkFromRoute from 'src/hooks/useNetworkFromRoute'
import { useWallet } from 'src/hooks/useWallet'

interface DefiAndProductsPointsProps {
  productName: string
  productAddress: string
  refreshKey: number
}

const DefiAndProductsLeftUsdValueWrapper = ({
  productName,
  productAddress,
  refreshKey
}: DefiAndProductsPointsProps) => {
  const nameUsed: string = productName ?? productAddress
  const wallet = useWallet()
  const { chainId } = useNetworkFromRoute()
  const productEthBalance: string = usePromise(
    getSingleTokenBalance as (
      s: string,
      address: string,
      chaindId: NetworkIds,
      maxButton: boolean,
      key: number
    ) => Promise<string>,
    nameUsed,
    wallet,
    chainId,
    false,
    refreshKey
  )

  if (productName) {
    return (
      <ProductLeftUsdValue ethBalance={productEthBalance} name={productName} />
    )
  }

  return (
    <DefiLeftUsdValue ethBalance={productEthBalance} address={productAddress} />
  )
}

const ActualValueLeftPoint = ({ refreshKey }: { refreshKey: number }) => {
  const { productName, productAddress } = useParams<{
    productName: string
    productAddress: string
  }>()

  const isCamelot = camelotUtils.isCamelot(productAddress)

  if (productName || !isCamelot) {
    return (
      <DefiAndProductsLeftUsdValueWrapper
        productAddress={productAddress}
        productName={productName}
        refreshKey={refreshKey}
      />
    )
  }

  return <CamelotLeftUsdValue pos={productAddress} refreshKey={refreshKey} />
}

export default ActualValueLeftPoint
