import { FormattedMessage } from 'react-intl'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

import cube from 'src/static/images/banner-info/cubes.png'
import cubeMobile from 'src/static/images/banner-info/cubes-mobile.png'
import './BannerInfo.sass'

const BannerInfo = () => {
  const { width } = useWindowDimensions()
  const isMobile: boolean = width < 460
  return (
    <div id="banner-info">
      <div className="split">
        <div className="left item">
          <h1>
            <FormattedMessage id="banner-info.title" />
          </h1>
        </div>
        <div className="right item">
          <img src={isMobile ? cubeMobile : cube} alt="" />
        </div>
      </div>
      <div className="badge">autolayer.io</div>
    </div>
  )
}

export default BannerInfo
