import { walletReducer } from './reducers/wallet'
import { networkReducer } from './reducers/network'

export const reducerList = {
  // Add your reducers here
  wallet: walletReducer,
  network: networkReducer
}

export default reducerList
