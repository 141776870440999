import {
  FC,
  forwardRef,
  Suspense,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import AuBnbDialogStake from './Types/AuBnbDialogStake/AuBnbDialogStake'
import AuBnbDialogUnstake from './Types/AuBnbDialogUntstake/AuBnbDialogUnstake'
import stakeUtils from '@utils/stake'
import { useDialogSteps } from '@hooks/useDialogSteps'
import Spinner from '@components/Loading/Spinner/Spinner'
import AuBnbDialogClaim from './Types/AuBnbDialogClaim/AuBnbDialogClaim'

import './AuBnbDialog.sass'
import '@components/TokenSelector/BalanceButtons/BalanceButtons.sass'

export type AubnbDialogTypes = 'stake' | 'unstake' | 'claim'

export interface AuBnbDialogMethods {
  open: (type: AubnbDialogTypes) => void
  close: () => void
}

type StepsReturn = ReturnType<typeof useDialogSteps>

export type AuBnbDialogProps = {
  updateRefreshKey: () => void
  refreshKey?: number
  stepsData: StepsReturn
}

const typesRecord: Record<AubnbDialogTypes, FC<AuBnbDialogProps>> = {
  stake: AuBnbDialogStake,
  unstake: AuBnbDialogUnstake,
  claim: AuBnbDialogClaim
}

interface StakeDialogProps {
  updateRefreshKey: () => void
  refreshKey?: number
}

const AuBnbDialog = forwardRef<AuBnbDialogMethods, StakeDialogProps>(
  ({ updateRefreshKey, refreshKey }, ref) => {
    const dialogRef = useRef<HTMLDialogElement>()
    const [data, setData] = useState<AubnbDialogTypes>('stake')

    const steps = useDialogSteps()

    const Component = typesRecord[data]

    const close = () => {
      dialogRef.current?.close()
      steps.resetSteps()
    }

    useImperativeHandle(ref, () => ({
      open: (data: AubnbDialogTypes) => {
        setData(data)
        dialogRef.current?.showModal()
      },
      close
    }))

    return (
      <dialog id="aubnb-dialog" ref={dialogRef}>
        <header>
          <h3>{stakeUtils.chooseDialogLabel(data)}</h3>
          <button title="Close dialog" onClick={close} autoFocus>
            <span className="icon icon-close" />
          </button>
        </header>
        <Suspense
          fallback={
            <div className="spinner-fallback">
              <Spinner />
            </div>
          }
        >
          <Component
            updateRefreshKey={updateRefreshKey}
            stepsData={steps}
            refreshKey={refreshKey}
          />
        </Suspense>
      </dialog>
    )
  }
)

AuBnbDialog.displayName = 'AuBnb Dialog'

export default AuBnbDialog
