import { useFetch } from '@gluedigital/ruse-fetch-extras'
import { airdropUrl } from 'src/constants'
import { AirdropTrackerInfo } from 'src/types'

const useAirdrops = (): AirdropTrackerInfo[] => {
  const airdrops: AirdropTrackerInfo[] = useFetch(airdropUrl)
  return airdrops || []
}

export default useAirdrops
