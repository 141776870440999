import { FormattedMessage } from 'react-intl'
import CamelotSelectedPositionData from '../Swap/CamelotSelectedPositionData/CamelotSelectedPositionData'

interface ContinueButtonProps {
  disabled: boolean
  darkMode?: boolean
  handleDeposit: () => Promise<void>
  selectedPositionData?: { balance: number; usdValue: number }
  bptName?: string
}

export default function ContinueButton({
  disabled,
  handleDeposit,
  darkMode,
  selectedPositionData,
  bptName
}: ContinueButtonProps) {
  return (
    <>
      {bptName &&
        selectedPositionData?.balance > 0 &&
        selectedPositionData?.usdValue > 0 && (
          <CamelotSelectedPositionData
            bptName={bptName}
            selectedPositionData={selectedPositionData}
          />
        )}
      <button
        className={
          !darkMode
            ? 'continue-deposit light-theme'
            : 'continue-deposit dark-theme'
        }
        disabled={disabled}
        onClick={handleDeposit}
      >
        <FormattedMessage id="continue" />
      </button>
    </>
  )
}
