const nativeCurrencys = {
  arbitrum: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18
  },
  bnb: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18
  }
}

export const ETHEREUM = {
  chainId: '0x1',
  chainName: 'Ethereum Mainnet',
  nativeCurrency: nativeCurrencys.arbitrum,
  rpcUrls: ['https://rpc.mevblocker.io'],
  blockExplorerUrls: ['https://etherscan.io/'],
  tokens: []
}

export const ARBITRUM = {
  chainId: '0xa4b1',
  chainName: 'Arbitrum One',
  nativeCurrency: nativeCurrencys.arbitrum,
  rpcUrls: ['https://arb1.arbitrum.io/rpc'],
  blockExplorerUrls: ['https://arbiscan.io/'],
  tokens: []
}

export const BINANCE = {
  chainId: '0x38',
  chainName: 'BNB Smart chain',
  nativeCurrency: nativeCurrencys.bnb,
  rpcUrls: ['https://bsc-dataseed.bnbchain.org'],
  blockExplorerUrls: ['https://bscscan.com/'],
  tokens: []
}

export const LINEA = {
  chainId: '0xe708',
  chainName: 'Linea',
  nativeCurrency: nativeCurrencys.arbitrum,
  rpcUrls: ['https://1rpc.io/linea'],
  blockExplorerUrls: ['https://lineascan.build/'],
  tokens: []
}

export const BASE = {
  chainId: '0x2105',
  chainName: 'Base',
  nativeCurrency: nativeCurrencys.arbitrum,
  rpcUrls: ['https://base-rpc.publicnode.com'],
  blockExplorerUrls: ['https://basescan.org/'],
  tokens: []
}

export const SCROLL = {
  chainId: '0x82750',
  chainName: 'Scroll',
  nativeCurrency: nativeCurrencys.arbitrum,
  rpcUrls: ['https://scroll.drpc.org'],
  blockExplorerUrls: ['https://scrollscan.com/'],
  tokens: []
}

export const MODE = {
  chainId: '0x868b',
  chainName: 'Mode',
  nativeCurrency: nativeCurrencys.arbitrum,
  rpcUrls: ['https://1rpc.io/mode'],
  blockExplorerUrls: ['https://modescan.com/'],
  tokens: []
}

export const OPTIMISM = {
  chainId: '0xa',
  chainName: 'Optimism',
  nativeCurrency: nativeCurrencys.arbitrum,
  rpcUrls: ['https://mainnet.optimism.io'],
  blockExplorerUrls: ['https://optimistic.etherscan.io/'],
  tokens: []
}

export const IS_DEV: boolean = process.env.IS_DEVELOP === 'true'

export const dataMicroserviceUrl: string =
  process.env.API_URL || 'https://data.autolayer.io'

export const airdropUrl: string = `${dataMicroserviceUrl}/airdrop-tracker`

export const ENV_ENVIROMENT = process.env.ENVIRONMENT

export const mainnetArbitrumCandidate = { ...ARBITRUM }

export const mainnetArbitrumPro = { ...ARBITRUM }
