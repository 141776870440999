import { GENERIC_ABIS } from '../abis'
import { CAMELOT_POOLS_INFO } from '../addresses'
import getWeb3 from '../web3'

export interface CamelotData {
  nftContractAddress: string
  poolAddress: string
  totalSupply: string
  userLPs: string
  totalAmount: string[]
  token0: string
  token1: string
}

interface CamelotDataResponse {
  nftContract: string
  poolAddress: string
  totalSupply: string
  userLPs: string
  totalAmounts: {
    total0: string
    total1: string
  }
}

const poolsTokens = [
  {
    token0: '0x4186BFC76E2E237523CBC30FD220FE055156b41F',
    token1: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'
  },
  {
    token0: '0x2416092f143378750bb29b79eD961ab195CcEea5',
    token1: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'
  }
]
const getFullCamelotData = async (user: string): Promise<CamelotData[]> => {
  if (!user) return
  const web3 = getWeb3('arbitrum')
  const contract = new web3.eth.Contract(
    GENERIC_ABIS.CamelotPoolsInfoAbi as any[],
    CAMELOT_POOLS_INFO
  )
  const data: CamelotDataResponse[] = await contract.methods
    .getCamelotPoolsData(user)
    .call()
  const formatedData: CamelotData[] = data.map((d, i) => ({
    nftContractAddress: d.nftContract,
    poolAddress: d.poolAddress,
    totalSupply: d.totalSupply,
    userLPs: d.userLPs,
    totalAmount: [d.totalAmounts.total0, d.totalAmounts.total1],
    token0: poolsTokens[i].token0,
    token1: poolsTokens[i].token1
  }))
  return formatedData
}

export default getFullCamelotData
