import { Helmet } from 'react-helmet-async'

export default function StakeMeta() {
  const image = 'https://i.imgur.com/zfu24im.png'

  return (
    <Helmet>
      <meta property="og:url" content="https://app.autolayer.io/stake/" />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}
