import RiskBox from './RiskBox/RiskBox'
import { RiskType } from 'src/types/RiskTypes'
import { useParams } from 'react-router'
import useRiskForTokens from 'src/hooks/useRiskForTokens'
import riskUtils from 'src/utils/risk'

import './RiskSection.sass'

const RiskSection = () => {
  const { productName } = useParams<{ productName: string }>()
  const risk = useRiskForTokens()
  const data = risk.find((r) => r.id === productName)

  const liquidityWithProtocolField = riskUtils.parseToInfoRisk(
    data?.liquidityInDexes || []
  )

  const liquidityGrouped = riskUtils.groupItems(liquidityWithProtocolField)

  const colateralGrouped = riskUtils.groupItems(data?.tvlAsCollateral || [])

  return (
    <section id="risk-section">
      <h2>
        <span>Risk Factor</span>
      </h2>
      <div className="risk-section-grid">
        <RiskBox riskType={RiskType.Collateral} info={colateralGrouped} />
        <RiskBox riskType={RiskType.Dexes} info={liquidityGrouped} />
      </div>
    </section>
  )
}

export default RiskSection
