import getWeb3 from '../web3'

const decodedWithdrawalAmount = (
  tx: any
): { token0Amount: string; token1Amount: string } => {
  try {
    const withdrawTopic =
      '0xebff2602b3f468259e1e99f613fed6691f3a6526effe6ef3e768ba7ae7a36c4f'
    const logs = tx.logs
    const withdrawLog = logs.find((log: any) => log.topics[0] === withdrawTopic)
    const data = withdrawLog.data
    const web3 = getWeb3('arbitrum')
    const decodedData = web3.eth.abi.decodeParameters(
      [
        {
          type: 'uint256',
          name: 'shares'
        },
        {
          type: 'uint256',
          name: 'amount0'
        },
        {
          type: 'uint256',
          name: 'amount1'
        }
      ],
      data
    ) as unknown as { amount0: string; amount1: string }
    const token0Amount: string = web3.utils.fromWei(
      decodedData.amount0,
      'ether'
    )
    const token1Amount: string = web3.utils.fromWei(
      decodedData.amount1,
      'ether'
    )
    return { token0Amount, token1Amount }
  } catch (error) {
    console.error('Error decoding withdrawal amount', error)
    return { token0Amount: undefined, token1Amount: undefined }
  }
}

export default decodedWithdrawalAmount
