import { Suspense, useState } from 'react'
import { useParams, useLocation, Navigate } from 'react-router-dom'
import ProductMeta from 'src/components/Meta/ProductMeta'
import textUtils from 'src/utils/text'
import Footer from 'src/components/Footer/Footer'
import HeaderProductInfo from './Header/HeaderProductInfo'
import BannerInfo from './BannerInfo/BannerInfo'
import { ArbitrumData } from 'src/data/Arbitrum'
import LeftDefiInfo from './LeftInfo/LeftDefiInfo'
import MiddleDefiInfo from './MiddleInfo/MiddleDefiInfo'
import { useSingleCamelotPool, useSinglePool } from 'src/hooks/usePools'
import ProductInfoButtons from './ProductInfoButtons/ProductInfoButtons'
import DepositDialog from 'src/components/DepositDialog/DepositDialog'
import SwapDialog from 'src/components/SwapDialog/SwapDialog'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import Loading from 'src/components/Loading/Loading'

declare const __CLIENT__: boolean

export const DefiInfo = () => {
  const { productAddress } = useParams<{ productAddress: string }>()
  const [isSwapDialogOpen, setIsSwapDialogOpen] = useState<boolean>(false)
  const [isDepositDialogOpen, setIsDepositDialogOpen] = useState<boolean>(false)
  const [refreshKey, setRefreshKey] = useState<number>(0)

  const defiBalancerInfo = useSinglePool(productAddress)
  const defiCamelotInfo = useSingleCamelotPool(productAddress)
  const defiInfo = defiBalancerInfo || defiCamelotInfo

  const handleRefreshKey = () => setRefreshKey((s: number) => s + 1)

  const location = useLocation()

  return (
    <>
      <ProductMeta
        url={
          __CLIENT__
            ? window.location.href
            : `https://app.autolayer.io/${location.pathname}`
        }
        description={textUtils.firstPhrase(defiInfo?.opengraph)}
        image={defiInfo?.image}
        title={`${defiInfo?.name} | Autolayer`}
      />
      <div id="defi-info" className="product-info page">
        {isDepositDialogOpen && (
          <DepositDialog
            setIsDepositDialogOpen={setIsDepositDialogOpen}
            refreshAutoLayerPoints={handleRefreshKey}
          />
        )}
        {isSwapDialogOpen && (
          <SwapDialog
            setIsSwapDialogOpen={setIsSwapDialogOpen}
            refreshAutoLayerPoints={handleRefreshKey}
            refreshKey={refreshKey}
          />
        )}
        <HeaderProductInfo productInfo={defiInfo} />
        <div className="container">
          <div className="grid-container">
            <LeftDefiInfo
              refreshKey={refreshKey}
              filteredTokens={defiInfo?.filteredTokens}
            />
            <MiddleDefiInfo productInfo={defiInfo} />
            <ErrorBoundary fallback={<div></div>}>
              <Suspense fallback={<Loading />}>
                <ProductInfoButtons
                  key={refreshKey}
                  refresher={refreshKey}
                  darkMode={false}
                  setIsSwapDialogOpen={setIsSwapDialogOpen}
                  setIsDepositDialogOpen={setIsDepositDialogOpen}
                />
              </Suspense>
            </ErrorBoundary>
          </div>
        </div>
        <div className="container">
          <BannerInfo />
        </div>
        <Footer />
      </div>
    </>
  )
}

const DefiInfoWrapper = () => {
  const { productAddress } = useParams<{ productAddress: string }>()
  const isDefiBalancer: boolean = ArbitrumData.pools.some(
    (p) => p.address.toLowerCase() === productAddress.toLowerCase()
  )
  const isDefiCamelot: boolean = ArbitrumData.camelotPools.some(
    (p) => p.pos.toLowerCase() === productAddress.toLowerCase()
  )
  if (!isDefiBalancer && !isDefiCamelot) {
    return <Navigate to="/defi-strategies" />
  }
  return <DefiInfo />
}

export default DefiInfoWrapper
