import { useState } from 'react'
import { useAutolayerPoints } from 'src/hooks/useAutolayerPoints'
import { AllPoints } from 'src/types'
import YourDepositPoints from 'src/components/Points/PointsDashboard/YourDepositPoints/YourDepositPoints'
import OtherPoints from 'src/components/Points/PointsDashboard/OtherPoints'
import PointsDashboard from 'src/components/Points/PointsDashboard/PointsDashboard'
import SoonPoints from 'src/components/Points/PointsDashboard/SoonPoints/SoonPoints'

import './DashboardPointsGrid.sass'
import { useTotalEigenLayerPoints } from 'src/hooks/useEigenLayerPoints'
import { productsPointsWithoutAutoLayerAndEigenLayer } from 'src/components/Points'

const DashboardPointsGrid = () => {
  const [openGrid, setOpenGrid] = useState(false)
  return (
    <div id="dashboard-points-grid" className={openGrid ? 'open-grid' : ''}>
      <div className="points-grid">
        <YourDepositPoints />
        <PointsDashboard
          type={'autoLayer'}
          useFetchPoints={useAutolayerPoints}
          top
        />
        <PointsDashboard
          type={'eigenLayer'}
          useFetchPoints={useTotalEigenLayerPoints}
          top
        />
        <OtherPoints />
        <div className="sub">
          {Object.entries(productsPointsWithoutAutoLayerAndEigenLayer).map(
            ([points, fetch]) => (
              <PointsDashboard
                key={points}
                type={points as AllPoints}
                useFetchPoints={fetch}
              />
            )
          )}
          <SoonPoints />
        </div>
      </div>
      <button onClick={() => setOpenGrid(!openGrid)}>
        <span className={`icon icon-angle_${!openGrid ? 'down' : 'up'}`} />
      </button>
    </div>
  )
}

export default DashboardPointsGrid
