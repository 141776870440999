import autoLayer from './autolayer/autolayerIconWhite.svg'
import eigenLayer from './eigenlayer/eigenlayer-icon-white.svg'
import renzo from './renzo/renzo-icon-white.svg'
import kelp from './kelpdao/kelpdao-icon-white.svg'
import puffer from './puffer/puffer-icon-white.svg'
import swell from './swell/swell-icon-white.svg'
import ether from './ether/ether-icon-white.svg'
import bedrock from './bedrock/bedrock-icon-white.svg'
import primestaked from './primestaked/primestacked-icon-white.svg'
import inception from './inception/inception-icon-white.svg'
import mellow from './mellow/mellow-icon-white.svg'
import symbiotic from './symbiotic/symbiotic-icon-white.svg'
import swbtc from './swell/swbtc-icon-white.svg'
import { ProductIcons } from 'src/types'

export const WhiteIcons: Record<ProductIcons, string> = {
  autoLayer,
  eigenLayer,
  renzo,
  kelp,
  puffer,
  swell,
  ether,
  bedrock,
  primestaked,
  inception,
  mellow,
  symbiotic,
  swbtc
}
