import { Suspense } from 'react'
import useDepositUsdPoints from 'src/hooks/useDepositUsdPoints'
import useTokensLRTValueUsd from 'src/hooks/useTokensLRTValueUsd'
import { Networks } from 'src/utils/networkHelper'
import { TopPointsDashboard } from '../TopPointsDashboard'
import Portfolio from 'src/components/Portfolio/Portfolio'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import { PointsDashboardError } from '../PointsDashboardError/PointsDashboardError'
import { PointsDashboardLoading } from '../PointsDashboardFallback/PointsDashboardFallback'

const YourDepositPoints = () => {
  const totalArbitrumUsd = useDepositUsdPoints()
  const { tokensUsdValue } = useTokensLRTValueUsd(Networks.ethereum)
  const { tokensUsdValue: bnbUsd } = useTokensLRTValueUsd(Networks.binance)
  const { tokensUsdValue: opUsd } = useTokensLRTValueUsd(Networks.optimism)
  const { tokensUsdValue: baseUsd } = useTokensLRTValueUsd(Networks.base)
  // const { tokensUsdValue: lineaUsd } = useTokensLRTValueUsd(Networks.linea)
  // console.log('lineaUsd', lineaUsd)
  // const { tokensUsdValue: modeUsd } = useTokensLRTValueUsd(Networks.mode)
  // const { tokensUsdValue: scrollUsd } = useTokensLRTValueUsd(Networks.scroll)

  const total = tokensUsdValue + totalArbitrumUsd + bnbUsd + opUsd + baseUsd

  return (
    <TopPointsDashboard amount={total} type="your-deposit">
      <Portfolio />
    </TopPointsDashboard>
  )
}

const YourDepositPointsWrapper = () => {
  return (
    <ErrorBoundary fallback={<PointsDashboardError />}>
      <Suspense fallback={<PointsDashboardLoading type="your-deposit" />}>
        <YourDepositPoints />
      </Suspense>
    </ErrorBoundary>
  )
}

export default YourDepositPointsWrapper
