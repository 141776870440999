import { Suspense, useRef, useState } from 'react'
import AuBNBMeta from '@components/Meta/AuBNBMeta'
import Footer from 'src/components/Footer/Footer'
import HeaderProductInfo from './Header/HeaderProductInfo'
import { BinanceData } from 'src/data/Binance'
import MiddleAuBnbInfo from './MiddleInfo/MiddleAuBnbInfo/MiddleAuBnbInfo'
import AuBnbBanner from '@components/AuBnbBanner/AuBnbBanner'

import '@components/Points/PointsBox/PointsBox.sass'
import AuBnbDialog, {
  AuBnbDialogMethods
} from '@components/AuBnbDialog/AuBnbDialog'
import useWalletChainId from '@hooks/useWalletChainId'
import { NetworkIds } from '@utils/networkHelper'
import ChangeNetworkButton from './ProductInfoButtons/ChangeNetworkButton'
import './LeftInfo/LeftProductInfo.sass'
import AubnbActualValue, {
  SkeletonAubnbActualValue
} from '@components/AuBnbBoxes/AubnbActualValue'
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary'
import { useWallet } from '@hooks/useWallet'

//* isDeposit permits to know if the user is depositing more or swapping the lst/lrst token to normal token

const AuBnbInfo = () => {
  const auBnb = BinanceData.auBnb
  const aubnbDialogRef = useRef<AuBnbDialogMethods>(null)
  const address = useWallet()
  const [refreshKey, setRefreshKey] = useState<number>(0)
  const handleRefreshKey = () => setRefreshKey((s: number) => s + 1)

  const openDialog: AuBnbDialogMethods['open'] = (data) =>
    aubnbDialogRef.current.open(data)

  const userWalletChainId = useWalletChainId()
  const binanceId: NetworkIds = '0x38'
  return (
    <>
      <AuBNBMeta />
      <div id="asset-info" className="product-info page">
        <HeaderProductInfo productInfo={auBnb} />
        <div className="container">
          <AuBnbBanner />
          <div className="grid-container">
            <ErrorBoundary
              fallback={<SkeletonAubnbActualValue isError={true} />}
            >
              <Suspense fallback={<SkeletonAubnbActualValue />}>
                <AubnbActualValue />
              </Suspense>
            </ErrorBoundary>
            <MiddleAuBnbInfo />
            {userWalletChainId === binanceId && (
              <div className={'product-info-buttons'}>
                <button
                  className="deposit-more"
                  onClick={(e) => {
                    openDialog('stake')
                    e.stopPropagation()
                  }}
                >
                  Restake
                </button>
                <button
                  className="swap"
                  onClick={(e) => {
                    openDialog('unstake')
                    e.stopPropagation()
                  }}
                >
                  Withdraw
                </button>
                <button
                  className="rewards"
                  onClick={(e) => {
                    openDialog('claim')
                    e.stopPropagation()
                  }}
                >
                  Rewards
                </button>
              </div>
            )}
            {address && userWalletChainId !== binanceId && (
              <ChangeNetworkButton usedChainId="0x38" />
            )}
          </div>
        </div>
        <AuBnbDialog
          updateRefreshKey={handleRefreshKey}
          ref={aubnbDialogRef}
          refreshKey={refreshKey}
        />
        <Footer />
      </div>
    </>
  )
}

export default AuBnbInfo
