import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'

import './ButtonChooseRST.sass'

export type Strategies = 'LST' | 'LRT' | 'defiStrategies'

const ButtonsChooseRST = () => {
  const getButtonClassName = (isActive) => `button ${isActive ? 'choosen' : ''}`
  return (
    <div className="tab-buttons">
      <div className="scroll-wrapper">
        <NavLink
          className={({ isActive }) => getButtonClassName(isActive)}
          to="/lrt"
        >
          <FormattedMessage id="dashboard.one-click-lrst" />
        </NavLink>

        <NavLink
          className={({ isActive }) => getButtonClassName(isActive)}
          to="/lst"
        >
          <FormattedMessage id="dashboard.one-click-restaking" />
        </NavLink>

        <NavLink
          className={({ isActive }) => getButtonClassName(isActive)}
          to="/defi-strategies"
        >
          <FormattedMessage id="dashboard.defi-strategies" />
        </NavLink>
      </div>
    </div>
  )
}

export default ButtonsChooseRST
