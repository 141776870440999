import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { TokensInfo } from 'src/hooks/useTokensLst'
import { getTokenImage } from 'src/utils/getTokenImage'
import ApyInfo from '../ApyInfo/ApyInfo'
import {
  NetworkIds,
  NetworkName,
  getItemByNetwork,
  getNetworkName
} from 'src/utils/networkHelper'
import { networkLogos } from 'src/static/logos/networks'
import ClusterMultiplier from '../MultiplierInfo/ClusterMultiplier/ClusterMultiplier'

import './AssetsCard.sass'

interface AssetsCardProps {
  token?: TokensInfo
  showNetwork?: boolean
}

const AssetsCard = ({ token, showNetwork = false }: AssetsCardProps) => {
  const { id, type, name, apr, network, multipliers } = token
  const navigate = useNavigate()
  const networkName: NetworkName = getNetworkName(network as NetworkIds)
  const { networkTag } = getItemByNetwork(network)
  const handleNavigate = () => {
    let route =
      `/info/${type.toLowerCase()}/${networkName}/` + id?.toLowerCase()
    if (id.toLowerCase() === 'aubnb') route = '/info/aubnb'

    navigate(route)
  }

  const showedApr = id === 'auBNB' ? 52.1 : !isNaN(+apr) ? +apr : null

  return (
    <article
      id={`assets-card-${name}`}
      className={
        id.toLowerCase() !== 'aubnb' ? 'assets-card' : 'assets-card aubnb'
      }
    >
      <header>
        <section className="left-wrapper">
          <img
            src={getTokenImage(id?.toLowerCase())}
            alt={id}
            className="token-image"
          />
          {showNetwork && (
            <div className={`network ${id.toLowerCase()}`}>
              <img
                src={networkLogos[networkName]}
                alt={`${networkName} logo`}
              />
              <span>{networkTag}</span>
            </div>
          )}
        </section>
        <section className="right-wrapper">
          <span className={`badge ${type}`}>{type}</span>
        </section>
      </header>
      <h1 title={name}>{name}</h1>
      <section className="assets-card-points">
        <ApyInfo apr={showedApr} />
        <ClusterMultiplier multipliers={multipliers} />
      </section>

      <button onClick={handleNavigate} className={id.toLowerCase()}>
        <FormattedMessage id="more-info" />
      </button>
      {id.toLowerCase() !== 'aubnb' && <div className="bg" />}
    </article>
  )
}

export default AssetsCard
