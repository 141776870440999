import { Suspense } from 'react'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import GenericPoints from './GenericPoints'
import Spinner from 'src/components/Loading/Spinner/Spinner'
import { Points as PointsType } from 'src/types'

interface RewardPointsProps {
  useFetchPoints: () => PointsType
}

function RewardPoints({ useFetchPoints }: RewardPointsProps) {
  const points = useFetchPoints()
  return <GenericPoints points={+points?.points} />
}

export default function RewardPointsWrapper({
  useFetchPoints
}: RewardPointsProps) {
  return (
    <ErrorBoundary fallback={<span>Error</span>}>
      <Suspense fallback={<Spinner />}>
        <RewardPoints useFetchPoints={useFetchPoints} />
      </Suspense>
    </ErrorBoundary>
  )
}
