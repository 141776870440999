import './IconMultiplier.sass'

const IconMultiplier = ({ icon, name }: { icon?: string; name: string }) => {
  return (
    <div
      id={`icon-multiplier-${name.toLowerCase().replace(/ /g, '_')}`}
      className="icon-multiplier"
    >
      {!icon ? (
        '?'
      ) : (
        <img title={name} loading="lazy" src={icon} alt={`${name} icon`} />
      )}
    </div>
  )
}

export default IconMultiplier
