import { FormattedMessage } from 'react-intl'
import { AuBnbDialogProps } from '../../AuBnbDialog'
import AuBnbDialogSteps from '../../Steps/AuBnbDialogSteps'
import TokenInput from '@components/TokenInput/TokenInput'
import { useWallet } from '@hooks/useWallet'
import { FormEvent, useState } from 'react'
import { getTokenImage } from '@utils/getTokenImage'
import { dataMicroserviceUrl } from 'src/constants'
import { claimAubnbRewards } from 'src/contracts/aubnb/aubnbMethods'
import useAubnbPendingRewards, {
  useAubnbClaimInfo
} from '@hooks/useAubnbPendingRewards'
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary'

const AuBnbDialogClaim = ({
  stepsData,
  updateRefreshKey,
  refreshKey
}: AuBnbDialogProps) => {
  const address = useWallet()
  const [enabled, setEnabled] = useState<boolean>(true)
  const { addStep, errorStep, resetSteps, setDone, steps, successStep } =
    stepsData

  const { ethPendingRewards, updateBlock } = useAubnbPendingRewards(address)
  const { lastBlockNumberClaimed } = useAubnbClaimInfo(address, refreshKey)

  const notAllClaimed =
    parseInt(lastBlockNumberClaimed.toString()) < updateBlock
  const pendingRewards = notAllClaimed ? ethPendingRewards : 0

  const handleClaim = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      const res = await fetch(`${dataMicroserviceUrl}/aubnb/sign/${address}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const { signature, weiPendingRewards, blockNumber } = await res.json()
      console.log({ signature, weiPendingRewards, blockNumber })
      addStep('stake.staking-dialog.stake.confirmation')
      const tx = await claimAubnbRewards(
        signature,
        address,
        weiPendingRewards,
        blockNumber
      )
      successStep({ txHash: tx })
      setDone('stake.staking-dialog.steps.done-staking', tx)
      updateRefreshKey()
    } catch (error) {
      console.error('Error approving AuBNB to unstake', error)
      errorStep()
    }
  }
  return (
    <form onSubmit={handleClaim}>
      <TokenInput
        className="stake"
        defaultCoin="aubnb"
        initialValue={pendingRewards}
        handleExternalChange={(value) => {
          resetSteps()
          if (value === 0) setEnabled(false)
        }}
        disabled={true}
      >
        <div>
          <img src={getTokenImage('lay3r')} alt="AuBNB logo" id="token-logo" />
          <span>LAY3R</span>
        </div>
      </TokenInput>
      <AuBnbDialogSteps steps={steps}>
        <button
          className="continue"
          disabled={!enabled || pendingRewards === 0}
          type="submit"
        >
          <FormattedMessage id="continue" />
        </button>
      </AuBnbDialogSteps>
    </form>
  )
}

const AubnbClaimWrapper = ({
  stepsData,
  updateRefreshKey,
  refreshKey
}: AuBnbDialogProps) => {
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <AuBnbDialogClaim
        stepsData={stepsData}
        updateRefreshKey={updateRefreshKey}
        refreshKey={refreshKey}
      />
    </ErrorBoundary>
  )
}

export default AubnbClaimWrapper
