import PointsIcon from '../PointsIcons'
import { type ProductIcons } from 'src/types'

import './PointsIconList.sass'

const PointsIconList = ({ list }: { list: ProductIcons[] }) => {
  const slicedList = list?.slice(0, 5) || []
  const length = list?.length
  if (!length) return <div />
  return (
    <div className="points-icon-list">
      <div className="points-list">
        {slicedList?.map((type, i) => <PointsIcon key={i} type={type} />)}
      </div>
      {length > 5 && (
        <div className="rest-points">
          <span>{`+${length - 5}`}</span>
        </div>
      )}
    </div>
  )
}

export default PointsIconList
