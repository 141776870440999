import { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import useAvsInfoForLRT from 'src/hooks/useAvsInfoForLRT'
import AvsInfoBox from './AvsInfoBox/AvsInfoBox'
import avsData from 'src/data/avs/avsData.json'

import './AvsSection.sass'

const AvsSection = () => {
  const { productName } = useParams<{ productName: string }>()
  const data = useAvsInfoForLRT()
  const [extended, setextended] = useState(false)
  const avsInfo = data.find((item) => item.id === productName)?.avsInfo

  const extendedAvsInfo = useMemo(() => {
    return avsInfo?.map((info) => {
      const avsDetails = avsData[productName]?.find(
        (item) => item.id.toLocaleLowerCase() === info.name.toLocaleLowerCase()
      )
      return avsDetails
        ? { ...info, extendedName: avsDetails.name, url: avsDetails.url }
        : info
    })
  }, [avsInfo, productName])

  const finalAvsInfo = useMemo(
    () => extendedAvsInfo?.slice(0, 4),
    [extendedAvsInfo]
  )
  const isExpandable = useMemo(
    () => extendedAvsInfo?.length > 4 && !extended,
    [extendedAvsInfo, extended]
  )

  const [info, setinfo] = useState(
    isExpandable ? finalAvsInfo : extendedAvsInfo
  )

  useEffect(() => {
    if (isExpandable) {
      setinfo(finalAvsInfo)
    } else {
      setinfo(extendedAvsInfo)
    }
  }, [extendedAvsInfo, extended, finalAvsInfo, isExpandable])

  return (
    <section id="avs-section">
      <h2>
        <span>
          <FormattedMessage id="avs-section.title" />
        </span>
        <span> ({extendedAvsInfo?.length})</span>
      </h2>
      <div id="avs-grid" className={`${extended ? 'extended' : ''}`}>
        {info.map((info) => (
          <AvsInfoBox key={info.name} avsInfo={info} />
        ))}
      </div>
      {extendedAvsInfo?.length > 4 && (
        <button onClick={() => setextended(!extended)}>
          <span>
            {extended ? (
              <FormattedMessage id="avs-section.hide" />
            ) : (
              <MarkdownMessage
                id="avs-section.extended"
                values={{ n: extendedAvsInfo.length - 4 }}
              />
            )}
          </span>
        </button>
      )}
    </section>
  )
}

export default AvsSection
