import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import Swap from '../Swap/Swap'
import SwapPools from '../Swap/SwapPools'
import WithdrawCamelotPools from '../Swap/WithdrawCamelotPools'
import { NetworkData, networkData } from 'src/data'
import useNetworkFromRoute from 'src/hooks/useNetworkFromRoute'

import './SwapDialog.sass'

interface SwapDialogProps {
  setIsSwapDialogOpen: (value: boolean) => void
  darkMode?: boolean
  refreshAutoLayerPoints?: () => void
  refreshKey?: number
}

const SwapDialog = ({
  setIsSwapDialogOpen,
  darkMode = false,
  refreshAutoLayerPoints,
  refreshKey
}: SwapDialogProps) => {
  const { productName } = useParams<{ productName: string }>()
  const { productAddress } = useParams<{ productAddress: string }>()
  const { chainId } = useNetworkFromRoute()
  const networkInfo: NetworkData = networkData(chainId)
  const bptName: string = networkInfo?.pools?.find(
    (pool) => pool.address.toLowerCase() === productAddress?.toLowerCase()
  )?.bptName
  const camelotPool = networkInfo?.camelotPools?.find(
    (pool) => pool.pos.toLowerCase() === productAddress?.toLowerCase()
  )?.bptName

  const title = productName ?? bptName
  return (
    <div id="swap-dialog">
      <header>
        {productName ? (
          <h3>
            <FormattedMessage id="swap" /> {title}
          </h3>
        ) : (
          <h3>
            {' '}
            <FormattedMessage id="token-selector.withdraw" />{' '}
          </h3>
        )}

        <button onClick={() => setIsSwapDialogOpen(false)}>
          <span className="icon icon-close" />
        </button>
      </header>
      {productName && (
        <Swap
          darkMode={darkMode}
          refreshAutoLayerPoints={refreshAutoLayerPoints}
        />
      )}
      {bptName && productAddress && (
        <SwapPools
          darkMode={darkMode}
          refreshAutoLayerPoints={refreshAutoLayerPoints}
        />
      )}
      {camelotPool && productAddress && (
        <WithdrawCamelotPools
          darkMode={darkMode}
          refreshAutoLayerPoints={refreshAutoLayerPoints}
          refreshKey={refreshKey}
        />
      )}
    </div>
  )
}

export default SwapDialog
