import { ReactNode, MouseEvent } from 'react'

import './NonModalLayout.sass'

interface NonModalLayoutProps {
  children: ReactNode
  image: {
    src: string
    alt: string
  }
}

export default function NonModalLayout({
  children,
  image: { alt, src }
}: NonModalLayoutProps) {
  const handleMouseDown = (event: MouseEvent<HTMLElement>) => {
    if (event.currentTarget !== document.activeElement) {
      return
    }
    const t = event.currentTarget
    setTimeout(() => t.blur(), 0)
  }

  return (
    <div
      className="non-modal-layout"
      tabIndex={0}
      onMouseDown={handleMouseDown}
      role="dialog"
    >
      <img src={src} alt={alt} role="button" />
      {children}
      <div className="veil" />
    </div>
  )
}
