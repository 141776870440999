import StakingContentDesktop from '../StakingContent/StakingContentDesktop'
import { FormattedMessage } from 'react-intl'
import { StakeDialogMethods } from '../StakeDialog/StakeDialog'
import { binancePools } from 'src/data/Staking/Binance/contracts'

import './Desktop.sass'

type StakingDataDesktopProps = Pick<StakeDialogMethods, 'open'>

const { pool1, pool2, pool3, pool4 } = binancePools

export default function StakingDataDesktop({
  open,
  refreshKey
}: { refreshKey: number } & StakingDataDesktopProps) {
  const header = 'stake.staking-data.header'
  return (
    <section className="staking-data-container desktop">
      <header>
        <span>
          <FormattedMessage id={`${header}.pools`} />
        </span>
        <span>
          <FormattedMessage id={`${header}.apy`} />
        </span>
        <span>
          <FormattedMessage id={`${header}.lock-term`} />
        </span>
        <span>
          <FormattedMessage id={`${header}.capacity`} />
        </span>
        <span>
          <FormattedMessage id={`${header}.my-stake`} />
        </span>
        <span>
          <FormattedMessage id={`${header}.my-rewards`} />
        </span>
        <span>
          <FormattedMessage id={`${header}.actions`} />
        </span>
      </header>
      <StakingContentDesktop
        refreshKey={refreshKey}
        apy={pool1.apy}
        image={pool1.logo}
        name={pool1.name}
        open={open}
        lockTime={pool1.lockTerm}
        maxCapacity={pool1.maxCapacity}
        poolAddress={pool1.address}
      />
      <StakingContentDesktop
        refreshKey={refreshKey}
        apy={pool3.apy}
        image={pool3.logo}
        name={pool3.name}
        open={open}
        lockTime={pool3.lockTerm}
        maxCapacity={pool3.maxCapacity}
        poolAddress={pool3.address}
      />
      <StakingContentDesktop
        refreshKey={refreshKey}
        apy={pool4.apy}
        image={pool4.logo}
        name={pool4.name}
        open={open}
        lockTime={pool4.lockTerm}
        maxCapacity={pool4.maxCapacity}
        poolAddress={pool4.address}
      />
      <StakingContentDesktop
        refreshKey={refreshKey}
        apy={pool2.apy}
        image={pool2.logo}
        name={pool2.name}
        open={open}
        lockTime={pool2.lockTerm}
        maxCapacity={pool2.maxCapacity}
        poolAddress={pool2.address}
      />
    </section>
  )
}
