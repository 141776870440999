import { CoingeckoDataSummary } from 'src/hooks/useCoingecko'
import { CamelotData } from './getFullCamelotData'
import { tokenAddressToSymbol } from 'src/utils/tokenAddressToName'
import getWeb3 from '../web3'
import { Networks } from '@utils/networkHelper'

export interface CamelotDataWithUserValues extends CamelotData {
  usdUserValue: number
  lpPrice: number
  liquidityUSD: number
}

const calculateLpValues = (
  data: CamelotData[],
  coingeckoData: CoingeckoDataSummary[]
): CamelotDataWithUserValues[] => {
  const web3 = getWeb3('arbitrum')
  const completeInfo: CamelotDataWithUserValues[] = data.map((d) => {
    const token0Symbol: string = tokenAddressToSymbol(
      d.token0,
      Networks.arbitrum
    )
    const token1Symbol: string = tokenAddressToSymbol(
      d.token1,
      Networks.arbitrum
    )
    const token0Price: number = coingeckoData.find(
      (data) => data.symbol.toLowerCase() === token0Symbol.toLowerCase()
    )?.current_price
    const token1Price: number = coingeckoData.find(
      (data) => data.symbol.toLowerCase() === token1Symbol.toLowerCase()
    )?.current_price
    const amount0: number = parseFloat(
      web3.utils.fromWei(d.totalAmount[0], 'ether')
    )
    const amount1: number = parseFloat(
      web3.utils.fromWei(d.totalAmount[1], 'ether')
    )
    const liquidityUSD: number = amount0 * token0Price + amount1 * token1Price
    const lpPrice: number =
      liquidityUSD / parseFloat(web3.utils.fromWei(d.totalSupply, 'ether'))
    const lpEthAmount: number = parseFloat(
      web3.utils.fromWei(d.userLPs, 'ether')
    )
    const usdUserValue: number = lpEthAmount * lpPrice
    return {
      ...d,
      usdUserValue,
      lpPrice,
      liquidityUSD
    }
  })
  return completeInfo
}

export default calculateLpValues
