import { NetworkName } from '@utils/networkHelper'
import { stakingAbi } from '../abis/AutoLayerAbis/StakingAbi'
import getWeb3 from '../web3'

export const getUserTotalStakedAmount = async (
  address: string,
  contractAddress: string,
  networkName: NetworkName = 'binance'
): Promise<number> => {
  if (!address) return 0
  const web3 = getWeb3(networkName)

  const contract = new web3.eth.Contract(stakingAbi, contractAddress)
  const userStakingPosition: number = await contract.methods
    .getUserTotalStakedAmount(address)
    .call()
  const totalStakedAmount = web3.utils.fromWei(
    userStakingPosition.toString(),
    'ether'
  )
  return parseFloat(totalStakedAmount)
}

export const getUserTotalPendingRewardAmount = async (
  address: string,
  contractAddress: string,
  networkName: NetworkName = 'binance'
): Promise<number> => {
  if (!address) return 0
  const web3 = getWeb3(networkName)

  const contract = new web3.eth.Contract(stakingAbi, contractAddress)
  const pendingRewards: bigint = await contract.methods
    .getUserTotalPendingRewardAmount(address)
    .call()
  const rewardsToClaim = web3.utils.fromWei(pendingRewards.toString(), 'ether')

  return parseFloat(rewardsToClaim)
}
