import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ConnectWallet from '../ConnectWallet/ConnectWallet'
import LanguageSelectorDialog from '../common/LanguageSelectorDialog/LanguageSelectorDialog'
import { networkVectors } from 'src/static/logos/networks'
import gear from 'src/static/images/connectwallet/gears.svg'
// import darkmode from 'src/static/images/settings-menu/darkmode.svg'
import language from 'src/static/images/settings-menu/language.svg'
import NonModalLayout from '../NonModalLayout/NonModalLayout'
import NetworkSelectorDialog from '../common/Network/NetworkSelectorDialog/NetworkSelectorDialog'
import networkChoice from '../common/Network/networkChoice.json'
import { useNetworkName } from 'src/hooks/useNetworkName'
import { DEFAULT_NETWORK_NAME } from 'src/utils/networkHelper'

import './SettingsMenu.sass'

const SettingsMenu = () => {
  const [dialogElement, setDialogElement] = useState<
    'language' | 'network' | 'none'
  >('none')
  const selectedNetwork = useNetworkName()

  return (
    <>
      <NonModalLayout image={{ src: gear, alt: 'gear icon' }}>
        <div className="bubble">
          {/* <button className="color-scheme">
            <img loading="lazy" src={darkmode} alt="moon icon" />
            <span><FormattedMessage id="color-scheme.dark" /></span>
          </button> */}
          <button
            className="language-selector"
            onClick={() => setDialogElement('language')}
          >
            <img loading="lazy" src={language} alt="language symbol" />
            <span>
              <FormattedMessage id="language-selector.text" />
            </span>
          </button>
          <section className="network-section">
            <h3>
              <FormattedMessage id="network-selector.actual" />
            </h3>
            <div>
              <img
                src={
                  networkVectors[selectedNetwork] ??
                  networkVectors[DEFAULT_NETWORK_NAME]
                }
                alt={`${networkChoice[selectedNetwork]?.label ?? DEFAULT_NETWORK_NAME} Logo`}
              />
              <span>
                {networkChoice[selectedNetwork]?.label ?? DEFAULT_NETWORK_NAME}
              </span>
            </div>
            <button
              className="select-network"
              onClick={() => setDialogElement('network')}
            >
              <FormattedMessage id="network-selector.title" />
            </button>
          </section>
          <section className="connect-section">
            <ConnectWallet />
          </section>
        </div>
      </NonModalLayout>
      <LanguageSelectorDialog
        chooseLanguage={dialogElement === 'language'}
        setChooseLanguage={() => setDialogElement('none')}
      />
      <NetworkSelectorDialog
        handleShow={() => setDialogElement('none')}
        showDialog={dialogElement === 'network'}
      />
    </>
  )
}

export default SettingsMenu
