import { NetworkName } from 'src/utils/networkHelper'

export interface RiskForLrtTokens {
  id: string
  totalSupply: number
  totalSupplyUsd: number
  tvlAsCollateral: RiskInfo[]
  liquidityInDexes: LiquidityDexesInfo[]
}

export type ProtocolForRisk = 'aave' | 'gearbox' | 'morpho' | 'aark' | 'prisma'
export type LiquidityDexes = 'camelot' | 'balancer' | 'uniswap'
export interface LiquidityDexesInfo {
  protocol: LiquidityDexes
  info: Array<{
    network: NetworkName
    amount: string
    usdValue: string
    percentageTvl: number
  }>
}

export interface RiskInfo {
  protocol: string
  amount: string
  usdValue: string
  percentageTvl: number
}

export enum RiskType {
  Collateral = 'collateral',
  Dexes = 'dexes'
}
