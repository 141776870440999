import { Suspense, lazy } from 'react'
import { AprChartProps } from './AprChart'
import Loading from 'src/components/Loading/Loading'

const AprChart = lazy(
  () => import(/* webpackChunkName: "apr-chart" */ './AprChart')
)

export default function AprChartWrapper(props: AprChartProps) {
  return (
    <Suspense fallback={<Loading colored />}>
      <AprChart {...props} />
    </Suspense>
  )
}
