import { NetworkIds, Networks } from 'src/utils/networkHelper'
import { AUTOLAYER_ABIS } from './abis'
import { SWAPS_CONTRACT } from './addresses'
import tokenNameToAddress from 'src/utils/tokenNameToAddress'
import { TransactionParams } from '@paraswap/sdk'
import web3 from '@utils/web3'
import { AbiItem } from 'web3'

const sendSwapTransaction = async (
  txParams: TransactionParams,
  outputToken: string,
  weiAmount: string,
  userAddress: string,
  chainId: NetworkIds
) => {
  const ETH_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
  const data: string = txParams.data
  const outputAddress =
    outputToken.length === 42
      ? outputToken.toLowerCase()
      : tokenNameToAddress(outputToken, chainId).toLowerCase()
  const swapsContractAddress: string = SWAPS_CONTRACT[chainId.toLowerCase()]
  const swapsContract = new web3.eth.Contract(
    AUTOLAYER_ABIS[chainId.toLowerCase()].swapContract as AbiItem[],
    swapsContractAddress.toLowerCase()
  )
  const gasPrice: number = Math.ceil(parseFloat(txParams.gasPrice) * 1.25)
  let sendSwapTx
  if (txParams.value !== '0') {
    //* Case to use when ETH is inputToken
    const estimatedGas = await swapsContract.methods
      .swapTokensWithETH(data)
      .estimateGas({
        from: userAddress,
        value: weiAmount
      })
    const increasedGas =
      chainId === Networks.ethereum
        ? Math.ceil(Number(estimatedGas) * 1.6)
        : Number(estimatedGas) * 2 /* Valid for Arbitrum and binance */
    sendSwapTx = await swapsContract.methods.swapTokensWithETH(data).send({
      from: userAddress,
      gasPrice: gasPrice.toString(),
      gas: increasedGas.toString(),
      value: weiAmount
    })
  } else if (outputAddress === ETH_ADDRESS.toLowerCase()) {
    //* Case to use when ETH is outputToken
    const estimatedGas = await swapsContract.methods
      .swapTokensWithETH(data)
      .estimateGas({
        from: userAddress
      })
    const increasedGas =
      chainId === Networks.ethereum
        ? Math.ceil(Number(estimatedGas) * 1.6)
        : Number(estimatedGas) * 2 /* Valid for Arbitrum and binance */
    sendSwapTx = await swapsContract.methods.swapTokensWithETH(data).send({
      from: userAddress,
      gasPrice: gasPrice.toString(),
      gas: increasedGas.toString()
    })
  } else {
    // Swapping ERC20
    const estimatedGas = await swapsContract.methods
      .swapTokens(data)
      .estimateGas({
        from: userAddress
      })
    const increasedGas =
      chainId === Networks.ethereum
        ? Math.ceil(Number(estimatedGas) * 1.6)
        : Number(estimatedGas) * 2 /* Valid for Arbitrum and binance */
    sendSwapTx = await swapsContract.methods.swapTokens(data).send({
      from: userAddress,
      gasPrice: gasPrice.toString(),
      gas: increasedGas.toString()
    })
  }

  return sendSwapTx
}
export default sendSwapTransaction
