import { AutolayerLogos } from '@static/logos/autolayer'
import TokenInput from '@components/TokenInput/TokenInput'
import { FormattedMessage } from 'react-intl'
import { FormEvent, Suspense, useState } from 'react'
import { approveForStake, stake } from 'src/contracts/staking/stake'
import { getRealWeiTokenBalance } from 'src/contracts/getBalance/getSingleTokenBalance'
import usePromise from '@hooks/usePromise'
import { StakeDialogsProps } from '../../StakeDialog'
import StakeDialogSteps from '../../Steps/StakeDialogSteps'
import { useWallet } from '@hooks/useWallet'
import { useGetAvailableCapacity } from '@hooks/useStaking'
import './StakeDialogStake.sass'

const BalanceLAY3R = ({ userAddress }: { userAddress: string }) => {
  const balance = usePromise(
    getRealWeiTokenBalance,
    'lay3r',
    userAddress,
    '0x38'
  )
  const visualBalance = balance ? +balance.toString() / 10 ** 18 : 0
  return <span>{visualBalance.toFixed(4)} LAY3R</span>
}

export default function StakeDialogStake({
  poolAddress,
  updateRefreshKey,
  stepsData
}: StakeDialogsProps) {
  const { addStep, successStep, errorStep, steps, setDone, resetSteps } =
    stepsData
  const address = useWallet()
  const [enabled, setEnabled] = useState<boolean>(false)
  const availableCapacity = useGetAvailableCapacity(poolAddress)

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const weiAmount = e.target[5].dataset.wei
    const weiAvailableCapacity = BigInt(availableCapacity * 10 ** 18)
    const amountWei: bigint =
      weiAmount > weiAvailableCapacity ? weiAvailableCapacity : weiAmount

    addStep('stake.staking-dialog.stake.approved')
    try {
      await approveForStake(address, poolAddress, amountWei)
      successStep()
    } catch (error) {
      console.error('Error approving for stake', error)
      errorStep()
      return
    }

    try {
      addStep('stake.staking-dialog.stake.confirmation')
      const stakeTxHash = await stake(address, poolAddress, amountWei)
      successStep({ txHash: stakeTxHash })
      setDone('stake.staking-dialog.steps.done-staking', stakeTxHash)
      updateRefreshKey()
    } catch (err) {
      errorStep()
    }
  }
  return (
    <form onSubmit={handleOnSubmit}>
      <p className="balance">
        <FormattedMessage id="pool-token-table.balance" />:{' '}
        <Suspense fallback={<span>0 LAY3R</span>}>
          <BalanceLAY3R userAddress={address} />
        </Suspense>
      </p>
      <p className="available">
        <FormattedMessage id="stake.max-available-amount" />
        <span>{availableCapacity?.toFixed(2)} LAY3R</span>
      </p>
      <TokenInput
        className="stake"
        handleExternalChange={(value) => {
          resetSteps()
          if (value === 0) {
            setEnabled(false)
          } else if (enabled === false) {
            setEnabled(true)
          }
        }}
      >
        <div>
          <img src={AutolayerLogos.autolayerIconWhite} alt="$LAY3R logo" />
          <span>$LAY3R</span>
        </div>
      </TokenInput>

      <StakeDialogSteps steps={steps}>
        <button className="continue" type="submit" disabled={!enabled}>
          <FormattedMessage id="continue" />
        </button>
      </StakeDialogSteps>
    </form>
  )
}
