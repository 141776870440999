import { FormattedMessage } from 'react-intl'
import useCoingecko, { CoingeckoDataSummary } from 'src/hooks/useCoingecko'
import { useCompactNumbers } from 'src/hooks/useCompactNumbers'

interface ProductLeftUsdValueProps {
  ethBalance: string
  name: string
}

export default function ProductLeftUsdValue({
  ethBalance,
  name
}: ProductLeftUsdValueProps) {
  const coingeckoData: CoingeckoDataSummary[] = useCoingecko()
  const { current_price: usdPrice } = coingeckoData.find(
    (c) => c.symbol?.toLowerCase() === name?.toLowerCase()
  )
  const usdValue: number = usdPrice * Number(ethBalance)
  const finalValue = useCompactNumbers()

  return (
    <div className="actual-value-points-box points-box">
      <h4>
        <FormattedMessage id="left-point.actual-value" />
      </h4>
      <div className="active-amount row">
        <strong className="amount">{parseFloat(ethBalance).toFixed(5)}</strong>
        <span className="badge-name">{name}</span>
      </div>
      <span className="usd-value">({finalValue(usdValue, 2)} USD) </span>
    </div>
  )
}
