import { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { useModal } from '@gluedigital/modal'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import { useCompactNumbers } from 'src/hooks/useCompactNumbers'
import usePortfolio from 'src/hooks/usePortfolio'
import { PortfolioData } from 'src/types/portfolioTypes'

import './PortfolioHeader.sass'
import Spinner from 'src/components/Loading/Spinner/Spinner'

const YourDeposit = () => {
  const portfolioData: PortfolioData = usePortfolio()
  const totalUsdValue: number = portfolioData?.totalUsdValue
  const DECIMALS: number = totalUsdValue < 1000 ? 2 : 3
  const finalAmount = useCompactNumbers()
  const totalVariation: string = portfolioData?.totalVariation24h
  return (
    <div className="your-deposit">
      <h2>
        <span className="currency">$ </span>
        <strong>{finalAmount(totalUsdValue, DECIMALS)}</strong>
      </h2>
      <span>{totalVariation}%</span>
    </div>
  )
}

const PortfolioHeader = () => {
  const modal = useModal()
  return (
    <header id="masthead-portfolio">
      <div className="header-container">
        <button onClick={() => modal.hide()}>
          <span className="icon icon-angle_left" />
          <span className="back-text">
            <FormattedMessage id="back-to-dashboard" />
          </span>
        </button>
        <h1>
          <FormattedMessage id="your-portfolio" />
        </h1>
        <ErrorBoundary fallback={<div className="error">Error</div>}>
          <Suspense fallback={<Spinner />}>
            <YourDeposit />
          </Suspense>
        </ErrorBoundary>
      </div>
    </header>
  )
}

export default PortfolioHeader
