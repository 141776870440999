import { FormattedMessage } from 'react-intl'
import { getTokenImage } from '@utils/getTokenImage'
import { BinanceData } from 'src/data/Binance'
import CompleteAprChart from '@components/Charts/AprChart/CompleteAprChart/CompleteAprChart'
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary'
import { generateAuBnbChart } from '@utils/time'

import './MiddleAuBnbInfo.sass'
import AuBnbApy from './APYs/AuBnbApy'

const MiddleAuBnbInfo = () => {
  const fallbackData = generateAuBnbChart()
  const auBnb = BinanceData.auBnb
  return (
    <section id="aubnb-middle-section" className="middle-section">
      <div className="top-aubnb-info">
        <div>
          <img src={getTokenImage('aubnb')} alt={'aubnb'} className="lrs-img" />
          <h3>
            Au<strong>BNB</strong>
          </h3>
        </div>
        <div className="tvl-info">
          <span>TVL</span>
          <span>
            <FormattedMessage id="coming-soon" />
          </span>
        </div>
      </div>
      <div className="description">
        <p>{auBnb.description}</p>
      </div>
      <div className="apy-boxes">
        <AuBnbApy value={52.1} title="APR" />
        <AuBnbApy value={0.1} title="BNB Staking Rewards" />
        <AuBnbApy value={-1} title="Restaking Rewards" />
        <AuBnbApy value={52} title="AutoLayer Rewards" />
      </div>
      <section className="chart-lrt" id="chart-apy">
        <h2>APY Overtime</h2>
        <ErrorBoundary fallback={<div />}>
          <CompleteAprChart productName={'aubnb'} fallbackData={fallbackData} />
        </ErrorBoundary>
      </section>
    </section>
  )
}

export default MiddleAuBnbInfo
