import ProgressBar from '@components/ProgressBar/ProgressBar'
import stakingUtils from '@utils/stake'
import { FormattedMessage } from 'react-intl'
import { StakingInfoProps } from './StakingInfo'

export default function StakingInfoWrapper({
  lockTime,
  maxCapacity
}: StakingInfoProps) {
  return (
    <div className="staking-info">
      <div>
        <span className="title">
          <FormattedMessage id="stake.staking-data.header.lock-term" />
        </span>
        <span className="value time">
          <span className="days">{lockTime}</span>
          <span>
            <FormattedMessage id="stake.staking-data.days" />
          </span>
        </span>
      </div>
      <div>
        <span className="title">
          <FormattedMessage id="stake.staking-data.header.capacity" />
        </span>
        <span className="value">
          <ProgressBar
            value={0}
            label={stakingUtils.getAbbreviatedNumber(maxCapacity)}
          />
        </span>
      </div>
      <div>
        <span className="title">
          <FormattedMessage id="stake.staking-data.header.my-stake" />
        </span>
        <span className="value bold"></span>
      </div>
      <div>
        <span className="title">
          <FormattedMessage id="stake.staking-data.header.my-rewards" />
        </span>
        <span className="value bold"></span>
      </div>
    </div>
  )
}
