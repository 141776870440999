import fire from '@static/vectors/fire.svg'
import StakingContentMobile from '../StakingContent/StakingContentMobile'
import { useState } from 'react'
import { StakeDialogMethods } from '../StakeDialog/StakeDialog'
import useWalletChainId from '@hooks/useWalletChainId'
import { useStakingTotalStaked } from '@hooks/useStaking'

import './Mobile.sass'

export interface DataProps {
  name: string
  image: string
  apy: number
  maxCapacity: number
  lockTime: number
  poolAddress: string
  refreshKey: number
}

type StakingDataMobileProps = Pick<StakeDialogMethods, 'open'>

export default function StakingDataMobile({
  apy,
  image,
  name,
  lockTime,
  maxCapacity,
  poolAddress,
  refreshKey,
  open: openDialog
}: DataProps & StakingDataMobileProps) {
  const [open, setOpen] = useState<boolean>(false)

  const userWalletChainId = useWalletChainId()
  const totalStaked = useStakingTotalStaked(poolAddress)
  const disableStake: boolean = totalStaked >= maxCapacity
  return (
    <article
      className={`staking-data mobile ${open ? 'open' : ''}`}
      tabIndex={0}
    >
      <header onClick={() => setOpen((prev) => !prev)}>
        <div>
          <div className="token-data">
            <img src={image} alt="" />
            <span>{name}</span>
          </div>
          <span>{apy}%APY</span>
          <img src={fire} alt="fire icon" className="fire" />
        </div>

        <div className="info">
          <button
            disabled={userWalletChainId !== '0x38' || disableStake}
            onClick={(e) => {
              openDialog({ type: 'stake', poolAddress })
              e.stopPropagation()
            }}
          >
            Stake
          </button>
          <span className="icon icon-angle_down" />
        </div>
      </header>
      <StakingContentMobile
        maxCapacity={maxCapacity}
        open={openDialog}
        lockTime={lockTime}
        poolAddress={poolAddress}
        refreshKey={refreshKey}
      />
    </article>
  )
}
