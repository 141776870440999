import { compactNumbers } from 'src/utils/compactNumbers'
import type { CamelotPool } from 'src/types/pools'

import './CamelotPositionsList.sass'

interface CamelotPositionItemProps {
  thisPool: CamelotPool
  camelotPosition: any
  onClick: (id: string, balance: number, usdValue: number) => void
}
const CamelotPositionItem = ({
  camelotPosition,
  onClick,
  thisPool
}: CamelotPositionItemProps) => {
  const { id, balance, usdValue } = camelotPosition
  return (
    <li onClick={() => onClick(id.toString(), balance, usdValue)}>
      <span className="position-id">Position #{id}</span>
      <div>
        <span className="usd-value"> ${usdValue.toFixed(2)} </span>
        <div>
          {balance > 0 && (
            <span className="usd-value">{`${compactNumbers(balance, 10)}`}</span>
          )}
          <span className="lp-name">{thisPool.bptName}</span>
        </div>
      </div>
    </li>
  )
}

export default CamelotPositionItem
