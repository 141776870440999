import { useMemo } from 'react'
import usePromise from './usePromise'
import { TokenProps } from 'src/components/TokenSelector/TokenSelectorList/TokenSelectorList'
import { useSelector } from 'react-redux'
import { WalletData } from 'src/types'
import useCoingecko, { CoingeckoDataSummary } from './useCoingecko'
import usePools, { useCamelotPools } from './usePools'

import {
  PortfolioData,
  PortfolioTokens,
  PortfolioStrategies,
  PortfolioCamelot
} from 'src/types/portfolioTypes'
import addTokensWeightForPools from 'src/helpers/addTokensWeightForPools'

import { NetworkData, networkData } from 'src/data'
import { NetworkIds, Networks, getNetworkName } from 'src/utils/networkHelper'
import {
  getPoolsBalanceListBatch,
  getTokensLRTBalanceListBatch
} from 'src/contracts/getBalance/getBalanceListBatch'
import getFullCamelotData, {
  CamelotData
} from 'src/contracts/Camelot/getFullCamelotData'
import calculateLpValues, {
  CamelotDataWithUserValues
} from 'src/contracts/Camelot/calculateLpValues'

export const useAssetsPortfolio = (chainId: NetworkIds): PortfolioData => {
  const wallet = useSelector((s: any) => s?.wallet as WalletData)
  const networkInfo: NetworkData = useMemo(
    () => networkData(chainId),
    [chainId]
  )
  const balanceCoins: TokenProps[] = usePromise(
    getTokensLRTBalanceListBatch,
    networkInfo.tokensLRS,
    wallet?.address,
    chainId
  )

  const networkName = getNetworkName(chainId)
  const priceVariation: CoingeckoDataSummary[] = useCoingecko()
  const tokens: PortfolioTokens[] = balanceCoins.map((coin: TokenProps) => {
    const coingeckoData: CoingeckoDataSummary = priceVariation.find(
      (p) => p.symbol?.toLowerCase() === coin?.id?.toLowerCase()
    )
    const usdValue: number = coingeckoData?.current_price * coin.balance
    const type: string = networkInfo.tokensLRS.find(
      (t) => t.address?.toLowerCase() === coin?.address?.toLowerCase()
    )?.type
    return {
      ...coin,
      usdValue,
      type,
      priceVariation: coingeckoData?.price_change_percentage_24h,
      networkName
    }
  })
  const tokensFiltered: PortfolioTokens[] = tokens.filter(
    (t) => t.balance > 0 || t.usdValue > 0
  )

  if (tokensFiltered.length === 0) {
    return { tokens, totalVariation24h: '0.00', totalUsdValue: 0 }
  }

  const tokensTotalPortfolioUsdValue: number = tokensFiltered.reduce(
    (acc, t) => acc + t.usdValue,
    0
  )

  const accPriceVariation: number = tokensFiltered.reduce((acc, t) => {
    const usdPercentage: number = t.usdValue / tokensTotalPortfolioUsdValue
    return acc + t.priceVariation * usdPercentage
  }, 0)
  const totalVariation: string = (
    accPriceVariation / tokensFiltered?.length
  )?.toFixed(2)

  const result = {
    tokens: tokensFiltered,
    totalVariation24h: totalVariation,
    totalUsdValue: tokensTotalPortfolioUsdValue
  }

  return result
}

export const useStrategiesPortfolio = (): PortfolioData => {
  const wallet = useSelector((s: any) => s?.wallet as WalletData)
  const networkInfo: NetworkData = networkData(Networks.arbitrum)
  const pools = usePools()
  const coingeckoData: CoingeckoDataSummary[] = useCoingecko()
  const balanceBPT: TokenProps[] = usePromise(
    getPoolsBalanceListBatch,
    networkInfo.pools,
    wallet?.address
  )
  const poolsWithBalance: PortfolioStrategies[] = pools.map((pool) => {
    const bptBalance: number =
      balanceBPT.find(
        (b) => b.address.toLowerCase() === pool.address.toLowerCase()
      )?.balance || 0
    const bptPrice: number =
      parseFloat(pool.liquidity) / parseFloat(pool.totalShares)
    const bptUserUsdValue: number = bptPrice * bptBalance
    return {
      ...pool,
      bptBalance,
      bptPrice: bptPrice?.toFixed(2),
      bptUserUsdValue
    }
  })
  const poolsWithTokenWeight: PortfolioStrategies[] = addTokensWeightForPools(
    poolsWithBalance,
    coingeckoData
  )
  const totalStrategiesUsdValue: number = poolsWithTokenWeight.reduce(
    (acc, p) => acc + p.bptUserUsdValue,
    0
  )
  const totalVariation: number =
    totalStrategiesUsdValue === 0
      ? 0
      : poolsWithTokenWeight.reduce(
          (acc, p) =>
            acc +
            (p.priceVariation * p.bptUserUsdValue) / totalStrategiesUsdValue,
          0
        )
  return {
    strategies: poolsWithTokenWeight,
    totalVariation24h: totalVariation.toFixed(2),
    totalUsdValue: totalStrategiesUsdValue
  }
}

export const useCamelotPortfolio = (): PortfolioData => {
  const camelotPools = useCamelotPools()
  const wallet = useSelector((s: any) => s?.wallet as WalletData)
  const coingeckoData: CoingeckoDataSummary[] = useCoingecko()
  const poolsFullData: CamelotData[] = usePromise(
    getFullCamelotData,
    wallet?.address
  )
  const camelotPoolsWithUserUsdValue: CamelotDataWithUserValues[] =
    calculateLpValues(poolsFullData, coingeckoData)

  // Join the info of the pools with the user info
  const camelotCompleteInfo: PortfolioCamelot[] = camelotPools.map((p) => {
    const poolInfo = camelotPoolsWithUserUsdValue.find(
      (c) => c.nftContractAddress.toLowerCase() === p.nftAddress.toLowerCase()
    )
    return { ...p, ...poolInfo }
  })

  const totalUserUsdValue: number = camelotCompleteInfo.reduce(
    (acc, c) => acc + c.usdUserValue,
    0
  )

  // Add pool price variation depending on tokens weight
  const poolsWithPriceVariation: PortfolioCamelot[] = camelotCompleteInfo.map(
    (p) => {
      const totalPriceVariation: number = p.filteredTokens.reduce(
        (acc, t) => acc + t.priceVariation24h * t.weight,
        0
      )
      const priceVariation: number =
        totalPriceVariation / p.filteredTokens.length
      return { ...p, priceVariation }
    }
  )

  // Total variation for camelot pools considering the portion that it represents in user porftolio
  const totalVariation: number =
    totalUserUsdValue === 0
      ? 0
      : poolsWithPriceVariation.reduce(
          (acc, c) =>
            acc + (c.priceVariation * c.usdUserValue) / totalUserUsdValue,
          0
        )

  return {
    camelot: camelotCompleteInfo,
    totalUsdValue: totalUserUsdValue,
    totalVariation24h: totalVariation.toFixed(2)
  }
}

//* This hooks return the totalVariation in 24 hours considering the variation of each token and the weight that they have inside the total position value.
const usePortfolio = (): PortfolioData => {
  const arbitrumAssets: PortfolioData = useAssetsPortfolio(Networks.arbitrum)
  const ethereumAssets: PortfolioData = useAssetsPortfolio(Networks.ethereum)
  const binanceAssets: PortfolioData = useAssetsPortfolio(Networks.binance)
  const baseAssets: PortfolioData = useAssetsPortfolio(Networks.base)
  const optimismAssets: PortfolioData = useAssetsPortfolio(Networks.optimism)
  const modeAssets: PortfolioData = useAssetsPortfolio(Networks.mode)
  const scrollAssets: PortfolioData = useAssetsPortfolio(Networks.scroll)
  //  const lineaAssets: PortfolioData = useAssetsPortfolio(Networks.linea)
  const allTokens: PortfolioTokens[] = [
    ...arbitrumAssets.tokens,
    ...ethereumAssets.tokens,
    ...binanceAssets.tokens,
    ...baseAssets.tokens,
    ...optimismAssets.tokens,
    ...modeAssets.tokens,
    ...scrollAssets.tokens
  ]
  const strategies: PortfolioData = useStrategiesPortfolio()
  const camelot: PortfolioData = useCamelotPortfolio()
  const totalPortfolioUsdValue: number =
    arbitrumAssets.totalUsdValue +
    ethereumAssets.totalUsdValue +
    binanceAssets.totalUsdValue +
    strategies.totalUsdValue +
    camelot.totalUsdValue
  const totalVariation = (
    parseFloat(arbitrumAssets?.totalVariation24h) +
    parseFloat(ethereumAssets?.totalVariation24h) +
    parseFloat(binanceAssets?.totalVariation24h) +
    parseFloat(strategies?.totalVariation24h) +
    parseFloat(camelot?.totalVariation24h)
  )?.toFixed(2)
  return {
    tokens: allTokens,
    strategies: strategies.strategies,
    camelot: camelot.camelot,
    totalVariation24h: totalVariation,
    totalUsdValue: totalPortfolioUsdValue
  }
}

export default usePortfolio
