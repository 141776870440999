import { WalletData } from 'src/types'
import { WalletActions } from '../actions/wallet'

const initialState = null

export function walletReducer(
  state: WalletData | null = initialState,
  action: WalletActions
): WalletData | null {
  switch (action.type) {
    case 'wallet/connect':
      return action.payload
    case 'wallet/disconnect':
      return initialState
    default:
      return state
  }
}
