import './CamelotSelectedPositionData.sass'
import { FormattedMessage } from 'react-intl'

interface CamelotSelectedPositionDataProps {
  selectedPositionData: { balance: number; usdValue: number }
  bptName: string
}

const CamelotSelectedPositionData = ({
  selectedPositionData,
  bptName
}: CamelotSelectedPositionDataProps) => {
  return (
    <div id="camelot-position-data">
      <section>
        <span>
          <strong>USD Value: </strong>$
          {selectedPositionData?.usdValue?.toFixed(2)}
        </span>
        <span>
          <strong>LP Amount: </strong>{' '}
          {selectedPositionData?.balance?.toFixed(6)}{' '}
          <strong className="bpt">{bptName}</strong>
        </span>
      </section>
      <p>
        <FormattedMessage id="after-withdrawal-you-receive" />{' '}
        <strong>{bptName.split('-')[0]}</strong> &{' '}
        <strong>{bptName.split('-')[1]}</strong>
      </p>
    </div>
  )
}

export default CamelotSelectedPositionData
