import { FormattedMessage } from 'react-intl'

import './DiscontinuedNotice.sass'

const DiscontinuedNotice = () => {
  const link = (
    <a
      href="https://x.com/ClayStack_HQ/status/1793570888853418239"
      target="_blank"
      rel="noreferrer noopener"
    >
      <FormattedMessage id="announcement" />
    </a>
  )
  return (
    <div id="discontinued-notice">
      <div className="icon icon-exclamation" />
      <h2>
        <FormattedMessage id="discontinued-notice.title" />
      </h2>
      <p>
        <FormattedMessage id="discontinued-notice.text" values={{ link }} />
      </p>
    </div>
  )
}

export default DiscontinuedNotice
