import { Points, ProductIcons } from 'src/types'
import { useAutolayerPoints } from 'src/hooks/useAutolayerPoints'
import {
  useBedrockPoints,
  useInceptionPoints,
  useKelpPoints,
  useMellowPoints,
  usePrimestakedPoints,
  usePufferPoints,
  useSwBtcPoints,
  useSwellPoints,
  useSymbioticPoints,
  useTotalEigenLayerPoints,
  useTotalEtherFiPoints,
  useTotalRenzoPoints
} from 'src/hooks/useEigenLayerPoints'
import {
  useLrtEtherPoints,
  useLrtMellowPoints,
  useLrtRenzoLeftPoints,
  useLrtSymbioticPoints
} from 'src/routes/ProductInfo/LeftInfo/LeftPointsTypes/LRT/useLrtPoints'
import {
  useLeftEigenLayer,
  useLeftEigenLayerEtherFiPoints
} from 'src/routes/ProductInfo/LeftInfo/LeftPointsTypes/EigenLayer/useLeftPointsEigenLayer'

// Dashboard Points
export const productsPointsWithoutAutoLayerAndEigenLayer: Record<
  Exclude<ProductIcons, 'autoLayer' | 'eigenLayer'>,
  () => Points
> = {
  renzo: useTotalRenzoPoints,
  kelp: useKelpPoints,
  puffer: usePufferPoints,
  swell: useSwellPoints,
  ether: useTotalEtherFiPoints,
  bedrock: useBedrockPoints,
  primestaked: usePrimestakedPoints,
  inception: useInceptionPoints,
  mellow: useMellowPoints,
  symbiotic: useSymbioticPoints,
  swbtc: useSwBtcPoints
}

// Airdrop Points
export const productsPointsWithoutAutoLayer: Record<
  Exclude<ProductIcons, 'autoLayer'>,
  () => Points
> = {
  eigenLayer: useTotalEigenLayerPoints,
  ...productsPointsWithoutAutoLayerAndEigenLayer
}

// Portfolio Points
export const productPoints: Record<ProductIcons, () => Points> = {
  autoLayer: useAutolayerPoints,
  eigenLayer: useTotalEigenLayerPoints,
  ...productsPointsWithoutAutoLayerAndEigenLayer
}

// Asset Info Points
export const productPointsLrt: Record<
  Exclude<ProductIcons, 'eigenLayer' | 'autoLayer'>,
  () => Points
> = {
  renzo: useLrtRenzoLeftPoints,
  swbtc: useSwBtcPoints,
  kelp: useKelpPoints,
  puffer: usePufferPoints,
  swell: useSwellPoints,
  primestaked: usePrimestakedPoints,
  bedrock: useBedrockPoints,
  inception: useInceptionPoints,
  mellow: useLrtMellowPoints,
  symbiotic: useLrtSymbioticPoints,
  ether: useLrtEtherPoints
}

// Asset Info Points For EigenLayer
export const productsPointsEigenLayer: Record<
  Exclude<
    ProductIcons,
    'autoLayer' | 'mellow' | 'renzo' | 'swbtc' | 'swell' | 'symbiotic'
  >,
  () => Points
> = {
  eigenLayer: useLeftEigenLayer,
  kelp: useKelpPoints,
  puffer: usePufferPoints,
  bedrock: useBedrockPoints,
  primestaked: usePrimestakedPoints,
  inception: useInceptionPoints,
  ether: useLeftEigenLayerEtherFiPoints
}
