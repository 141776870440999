import { ArbitrumData } from 'src/data/Arbitrum'
import { AUTOLAYER_ABIS } from './abis'
import { POINTS_CONTRACT } from './addresses'
import { Networks } from 'src/utils/networkHelper'
import getWeb3 from './web3'
import { AbiItem } from 'web3'

const getAutolayerPoints = async (userAddress: string): Promise<string> => {
  const arbWeb3 = getWeb3('arbitrum')
  const ethWeb3 = getWeb3('ethereum')
  const bnbWeb3 = getWeb3('binance')
  const optimismWeb3 = getWeb3('optimism')
  const baseWeb3 = getWeb3('base')
  const pointsAddressArb: string = POINTS_CONTRACT[Networks.arbitrum]
  const pointAddressEth: string = POINTS_CONTRACT[Networks.ethereum]
  const pointAddressBnb: string = POINTS_CONTRACT[Networks.binance]
  const pointAddressOptimism: string = POINTS_CONTRACT[Networks.optimism]
  const pointAddressBase: string = POINTS_CONTRACT[Networks.base]
  const pointsAbi: AbiItem[] = AUTOLAYER_ABIS['0x1'].pointsContract
  const pointsContractArb = new arbWeb3.eth.Contract(
    pointsAbi,
    pointsAddressArb
  )
  const pointsContractEth = new ethWeb3.eth.Contract(pointsAbi, pointAddressEth)
  const pointsContractBnb = new bnbWeb3.eth.Contract(pointsAbi, pointAddressBnb)
  const pointsContractOptimism = new optimismWeb3.eth.Contract(
    pointsAbi,
    pointAddressOptimism
  )
  const pointsContractBase = new baseWeb3.eth.Contract(
    pointsAbi,
    pointAddressBase
  )
  const userPointsWeiArb: number = await pointsContractArb.methods
    .userPoints(userAddress)
    .call()
  const userPointsWeiEth: number = await pointsContractEth.methods
    .userPoints(userAddress)
    .call()
  const userPointsWeiBnb: number = await pointsContractBnb.methods
    .userPoints(userAddress)
    .call()
  const userPointsWeiOptimism: number = await pointsContractOptimism.methods
    .userPoints(userAddress)
    .call()
  const userPointsWeiBase: number = await pointsContractBase.methods
    .userPoints(userAddress)
    .call()
  const userPointsArb: string = arbWeb3.utils.fromWei(
    userPointsWeiArb.toString(),
    'ether'
  )
  const userPointsEth: string = ethWeb3.utils.fromWei(
    userPointsWeiEth.toString(),
    'ether'
  )
  const userPointsBnb: string = bnbWeb3.utils.fromWei(
    userPointsWeiBnb.toString(),
    'ether'
  )
  const userPointsOptimism: string = optimismWeb3.utils.fromWei(
    userPointsWeiOptimism.toString(),
    'ether'
  )
  const userPointsBase: string = baseWeb3.utils.fromWei(
    userPointsWeiBase.toString(),
    'ether'
  )
  const total: number =
    parseFloat(userPointsArb) +
    parseFloat(userPointsEth) +
    parseFloat(userPointsBnb) +
    parseFloat(userPointsOptimism) +
    parseFloat(userPointsBase)
  return total.toFixed(5)
}

export default getAutolayerPoints

//* Read transaction information for getting the corresponding event to get the points generated in this tx
export const getAutolayerPointsAfterTx = (
  sendSwapTx,
  wallet: string,
  isDeposit: boolean = true,
  outputAddress?: string
): { transactionHash: string; points: string } => {
  if (!isDeposit) {
    const isForPoints: boolean = ArbitrumData.tokensLRS.some(
      (token) => token.address.toLowerCase() === outputAddress.toLowerCase()
    )
    if (!isForPoints) {
      return { transactionHash: sendSwapTx?.transactionHash, points: '0' }
    }
  }

  const arbWeb3 = getWeb3('arbitrum')
  const pointTopic: string =
    '0x235c18fe54960bc2b1072d9a404e225ef54b523b788af3cb8510696cd28d1621'
  const logs = sendSwapTx?.logs
  const filterdLogsByTopic = logs?.filter((log) =>
    log?.topics.includes(pointTopic)
  )
  const decodedData = filterdLogsByTopic?.map(
    (log) =>
      arbWeb3.eth.abi.decodeParameters(
        [
          { type: 'address', name: 'user' },
          { type: 'uint256', name: 'points' }
        ],
        log?.data
      ) as unknown as { user: string; points: string }
  )

  const info = decodedData.find((info) => info.user.toLowerCase() === wallet)

  const pointsWei: string = info.points
  const points: string = arbWeb3.utils.fromWei(pointsWei, 'ether')
  return { transactionHash: sendSwapTx?.transactionHash, points }
}
