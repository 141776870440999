import { IS_DEV } from 'src/constants'

export const CAMELOT_POOLS_INFO = '0x5652b1aDcA3047ee1518c8893A4E3670A32f6A66'

// TODO: Add new addresses for contracts

//* Swaps = forwarder

//* Arbitrum contracts
const swapContractDEV = '0x9235f91630D49344FbFD2f975decc78c313f008E'
const pointsContractDEV = '0xcC9fD3a26A657635016148DBa8C3C00DbC175757'
const pointsMultipleSeasonArbDEV = '0x583a137D927ab7D183f12b3CD53cDE3062777E94'
const referalArbDEV = '0xB73C0c4E238455bD0cf27a790D2b4315A3170e55'

const swapContractPROD = '0x7d71F03B550D7373F965098c2E618005ff580740'
const pointsContractPROD = '0x052a9b2D41207ecCb1b63Af90236AFA4F515a217'
const pointsMultipleSeasonArbPRO = '0xEE7c5DAa083b6e5867b8d708Aa82B20328be2EA8'
const referalArbPRO = '0x65740Ce1a059094DeA19e2Aa6d11219dE5fCC275'

//* Ethereum contracts
const swapContractEthDEV = '0x05854f7fb3E21760184C768B2a6fE0363EAFaFBE'
const pointsContractEthDEV = '0x9566D8998a47D53C948803744864e63456177353'

const swapContractEthereumPROD = '0x51591b8e88739B8f823307dcA92220f479709868'
const pointsContractEthereumPROD = '0x9566D8998a47D53C948803744864e63456177353'
const pointsMultipleSeasonEthPRO = '0x0066d7600Ee5A01BA2D1194723523d0D701a9938'
const referalEthPRO = '0x14D33B74Ed2ef1bdf8AF49B6C0f221877ccafCE3'

//* Bnb Contracts
const swapContractBnbDEV = '0xA6C83520610D5d9399568be60D4B3cC9AC951286'
const pointsContractBnbDEV = '0x0f69682Bb7E6269225B0349A67cC5dc8BDC94fC9'
const pointsMultipleSeasonBnbDEV = '0xc10ae42be523093976f5fed67b39e22403200113'
const referalBnbDEV = '0x9864294b35e6531E2ea82caE81B573092194EF74'

const swapContractBnbPROD = '0x25c3388ffB182187b3B1A0A6DbB5D6C9e3F5Df09'
const pointsContractBnbPROD = '0x9566D8998a47D53C948803744864e63456177353'
const pointsMultipleSeasonBnbPRO = '0x05854f7fb3E21760184C768B2a6fE0363EAFaFBE'
const referalBnbPRO = '0xfF89342C737Ae850d620842089073a0E94a8C7BB'

// OPTIMISM
const pointsOptimismDEV = '0x6ffC92822FBeC64c250e6B76e16c331c3d22d391'
const multipleSeasonOptimismDEV = '0x611d9DaBfe93Da81bA35324C76e4DB88B09c5456'
const referalOptimismDEV = '0x53DeA76df3dA9f27C1313CE186fA90C46e1E4427'
const swapContractOptimismDEV = '0x4d3Cb5EC44C609dB073786448E13bB9616f8d2d5'

const pointsOptimismPRO = '0x9566D8998a47D53C948803744864e63456177353'
const multipleSeasonOptimismPRO = '0xBC28FC02568EB3A74f84E21b8976cC8DE34a315F'
const referalOptimismPRO = '0x1B5AA205e1f216d49d0b7d3313C0b9037AF66845'
const swapContractOptimismPRO = '0x8f73B8303198C30Df10E02257EfBd17f7eC929a8'

// BASE
const pointsBaseDEV = '0xA735BDE3dcb77F4442Ee90b10868A70346Fc7DAf'
const multipleSeasonBaseDEV = '0xe7350651d22bd1843531De9631735620a83cF792'
const referalBaseDEV = '0xd5dC90100777f800F1adAB275435Dfffda6B49b0'
const swapContracBaseDEV = '0x85C031266b4bfc52219ED285660196C3608a98F4'

const pointsBasePRO = '0x9566D8998a47D53C948803744864e63456177353'
const multipleSeasonBasePRO = '0xBC28FC02568EB3A74f84E21b8976cC8DE34a315F'
const referalBasePRO = '0x1B5AA205e1f216d49d0b7d3313C0b9037AF66845'
const swapContracBasePRO = '0x8f73B8303198C30Df10E02257EfBd17f7eC929a8'

// MODE

const pointsModeDEV = '0x33e034CF6f0847cDFCf92737F22552d329098fE6'
const multipleSeasonModeDEV = '0xbefA54f940101476998D546553993f434E1Df7Fe'
// SCROLL

const pointsScrollDEV = '0x33e034CF6f0847cDFCf92737F22552d329098fE6'
const multipleSeasonScrollDEV = '0xbefA54f940101476998D546553993f434E1Df7Fe'

export const SWAPS_CONTRACT = {
  '0xa4b1': IS_DEV ? swapContractDEV : swapContractPROD,
  '0x1': IS_DEV ? swapContractEthDEV : swapContractEthereumPROD,
  '0x38': IS_DEV ? swapContractBnbDEV : swapContractBnbPROD,
  '0xa': IS_DEV ? swapContractOptimismDEV : swapContractOptimismPRO,
  '0x2105': IS_DEV ? swapContracBaseDEV : swapContracBasePRO,
  '0xe708': IS_DEV ? 'a' : 'b',
  '0x868b': IS_DEV ? 'a' : 'b',
  '0x82750': IS_DEV ? 'a' : 'b'
}

export const POINTS_CONTRACT = {
  '0xa4b1': IS_DEV ? pointsContractDEV : pointsContractPROD,
  '0x1': IS_DEV ? pointsContractEthDEV : pointsContractEthereumPROD,
  '0x38': IS_DEV ? pointsContractBnbDEV : pointsContractBnbPROD,
  '0xa': IS_DEV ? pointsOptimismDEV : pointsOptimismPRO,
  '0x2105': IS_DEV ? pointsBaseDEV : pointsBasePRO,
  '0xe708': IS_DEV ? 'a' : 'b',
  '0x868b': IS_DEV ? pointsModeDEV : 'b',
  '0x82750': IS_DEV ? pointsScrollDEV : 'b'
}

export const POINTS_SEASON2_CONTRACT = {
  '0xa4b1': IS_DEV ? pointsMultipleSeasonArbDEV : pointsMultipleSeasonArbPRO,
  '0x1': pointsMultipleSeasonEthPRO,
  '0x38': IS_DEV ? pointsMultipleSeasonBnbDEV : pointsMultipleSeasonBnbPRO,
  '0xa': IS_DEV ? multipleSeasonOptimismDEV : multipleSeasonOptimismPRO,
  '0x2105': IS_DEV ? multipleSeasonBaseDEV : multipleSeasonBasePRO,
  '0xe708': IS_DEV ? 'a' : 'b',
  '0x868b': IS_DEV ? multipleSeasonModeDEV : multipleSeasonModeDEV,
  '0x82750': IS_DEV ? multipleSeasonScrollDEV : multipleSeasonScrollDEV
}

export const REFERALS_CONTRACT = {
  '0xa4b1': IS_DEV ? referalArbDEV : referalArbPRO,
  '0x38': IS_DEV ? referalBnbDEV : referalBnbPRO,
  '0x1': referalEthPRO,
  '0xa': IS_DEV ? referalOptimismDEV : referalOptimismPRO,
  '0x2105': IS_DEV ? referalBaseDEV : referalBasePRO,
  '0xe708': IS_DEV ? 'a' : 'b',
  '0x868b': IS_DEV ? 'a' : 'b',
  '0x82750': IS_DEV ? 'a' : 'b'
}
