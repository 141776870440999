import { NetworkData, networkData } from 'src/data'
import { CoingeckoDataSummary } from 'src/hooks/useCoingecko'
import { BalancerPool } from 'src/types/pools'
import { NetworkIds } from 'src/utils/networkHelper'

export const calculatePoolTokenOutputAmount = (
  thisPool: BalancerPool,
  coingeckoData: CoingeckoDataSummary[],
  amount: number,
  poolTokenAddress: string,
  chainId?: NetworkIds
) => {
  const bptPrice: number =
    parseFloat(thisPool.liquidity) / parseFloat(thisPool.totalShares)
  const bptUsdValue: number = bptPrice * amount
  const networkInfo: NetworkData = networkData(chainId)
  const poolTokenSymbol: string = networkInfo.tokens.find(
    (token) => token.address.toLowerCase() === poolTokenAddress.toLowerCase()
  )?.id
  const outputTokenPrice: number = coingeckoData.find(
    (token) => token.symbol.toLowerCase() === poolTokenSymbol.toLowerCase()
  )?.current_price
  const outputPoolTokenAmount: number = bptUsdValue / outputTokenPrice
  return outputPoolTokenAmount
}
