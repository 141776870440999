import { Suspense } from 'react'
import Deposit from '../Deposit/Deposit'
import { useParams } from 'react-router-dom'
import DepositPools from '../Deposit/DepositPools'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import Loading from '../Loading/Loading'
import DepositCamelotPools from '../Deposit/DepositCamelotPools'
import { networkData } from 'src/data'
import useNetworkFromRoute from 'src/hooks/useNetworkFromRoute'

import './DepositDialog.sass'

export interface DepositDialogProps {
  setIsDepositDialogOpen: (value: boolean) => void
  darkMode?: boolean
  refreshAutoLayerPoints?: () => void
}

const DepositContainer = ({
  darkMode,
  refreshAutoLayerPoints
}: Omit<DepositDialogProps, 'setIsDepositDialogOpen'>) => {
  const { productName, productAddress } = useParams<{
    productName: string
    productAddress: string
  }>()

  const { chainId } = useNetworkFromRoute()
  const camelotPool = networkData(chainId)?.camelotPools?.find(
    (pool) => pool.pos.toLowerCase() === productAddress?.toLowerCase()
  )?.bptName

  if (productName) {
    return (
      <Deposit
        darkMode={darkMode}
        refreshAutoLayerPoints={refreshAutoLayerPoints}
      />
    )
  }

  if (camelotPool) {
    return (
      <DepositCamelotPools
        darkMode={darkMode}
        refreshAutoLayerPoints={refreshAutoLayerPoints}
      />
    )
  }

  return (
    <DepositPools
      darkMode={darkMode}
      refreshAutoLayerPoints={refreshAutoLayerPoints}
    />
  )
}

const DepositDialog = ({
  setIsDepositDialogOpen,
  refreshAutoLayerPoints,
  darkMode = false
}: DepositDialogProps) => {
  const { productName, productAddress } = useParams<{
    productName: string
    productAddress: string
  }>()

  const { chainId } = useNetworkFromRoute()
  const bptName: string = networkData(chainId)?.pools?.find(
    (pool) => pool.address.toLowerCase() === productAddress?.toLowerCase()
  )?.bptName

  const title: string = productName ?? bptName

  return (
    <div id="deposit-dialog">
      <header>
        <h3> Restake {title}</h3>
        <button onClick={() => setIsDepositDialogOpen(false)}>
          <span className="icon icon-close" />
        </button>
      </header>
      <ErrorBoundary fallback={<div>Error</div>}>
        <Suspense fallback={<Loading colored />}>
          <DepositContainer
            darkMode={darkMode}
            refreshAutoLayerPoints={refreshAutoLayerPoints}
          />
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}

export default DepositDialog
