import { TokensInfoWithoutMultiplier } from 'src/hooks/useTokensLst'
import tokensLRS from './tokensLRS.json'
import tokens from './tokens.json'
const lrtTokens = tokensLRS.map((t) => ({
  address: t.address,
  id: t.id,
  name: t.name,
  decimals: t.decimals
}))
const newTokens = tokens.slice(0, 3).concat(lrtTokens).concat(tokens.slice(3))

export const BaseData = {
  tokensLRS: tokensLRS as TokensInfoWithoutMultiplier[],
  tokens: newTokens
}
