import { SimpleFetchSDK, constructSimpleSDK } from '@paraswap/sdk'
import { NetworkName } from 'src/utils/networkHelper'

declare const __CLIENT__: boolean

const paraswapMap = new Map<NetworkName, SimpleFetchSDK>()

export const getParaswapSDK = (networkName: NetworkName) => {
  if (!paraswapMap.has(networkName)) {
    let chainId: number = 42161
    if (networkName === 'ethereum') chainId = 1
    else if (networkName === 'binance') chainId = 56
    else if (networkName === 'optimism') chainId = 10
    else if (networkName === 'base') chainId = 8453
    // else if (networkName === 'linea') chainId = 43113
    // else if (networkName === 'mode') chainId = 101
    // else if (networkName === 'scroll') chainId = 1
    paraswapMap.set(
      networkName,
      constructSimpleSDK({ chainId, fetch: window.fetch, version: '6.2' })
    )
  }
  return __CLIENT__ && paraswapMap.get(networkName)
}
