import MarkdownMessage from '@gluedigital/markdown-message'
import ConnectButton from 'src/components/ConnectWallet/ConnectButton/ConnectButton'

import './FallbackNoConnected.sass'

const FallbackNoConnected = () => {
  return (
    <section id="left-points-fallback">
      <div id="fallback-no-connected">
        <div>
          <p>
            <MarkdownMessage id="left-points-fallback" />
          </p>
          <ConnectButton />
        </div>
      </div>
    </section>
  )
}

export default FallbackNoConnected
