import TokenInput from '@components/TokenInput/TokenInput'
import { FormattedMessage } from 'react-intl'
import { FormEvent, useState } from 'react'
import { AuBnbDialogProps } from '../../AuBnbDialog'
import AuBnbDialogSteps from '../../Steps/AuBnbDialogSteps'
import { useWallet } from '@hooks/useWallet'
import { getTokenImage } from '@utils/getTokenImage'
import {
  depositBnbToAuBnb,
  getAubnbRatio
} from 'src/contracts/aubnb/aubnbMethods'

import './AuBnbDialogStake.sass'

export default function AuBnbDialogStake({
  updateRefreshKey,
  stepsData
}: AuBnbDialogProps) {
  const { addStep, successStep, errorStep, steps, setDone, resetSteps } =
    stepsData
  const address = useWallet()
  const [enabled, setEnabled] = useState<boolean>(false)

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const bnbWeiAmount = e.target[5].dataset.wei as string

    try {
      addStep('stake.staking-dialog.stake.confirmation')
      const { ratio } = await getAubnbRatio()
      const depositTx = await depositBnbToAuBnb(
        address,
        ratio,
        BigInt(bnbWeiAmount)
      )
      successStep({ txHash: depositTx })
      setDone('stake.staking-dialog.steps.done-staking', depositTx)
      updateRefreshKey()
    } catch (err) {
      errorStep()
      console.error('Error staking aubnb', err)
    }
  }
  return (
    <form onSubmit={handleOnSubmit}>
      <TokenInput
        className="stake"
        defaultCoin="bnb"
        handleExternalChange={(value) => {
          resetSteps()
          if (value === 0) {
            setEnabled(false)
          } else if (enabled === false) {
            setEnabled(true)
          }
        }}
      >
        <div>
          <img src={getTokenImage('bnb')} alt="BNB logo" id="token-logo" />
          <span>$BNB</span>
        </div>
      </TokenInput>

      <AuBnbDialogSteps steps={steps}>
        <button className="continue" type="submit" disabled={!enabled}>
          <FormattedMessage id="continue" />
        </button>
      </AuBnbDialogSteps>
    </form>
  )
}
