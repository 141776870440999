import { NetworkActions } from '../actions/network'

const initialState = ''

export function networkReducer(
  state: string = initialState,
  action: NetworkActions
): string {
  switch (action.type) {
    case 'network/set':
      return action.payload
    default:
      return state
  }
}
