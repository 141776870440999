import TokenInput from '@components/TokenInput/TokenInput'
import { AutolayerLogos } from '@static/logos/autolayer'
import { FormattedMessage } from 'react-intl'
import { stakeReward } from 'src/contracts/staking/stake'
import { StakeDialogsProps } from '../../StakeDialog'
import StakeDialogSteps from '../../Steps/StakeDialogSteps'
import { useWallet } from '@hooks/useWallet'

export default function StakeDialogStakeReward({
  initialValue,
  poolAddress,
  positionId,
  updateRefreshKey,
  stepsData
}: StakeDialogsProps) {
  const address = useWallet()

  const { addStep, successStep, errorStep, steps, setDone } = stepsData

  const handleStakeRewards = async () => {
    try {
      addStep('stake.staking-dialog.stake.confirmation')
      const tx = await stakeReward(address, poolAddress, positionId)
      successStep()
      setDone('stake.staking-dialog.steps.done-restake', tx)
      updateRefreshKey()
    } catch (err) {
      console.error('Error staking rewards', err)
      errorStep()
    }
  }

  return (
    <>
      <TokenInput className="stake" disabled initialValue={initialValue}>
        <div>
          <img src={AutolayerLogos.autolayerIconWhite} alt="$LAY3R logo" />
          <span>$LAY3R</span>
        </div>
      </TokenInput>
      <StakeDialogSteps steps={steps}>
        <button
          className="continue"
          onClick={handleStakeRewards}
          disabled={initialValue === 0}
        >
          <FormattedMessage id="continue" />
        </button>
      </StakeDialogSteps>
    </>
  )
}
