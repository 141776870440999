import { FormattedMessage } from 'react-intl'
import { getTokenImage } from 'src/utils/getTokenImage'
import { useCompactNumbers } from 'src/hooks/useCompactNumbers'
import useTokensWeightForPools from 'src/hooks/useTokensWeightForPools'
import { BalancerPool, Pools, PoolsTokens } from 'src/types/pools'

import './PoolTokenTable.sass'

const PoolTokenTable = ({ tokens }: { tokens: PoolsTokens[] }) => {
  const calc = useCompactNumbers()
  return (
    <table id="pool-token-table">
      <colgroup>
        <col className="col-token" />
        <col className="col-balance" />
        <col className="col-usd-value" />
        <col className="col-token-weight" />
      </colgroup>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="pool-token-table.token" />
          </th>
          <th>
            <FormattedMessage id="pool-token-table.balance" />
          </th>
          <th>
            <FormattedMessage id="pool-token-table.usd-value" />
          </th>
          <th>
            <FormattedMessage id="pool-token-table.token-weight" />
          </th>
        </tr>
      </thead>
      <tbody>
        {tokens?.map((t) => {
          const scanLink: string = 'https://arbiscan.io/address/' + t?.address
          const finalValue = calc(t.usdValue, 2)
          return (
            <tr key={t?.address}>
              <td className="cell-token">
                <img
                  src={getTokenImage(t?.symbol.toLowerCase())}
                  alt={t?.symbol}
                  className="token-image"
                />
                <strong className="token-name">{t.symbol}</strong>
                <a rel="noopener noreferrer" target="_blank" href={scanLink}>
                  <span className="icon icon-external_window" />
                </a>
              </td>
              <td className="cell-balance">
                <span className="token-balance">
                  {parseFloat(t?.balance).toFixed(5)}
                </span>
              </td>
              <td className="cell-usd-value">
                <span className="usd-value">${finalValue}</span>
              </td>
              <td className="cell-token-weight">
                <span className="token-weight">
                  {(t.weight * 100).toFixed(2)}%
                </span>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const PoolTokenBalancerTable = ({
  productInfo
}: {
  productInfo: BalancerPool
}) => {
  const info = useTokensWeightForPools(productInfo)
  return (
    <div className="pool-token-wrapper">
      <PoolTokenTable tokens={info[0].filteredTokens} />
    </div>
  )
}

const PoolTokenWrapper = ({ productInfo }: { productInfo: Pools }) => {
  return productInfo.provider === 'Camelot' ? (
    <PoolTokenTable tokens={productInfo.filteredTokens} />
  ) : (
    <PoolTokenBalancerTable productInfo={productInfo} />
  )
}

export default PoolTokenWrapper
