import tokensImages from '../data/tokensImages.json'
import avsImages from '../data/avs/avsImages.json'
import protocolImages from '../data/protocol/protocolImages.json'

export const getTokenImage = (symbol: string) => {
  const lowerSymbol: string = symbol.toLowerCase()
  if (tokensImages.includes(`${lowerSymbol}.png`)) {
    return `/images/tokens/${lowerSymbol}.png`
  }
  return '/images/tokens/TOKEN.svg'
}

export const getAVSImage = (symbol: string) => {
  const lowerSymbol: string = symbol.toLowerCase()
  if (avsImages.includes(`${lowerSymbol}.png`)) {
    return `/images/avs/${lowerSymbol}.png`
  }
  return '/images/tokens/TOKEN.svg'
}

export const getProtocolImage = (symbol: string) => {
  const lowerSymbol: string = symbol.toLowerCase()
  if (protocolImages.includes(`${lowerSymbol}.png`)) {
    return `/images/protocol/${lowerSymbol}.png`
  }
  return '/images/tokens/TOKEN.svg'
}
