import {
  StakeDialogReducedStatus,
  StakeDialogStep
} from '@components/Stake/StakeDialog/Steps/StakeDialogSteps'
import { useState } from 'react'

export function useDialogSteps() {
  const [steps, setSteps] = useState<StakeDialogStep[]>([])

  const addStep = (text: string) =>
    setSteps((prev) => [...prev, { type: 'loading', text }])

  const resetSteps = () => setSteps([])

  const modifyStep = (
    type: StakeDialogReducedStatus,
    extras?: { [key: string]: any }
  ) => {
    setSteps((prev) => {
      const copyPrev = [...prev]
      const lastStep = copyPrev.at(-1)
      lastStep.type = type
      lastStep.extra = extras

      return [...copyPrev.slice(0, -1), lastStep]
    })
  }

  const successStep = (extras?: { [key: string]: any }) =>
    modifyStep('success', extras)

  const errorStep = (extras?: { [key: string]: any }) =>
    modifyStep('error', extras)

  const setDone = (message: string, txHash) =>
    setSteps((prev) => [
      ...prev,
      { type: 'done', text: message, extra: { txHash } }
    ])

  return {
    successStep,
    errorStep,
    addStep,
    setDone,
    resetSteps,
    steps
  }
}
