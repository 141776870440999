import { AffiliatesEntryProps } from '../../AffiliatesTable/TableRow/TableRow'
import { FormattedMessage } from 'react-intl'
import copyIcon from 'src/static/vectors/copy.svg'

export default function CardEntry({
  code,
  points,
  traders,
  volume
}: AffiliatesEntryProps) {
  const copyToClipboard = () => navigator.clipboard.writeText(code)

  return (
    <article className="affiliate-card">
      <header>
        <h4>{code}</h4>
        <button onClick={copyToClipboard}>
          <img src={copyIcon} alt="copy icon" />
        </button>
      </header>
      <main>
        <div>
          <span className="head">
            <FormattedMessage id="referrals.affiliates.header.volume" />
          </span>
          <span className="value">{volume}</span>
        </div>
        <div>
          <span className="head">
            <FormattedMessage id="referrals.affiliates.header.trader" />
          </span>
          <span className="value">{traders}</span>
        </div>
      </main>
      <footer>
        <span>
          <FormattedMessage id="referrals-info-card.title.points-earned" />
        </span>
        <span className="value">{points}</span>
      </footer>
    </article>
  )
}
