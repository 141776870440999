import { GENERIC_ABIS } from '../abis'
import { getEtherNumberAmount } from '../tokenDecimals/getEtherNumberAmount'
import getWeb3 from '../web3'
import {
  NetworkIds,
  getNativeCurrencySymbol,
  getNetworkName
} from 'src/utils/networkHelper'
import { BalanceResult } from './getBalanceListBatch'

export const getTokenBalanceList = async (
  tokens: any[],
  address: string,
  chainId: NetworkIds
) => {
  const userAddress: string = address
  if (!userAddress) return []
  const networkCoin: string = getNativeCurrencySymbol(chainId)
  const weiBalance = []
  const balancesEthPromise = []
  const tokensList = tokens.length > 10 ? tokens.slice(0, 10) : tokens
  const networkName = getNetworkName(chainId)
  const web3 = getWeb3(networkName)
  for (const token of tokensList) {
    if (token.id === networkCoin) {
      weiBalance.push(web3.eth.getBalance(userAddress))
      continue
    }
    const tokenContract = new web3.eth.Contract(
      GENERIC_ABIS.token as any[],
      token?.address
    )
    weiBalance.push(tokenContract.methods.balanceOf(userAddress).call())
  }

  const balancesWei = await Promise.all(weiBalance)

  for (let i = 0; i < balancesWei?.length; i++) {
    balancesEthPromise.push(
      getEtherNumberAmount(tokensList[i].address, balancesWei[i], chainId)
    )
  }

  const balancesEth = await Promise.all(balancesEthPromise)

  const balancesResult: any[] = tokensList.map((token, i) => {
    const info: BalanceResult = {
      balance: balancesEth[i],
      id: token.id,
      name: token.name,
      address: token.address
    }
    return info
  })
  return balancesResult
}

export const getPoolsBalanceList = async (pools: any[], address: string) => {
  const userAddress: string = address
  if (!userAddress) return []
  const weiBalance = []
  const web3 = getWeb3('arbitrum')
  for (const token of pools) {
    const tokenContract = new web3.eth.Contract(
      GENERIC_ABIS.token as any[],
      token?.address
    )
    weiBalance.push(tokenContract.methods.balanceOf(userAddress).call())
  }

  const balancesWei = await Promise.all(weiBalance)
  const balancesEth: string[] = balancesWei.map((balance) =>
    web3.utils.fromWei(balance, 'ether')
  )
  const balancesResult: any[] = pools.map((token, i) => {
    const info: BalanceResult = {
      balance: balancesEth[i],
      id: token.id,
      name: token.name,
      address: token.address
    }
    return info
  })
  return balancesResult
}
