import CustomSelect from '../../../CustomSelect/CustomSelect'
import { Network } from 'src/types'
import { networkLogos } from 'src/static/logos/networks'
import networkChoice from '../networkChoice.json'
import { NetworkName } from 'src/utils/networkHelper'

const networks = Object.entries(networkChoice).map(([value, { label }]) => ({
  value,
  label
})) as { value: NetworkName; label: string }[]

const filteredNetwork = networks.filter(
  ({ value }) => value !== 'linea' && value !== 'mode' && value !== 'scroll'
)
const options: { value: Network; label: string }[] = [
  { value: 'all networks', label: 'All Networks' },
  ...filteredNetwork
]

const Option = ({ option }: { option: { value: Network; label: string } }) => (
  <div className="selected-network">
    {option.value === 'all networks' ? null : (
      <img
        src={networkLogos[option.value]}
        alt={`${option.label} icon`}
        loading="lazy"
      />
    )}
    <span>{option.label}</span>
  </div>
)

interface SelectNetworkProps {
  handleNetworkChange: (value: Network) => void
  selectedNetwork: Network
}

export default function SelectNetwork({
  handleNetworkChange,
  selectedNetwork
}: SelectNetworkProps) {
  return (
    <CustomSelect
      OptionComponent={Option}
      onChange={{
        add: handleNetworkChange
      }}
      options={options}
      value={selectedNetwork}
    />
  )
}
