import { FormattedMessage } from 'react-intl'
import useNetworkFromRoute from 'src/hooks/useNetworkFromRoute'
import { changeChain } from 'src/utils/walletHelpers'
import { getItemByNetwork, NetworkIds } from 'src/utils/networkHelper'
import { useWallet } from '@hooks/useWallet'

import './ProductInfoButtons.sass'

interface ChangeNetworkButtonProps {
  darkMode?: boolean
  usedChainId?: NetworkIds
}

const ChangeNetworkButton = ({
  darkMode,
  usedChainId
}: ChangeNetworkButtonProps) => {
  const { chainId } = useNetworkFromRoute()
  const { networkTag } = getItemByNetwork(chainId)
  const wallet = useWallet()
  const chainIdToChange = usedChainId ?? chainId
  return (
    <div
      className={`product-info-buttons network ${!darkMode ? 'dark-theme' : ''}`}
    >
      <p>
        <span>
          <FormattedMessage id="change-network-to" />
        </span>
        <strong>{networkTag}</strong>
      </p>
      <button
        disabled={wallet === null}
        className="network"
        onClick={() => changeChain(chainIdToChange)}
      >
        <FormattedMessage id="change-network" />
      </button>
    </div>
  )
}

export default ChangeNetworkButton
