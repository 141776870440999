import { AbiItem } from 'web3-utils'

export const referalsAbi: AbiItem[] = [
  {
    type: 'constructor',
    inputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'claimReferal',
    inputs: [
      {
        name: 'referalCode',
        type: 'string',
        internalType: 'string'
      }
    ],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'createReferal',
    inputs: [
      {
        name: 'referalCode',
        type: 'string',
        internalType: 'string'
      }
    ],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'getReferalCode',
    inputs: [
      {
        name: 'code',
        type: 'string',
        internalType: 'string'
      }
    ],
    outputs: [
      {
        name: '',
        type: 'tuple',
        internalType: 'struct ReferalsUtils.ReferalCode',
        components: [
          {
            name: 'owner',
            type: 'address',
            internalType: 'address'
          },
          {
            name: 'users',
            type: 'address[]',
            internalType: 'address[]'
          },
          {
            name: 'volumeUsd',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'usageCount',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'pointsObtained',
            type: 'uint256',
            internalType: 'uint256'
          }
        ]
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'getReferalCodes',
    inputs: [
      {
        name: 'wallet',
        type: 'address',
        internalType: 'address'
      }
    ],
    outputs: [
      {
        name: '',
        type: 'string[]',
        internalType: 'string[]'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'getUserReferal',
    inputs: [
      {
        name: 'user',
        type: 'address',
        internalType: 'address'
      }
    ],
    outputs: [
      {
        name: '',
        type: 'tuple',
        internalType: 'struct ReferalsUtils.RefereeStats',
        components: [
          {
            name: 'referalCode',
            type: 'string',
            internalType: 'string'
          },
          {
            name: 'volumeUsd',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'usageCount',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'pointsObtained',
            type: 'uint256',
            internalType: 'uint256'
          }
        ]
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'isAllowed',
    inputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address'
      }
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'owner',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'removeAllowed',
    inputs: [
      {
        name: 'notAllowedAddress_',
        type: 'address',
        internalType: 'address'
      }
    ],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'renounceOwnership',
    inputs: [],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'setAllowed',
    inputs: [
      {
        name: 'allowedAddress_',
        type: 'address',
        internalType: 'address'
      }
    ],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'trackTransaction',
    inputs: [
      {
        name: 'user',
        type: 'address',
        internalType: 'address'
      },
      {
        name: 'referalCode',
        type: 'string',
        internalType: 'string'
      },
      {
        name: 'volumeUsd',
        type: 'uint256',
        internalType: 'uint256'
      },
      {
        name: 'referalPoints',
        type: 'uint256',
        internalType: 'uint256'
      },
      {
        name: 'refereePoints',
        type: 'uint256',
        internalType: 'uint256'
      }
    ],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'transferOwnership',
    inputs: [
      {
        name: 'newOwner',
        type: 'address',
        internalType: 'address'
      }
    ],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'userCodes',
    inputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address'
      },
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256'
      }
    ],
    outputs: [
      {
        name: '',
        type: 'string',
        internalType: 'string'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'event',
    name: 'CodeClaimed',
    inputs: [
      {
        name: 'user',
        type: 'address',
        indexed: false,
        internalType: 'address'
      },
      {
        name: 'code',
        type: 'string',
        indexed: false,
        internalType: 'string'
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'CodeCreated',
    inputs: [
      {
        name: 'user',
        type: 'address',
        indexed: false,
        internalType: 'address'
      },
      {
        name: 'code',
        type: 'string',
        indexed: false,
        internalType: 'string'
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'OwnershipTransferred',
    inputs: [
      {
        name: 'previousOwner',
        type: 'address',
        indexed: true,
        internalType: 'address'
      },
      {
        name: 'newOwner',
        type: 'address',
        indexed: true,
        internalType: 'address'
      }
    ],
    anonymous: false
  }
  // {
  //   type: 'error',
  //   name: 'CodeAlreadyClaimed',
  //   inputs: [
  //     {
  //       name: 'code',
  //       type: 'string',
  //       internalType: 'string'
  //     }
  //   ]
  // },
  // {
  //   type: 'error',
  //   name: 'CodeAlreadyExists',
  //   inputs: [
  //     {
  //       name: 'code',
  //       type: 'string',
  //       internalType: 'string'
  //     }
  //   ]
  // },
  // {
  //   type: 'error',
  //   name: 'CodeNotFound',
  //   inputs: [
  //     {
  //       name: 'code',
  //       type: 'string',
  //       internalType: 'string'
  //     }
  //   ]
  // },
  // {
  //   type: 'error',
  //   name: 'NotAllowedForwarder',
  //   inputs: []
  // },
  // {
  //   type: 'error',
  //   name: 'OwnableInvalidOwner',
  //   inputs: [
  //     {
  //       name: 'owner',
  //       type: 'address',
  //       internalType: 'address'
  //     }
  //   ]
  // },
  // {
  //   type: 'error',
  //   name: 'OwnableUnauthorizedAccount',
  //   inputs: [
  //     {
  //       name: 'account',
  //       type: 'address',
  //       internalType: 'address'
  //     }
  //   ]
  // },
  // {
  //   type: 'error',
  //   name: 'OwnerCannotClaimHisOwnCode',
  //   inputs: [
  //     {
  //       name: 'code',
  //       type: 'string',
  //       internalType: 'string'
  //     }
  //   ]
  // }
]
