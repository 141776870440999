export default function TableRowFallback() {
  return (
    <div className="row fallback">
      <div className="code" />
      <span className="volume" />
      <span className="traders" />
      <span className="points" />
    </div>
  )
}
