import { useModal } from '@gluedigital/modal'
import { useNavigate } from 'react-router-dom'
import usePortfolio from 'src/hooks/usePortfolio'
import {
  PortfolioCamelot,
  PortfolioData,
  PortfolioStrategies
} from 'src/types/portfolioTypes'
import TokenImageList from 'src/components/TokenImageList/TokenImageList'
import useIsResponsive from 'src/hooks/useIsResponsive'
import { NetworkName } from 'src/utils/networkHelper'

import './DefiBodyTable.sass'
import { PoolsTokens } from 'src/types/pools'

const DefiRow = ({
  strategy,
  camelotPools
}: {
  strategy?: PortfolioStrategies
  camelotPools?: PortfolioCamelot
}) => {
  const name: string = strategy ? strategy.name : camelotPools.name
  const lpUserUsdValue: number = strategy
    ? strategy.bptUserUsdValue
    : camelotPools.usdUserValue
  const apy: string = strategy ? strategy.apy : camelotPools.apy
  const tokens: PoolsTokens[] = strategy
    ? strategy.filteredTokens
    : camelotPools.filteredTokens
  const provider: string = strategy ? strategy.provider : camelotPools.provider
  const address: string = strategy ? strategy.address : camelotPools.pos
  const lpName: string = strategy ? strategy.bptName : camelotPools.bptName

  const modal = useModal()
  const navigate = useNavigate()
  const isResponsive = useIsResponsive()
  // TODO: Change this network to real one when we integrate pools in Ethereum
  const networkName: NetworkName = 'arbitrum'

  const handleRow = () => {
    navigate(`/info/defi-strategies/${networkName}/${address.toLowerCase()}`)
    modal.hide()
  }

  return (
    <tr
      className="defi-row port-folio-grid-row"
      id={`defi-row-${lpName}`}
      onClick={() => handleRow()}
    >
      <td className="row-title">
        <h2 title={name}>{name}</h2>
        {isResponsive && (
          <span className={`protocol ${provider?.toLocaleLowerCase()}`}>
            {provider}
          </span>
        )}
      </td>
      <td className="row-token">
        <TokenImageList tokens={tokens} mini />
      </td>
      {!isResponsive && (
        <td className="row-protocol">
          <span className={`protocol ${provider?.toLocaleLowerCase()}`}>
            {provider}
          </span>
        </td>
      )}
      <td className="row-apy">
        <span title={apy?.toString() + '%'} className="apy">
          {(+apy)?.toFixed(2)}%
        </span>
      </td>
      <td className="row-usd-value">
        <span className="amount" title={'$' + lpUserUsdValue?.toString()}>
          <span className="dollar-symbol">$</span>
          <strong>{lpUserUsdValue?.toFixed(2)}</strong>
        </span>
      </td>
    </tr>
  )
}

const DefiBodyTable = () => {
  const data: PortfolioData = usePortfolio()
  const isResponsive = useIsResponsive()
  const strategies: PortfolioStrategies[] = data.strategies.filter(
    (d) => d.bptBalance > 0 || d.bptUserUsdValue > 0
  )
  const camelotPools: PortfolioCamelot[] = data.camelot.filter(
    (d: PortfolioCamelot) => d.balanceLp > 0 || d.usdUserValue > 0
  )
  return (
    <table className="portfolio-table" id="defi-table" cellSpacing={0}>
      <colgroup>
        <col className="col-title" />
        <col className="col-tokens" />
        {!isResponsive && <col className="col-protocol" />}
        <col className="col-apy" />
        <col className="col-usd-value" />
      </colgroup>
      <thead>
        <tr>
          <th className="row-title">Title</th>
          <th className="row-token">Tokens</th>
          {!isResponsive && <th className="row-protocol">Protocol</th>}
          <th className="row-apy">Apy</th>
          <th className="row-usd-value">USD Value</th>
        </tr>
      </thead>
      <tbody>
        {strategies.map((d) => (
          <DefiRow key={d.id} strategy={d} />
        ))}
        {camelotPools.map((d) => (
          <DefiRow key={d.id} camelotPools={d} />
        ))}
      </tbody>
    </table>
  )
}

export default DefiBodyTable
