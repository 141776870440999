import TableRow from './TableRow/TableRow'
import { FormattedMessage } from 'react-intl'
import { ReferalStats } from 'src/types'
import TableRowFallback from './TableRow/TableRowFallback'
import NetworkWithLogo from 'src/components/NetworkWithLogo/NetworkWithLogo'

import './AffiliatesTable.sass'

interface AffiliatesTableProps {
  openDialog: () => void
  codes: ReferalStats[]
  loading: boolean
}

export default function AffiliatesTable({
  openDialog,
  codes,
  loading
}: AffiliatesTableProps) {
  return (
    <section id="affiliates-table">
      <header>
        <div className="title">
          <div>
            <h3>
              <FormattedMessage id="referrals.affiliates.header.title" />
            </h3>
            <NetworkWithLogo />
          </div>

          <button onClick={openDialog}>
            <span className="icon icon-plus" />
            <FormattedMessage id="start-inviting" />
          </button>
        </div>
        <div className="table-header">
          <span>
            <FormattedMessage id="referrals.affiliates.header.code" />
          </span>
          <span>
            <FormattedMessage id="referrals.affiliates.header.volume" />
          </span>
          <span>
            <FormattedMessage id="referrals.affiliates.header.trader" />
          </span>
          <span>
            <FormattedMessage id="referrals-info-card.title.points-earned" />
          </span>
        </div>
      </header>
      <main>
        {loading ? (
          <>
            <TableRowFallback />
            <TableRowFallback />
            <TableRowFallback />
          </>
        ) : (
          codes.map((code, i) => (
            <TableRow
              key={i}
              code={code.name}
              points={code.pointsObtained}
              traders={code.usageCount}
              volume={code.volumeUsd}
            />
          ))
        )}
      </main>
    </section>
  )
}
