import { useSelector } from 'react-redux'
import { NetworkName, Networks } from 'src/utils/networkHelper'

export const useNetworkName = (): NetworkName => {
  const network: NetworkName = useSelector((s: any) => s.network)
  const networkName = Object.keys(Networks).find(
    (key) => Networks[key] === network
  ) as NetworkName
  return networkName
}
