import './PointsInfo.sass'

const PointsInfo = () => {
  return (
    <div className="points-info">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://docs.autolayer.io/autolayer/welcome/introduction/points-system"
      >
        <span>?</span>
      </a>
    </div>
  )
}

export default PointsInfo
