import { FormattedNumber } from 'react-intl'

import copyIcon from 'src/static/vectors/copy.svg'

export interface AffiliatesEntryProps {
  code: string
  volume: number
  traders: number
  points: number
}

export default function TableRow({
  code,
  points,
  traders,
  volume
}: AffiliatesEntryProps) {
  const copyToClipboard = () => navigator.clipboard.writeText(code)
  return (
    <div className="row">
      <div className="code">
        <span>{code}</span>
        <button onClick={copyToClipboard}>
          <img src={copyIcon} alt="copy icon" />
        </button>
      </div>
      <span className="volume">
        <FormattedNumber
          value={volume}
          currency="USD"
          currencyDisplay="symbol"
          style="currency"
        />
      </span>
      <span className="traders">{traders}</span>
      <span className="points">
        <FormattedNumber
          value={points}
          style="decimal"
          maximumFractionDigits={2}
        />
      </span>
    </div>
  )
}
