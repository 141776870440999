import { Suspense } from 'react'
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary'
import { TopPointsDashboard } from './TopPointsDashboard'
import { useOtherEigenLayerPoints } from 'src/hooks/useEigenLayerPoints'

import PointsIconList from '../Icons/PointsIconList/PointsIconList'
import { PointsDashboardError } from './PointsDashboardError/PointsDashboardError'
import { PointsDashboardLoading } from './PointsDashboardFallback/PointsDashboardFallback'

const OtherPoints = () => {
  //* At this moment we only show in other points the total of EigenLayerPoints generated by Renzo and KelpDAO
  const { points } = useOtherEigenLayerPoints()
  return (
    <TopPointsDashboard amount={+points} type="other-points">
      <PointsIconList
        list={[
          'renzo',
          'kelp',
          'puffer',
          'swell',
          'ether',
          'bedrock',
          'primestaked',
          'inception',
          'mellow',
          'symbiotic',
          'swbtc'
        ]}
      />
    </TopPointsDashboard>
  )
}

const OtherPointsWrapper = () => {
  return (
    <ErrorBoundary fallback={<PointsDashboardError />}>
      <Suspense fallback={<PointsDashboardLoading type="other-points" />}>
        <OtherPoints />
      </Suspense>
    </ErrorBoundary>
  )
}

export default OtherPointsWrapper
