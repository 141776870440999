import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import GenericPoints from '../GenericPoints/GenericPoints'
import ApyInfo from '../ApyInfo/ApyInfo'
import TokenImageList from '../TokenImageList/TokenImageList'
import ListMultiplier from '../MultiplierInfo/ListMultiplier/ListMultiplier'
import { NetworkName, getNetworkName } from 'src/utils/networkHelper'
import { protocolsIcons } from 'src/static/logos/protocols'
import { BalancerPool, CamelotPool, Pools } from 'src/types/pools'

import './DefiCard.sass'

interface DefiCardProps {
  strategy?: BalancerPool
  camelotPools?: CamelotPool
}

function checkIsBalancer(pool: Pools): pool is BalancerPool {
  return (pool as BalancerPool).featured
}

const DefiCard = ({ strategy, camelotPools }: DefiCardProps) => {
  const infoUsed = strategy ?? camelotPools
  const {
    network,
    address,
    name,
    apy,
    provider,
    filteredTokens,
    liquidity,
    multipliers
  } = infoUsed
  const urlData: string = strategy ? address : camelotPools?.pos
  const navigate = useNavigate()
  const networkName: NetworkName = getNetworkName(network)
  const tvl = +liquidity

  const isFeatured = checkIsBalancer(infoUsed) ? infoUsed.featured : false

  return (
    <article
      id={`defi-card-${name}`}
      className={`defi-card ${isFeatured ? 'featured' : ''}`}
    >
      <header>
        <TokenImageList tokens={filteredTokens} />
        <span className={`provider-badge ${provider.toLowerCase()}`}>
          <img
            loading="lazy"
            src={protocolsIcons?.[provider.toLowerCase()]}
            alt={provider}
            className="provider-badge-image"
          />
          <span>{provider}</span>
        </span>
      </header>
      <h1 title={name}>{name}</h1>
      <section className="strategy-card-points">
        <ApyInfo apr={!isNaN(+apy) ? +apy : null} />
        <GenericPoints type="tvl" points={tvl} />
      </section>
      <ListMultiplier multipliers={multipliers} />
      <button
        className={isFeatured ? 'button-featured' : 'button-not-featured'}
        onClick={() =>
          navigate(`/info/defi-strategies/${networkName}/` + urlData)
        }
      >
        <FormattedMessage id="more-info" />
      </button>
      {isFeatured ? <div id="featured-bg" /> : <div className="bg" />}
    </article>
  )
}

export default DefiCard
