import { useCallback, useEffect, useRef, useState } from 'react'
import ReferralsCard from './ReferralsCard/ReferralsCard'
import ReferralsDialog from '../ReferralsDialog/ReferralsDialog'
import edit from 'src/static/vectors/edit.svg'
import referalUtils from 'src/utils/referals'
import { RefereeStats, WalletData } from 'src/types'
import getWeb3 from 'src/contracts/web3'
import { useSelector } from 'react-redux'
import { getNetworkName, NetworkIds } from 'src/utils/networkHelper'
import { FormattedNumber } from 'react-intl'

const Traders = () => {
  const networkId = useSelector((s: { network: NetworkIds }) => s.network)
  const wallet = useSelector((s: { wallet: WalletData }) => s.wallet)

  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [refereeStats, setRefereeStats] = useState<RefereeStats | null>(null)
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const firstRenderRef = useRef<boolean>(true)

  const handleClose = () => setShowDialog(false)

  const handleOpen = () => setShowDialog(true)

  const [refreshKey, setRefreshKey] = useState<number>(0)

  const refresh = useCallback(() => setRefreshKey((prev) => prev + 1), [])

  useEffect(() => {
    if (!wallet?.address) return
    setLoading(true)
    const web3 = getWeb3(getNetworkName(networkId))

    referalUtils
      .getUserReferal(wallet.address, web3, networkId)
      .then((refereeStats) => {
        const newRefereeStats: RefereeStats = {
          ...refereeStats,
          pointsObtained: referalUtils.getValue(
            refereeStats.pointsObtained,
            networkId
          ),
          volumeUsd: referalUtils.getValue(refereeStats.volumeUsd, networkId)
        }

        setRefereeStats(newRefereeStats)
        if (firstRenderRef.current) {
          setShowDialog(refereeStats.referalCode === '')
        }

        firstRenderRef.current = false
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }, [wallet?.address, networkId, refreshKey])

  const status = error ? 'error' : loading ? 'loading' : 'done'

  return (
    <>
      <div id="traders" className="referrals-grid">
        <ReferralsCard
          type="traders"
          text="active-code"
          amount={refereeStats?.referalCode}
          status={status}
          icon={
            !refereeStats?.referalCode ? (
              <button onClick={handleOpen} className="icon">
                <img src={edit} alt="edit icon" onClick={handleOpen} />
              </button>
            ) : null
          }
        />
        <ReferralsCard
          type="traders"
          text="trading-volume"
          status={status}
          amount={
            <FormattedNumber
              value={refereeStats?.volumeUsd}
              currency="USD"
              currencyDisplay="narrowSymbol"
              style="currency"
            />
          }
        />
        <ReferralsCard
          type="traders"
          text="points-earned"
          status={status}
          amount={refereeStats?.pointsObtained}
        />
        {showDialog && (
          <ReferralsDialog
            handleClose={handleClose}
            type="insert"
            refresh={refresh}
          />
        )}
      </div>
    </>
  )
}

export default Traders
