import CommonPoints, { CommonPointsProps } from './CommonPoints/CommonPoints'

interface TopPointsDashboardProps extends CommonPointsProps {
  grouped?: boolean
}

export function TopPointsDashboard(props: TopPointsDashboardProps) {
  return (
    <article className={`points-item-dashboard points-item-${props.type}`}>
      <CommonPoints {...props} />
      <footer className={props.grouped ? 'group' : ''}>{props.children}</footer>
    </article>
  )
}
