import { NetworkData } from 'src/data'
import { TokenProps } from './TokenSelectorList'

//* Remove from tokenList the tokens which are included in the pool of the token used as output
export const filterTokenInListToAvoidSwapSameToken = (
  filterCoins: TokenProps[],
  networkInfo: NetworkData,
  productAddress: string,
  inputCoin?: string
): TokenProps[] => {
  let coinstWithInputCoin: TokenProps[] = []
  if (!productAddress) {
    coinstWithInputCoin = filterCoins.filter(
      (c) => c.id.toLowerCase() !== inputCoin?.toLowerCase()
    )
  } else {
    const balancerPool = networkInfo.pools.find(
      (p) => p.address.toLowerCase() === productAddress.toLowerCase()
    )
    if (balancerPool) {
      coinstWithInputCoin = filterCoins.filter(
        (c) => !balancerPool.symbolTokens.includes(c.id.toLowerCase())
      )
    } else {
      const camelotPool = networkInfo.camelotPools.find(
        (p) => p.pos.toLowerCase() === productAddress.toLowerCase()
      )
      if (camelotPool) {
        coinstWithInputCoin = filterCoins.filter(
          (c) => !camelotPool.symbolTokens.includes(c.id.toLowerCase())
        )
      }
    }
  }
  return coinstWithInputCoin
}
