import {
  BuildSwapTxInput,
  BuildOptionsBase,
  TransactionParams,
  OptimalRate
} from '@paraswap/sdk'
import { BuildTxInputBase } from '@paraswap/sdk/dist/methods/swap/transaction'
import { SWAPS_CONTRACT } from '../addresses'
import swapUtils from 'src/utils/swap'
import {
  NetworkIds,
  NetworkName,
  getNetworkName
} from 'src/utils/networkHelper'
import { getParaswapSDK } from './sdk'

const buildParaswapTx = async (
  priceRoute: OptimalRate,
  tokenIn: string,
  tokenOut: string,
  amount: string,
  chainId: NetworkIds,
  slippage: number
): Promise<TransactionParams> => {
  const swapsContractAddress: string = SWAPS_CONTRACT[chainId.toLowerCase()]

  const TX_FEE: number = 20 // 0.2%
  const FEE_TREASURY_ADDRESS: string = process.env.FEE_TREASURY

  const inputToken = swapUtils.getTokenAddress(tokenIn, chainId)
  const outputToken = swapUtils.getTokenAddress(tokenOut, chainId)

  const inputDecimals = swapUtils.getTokenDecimals(inputToken, chainId)
  const outputDecimals = swapUtils.getTokenDecimals(outputToken, chainId)

  const buildTxInputBase: BuildTxInputBase = {
    srcToken: inputToken,
    destToken: outputToken,
    userAddress: swapsContractAddress.toLowerCase(),
    srcDecimals: inputDecimals,
    destDecimals: outputDecimals
  }

  const buildTxInput: BuildSwapTxInput = {
    ...buildTxInputBase,
    priceRoute,
    slippage,
    srcAmount: amount,
    partnerAddress: FEE_TREASURY_ADDRESS,
    partnerFeeBps: TX_FEE,
    takeSurplus: false,
    isDirectFeeTransfer: true,
    isSurplusToUser: false
  }

  const options: BuildOptionsBase = {
    ignoreChecks: true
  }
  const networkName: NetworkName = getNetworkName(chainId)
  const paraSwapMin = getParaswapSDK(networkName)
  const txParams: TransactionParams = await paraSwapMin.swap.buildTx(
    buildTxInput,
    options
  )
  return txParams
}

export default buildParaswapTx
