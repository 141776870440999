import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import SettingsMenu from '../SettingsMenu/SettingsMenu'

import './ExternalHeader.sass'

interface ExternalHeaderProps {
  name: string
  showSettingsMenu?: boolean
  learnMoreUrl?: string
}

const ExternalHeader = ({
  name,
  showSettingsMenu = true,
  learnMoreUrl = ''
}: ExternalHeaderProps) => {
  return (
    <header className="masthead-external" id={`${name}-masthead-external`}>
      <div className="container">
        <Link to="/" className="button">
          <span className="icon icon-angle_left" />
          <span className="back-text">
            <FormattedMessage id="back-to-dashboard" />
          </span>
        </Link>
        <h1>
          <span>
            <FormattedMessage id={`${name}-header.title`} />
          </span>
        </h1>
        <div className="right-buttons">
          {!!learnMoreUrl && (
            <a
              rel="noopener noreferrer"
              className="button learn-more"
              target="_blank"
              href={learnMoreUrl}
            >
              <FormattedMessage id="learn-more" />
            </a>
          )}
          {showSettingsMenu && <SettingsMenu />}
        </div>
      </div>
    </header>
  )
}

export default ExternalHeader
