import { FormattedMessage } from 'react-intl'
import copyIcon from 'src/static/vectors/copy.svg'

export default function CardEntryFallback() {
  return (
    <article className="affiliate-card fallback">
      <header>
        <h4></h4>
        <button>
          <img src={copyIcon} alt="copy icon" />
        </button>
      </header>
      <main>
        <div>
          <span className="head">
            <FormattedMessage id="referrals.affiliates.header.volume" />
          </span>
          <span className="value" />
        </div>
        <div>
          <span className="head">
            <FormattedMessage id="referrals.affiliates.header.trader" />
          </span>
          <span className="value" />
        </div>
      </main>
      <footer>
        <span>
          <FormattedMessage id="referrals-info-card.title.points-earned" />
        </span>
        <span className="value" />
      </footer>
    </article>
  )
}
