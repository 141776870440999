import { OptimalRate, SwapSide, ContractMethod } from '@paraswap/sdk'
import {
  NetworkIds,
  NetworkName,
  getNetworkName
} from 'src/utils/networkHelper'
import swapUtils from 'src/utils/swap'
import { getParaswapSDK } from './sdk'

const getParaswapRoute = async (
  tokenIn: string,
  tokenOut: string,
  amount: string,
  chainId: NetworkIds
): Promise<OptimalRate> => {
  const inputToken = swapUtils.getTokenAddress(tokenIn, chainId)
  const outputToken = swapUtils.getTokenAddress(tokenOut, chainId)

  const inputDecimals = swapUtils.getTokenDecimals(inputToken, chainId)
  const outputDecimals = swapUtils.getTokenDecimals(outputToken, chainId)

  const networkName: NetworkName = getNetworkName(chainId)
  const paraSwapMin = getParaswapSDK(networkName)
  const priceRoute: OptimalRate = await paraSwapMin.swap.getRate({
    srcToken: inputToken,
    destToken: outputToken,
    srcDecimals: inputDecimals,
    destDecimals: outputDecimals,
    amount,
    side: SwapSide.SELL,
    options: {
      includeContractMethods: [
        ContractMethod.swapExactAmountInOnUniswapV3,
        ContractMethod.swapExactAmountIn
      ],
      excludeContractMethodsWithoutFeeModel: true
    }
  })
  return priceRoute
}

export default getParaswapRoute
