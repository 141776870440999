import './ApyInfo.sass'

const ApyInfo = ({ apr }: { apr: number }) => {
  return (
    <div className="apy-info">
      <section>
        <h3>APY</h3>
      </section>
      <section>
        <span title={apr + '%'} className="apy-value">
          {' '}
          {apr ?? '-'} %
        </span>
      </section>
    </div>
  )
}

export default ApyInfo
