import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { SmallIcons } from 'src/static/images/iconsSmall'

import './Welcome.sass'

const Welcome = () => {
  const navigate = useNavigate()
  const clickButton = () => {
    import(/* webpackChunkName: "wallet" */ 'src/utils/wallet')
      .then((m) => m.connect())
      .then(() => navigate('/lrt'))
  }
  return (
    <div id="welcome-connect">
      <div className="wrapper">
        <h1>
          <FormattedMessage id="landing.hero-banner.title" />
        </h1>
        <p>
          <FormattedMessage id="landing.hero-banner.text" />
        </p>
        <button onClick={clickButton}>
          <img src={SmallIcons.walletWhite} alt="wallet" />
          <span>
            <FormattedMessage id="connect-wallet" />
          </span>
        </button>
      </div>
    </div>
  )
}

export default Welcome
