import { WhiteIcons } from 'src/static/logos/whiteIcons'
import { AllPoints, ProductIcons } from 'src/types'

interface ImageProps {
  title: string
  alt: string
}

const icons: Record<ProductIcons, ImageProps> = {
  autoLayer: {
    alt: 'Autolayer icon',
    title: 'AutoLayer'
  },
  eigenLayer: {
    alt: 'EigenLayer icon',
    title: 'EigenLayer'
  },
  renzo: {
    alt: 'Renzo icon',
    title: 'Renzo'
  },
  kelp: {
    alt: 'Kelp DAO icon',
    title: 'Kelp DAO'
  },
  puffer: {
    alt: 'Puffer Finance icon',
    title: 'Puffer Finance'
  },
  swell: {
    alt: 'Swell Network icon',
    title: 'Swell Network'
  },
  ether: {
    alt: 'Ether icon',
    title: 'Ether'
  },
  bedrock: {
    alt: 'Bedrock icon',
    title: 'Bedrock'
  },
  primestaked: {
    alt: 'Primestaked icon',
    title: 'Primestaked'
  },
  inception: {
    alt: 'Inception icon',
    title: 'Inception'
  },
  mellow: {
    alt: 'Mellow icon',
    title: 'Mellow'
  },
  symbiotic: {
    alt: 'Symbiotic icon',
    title: 'Symbiotic'
  },
  swbtc: {
    alt: 'Swell Btc icon',
    title: 'Swell Btc'
  }
}

export interface PointsIconProps {
  type: AllPoints
}

export default function PointsIcon({ type }: PointsIconProps) {
  const { alt, title } = icons[type]
  const src = WhiteIcons[type]

  return (
    <div className={`points-symbol ${type}-symbol`}>
      <img loading="lazy" title={title} src={src} alt={alt} />
    </div>
  )
}
