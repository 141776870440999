import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCompactNumbers } from 'src/hooks/useCompactNumbers'
import { AllPoints } from 'src/types'
import PointsInfo from '../PointsInfo/PointsInfo'

export interface CommonPointsProps {
  amount: number
  type: AllPoints
  children?: ReactNode
  withInfo?: boolean
  inlineLogo?: boolean
}

export default function CommonPoints({
  amount = 0,
  children,
  type,
  withInfo = false,
  inlineLogo = false
}: CommonPointsProps) {
  const DECIMALS: number = amount < 1000 ? 2 : 3
  const finalAmount = useCompactNumbers()
  const value = finalAmount(amount, DECIMALS)
  const title =
    amount === -1
      ? 'coming soon'
      : amount < 1
        ? amount.toFixed(5)
        : amount.toFixed(2)

  return (
    <section className="inside-container">
      <h2>
        <span>
          <FormattedMessage id={`dashboard.${type}`} />
        </span>
        {withInfo ? <PointsInfo /> : null}
      </h2>
      <div>
        <h3 title={title}>
          {type === 'your-deposit' ? <span className="currency">$</span> : ''}
          {+value === -1 ? (
            <span id="coming-soon">
              <FormattedMessage id="coming-soon" />
            </span>
          ) : (
            <span>{value}</span>
          )}
        </h3>
        {inlineLogo ? children : null}
      </div>
    </section>
  )
}
