import { useFetch } from '@gluedigital/ruse-fetch-extras'
import { dataMicroserviceUrl } from 'src/constants'
import { BalancerPool, CamelotPool } from 'src/types/pools'

export const useCamelotPools = (): CamelotPool[] => {
  const pools = useFetch<CamelotPool[]>(dataMicroserviceUrl + '/camelot-pools')
  return pools
}

export const useSingleCamelotPool = (poolAddress: string): CamelotPool => {
  const pool = useFetch<CamelotPool>(
    dataMicroserviceUrl + '/camelot-pool/' + poolAddress
  )
  return pool
}

export const useSinglePool = (poolAddress: string): BalancerPool => {
  const pool = useFetch<BalancerPool>(
    dataMicroserviceUrl + '/pool/' + poolAddress
  )
  return pool
}

const usePools = (): BalancerPool[] => {
  const pools = useFetch<BalancerPool[]>(dataMicroserviceUrl + '/pools')
  return pools
}

export default usePools
