import { referalsAbi } from 'src/contracts/abis/AutoLayerAbis/ReferalsAbi'
import { ReferalCodeData, ReferalStats, RefereeStats } from 'src/types'
import Web3 from 'web3'
import { REFERALS_CONTRACT } from 'src/contracts/addresses'
import getWeb3 from 'src/contracts/web3'
import { getNetworkName, NetworkIds } from './networkHelper'
import web3 from '@utils/web3'

function getTotalReferal(
  allCodes: ReferalStats[],
  key: 'users' | 'volumeUsd' | 'pointsObtained'
): number {
  const total = allCodes
    .map((codeStats) =>
      key === 'users' ? codeStats[key].length : +codeStats[key]
    )
    .reduce((acc, curr) => acc + curr, 0)
  return total
}

function getValue(value: number, networkId: NetworkIds): number {
  const web3 = getWeb3(getNetworkName(networkId))
  return +Number(web3.utils.fromWei(value.toString(), 'ether')).toFixed(2)
}

async function createReferalCode(
  code: string,
  wallet: string,
  networkId: NetworkIds
): Promise<string> {
  const referalsContract = new web3.eth.Contract(
    referalsAbi,
    REFERALS_CONTRACT[networkId]
  )

  const tx = await referalsContract.methods.createReferal(code).send({
    from: wallet
  })
  return tx.transactionHash
}

async function getCreatedReferalCodes(
  wallet: string,
  web3: Web3,
  networkId: NetworkIds
): Promise<string[]> {
  const referalsContract = new web3.eth.Contract(
    referalsAbi,
    REFERALS_CONTRACT[networkId]
  )
  const createdCodes: string[] = await referalsContract.methods
    .getReferalCodes(wallet)
    .call()

  return createdCodes
}

async function claimCode(
  wallet: string,
  code: string,
  networkId: NetworkIds
): Promise<string> {
  const referalsContract = new web3.eth.Contract(
    referalsAbi,
    REFERALS_CONTRACT[networkId]
  )
  const tx = await referalsContract.methods.claimReferal(code).send({
    from: wallet
  })
  return tx.transactionHash
}

async function getReferalCode(
  code: string,
  web3: Web3,
  networkId: NetworkIds
): Promise<ReferalCodeData> {
  const referalsContract = new web3.eth.Contract(
    referalsAbi,
    REFERALS_CONTRACT[networkId]
  )

  const codeData: ReferalCodeData = await referalsContract.methods
    .getReferalCode(code)
    .call()

  return codeData
}

async function getCreatedCodesData(
  wallet: string,
  web3: Web3,
  networkId: NetworkIds
) {
  const codes: string[] = await getCreatedReferalCodes(wallet, web3, networkId)

  const result: ReferalStats[] = []

  for (const code of codes) {
    const codeData = await getReferalCode(code, web3, networkId)
    const { volumeUsd, usageCount, pointsObtained, users } = codeData
    result.push({ volumeUsd, usageCount, pointsObtained, name: code, users })
  }

  return result
}

async function getUserReferal(
  wallet: string,
  web3: Web3,
  networkId: NetworkIds
): Promise<RefereeStats> {
  const referalsContract = new web3.eth.Contract(
    referalsAbi,
    REFERALS_CONTRACT[networkId]
  )

  const refereeStats: RefereeStats = await referalsContract.methods
    .getUserReferal(wallet)
    .call()

  return refereeStats
}

async function checkUserClaimedCode(
  wallet: string,
  web3: Web3,
  networkId: NetworkIds
): Promise<boolean> {
  const refereeStats = await getUserReferal(wallet, web3, networkId)
  return refereeStats.referalCode !== ''
}

const referalUtils = {
  createReferalCode,
  getCreatedReferalCodes,
  claimCode,
  getCreatedCodesData,
  checkUserClaimedCode,
  getUserReferal,
  getTotalReferal,
  getValue,
  getReferalCode
}

export default referalUtils
