import ProgressBar, {
  ProgressWrapper
} from '@components/ProgressBar/ProgressBar'
import {
  useGetTotalUserRewards,
  useGetTotalUserStakedAmount
} from '@hooks/useStaking'
import { useWallet } from '@hooks/useWallet'
import stakingUtils from '@utils/stake'
import { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'

export interface StakingInfoProps {
  lockTime: number
  maxCapacity: number
  poolAddress: string
}

export default function StakingInfo({
  lockTime,
  maxCapacity,
  poolAddress
}: StakingInfoProps) {
  const address = useWallet()
  const stakedAmount = useGetTotalUserStakedAmount(address, poolAddress)
  const rewards = useGetTotalUserRewards(address, poolAddress)

  return (
    <div className="staking-info">
      <div>
        <span className="title">
          <FormattedMessage id="stake.staking-data.header.lock-term" />
        </span>
        <span className="value time">
          <span className="days">{lockTime}</span>
          <span>
            <FormattedMessage id="stake.staking-data.days" />
          </span>
        </span>
      </div>
      <div>
        <span className="title">
          <FormattedMessage id="stake.staking-data.header.capacity" />
        </span>
        <span className="value">
          <Suspense
            fallback={
              <ProgressBar
                value={0}
                label={stakingUtils.getAbbreviatedNumber(maxCapacity)}
              />
            }
          >
            <ProgressWrapper
              maxCapacity={maxCapacity}
              poolAddress={poolAddress}
            />
          </Suspense>
        </span>
      </div>
      <div>
        <span className="title">
          <FormattedMessage id="stake.staking-data.header.my-stake" />
        </span>
        <span className="value bold">
          {stakingUtils.getAbbreviatedNumber(stakedAmount)}
        </span>
      </div>
      <div>
        <span className="title">
          <FormattedMessage id="stake.staking-data.header.my-rewards" />
        </span>
        <span className="value bold">
          {stakingUtils.getAbbreviatedNumber(rewards)}
        </span>
      </div>
    </div>
  )
}
