import ethereumLogo from '../../static/logos/networks/ethereum-logo.png'
import arbitrumLogo from '../../static/logos/networks/arbitrum-logo.png'
import binanceLogo from '../../static/logos/networks/binance-logo.png'
import baseLogo from '../../static/logos/networks/base-logo.png'
import optimismLogo from '../../static/logos/networks/optimism-logo.png'
import arbitrumVector from '../../static/logos/networks/arbitrum.svg'
import ethereumVector from '../../static/logos/networks/ethereum.svg'
import baseVector from '../../static/logos/networks/base.svg'
import optimismVector from '../../static/logos/networks/optimism.svg'
import ethereumVectorWhite from '../../static/logos/networks/ethereumWhite.svg'
import arbitrumVectorWhite from '../../static/logos/networks/arbitrumWhite.svg'
import binanceVector from '../../static/logos/networks/binance.svg'
import binanceVectorWhite from '../../static/logos/networks/binanceWhite.svg'
import { NetworkName } from 'src/utils/networkHelper'

// TODO: Update Logos and vectors for new networks
export const networkLogos: Record<NetworkName, string> = {
  arbitrum: arbitrumLogo,
  ethereum: ethereumLogo,
  binance: binanceLogo,
  linea: arbitrumLogo,
  base: baseLogo,
  scroll: arbitrumLogo,
  mode: arbitrumLogo,
  optimism: optimismLogo
}

export const networkVectors: Record<NetworkName, string> = {
  arbitrum: arbitrumVector,
  ethereum: ethereumVector,
  binance: binanceVector,
  linea: arbitrumVector,
  base: baseVector,
  scroll: arbitrumVector,
  mode: arbitrumVector,
  optimism: optimismVector
}

export const networkVectorsWhite: Record<NetworkName, string> = {
  arbitrum: arbitrumVectorWhite,
  ethereum: ethereumVectorWhite,
  binance: binanceVectorWhite,
  linea: arbitrumVectorWhite,
  base: arbitrumVectorWhite,
  scroll: arbitrumVectorWhite,
  mode: arbitrumVectorWhite,
  optimism: arbitrumVectorWhite
}
