import { useFetch } from '@gluedigital/ruse-fetch-extras'
import { dataMicroserviceUrl } from 'src/constants'
import { RiskForLrtTokens } from 'src/types/RiskTypes'

const useRiskForTokens = (): RiskForLrtTokens[] => {
  const riskUrl: string = `${dataMicroserviceUrl}/risk-for-tokens`
  const riskInfo: RiskForLrtTokens[] = useFetch(riskUrl)
  return riskInfo || []
}

export default useRiskForTokens
