import web3 from 'src/utils/web3'
import {
  mainnetArbitrumCandidate,
  mainnetArbitrumPro,
  ENV_ENVIROMENT,
  ARBITRUM,
  ETHEREUM,
  BINANCE,
  BASE,
  SCROLL,
  LINEA,
  OPTIMISM,
  MODE
} from 'src/constants'
import { Environments, NetworkIds, Networks } from 'src/utils/networkHelper'

interface NetworkParamsData {
  chainId: string
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
  rpcUrls: string[]
  blockExplorerUrls: string[]
}

export const getNetworkParams = (networkId: NetworkIds): NetworkParamsData => {
  let networkParams: NetworkParamsData
  switch (networkId) {
    case Networks.arbitrum:
      networkParams = ARBITRUM
      break
    case Networks.ethereum:
      networkParams = ETHEREUM
      break
    case Networks.binance:
      networkParams = BINANCE
      break
    case Networks.base:
      networkParams = BASE
      break
    case Networks.scroll:
      networkParams = SCROLL
      break
    case Networks.linea:
      networkParams = LINEA
      break
    case Networks.optimism:
      networkParams = OPTIMISM
      break
    case Networks.mode:
      networkParams = MODE
      break
    default:
      if (ENV_ENVIROMENT === Environments.mainnetPro) {
        networkParams = mainnetArbitrumPro
      } else {
        networkParams = mainnetArbitrumCandidate
      }
      break
  }
  return networkParams
}

export const changeChain = async (networkId: NetworkIds = '0xa4b1') => {
  const networkParams = getNetworkParams(networkId)
  const chainParams = {
    chainId: networkParams?.chainId,
    chainName: networkParams?.chainName,
    rpcUrls: networkParams?.rpcUrls,
    blockExplorerUrls: networkParams?.blockExplorerUrls,
    nativeCurrency: networkParams?.nativeCurrency
  }

  return (web3.currentProvider as any).request({
    method: 'wallet_addEthereumChain',
    params: [chainParams]
  })
}
