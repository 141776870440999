import {
  FailExecutionSteps,
  ExecutionSteps,
  FailRemoveLiquiditySteps,
  RemoveLiquiditySteps
} from 'src/types/DepositTypes'

const executionStepMessages = {
  0: [['getting-route', 'running']],
  1: [
    ['getting-route', 'done'],
    ['approving-token', 'running']
  ],
  2: [
    ['getting-route', 'done'],
    ['approving-token', 'done'],
    ['sending-token', 'running']
  ],
  3: [
    ['getting-route', 'done'],
    ['approving-token', 'done'],
    ['sending-token', 'done']
  ]
}

const failExecutionStepMessages = {
  1: [['getting-route', 'fail']],
  2: [
    ['getting-route', 'done'],
    ['approving-token', 'fail']
  ],
  3: [
    ['getting-route', 'done'],
    ['approving-token', 'done'],
    ['sending-token', 'fail']
  ]
}

const createMessageList = (
  failExecutionStep: FailExecutionSteps,
  executionStep: ExecutionSteps
): string[][] => {
  if (!failExecutionStep) {
    const messages: string[][] = executionStepMessages[executionStep]
    return messages
  }
  const errorMessage: string[][] = failExecutionStepMessages[failExecutionStep]
  return errorMessage
}
export default createMessageList

const executionStepMessagesForPoolsWithdrawal = {
  0: [['approving-token', 'running']],
  1: [
    ['approving-token', 'done'],
    ['signing', 'running']
  ],
  2: [
    ['approving-token', 'done'],
    ['signing', 'done'],
    ['approving-token', 'running']
  ],
  3: [
    ['approving-token', 'done'],
    ['signing', 'done'],
    ['approving-token', 'done'],
    ['signing', 'running']
  ],
  4: [
    ['approving-token', 'done'],
    ['signing', 'done'],
    ['approving-token', 'done'],
    ['signing', 'done']
  ]
}

const failExecutionStepMessagesForPoolsWithdrawal = {
  1: [['approving-token', 'fail']],
  2: [
    ['approving-token', 'done'],
    ['signing', 'fail']
  ],
  3: [
    ['approving-token', 'done'],
    ['signing', 'done'],
    ['approving-token', 'fail']
  ],
  4: [
    ['approving-token', 'done'],
    ['signing', 'done'],
    ['approving-token', 'done'],
    ['signing', 'fail']
  ]
}

export const createMessageListForPoolsWithdrawal = (
  failExecutionStep: FailRemoveLiquiditySteps,
  executionStep: RemoveLiquiditySteps
): string[][] => {
  if (!failExecutionStep) {
    const messages: string[][] =
      executionStepMessagesForPoolsWithdrawal[executionStep]
    return messages
  }
  const errorMessage: string[][] =
    failExecutionStepMessagesForPoolsWithdrawal[failExecutionStep]
  return errorMessage
}
