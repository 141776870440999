import { FormattedMessage } from 'react-intl'
import { AvsInfoType } from 'src/types/AvsTypes'
import { getAVSImage } from 'src/utils/getTokenImage'
import { compactNumbers } from 'src/utils/compactNumbers'

import './AvsInfoBox.sass'

interface AvsInfoTypeExtend extends AvsInfoType {
  extendedName?: string
  url?: string
}

const AvsInfoBox = ({ avsInfo }: { avsInfo: AvsInfoTypeExtend }) => {
  const lowerName = avsInfo.name.toLocaleLowerCase()
  const extendedName = avsInfo.extendedName || avsInfo.name
  const points =
    compactNumbers(+avsInfo.points, 2) +
    (compactNumbers(+avsInfo.points) !== '-' ? ' Points' : '')
  return (
    <article id={`avs-info-box-${lowerName}`} className="avs-info-box">
      <header>
        <h3>
          <a href={avsInfo?.url} target="_blank" rel="noopener noreferrer">
            <div className="avs-image">
              <img
                src={getAVSImage(lowerName)}
                title={avsInfo.name}
                alt={`logo ${avsInfo.name}`}
              />
            </div>
            <span>{extendedName}</span>
          </a>
        </h3>
      </header>
      <div className="row">
        <div className="cell">
          <h4>
            <FormattedMessage id="info-box.tvl" />
          </h4>
          <span>{avsInfo.percentageDelegated}%</span>
        </div>
        <div className="cell">
          <h4>
            <FormattedMessage id="avs-info-box.staked" />
          </h4>
          <span>{compactNumbers(+avsInfo.staked, 2)} ETH</span>
        </div>
      </div>
      <div className="row">
        <div className="cell">
          <h4>
            <FormattedMessage id="avs-info-box.incentives" />
          </h4>
          <span>{points}</span>
        </div>
      </div>
    </article>
  )
}

export default AvsInfoBox
