import { FormattedMessage } from 'react-intl'
import { useCompactNumbers } from 'src/hooks/useCompactNumbers'

import './GenericPoints.sass'

interface GenericPointsProps {
  type: string
  points: number
}

const typeConfig = {
  tvl: {
    prefix: '$ ',
    suffix: ''
  },
  restaking: {
    prefix: '',
    suffix: ' %'
  },
  default: {
    prefix: '',
    suffix: ''
  }
}

const GenericPoints = ({ type, points }: GenericPointsProps) => {
  const title = type === 'tvl' ? 'tvl' : <FormattedMessage id={type} />
  const calc = useCompactNumbers()

  const { prefix, suffix } = typeConfig[type] || typeConfig.default
  const content =
    points >= 0 ? (
      `${prefix}${calc(points, 2)}${suffix}`
    ) : (
      <FormattedMessage id="coming-soon" />
    )
  return (
    <section className="points-container">
      <section>
        <h3>{title}</h3>
      </section>
      <section>
        <strong title={points.toFixed(2)}>{content}</strong>
      </section>
    </section>
  )
}

export const GenericPointsFallback = ({
  pointsType,
  type = 'loading'
}: {
  pointsType: string
  type?: 'loading' | 'error-loading-data'
}) => {
  return (
    <section className="points-container">
      <section>
        <h3>
          <FormattedMessage id={pointsType} />
        </h3>
      </section>
      <section>
        <strong className="points-text">
          {' '}
          <FormattedMessage id={type} />
        </strong>
      </section>
    </section>
  )
}

export default GenericPoints
