import { FormattedMessage } from 'react-intl'
import fire from '@static/vectors/fire-without-bg.svg'
import { useNavigate } from 'react-router'

import './NavigateStake.sass'

export default function NavigateStake() {
  const navigate = useNavigate()

  return (
    <button
      role="link"
      title="Navigate to Stake"
      className="navigate-to-stake"
      onClick={() => navigate(`/stake`)}
    >
      <img src={fire} alt="fire icon" />
      Stake my $LAY3R
    </button>
  )
}
