import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getLpEquivalentForNft } from 'src/contracts/Camelot/getNFTId'
import { getRealWeiTokenBalance } from 'src/contracts/getBalance/getSingleTokenBalance'
import { WalletData } from 'src/types'
import useNetworkFromRoute from './useNetworkFromRoute'

const useEnoughBalance = (
  realAmountWei: bigint,
  tokenSymbol: string
): boolean => {
  const [hasEnoughBalance, setHasEnoughBalance] = useState<boolean>(false)
  const wallet = useSelector((s: any) => s.wallet as WalletData)
  const userAddress: string = wallet?.address
  const { chainId } = useNetworkFromRoute()
  useEffect(() => {
    const getTokenBalance = async () => {
      const balance: string = await getRealWeiTokenBalance(
        tokenSymbol,
        userAddress,
        chainId
      )
      if (!balance) {
        setHasEnoughBalance(false)
        return
      }
      const bigIntBalance: bigint = BigInt(balance)
      setHasEnoughBalance(bigIntBalance >= realAmountWei)
    }
    getTokenBalance().catch((error) => {
      console.error('Error in hook useEnoughBalance', error.message)
      setHasEnoughBalance(false)
    })
  }, [realAmountWei, tokenSymbol, userAddress, chainId])
  return hasEnoughBalance
}

export default useEnoughBalance

export const useEnoughBalanceCamelotPools = (
  realAmountWei: bigint,
  nftId: string,
  nftAddress: string
): boolean => {
  const [hasEnoughBalance, setHasEnoughBalance] = useState<boolean>(false)
  useEffect(() => {
    if (nftId === '' || nftAddress === '') return
    getLpEquivalentForNft(nftAddress, Number(nftId))
      .then((info) => {
        const nftLpAmountWei: bigint = BigInt(info.amount)
        setHasEnoughBalance(nftLpAmountWei >= realAmountWei)
      })
      .catch((error) => {
        console.error(
          'Error in hook useEnoughBalanceCamelotPools',
          error.message
        )
        setHasEnoughBalance(false)
      })
  }, [realAmountWei, nftId, nftAddress])
  return hasEnoughBalance
}
