import { FormattedMessage } from 'react-intl'
import { useModal } from '@gluedigital/modal'

import 'src/components/modals/PortfolioModal/PortfolioModal'
import './Portfolio.sass'

const Portfolio = () => {
  const modal = useModal()
  return (
    <div id="portfolio">
      <button onClick={() => modal.show('portfolio-modal')}>
        <span>
          <FormattedMessage id="more-info" />
        </span>
        <span className="icon icon-plus" />
      </button>
    </div>
  )
}

export default Portfolio
