import { LiquidityDexesInfo } from 'src/types/RiskTypes'

export interface GroupedRiskInfo {
  percentageTvl: number
  splitedTvl: { percentageTvl: number; network: string }[]
}

export interface InfoRiskBox {
  percentageTvl: number
  protocol: string
  network?: string
}

function groupItems<T extends InfoRiskBox>(
  items: T[]
): Record<string, GroupedRiskInfo> {
  return items.reduce(
    (acc, item) => {
      if (!acc[item.protocol]) {
        acc[item.protocol] = {
          percentageTvl: 0,
          splitedTvl: []
        }
      }
      acc[item.protocol].percentageTvl += item.percentageTvl
      if (item.network) {
        acc[item.protocol].splitedTvl.push({
          network: item.network,
          percentageTvl: item.percentageTvl
        })
      }
      return acc
    },
    {} as Record<string, GroupedRiskInfo>
  )
}

function parseToInfoRisk(dexes: LiquidityDexesInfo[]): InfoRiskBox[] {
  return dexes.flatMap((liquidity) =>
    liquidity.info.map((info) => ({
      protocol: liquidity.protocol,
      percentageTvl: info.percentageTvl,
      network: info.network
    }))
  )
}

const riskUtils = {
  parseToInfoRisk,
  groupItems
}

export default riskUtils
