import AffiliatesCards from 'src/components/Referrals/AffiliatesCards/AffiliatesCards'
import AffiliatesTable from 'src/components/Referrals/AffiliatesTable/AffiliatesTable'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { ReferalStats } from 'src/types'

interface AffiliatesDataProps {
  handleOpen: () => void
  allCodes: ReferalStats[]
  loading: boolean
}

export default function AffiliatesData({
  handleOpen,
  allCodes,
  loading
}: AffiliatesDataProps) {
  const { width } = useWindowDimensions()

  const isMobile: boolean = width < 460

  return isMobile ? (
    <AffiliatesCards
      codes={allCodes}
      loading={loading}
      openDialog={handleOpen}
    />
  ) : (
    <AffiliatesTable
      openDialog={handleOpen}
      codes={allCodes}
      loading={loading}
    />
  )
}
