import { ArbitrumData } from 'src/data/Arbitrum'
import { GENERIC_ABIS } from '../abis'
import getWeb3 from '../web3'
import { AbiItem, JsonRpcOptionalRequest } from 'web3'

const jsonRpc = (
  id: string,
  address: string,
  data: string
): JsonRpcOptionalRequest => ({
  jsonrpc: '2.0',
  method: 'eth_call',
  params: [
    {
      to: address,
      data
    }
  ],
  id
})

export const getAmountOfPoolNftsForUser = async (
  nftPoolAddress: string,
  userAddress: string
): Promise<number> => {
  if (!userAddress) return 0
  if (!nftPoolAddress) return 0
  const web3 = getWeb3('arbitrum')
  const nftPoolContract = new web3.eth.Contract(
    GENERIC_ABIS.nftPoolCamelotAbi as AbiItem[],
    nftPoolAddress
  )
  const amount: string = await nftPoolContract.methods
    .balanceOf(userAddress)
    .call()
  return parseInt(amount)
}

export const getNFTIds = async (
  nftPoolAddress: string,
  userAddress: string
): Promise<number[]> => {
  const web3 = getWeb3('arbitrum')
  const totalUserNfts: number = await getAmountOfPoolNftsForUser(
    nftPoolAddress,
    userAddress
  )
  if (totalUserNfts === 0) return []
  const nftPoolContract = new web3.eth.Contract(
    GENERIC_ABIS.nftPoolCamelotAbi as AbiItem[],
    nftPoolAddress
  )
  const batchRequest = new web3.BatchRequest()
  const promises: Promise<string>[] = []
  for (let i = 0; i < totalUserNfts; i++) {
    const data = nftPoolContract.methods
      .tokenOfOwnerByIndex(userAddress, i)
      .encodeABI()
    promises.push(batchRequest.add(jsonRpc(i.toString(), nftPoolAddress, data)))
  }
  await batchRequest.execute()
  const ids = (await Promise.all(promises)).map((id: string) => parseInt(id))
  return ids
}

export interface LpInfoForCamelotNft {
  amount: number
  amountWithMultiplier: number
  startLockTime: number
  lockDuration: number
  lockMultiplier: number
  rewardDebt: number
  boostPoints: number
  totalMultiplier: number
}

//* With this function we get the LP amount equivalent for a NFT Camelot Position
export const getLpEquivalentForNft = async (
  nftPoolAddress: string,
  nftId: number
): Promise<LpInfoForCamelotNft> => {
  const web3 = getWeb3('arbitrum')
  const nftPoolContract = new web3.eth.Contract(
    GENERIC_ABIS.nftPoolCamelotAbi as AbiItem[],
    nftPoolAddress
  )
  const info: LpInfoForCamelotNft = await nftPoolContract.methods
    .getStakingPosition(nftId)
    .call()
  return info
}

export const getLpsForNfts = async (
  nftPoolAddress: string,
  ids: number[]
): Promise<number[]> => {
  const web3 = getWeb3('arbitrum')
  const nftPoolContract = new web3.eth.Contract(
    GENERIC_ABIS.nftPoolCamelotAbi as AbiItem[],
    nftPoolAddress
  )
  const batchRequest = new web3.BatchRequest()
  const info: Promise<LpInfoForCamelotNft>[] = ids.map((id, index) => {
    const data = nftPoolContract.methods.getStakingPosition(id).encodeABI()
    return batchRequest.add(jsonRpc(index.toString(), nftPoolAddress, data))
  })

  await batchRequest.execute()
  const infoResolved = await Promise.all(info)
  const decodedAmountsInfo = infoResolved.map((info: LpInfoForCamelotNft) => {
    const decoded = web3.eth.abi.decodeParameters(['uint256'], info.toString())
    return decoded[0].toString()
  })
  const ethAmounts: number[] = decodedAmountsInfo.map((info: string) =>
    parseFloat(web3.utils.fromWei(info, 'ether'))
  )
  return ethAmounts
}

export const getCamelotLpBalanceForSinglePool = async (
  nftPoolAddress: string,
  userAddress: string
): Promise<number> => {
  const ids: number[] = await getNFTIds(nftPoolAddress, userAddress)
  if (ids?.length === 0) return 0
  const lpsEthAmount: number[] = await getLpsForNfts(nftPoolAddress, ids)
  const lpTotal: number = lpsEthAmount.reduce((acc, l) => acc + l, 0)
  return lpTotal
}

export const getCamelotLpBalancesForAllPools = async (
  userAddress: string
): Promise<{ nftAddress: string; balanceLp: number }[]> => {
  const nftPools: string[] = ArbitrumData.camelotPools.map((p) => p.nftAddress)
  const balances: { nftAddress: string; balanceLp: number }[] = []
  for (const nftPoolAddress of nftPools) {
    const balance: number = await getCamelotLpBalanceForSinglePool(
      nftPoolAddress,
      userAddress
    )
    balances.push({ nftAddress: nftPoolAddress, balanceLp: balance })
  }
  return balances
}
