import PointsDashboardWrapper from './PointsDashboardWrapper'
import CommonPoints, { CommonPointsProps } from './CommonPoints/CommonPoints'

export function ReducedPointsDashboard(
  props: Omit<CommonPointsProps, 'inlineLogo'>
) {
  return (
    <PointsDashboardWrapper type={props.type}>
      <CommonPoints {...props} inlineLogo />
    </PointsDashboardWrapper>
  )
}
