import { useSelector } from 'react-redux'
import { WalletData } from 'src/types'

const useIsConnected = (): boolean => {
  const wallet = useSelector((s: any) => s.wallet as WalletData)
  if (wallet?.address) return true
  else return false
}

export default useIsConnected
