import { TokensInfoWithoutMultiplier } from 'src/hooks/useTokensLst'
import { Multipliers } from 'src/types'
import { ArbitrumData } from './Arbitrum'
import { BinanceData } from './Binance'
import { EthereumData } from './Ethereum'
import {
  DEFAULT_NETWORK_DATA,
  NetworkIds,
  Networks
} from 'src/utils/networkHelper'
import { LineaData } from './Linea'
import { ModeData } from './Mode'
import { BaseData } from './Base'
import { OptimismData } from './Optimism'
import { ScrollData } from './Scroll'

interface TokenData {
  id: string
  name: string
  address: string
  decimals: string
}

interface TokenLRSData extends TokenData {
  network?: string
  type?: string
  opengraph?: string
  description?: string
  image?: string
  multipliers: Multipliers
}
export interface NetworkData {
  tokens: TokenData[]
  tokensLRS: TokensInfoWithoutMultiplier[]
  pools?: any[]
  camelotPools?: any[]
}

const defaultNetworkData = {
  tokens: [],
  tokensLRS: [],
  camelotPools: [],
  pools: []
}

export const networkData = (chainId: NetworkIds): NetworkData => {
  if (!chainId) {
    return DEFAULT_NETWORK_DATA // default network in case there is no wallet connected
  } else if (chainId?.toLowerCase() === Networks.ethereum.toLowerCase()) {
    return EthereumData
  } else if (chainId?.toLowerCase() === Networks.arbitrum.toLowerCase()) {
    return ArbitrumData
  } else if (chainId?.toLowerCase() === Networks.binance.toLowerCase()) {
    return BinanceData
  } else if (chainId?.toLowerCase() === Networks.linea.toLowerCase()) {
    return LineaData
  } else if (chainId?.toLowerCase() === Networks.mode.toLowerCase()) {
    return ModeData
  } else if (chainId?.toLowerCase() === Networks.base.toLowerCase()) {
    return BaseData
  } else if (chainId?.toLowerCase() === Networks.optimism.toLowerCase()) {
    return OptimismData
  } else if (chainId?.toLowerCase() === Networks.scroll.toLowerCase()) {
    return ScrollData
  } else {
    return defaultNetworkData
  }
}
