import community from 'src/data/community.json'
import social from 'src/data/social.json'

import './SocialLinks.sass'

export enum LinkType {
  All = 'all',
  Community = 'community',
  Social = 'social'
}

const SocialLinks = ({ type = LinkType.All }: { type?: LinkType }) => {
  const linkMappings = {
    [LinkType.All]: [...community, ...social],
    [LinkType.Community]: community,
    [LinkType.Social]: social
  }
  const links = linkMappings[type] || []
  return (
    <div className="social-links">
      {links.map((c) => (
        <a
          key={c.name}
          target="_blank"
          rel="noreferrer noopener"
          className={`icon icon-${c.name}`}
          href={c.href}
          title={`${c.name} link`}
        />
      ))}
    </div>
  )
}

export default SocialLinks
