import { Suspense, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import TokenSearch from './TokenSearch/TokenSearch'
import Loading from '../Loading/Loading'
import TokenSelectorList from './TokenSelectorList/TokenSelectorList'
import { getTokenImage } from 'src/utils/getTokenImage'
import BalanceButtons from './BalanceButtons/BalanceButtons'
import { NetworkData, networkData } from 'src/data'
import swapIcon from 'src/static/vectors/swap-black.svg'
import web3 from 'src/utils/web3'
import useNetworkFromRoute from 'src/hooks/useNetworkFromRoute'

import './SwapSelector.sass'

const MINIMUN_VALUE: number = 0

interface TokenSelectorProps {
  previousData?: { outputCoin: string; amount: number; inputCoin: string }
  onChange?: ({
    amount,
    outputCoin
  }: {
    outputCoin: string
    amount: number
  }) => void
  searchMode?: boolean
  setSearchMode?: (value: boolean) => void | null
  blocked?: boolean
  darkMode?: boolean
  swapPreviewAmount?: string
  setRealWeiAmount?: (value: bigint) => void
}
const SwapSelector = ({
  previousData,
  onChange,
  searchMode = false,
  setSearchMode,
  blocked = true,
  darkMode = false,
  swapPreviewAmount,
  setRealWeiAmount
}: TokenSelectorProps) => {
  const [search, setSearch] = useState<string>('')
  const { amount, outputCoin, inputCoin } = previousData
  const setCoin = (coin: string) => {
    !!onChange && onChange({ ...previousData, outputCoin: coin })
  }
  //* If bpt exists is defi strategy, otherwhise is a token
  const { chainId } = useNetworkFromRoute()
  const networkInfo: NetworkData = networkData(chainId)
  const bptName: string = networkInfo?.pools?.find(
    (pool) => pool.address.toLowerCase() === inputCoin?.toLowerCase()
  )?.bptName

  return (
    <div
      className={`swap-selector ${blocked ? 'blocked' : ''} ${darkMode ? 'dark-theme' : 'light-theme'}`}
    >
      <div className="selector-wrapper">
        <section
          className="token-selector-info"
          onClick={() => setSearchMode?.(false)}
        >
          <label className="input-container">
            <h2>
              {!bptName ? (
                <FormattedMessage id="swap" />
              ) : (
                <FormattedMessage id="token-selector.withdraw" />
              )}
            </h2>
            <BalanceButtons
              disabled={blocked}
              previousData={previousData}
              onChange={onChange}
              isSwap={true}
              setRealWeiAmount={setRealWeiAmount}
            />
            <input
              readOnly={blocked}
              className={`amount-input ${('' + amount).length < 10 ? 'big' : ''}`}
              disabled={amount < MINIMUN_VALUE}
              type="number"
              inputMode="decimal"
              onFocus={() => setSearchMode?.(false)}
              onBlur={() => {
                // LST, LRT and BPT tokens all of them have 18 decimals
                const weiAmount: string = web3.utils.toWei(
                  amount.toString(),
                  'ether'
                )
                setRealWeiAmount?.(BigInt(weiAmount))
              }}
              onChange={(e) => {
                e.preventDefault()
                const value: string = e.target.value
                const decimals: string = value.split(',')[1]
                if (decimals && decimals.length > 6) return
                onChange({ ...previousData, amount: +e.target.value })
              }}
              placeholder="0"
              value={amount}
              min={MINIMUN_VALUE}
              step="any"
            />
          </label>
          {!bptName ? (
            <h1
              className={
                inputCoin?.length > 4
                  ? inputCoin?.length > 6
                    ? 'micro'
                    : 'mini'
                  : ''
              }
            >
              <img title={inputCoin} src={getTokenImage(inputCoin)} />
              <span className="input-coin">{bptName || inputCoin}</span>
            </h1>
          ) : (
            <h1 className={inputCoin?.length > 6 ? 'super-micro' : 'micro'}>
              <span className="input-coin">{bptName || inputCoin}</span>
            </h1>
          )}
          <img src={swapIcon} alt="swap icon" className="swap-icon" />
        </section>
        <section
          className="token-selector-info"
          onClick={() => setSearchMode?.(false)}
        >
          {swapPreviewAmount ? (
            <label className="input-container">
              <h2>
                <FormattedMessage id="token-selector.preview" />
              </h2>
              <span className="preview-amount">{swapPreviewAmount}</span>
            </label>
          ) : (
            <label className="input-container" />
          )}
          <h1
            className={
              outputCoin?.length > 4
                ? outputCoin?.length > 6
                  ? 'micro'
                  : 'mini'
                : ''
            }
            onClick={(e) => {
              e.stopPropagation()
              if (!blocked) {
                setSearchMode?.(!searchMode)
              }
            }}
          >
            <img title={outputCoin} src={getTokenImage(outputCoin)} />
            <span>{outputCoin || networkInfo.tokens[0].id}</span>
            <span
              className={`icon icon-angle_${searchMode ? 'right' : 'down'}`}
            />
          </h1>
        </section>
      </div>

      <section className={`token-selector-select ${searchMode ? 'open' : ''}`}>
        <TokenSearch search={search} setSearch={setSearch} />
        <section className="token-selector-list-wrapper">
          <ul
            className="token-selector-list"
            onClick={() => setSearchMode?.(false)}
          >
            <ErrorBoundary
              fallback={
                <>
                  <FormattedMessage id="error-loading-tokens-balance" />
                </>
              }
            >
              <Suspense fallback={<Loading colored />}>
                {!!onChange && (
                  <TokenSelectorList
                    coins={networkInfo.tokens}
                    search={search}
                    outputCoin={outputCoin}
                    setCoin={setCoin}
                    inputCoin={inputCoin}
                  />
                )}
              </Suspense>
            </ErrorBoundary>
          </ul>
        </section>
      </section>
    </div>
  )
}

export default SwapSelector
