import tokensLRS from './tokensLRS.json'
import tokens from './tokens.json'
import { TokensInfoWithoutMultiplier } from 'src/hooks/useTokensLst'

const lrtTokens = tokensLRS.map((t) => ({
  address: t.address,
  id: t.id,
  name: t.name,
  decimals: t.decimals
}))
const newTokens = tokens.slice(0, 3).concat(lrtTokens).concat(tokens.slice(3))
// const filteredLrt = tokensLRS.filter(t => t.id.toLowerCase() === "weeth" || t.id.toLowerCase() === "ezeth" || t.id.toLowerCase() === "rseth"
// || t.id.toLowerCase() === "pufeth" || t.id.toLowerCase() === "eeth" || t.id.toLowerCase() === "rsweth")
export const EthereumData = {
  tokensLRS: tokensLRS as TokensInfoWithoutMultiplier[],
  tokens: newTokens
}
