import { TransactionParams } from '@paraswap/sdk'
import { AUTOLAYER_ABIS } from './abis'
import { SWAPS_CONTRACT } from './addresses'
import { NetworkIds } from 'src/utils/networkHelper'
import web3 from '@utils/web3'
import { BalancerPool } from 'src/types/pools'
import { AbiItem } from 'web3'

/*
@txParams --> Data generated with Paraswap SDK for previous swap
@pool --> Destination pool info
@weiAmount --> Amount to be sent in the transaction
@userAddress --> User address
*/

const addLiquidityBalancerPool = async (
  txParams: TransactionParams,
  pool: BalancerPool,
  weiAmount: string,
  userAddress: string,
  chainId: NetworkIds
) => {
  const data: string = txParams.data
  const gasPrice = await web3.eth.getGasPrice()
  const swapsContractAddress: string = SWAPS_CONTRACT[chainId.toLowerCase()]
  const swapsContract = new web3.eth.Contract(
    AUTOLAYER_ABIS[chainId.toLowerCase()].swapContract as AbiItem[],
    swapsContractAddress.toLowerCase()
  )

  const poolTokensAddresses: string[] = pool?.filteredTokens.map((token) =>
    token.address.toLowerCase()
  )
  poolTokensAddresses.sort((a, b) => Number(a) - Number(b)) // order addresses from lower to higher
  const poolTokensAddressesWithBpt: string[] = pool.tokens.map((token) =>
    token.address.toLowerCase()
  )
  poolTokensAddressesWithBpt.sort((a, b) => Number(a) - Number(b)) // order addresses from lower to higher
  const poolId: string = pool.id.toLowerCase()

  //* if value different 0, tokenIn is native ETH and we must send to the contract
  const estimatedGas = await swapsContract.methods
    .addLiquidityToBalancer(
      data,
      poolTokensAddresses,
      poolTokensAddressesWithBpt,
      poolId
    )
    .estimateGas({
      from: userAddress,
      value: txParams.value !== '0' ? weiAmount : '0'
    })
  const increasedGasPrice = Number(estimatedGas) * 2
  const sendSwapTx = await swapsContract.methods
    .addLiquidityToBalancer(
      data,
      poolTokensAddresses,
      poolTokensAddressesWithBpt,
      poolId
    )
    .send({
      from: userAddress,
      gasPrice: gasPrice.toString(),
      gas: increasedGasPrice.toString(),
      value: txParams.value !== '0' ? weiAmount : '0'
    })

  return sendSwapTx
}
export default addLiquidityBalancerPool
