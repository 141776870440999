import FallbackNoConnected from '@components/FallbackNoConnected/FallbackNoConnected'
import useCoingecko from '@hooks/useCoingecko'
import usePromise from '@hooks/usePromise'
import { useWallet } from '@hooks/useWallet'
import aubnb from '@static/vectors/aubnb.svg'
import { getRealWeiTokenBalance } from 'src/contracts/getBalance/getSingleTokenBalance'
import getWeb3 from 'src/contracts/web3'

const AubnbActualValue = () => {
  const aubnbText = 'aubnb'
  const address = useWallet()
  const web3 = getWeb3('binance')
  const coingeckoData = useCoingecko()
  const tokenBalance = usePromise(
    getRealWeiTokenBalance,
    aubnbText.toUpperCase(),
    address,
    '0x38'
  ) as unknown as bigint

  const ethBalance = tokenBalance
    ? +web3.utils.fromWei(tokenBalance?.toString(), 'ether')
    : 0

  const bnbPrice = coingeckoData.find(
    (coin) => coin.symbol === 'bnb'
  )?.current_price

  const valueUsd = ethBalance * bnbPrice

  return (
    <div id="left-asset-info" className="left-product-info">
      <div className="points-box aubnb-points-box">
        <h4>Your position</h4>
        <div>
          <span className="position">{ethBalance?.toFixed(5)}</span>
          <div>
            <img src={aubnb} alt={'aubnb'} />
          </div>
        </div>
        <span className="position-usd">({valueUsd?.toFixed(2)} USD)</span>
      </div>
    </div>
  )
}

const AubnbActualValueWrapper = () => {
  const address = useWallet()
  if (!address) {
    return <FallbackNoConnected />
  }
  return <AubnbActualValue />
}

export default AubnbActualValueWrapper

export const SkeletonAubnbActualValue = ({
  isError = false
}: {
  isError?: boolean
}) => {
  return (
    <div id="left-asset-info" className="left-product-info">
      <div className="points-box aubnb-points-box">
        <h4>Your position</h4>
        <div>
          {!isError ? (
            <span className="position">0.00000</span>
          ) : (
            <span className="position">Error</span>
          )}
          <div>
            <img src={aubnb} alt={'aubnb'} />
          </div>
        </div>
        <span className="position-usd">(0 USD)</span>
      </div>
    </div>
  )
}
