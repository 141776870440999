import { Suspense } from 'react'
import { useParams } from 'react-router'
import { getTokenImage } from 'src/utils/getTokenImage'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import RestakingRewardsMiddle from 'src/components/GenericPoints/RestakingRewards'
import useTokensLst, { TokensInfo } from 'src/hooks/useTokensLst'
import useTokensLrst from 'src/hooks/useTokensLrst'
import ApyInfo from 'src/components/ApyInfo/ApyInfo'
import {
  NetworkName,
  getItemByNetwork,
  getNetworkIdByName
} from 'src/utils/networkHelper'
import { networkLogos } from 'src/static/logos/networks'
import RiskSection from './RiskSection/RiskSection'
import AvsSection from './AvsSection/AvsSection'
import AvsSectionLoading from './AvsSection/AvsSectionLoading'
import RiskSectionLoading from './RiskSection/RiskSectionLoading'
import CompleteAprChart from 'src/components/Charts/AprChart/CompleteAprChart/CompleteAprChart'
import CompleteRiskChart from 'src/components/Charts/RiskChart/CompleteRiskChart/CompleteRiskChart'
import { FormattedMessage } from 'react-intl'
import ListMultiplier from 'src/components/MultiplierInfo/ListMultiplier/ListMultiplier'
import Spinner from 'src/components/Loading/Spinner/Spinner'

import './MiddleProductInfo.sass'

interface MiddleAssetInfoProps {
  tokenInfo: TokensInfo
}

const InfoLst = ({ productName }: { productName?: string }) => {
  const tokens: TokensInfo[] = useTokensLst()
  const { networkName } = useParams<{ networkName: NetworkName }>()
  const networkId = getNetworkIdByName(networkName)

  const info: TokensInfo = tokens?.find(
    (t) =>
      t?.id?.toLowerCase() === productName?.toLowerCase() &&
      t?.network === networkId
  )

  return (
    <>
      <section className="middle-info">
        <ListMultiplier
          multipliers={{ autoLayer: info.multipliers.autoLayer }}
        />
        <ApyInfo apr={+info.apr} />
      </section>
    </>
  )
}
const InfoLrst = ({ productName }: { productName?: string }) => {
  const tokens: TokensInfo[] = useTokensLrst()
  const { networkName } = useParams<{ networkName: NetworkName }>()
  const networkId = getNetworkIdByName(networkName)
  const info: TokensInfo = tokens?.find(
    (t) =>
      t?.id?.toLowerCase() === productName?.toLowerCase() &&
      t?.network === networkId
  )

  return (
    <>
      <section className="multipliers-info">
        <h2>
          <FormattedMessage id="middle-section.multipliers" />
        </h2>
        <ListMultiplier multipliers={info.multipliers} />
      </section>
      <section className="middle-info">
        <ApyInfo apr={!isNaN(+info.apr) ? +info.apr : null} />
        <RestakingRewardsMiddle productName={info?.id?.toLowerCase()} />
      </section>
    </>
  )
}

const MiddleAssetInfo = ({ tokenInfo }: MiddleAssetInfoProps) => {
  const { networkName } = useParams<{ networkName: NetworkName }>()
  const { id, type, name, description, network } = tokenInfo
  const { networkTag } = getItemByNetwork(network)
  const isLST: boolean = type === 'LST'
  const idLower = id.toLowerCase()
  const hasAVS = idLower === 'ezeth' || idLower === 'rseth'
  const noRiskIds = ['pzeth', 'eeth', 'primeeth', 'ineth']
  const hasRisk = !isLST && !noRiskIds.includes(idLower)
  return (
    <section id="asset-middle-section" className="middle-section">
      <section className="top-info">
        <img src={getTokenImage(idLower)} alt={id} className="lrs-img" />
        {!isLST && (
          <div className="network">
            <img src={networkLogos[networkName]} alt={`${networkName} logo`} />
            <span>{networkTag}</span>
          </div>
        )}
        <span className={`badge ${type}`}>{type}</span>
      </section>
      <section>
        <h1>{name}</h1>
        <p>{description}</p>
      </section>
      {!isLST ? (
        <ErrorBoundary fallback={'error'}>
          <Suspense fallback={<Spinner />}>
            <InfoLrst productName={idLower} />
          </Suspense>
        </ErrorBoundary>
      ) : (
        <ErrorBoundary fallback={'error'}>
          <Suspense fallback={<Spinner />}>
            <InfoLst productName={idLower} />
          </Suspense>
        </ErrorBoundary>
      )}
      {hasAVS && (
        <ErrorBoundary fallback={<div />}>
          <Suspense fallback={<AvsSectionLoading />}>
            <AvsSection />
          </Suspense>
        </ErrorBoundary>
      )}
      <section className="chart-lrt" id="chart-apy">
        <h2>APY Overtime</h2>
        <ErrorBoundary fallback={<div />}>
          <CompleteAprChart productName={idLower} />
        </ErrorBoundary>
      </section>
      {hasRisk && (
        <>
          <ErrorBoundary fallback={<div />}>
            <Suspense fallback={<RiskSectionLoading />}>
              <RiskSection />
            </Suspense>
          </ErrorBoundary>
          <section className="chart-lrt" id="chart-risk">
            <h2>Risk Factor Overtime</h2>
            <ErrorBoundary fallback={<div />}>
              <CompleteRiskChart productName={idLower} />
            </ErrorBoundary>
          </section>
        </>
      )}
    </section>
  )
}

export default MiddleAssetInfo
