import { FormattedMessage } from 'react-intl'
import StakingButtons from '../StakigInfo/StakingButtons/StakingButtons'
import StakingInfo from '../StakigInfo/StakingInfo'
import { StakeDialogMethods } from '../StakeDialog/StakeDialog'
import stakingUtils from '@utils/stake'
import { Suspense } from 'react'
import {
  useGetPendingRewardsInPosition,
  useStakingPosition,
  useStakingPositionNumber
} from '@hooks/useStaking'
import StakingInfoWrapper from '../StakigInfo/StakingInfoWrapper'
import { useWallet } from '@hooks/useWallet'

export interface Entry {
  days: number
  stake: number
  rewards: number
}

interface StakingContentProps extends Pick<StakeDialogMethods, 'open'> {
  lockTime: number
  maxCapacity: number
  poolAddress: string
  refreshKey: number
}

function StakingContentRowFallback({ open }: Pick<StakeDialogMethods, 'open'>) {
  return (
    <div className="staking-entry fallback">
      <div>
        <div className="time">
          <span className="days" />
          <span>
            <FormattedMessage id="stake.staking-data.days" />
          </span>
        </div>
        <div>
          <span />
        </div>
        <div>
          <span />
        </div>
      </div>
      <StakingButtons
        open={open}
        rewards={0}
        poolAddress={''}
        positionId={0}
        pendingDate={10}
      />
    </div>
  )
}

function StakingContentRow({
  open,
  poolAddress,
  positionId,
  lockTime
}: { poolAddress: string; positionId: number; lockTime: number } & Pick<
  StakeDialogMethods,
  'open'
>) {
  const address = useWallet()
  const positionData = useStakingPosition(address, positionId, poolAddress)
  const rewards = useGetPendingRewardsInPosition(
    address,
    positionId,
    poolAddress
  )

  if (!positionData) return null
  const { startTime, currentAmount } = positionData

  const pendingDate = stakingUtils.getPendingDate(startTime, lockTime)

  if (!positionData.active) return null

  return (
    <div className="staking-entry">
      <div>
        <div className="time">
          <span className="days">{pendingDate}</span>
          <span>
            <FormattedMessage id="stake.staking-data.days" />
          </span>
        </div>
        <div>{stakingUtils.getAbbreviatedNumber(currentAmount)}</div>
        <div>{stakingUtils.getAbbreviatedNumber(rewards)}</div>
      </div>
      <StakingButtons
        open={open}
        rewards={rewards}
        poolAddress={poolAddress}
        positionId={positionId}
        pendingDate={pendingDate}
      />
    </div>
  )
}

function StakingContentRowsWrapper({
  lockTime,
  poolAddress,
  refreshKey,
  open
}: StakingContentProps) {
  const address = useWallet()
  const positionsNumber = useStakingPositionNumber(
    address,
    poolAddress,
    refreshKey
  )

  const entries = Array.from({ length: positionsNumber })

  return entries.map((_, index) => (
    <StakingContentRow
      open={open}
      poolAddress={poolAddress}
      positionId={index}
      lockTime={lockTime}
      key={index}
    />
  ))
}
export default function StakingContentMobile({
  lockTime,
  maxCapacity,
  poolAddress,
  refreshKey,
  open
}: StakingContentProps) {
  const fallbackEntries = [0, 1]
  return (
    <div className="staking-extended">
      <Suspense
        fallback={
          <StakingInfoWrapper
            lockTime={lockTime}
            maxCapacity={maxCapacity}
            poolAddress={poolAddress}
          />
        }
      >
        <StakingInfo
          lockTime={lockTime}
          maxCapacity={maxCapacity}
          poolAddress={poolAddress}
        />
      </Suspense>
      <Suspense
        fallback={fallbackEntries.map((i) => (
          <StakingContentRowFallback key={i} open={open} />
        ))}
      >
        <StakingContentRowsWrapper
          lockTime={lockTime}
          maxCapacity={maxCapacity}
          open={open}
          poolAddress={poolAddress}
          refreshKey={refreshKey}
        />
      </Suspense>
    </div>
  )
}
