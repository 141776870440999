import { useEffect } from 'react'

const cacheTime = 30000

/**
 * A bookkeeping entry describing a previous call
 */
interface CallDescriptor {
  func: () => void
  args: any[]
  uses: number
  state: 'pending' | 'resolved' | 'rejected'
  promise?: Promise<any>
  value?: any
  error?: Error
  resolvedDate?: number
}

/**
 * The cache of all calls still considered active
 */
const activeCalls: CallDescriptor[] = []

/**
 * Find a previous call for the given function and arguments
 */
function findCall(func: () => void, args: any[]) {
  for (const call of activeCalls) {
    if (call.func !== func) continue
    if (call.args.length !== args.length) continue
    if (call.args.some((a, i) => a !== args[i])) continue
    return call
  }
  return null
}

/**
 * Cleanup calls that have resolved, but are not in use after 1s
 */
function cleanupCalls() {
  const now = Date.now()
  let i = 0
  let j = 0
  while (i < activeCalls.length) {
    const val = activeCalls[i]
    const isInactive =
      val.state !== 'pending' &&
      val.uses === 0 &&
      now - val.resolvedDate >= cacheTime
    if (!isInactive) activeCalls[j++] = val
    i++
  }
  activeCalls.length = j
}

export function getCacheSize(): number {
  return activeCalls.length
}

/**
 * Adapt any async function to work with Suspense.
 *
 * This hook receives a promise-returning function, and the arguments to pass
 * to it, and suspends the component until the promise resolves, continuing
 * with the value as if it was sync.
 *
 * We cache the result as long as it's still in use (eg: between two
 * components). The comparison is done by identity of the function and all
 * arguments.
 *
 * **Warning**: Make sure the function and its arguments are not changing on
 * every render, or the hook will stall forever. Treat them as useEffect
 * dependencies.
 * @param promiseCreator The async function to wrap
 * @param args Arguments to the async function
 * @returns The value returned by the function, after resolving
 */
function usePromise<T, P extends any[]>(
  promiseCreator: (...args: P) => Promise<T>,
  ...args: P
): T {
  const found = findCall(promiseCreator, args)

  // Keep track of how many hooks are using this "found" entry
  useEffect(() => {
    if (found) {
      found.uses++
      return () => {
        found.uses--
        setTimeout(cleanupCalls, cacheTime)
      }
    }
  }, [found])

  if (found) {
    if (found.state === 'resolved') {
      return found.value
    } else if (found.state === 'rejected') {
      throw found.error
    } else {
      throw found.promise
    }
  }

  // First time doing this call
  const status: CallDescriptor = {
    func: promiseCreator,
    args,
    uses: 0,
    state: 'pending',
    promise: promiseCreator(...args)
  }
  activeCalls.push(status)
  status.promise
    .finally(() => {
      status.resolvedDate = Date.now()
      setTimeout(cleanupCalls, cacheTime)
    })
    .then((v) => {
      status.state = 'resolved'
      status.value = v
    })
    .catch((e) => {
      status.state = 'rejected'
      status.error = e
    })
  throw status.promise
}

export default usePromise
