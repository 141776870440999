import { useParams } from 'react-router'
import { ArbitrumData } from 'src/data/Arbitrum'
import { useKelpPoints, useRenzoPoints } from 'src/hooks/useEigenLayerPoints'
import { Points } from 'src/types'

export function useEigenLayerWithRenzo(): Points {
  const { productAddress } = useParams<{ productAddress: string }>()
  const isDefiCamelot: boolean = ArbitrumData.camelotPools.some(
    (p) => p.pos.toLowerCase() === productAddress.toLowerCase()
  )
  const { balancer, camelot } = useRenzoPoints()
  const eigenLayerPoints = isDefiCamelot
    ? camelot.eigenLayerPoints
    : balancer.eigenLayerPoints

  return { points: eigenLayerPoints }
}

export function useEigenLayerWithKelp(): Points {
  const { eigenLayerPoints } = useKelpPoints()
  return { points: eigenLayerPoints }
}

export function useEigenLayerWithKelpAndRenzo(): Points {
  const { eigenLayerPoints: kelpEigenLayer } = useKelpPoints()
  const { balancer } = useRenzoPoints()
  const renzoEigenLayerPoints: number = +balancer.eigenLayerPoints
  const total = parseFloat(kelpEigenLayer) + renzoEigenLayerPoints
  return { points: total.toString() }
}
