import { useFetch } from '@gluedigital/ruse-fetch-extras'
import { dataMicroserviceUrl } from 'src/constants'
import { TokensInfo } from './useTokensLst'
import { NetworkIds } from 'src/utils/networkHelper'

export type TokensInfoCrossChain = Omit<TokensInfo, 'network' | 'address'> & {
  blockchainData: Array<{
    network: NetworkIds
    address: string
  }>
}

const useTokensLrst = (): TokensInfo[] => {
  const route = '/lrst'
  const tokens = useFetch<TokensInfo[]>(dataMicroserviceUrl + route)
  return tokens
}

export default useTokensLrst

export const useTokensLrtCrosschain = (): TokensInfoCrossChain[] => {
  const route = '/lrt-crosschain'
  const tokens: TokensInfoCrossChain[] = useFetch(dataMicroserviceUrl + route)
  return tokens
}

export const useSingleTokenLrtCrosschain = (
  tokenId: string
): TokensInfoCrossChain => {
  const route = `/lrt-crosschain/${tokenId}`
  const token: TokensInfoCrossChain = useFetch(dataMicroserviceUrl + route)
  return token
}
