import NavigationMenu from '@components/NavigationMenu/NavigationMenu'
import useIsResponsive from '@hooks/useIsResponsive'
import GoToAirdrops from 'src/components/GoToAirdrops/GoToAirdrops'
import GoToReferrals from 'src/components/GoToReferrals/GoToReferrals'
import SettingsMenu from 'src/components/SettingsMenu/SettingsMenu'
import { AutolayerLogos } from 'src/static/logos/autolayer'
import NavigateStake from '@components/StakeBanner/NavigateStake/NavigateStake'

import './DashboardHeader.sass'

export default function DashboardHeader() {
  const isResponsive = useIsResponsive()
  return (
    <header id="dashboard-masthead">
      <div className="container">
        <section className="logo-wrapper">
          <img
            className="only-mobile"
            src={AutolayerLogos.autolayerIconDark}
            alt="Autolayer logo"
          />
          <img
            className="only-desktop"
            src={AutolayerLogos.autolayerLogoDark}
            alt="Autolayer logo"
          />
        </section>
        <section className="connect-wrapper">
          {isResponsive ? (
            <>
              <SettingsMenu />
              <NavigationMenu />
            </>
          ) : (
            <>
              <NavigateStake />
              <GoToReferrals />
              <GoToAirdrops />
              <SettingsMenu />
            </>
          )}
        </section>
      </div>
    </header>
  )
}
