import tokenNameToAddress from 'src/utils/tokenNameToAddress'
import { NetworkIds } from './networkHelper'
import { networkData } from 'src/data'

function getTokenAddress(token: string, chainId: NetworkIds) {
  return token.length === 42
    ? token.toLowerCase()
    : tokenNameToAddress(token, chainId)
}

function getTokenDecimals(tokenAddress: string, chainId: NetworkIds) {
  const networkInfo = networkData(chainId)
  const decimals: number = +networkInfo.tokens.find(
    (token) => token.address.toLowerCase() === tokenAddress
  )?.decimals

  return decimals
}

const swapUtils = {
  getTokenDecimals,
  getTokenAddress
}

export default swapUtils
