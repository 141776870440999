import { FormattedMessage } from 'react-intl'

export function PointsDashboardError() {
  return (
    <article className="points-item-dashboard points-item-error">
      <section className="inside-container">
        <h2>
          <span className="icon icon-warning" />
          <span>
            <FormattedMessage id="error" />
          </span>
        </h2>
      </section>
      <p>
        <span>
          <FormattedMessage id="error-loading-your-values" />.{' '}
        </span>
      </p>
    </article>
  )
}
