import { Suspense, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import Dashboard from './Dashboard/Dashboard'
import { Modal, ModalWrapper } from '@gluedigital/modal'
import NotFound from './NotFound/NotFound'
import Meta from 'src/components/Meta/Meta'
import ProductInfo from './ProductInfo/ProductInfo'
import DefiInfo from './ProductInfo/DefiInfo'
import AssetInfo from './ProductInfo/AssetInfo'
import GoToAirdropChecker from 'src/components/GoToAirdropChecker/GoToAirdropChecker'
import Airdrop from './Airdrop/Airdrop'
import Referrals from './Referrals/Referrals'
import Stake from './Stake/Stake'
import Affiliates from './Referrals/Grids/Affiliates'
import Traders from './Referrals/Grids/Traders'
import {
  DefiStrategiesGrid,
  LrstStrategiesGrid,
  LstStrategiesGrid
} from '@components/ProductGrid/ProductGrid'
import AuBnbInfo from './ProductInfo/AuBnbInfo'

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: '@@_AFTER_RENDER' })
    import(/* webpackChunkName: "wallet" */ 'src/utils/wallet').then((m) =>
      m.setup()
    )
  }, [dispatch])

  return (
    <ModalWrapper>
      <Meta />
      <main id="app">
        <div className="pages-transition-group">
          <GoToAirdropChecker />
          <Routes>
            <Route path="/" element={<Dashboard />}>
              <Route path="lst" element={<LstStrategiesGrid />} />
              <Route path="lrt" element={<LrstStrategiesGrid />} />
              <Route path="defi-strategies" element={<DefiStrategiesGrid />} />
            </Route>
            <Route path="/stake" element={<Stake />} />
            <Route path="info" element={<ProductInfo />}>
              <Route path="aubnb" element={<AuBnbInfo />} />
              <Route
                path="defi-strategies/:networkName/:productAddress"
                element={<DefiInfo />}
              />
              <Route
                path="lst/:networkName/:productName"
                element={<AssetInfo />}
              />
              <Route
                path="lrt/:networkName/:productName"
                element={<AssetInfo />}
              />
            </Route>
            <Route path="/referrals" element={<Referrals />}>
              <Route path="traders" element={<Traders />} />
              <Route path="affiliates" element={<Affiliates />} />
            </Route>
            <Route path="/airdrop" element={<Airdrop />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Modal />
      </main>
    </ModalWrapper>
  )
}

const AppWrapper = (props) => (
  <Suspense fallback={<div />}>
    <App {...props} />
  </Suspense>
)

export default AppWrapper
