import { FormattedMessage } from 'react-intl'
import { useCopy } from 'src/hooks/useCopy'
import { TokensInfo } from 'src/hooks/useTokensLst'
import { useLocation } from 'react-router'
import { Pools } from 'src/types/pools'

import './ShareLstOptions.sass'

interface ShareLstProps {
  productInfo: TokensInfo | Pools
}

declare const __CLIENT__: boolean

export default function ShareLstOptions({ productInfo }: ShareLstProps) {
  const { showCopied, copyClipBoard } = useCopy()

  const { pathname } = useLocation()

  const url = __CLIENT__
    ? window.location.href
    : `https://app.autolayer.io/${pathname}`

  const text = `AutoLayer's 1-Click Liquid Staking: ${productInfo.name} (${productInfo.id})`

  const urlTwitter: string = `https://twitter.com/intent/tweet?text=${text}&url=${url}`

  const urlTelegram: string = `https://t.me/share/url?url=${url}&text=${text}`

  return (
    <div id="product-share" tabIndex={-1}>
      <ul>
        <li>
          <a href={urlTelegram} target="_blank" rel="noreferrer noopener">
            <span className="icon icon-telegram" />
            <span>
              <FormattedMessage id="share-telegram" />
            </span>
          </a>
        </li>
        <li>
          <a href={urlTwitter} target="_blank" rel="noopener noreferrer">
            <span className="icon icon-x" />
            <span>
              <FormattedMessage id="share-twitter" />
            </span>
          </a>
        </li>
        <li onClick={(e) => copyClipBoard(e, url)}>
          {!showCopied ? (
            <>
              <span className="icon icon-copypaste" />
              <span>
                <FormattedMessage id="share-link" />
              </span>
            </>
          ) : (
            <span>
              <FormattedMessage id="share-link-success" />
            </span>
          )}
        </li>
      </ul>
    </div>
  )
}
