import { CoingeckoDataSummary } from 'src/hooks/useCoingecko'
import { tokenAddressToSymbol } from 'src/utils/tokenAddressToName'
import { GENERIC_ABIS } from '../abis'
import { ArbitrumData } from 'src/data/Arbitrum'
import getWeb3 from '../web3'
import { getCamelotLpBalancesForAllPools } from './getNFTId'
import { JsonRpcOptionalRequest } from 'web3'
import { Networks } from '@utils/networkHelper'

//* With this functions we get the Liquidity and totalSupply of Camelot NFTs to obtain the USD value of the NFT and the unitary price for the LP
const getLiquidityForLpCamelotNFT = async (
  posAddress: string,
  coingeckoData: CoingeckoDataSummary[]
): Promise<number> => {
  const web3 = getWeb3('arbitrum')
  const posContract = new web3.eth.Contract(
    GENERIC_ABIS.camelotLpAbi as any[],
    posAddress
  )
  const batchRequest = new web3.BatchRequest()
  const jsonRpc = (id: string, data: string): JsonRpcOptionalRequest => ({
    jsonrpc: '2.0',
    method: 'eth_call',
    params: [
      {
        to: posAddress,
        data
      }
    ],
    id
  })

  const totalAmount = posContract.methods.getTotalAmounts().encodeABI()
  const token0 = posContract.methods.token0().encodeABI()
  const token1 = posContract.methods.token1().encodeABI()

  const promises = [
    batchRequest.add(jsonRpc('1', totalAmount)),
    batchRequest.add(jsonRpc('2', token0)),
    batchRequest.add(jsonRpc('3', token1))
  ]
  await batchRequest.execute()
  const [weiAmounts, token0AddressRaw, token1AddressRaw]: any[] =
    await Promise.all(promises)

  const stringAmount = web3.eth.abi.decodeParameters(
    ['uint256', 'uint256'],
    weiAmounts
  )
  const weiAmountArray: string[] = [
    stringAmount[0].toString(),
    stringAmount[1].toString()
  ]

  const amounts: number[] = weiAmountArray.map((amount: string) =>
    parseFloat(web3.utils.fromWei(amount, 'ether'))
  )
  const token0Address: string = web3.eth.abi.decodeParameter(
    'address',
    token0AddressRaw
  ) as string
  const token1Address: string = web3.eth.abi.decodeParameter(
    'address',
    token1AddressRaw
  ) as string
  const token0Symbol: string = tokenAddressToSymbol(
    token0Address,
    Networks.arbitrum
  )
  const token1Symbol: string = tokenAddressToSymbol(
    token1Address,
    Networks.arbitrum
  )
  const token0Price: number = coingeckoData.find(
    (data) => data.symbol.toLowerCase() === token0Symbol.toLowerCase()
  )?.current_price
  const token1Price: number = coingeckoData.find(
    (data) => data.symbol.toLowerCase() === token1Symbol.toLowerCase()
  )?.current_price
  const liquidityUSD: number =
    amounts[0] * token0Price + amounts[1] * token1Price
  return liquidityUSD
}

const getTotalSupplyForLpCamelotNFT = async (
  posAddress: string
): Promise<number> => {
  const web3 = getWeb3('arbitrum')
  const posContract = new web3.eth.Contract(
    GENERIC_ABIS.camelotLpAbi as any[],
    posAddress
  )
  const totalSupplyWei: number = await posContract.methods.totalSupply().call()
  const totalSupply = parseFloat(
    web3.utils.fromWei(totalSupplyWei.toString(), 'ether')
  )
  return totalSupply
}

export const getLpUSDPriceForNftPosition = async (
  posAddress: string,
  coingeckoData: CoingeckoDataSummary[]
): Promise<number> => {
  const liquidityUSD: number = await getLiquidityForLpCamelotNFT(
    posAddress,
    coingeckoData
  )
  const totalSupply: number = await getTotalSupplyForLpCamelotNFT(posAddress)
  const unitaryLpPriceUsd: number = liquidityUSD / totalSupply
  return unitaryLpPriceUsd
}

export interface CamelotPoolWithLpPrice {
  pos: string
  lpPrice: number
  nftAddress: string
}

export const addLpPriceToCamelotPoolArray = async (
  coingeckoData: CoingeckoDataSummary[]
): Promise<CamelotPoolWithLpPrice[]> => {
  const poolsWithLpPrice = await Promise.all(
    ArbitrumData.camelotPools.map(async (p) => {
      const lpPrice: number = await getLpUSDPriceForNftPosition(
        p.pos,
        coingeckoData
      )
      return { pos: p.pos, nftAddress: p.nftAddress, lpPrice }
    })
  )
  return poolsWithLpPrice
}

export const getCamelotPositionsUsdValue = async (
  coingeckoData: CoingeckoDataSummary[],
  userAddress: string
): Promise<number> => {
  const camelotPoolsWithLpPrice: CamelotPoolWithLpPrice[] =
    await addLpPriceToCamelotPoolArray(coingeckoData)
  const camelotPoolsBalances: { nftAddress: string; balanceLp: number }[] =
    await getCamelotLpBalancesForAllPools(userAddress)
  const camelotUsdValue: number =
    camelotPoolsBalances?.reduce((acc, p) => {
      const lpPrice: number = camelotPoolsWithLpPrice.find(
        (c) => c.nftAddress.toLowerCase() === p.nftAddress.toLowerCase()
      )?.lpPrice
      return acc + lpPrice * p.balanceLp
    }, 0) ?? 0
  return camelotUsdValue
}
