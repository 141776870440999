import MultiplierInfo from '../MultiplierInfo'
import {
  Multiplier,
  multipliersList,
  MultiplierValue
} from '../multipliersList'
import IconMultiplier from '../IconMultiplier/IconMultiplier'
import { Multipliers } from 'src/types'

import './ClusterMultiplier.sass'

const ClusterMultiplier = ({ multipliers }: { multipliers: Multipliers }) => {
  const groupedMultipliers = Object.entries(multipliersList).reduce(
    (acc, [key, value]) => {
      const multiplierValue = multipliers[key as Multiplier]
      if (!multiplierValue) return acc
      if (!acc[multiplierValue]) acc[multiplierValue] = []
      acc[multiplierValue].push(value)
      return acc
    },
    {} as { [key: string]: MultiplierValue[] }
  )

  return (
    <section className="cluster-multiplier">
      {Object.entries(groupedMultipliers).map(([multiplierValue, items]) => (
        <div key={multiplierValue} className="product-multiplier">
          <MultiplierInfo amount={+multiplierValue} />
          <div className="product-multiplier-wrapper">
            {items.map(({ name, icon }) => (
              <IconMultiplier
                key={name}
                name={name.toLowerCase()}
                icon={icon}
              />
            ))}
          </div>
        </div>
      ))}
    </section>
  )
}

export default ClusterMultiplier
