import { BalancerPool } from 'src/types/pools'
import { AUTOLAYER_ABIS } from './abis'
import { SWAPS_CONTRACT } from './addresses'
import { getEtherNumberAmount } from './tokenDecimals/getEtherNumberAmount'
import { NetworkIds } from 'src/utils/networkHelper'
import web3 from '@utils/web3'
import { AbiItem } from 'web3'

/*
@pool --> Destination pool info
@weiAmount --> Amount to be sent in the transaction
@userAddress --> User address
*/

const removeLiquidityBalancerPool = async (
  pool: BalancerPool,
  bptWeiAmount: string,
  userAddress: string,
  bptAddress: string,
  chainId: NetworkIds
) => {
  const gasPrice = await web3.eth.getGasPrice()
  const swapsContractAddress: string = SWAPS_CONTRACT[chainId.toLowerCase()]
  const swapsContract = new web3.eth.Contract(
    AUTOLAYER_ABIS[chainId.toLowerCase()].swapContract as AbiItem[],
    swapsContractAddress.toLowerCase()
  )

  const poolId: string = pool.id.toLowerCase()
  const poolTokensAddresses: string[] = pool?.filteredTokens.map((token) =>
    token.address.toLowerCase()
  )
  const poolTokensWithBptAddresses: string[] = pool.tokens.map((token) =>
    token.address.toLowerCase()
  )
  poolTokensAddresses.sort((a, b) => Number(a) - Number(b)) // order addresses from lower to higher
  poolTokensWithBptAddresses.sort((a, b) => Number(a) - Number(b)) // order addresses from lower to higher
  const amountMin: number[] = poolTokensWithBptAddresses.map(() => 0)
  const poolTokenOutputAddress: string = poolTokensAddresses[0]

  const estimatedGas = await swapsContract.methods
    .removeLiquidityFromBalancer(
      poolId,
      bptAddress,
      poolTokenOutputAddress,
      poolTokensWithBptAddresses,
      amountMin,
      bptWeiAmount
    )
    .estimateGas({
      from: userAddress
    })
  const increasedGasPrice = Number(estimatedGas) * 2
  const removeLiquidityTx = await swapsContract.methods
    .removeLiquidityFromBalancer(
      poolId,
      bptAddress,
      poolTokenOutputAddress,
      poolTokensWithBptAddresses,
      amountMin,
      bptWeiAmount
    )
    .send({
      from: userAddress,
      gasPrice: gasPrice.toString(),
      gas: increasedGasPrice.toString()
    })

  const txLogs = removeLiquidityTx.logs
  const txLogsArray: any[] = Object.values(txLogs)
  const lastTransferEvent = txLogs[txLogsArray?.length - 1]
  const hexAmountWei: string = lastTransferEvent?.data
  const amountWei: string = web3.utils.hexToNumberString(hexAmountWei)
  const amountEth: string = await getEtherNumberAmount(
    poolTokenOutputAddress,
    amountWei,
    chainId
  )
  const transactionHash = removeLiquidityTx.transactionHash
  return { transactionHash, amountWei, amountEth }
}

export default removeLiquidityBalancerPool
