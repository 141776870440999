import { BINANCE } from 'src/constants'
import { NetworkData } from 'src/data'
import networkChoices from '../components/common/Network/networkChoice.json'
import { BinanceData } from 'src/data/Binance'

export enum Networks {
  arbitrum = '0xa4b1',
  ethereum = '0x1',
  binance = '0x38',
  base = '0x2105',
  scroll = '0x82750',
  linea = '0xe708',
  optimism = '0xa',
  mode = '0x868b'
}

export type NetworkIds = `${Networks}`

export type NetworkName = keyof typeof networkChoices

export enum Environments {
  localDev = 'LOCAL_DEV',
  fantomLocalDev = 'MAINNET_FANTOM_DEV_LOCAL',
  arbitrumLocal = 'MAINNET_ARBITRUM_DEV_LOCAL',
  mainnetCandidate = 'MAINNET_DEV',
  mainnetPro = 'MAINNET_PRO'
}

// Default values for networks
export const DEFAULT_NETWORK_ID: NetworkIds = '0x38'
export const DEFAULT_NETWORK_NAME: NetworkName = 'binance'
export const DEFAULT_NETWORK_DATA: NetworkData = BinanceData
export const DEFAULT_NETWORK = BINANCE

export const getNetworkName = (networkId: NetworkIds): NetworkName => {
  if (networkId === Networks.arbitrum) return 'arbitrum'
  else if (networkId === Networks.ethereum) return 'ethereum'
  else if (networkId === Networks.binance) return 'binance'
  else if (networkId === Networks.base) return 'base'
  else if (networkId === Networks.scroll) return 'scroll'
  else if (networkId === Networks.linea) return 'linea'
  else if (networkId === Networks.optimism) return 'optimism'
  else if (networkId === Networks.mode) return 'mode'
  else if (!networkId) return DEFAULT_NETWORK_NAME
}

export const getNetworkIdByName = (networkName: NetworkName): NetworkIds => {
  if (networkName === 'arbitrum') return Networks.arbitrum
  else if (networkName === 'ethereum') return Networks.ethereum
  else if (networkName === 'binance') return Networks.binance
  else if (networkName === 'base') return Networks.base
  else if (networkName === 'scroll') return Networks.scroll
  else if (networkName === 'linea') return Networks.linea
  else if (networkName === 'optimism') return Networks.optimism
  else if (networkName === 'mode') return Networks.mode
  else return DEFAULT_NETWORK_ID
}

export const getNativeCurrencySymbol = (networkId: NetworkIds): string => {
  if (networkId === Networks.binance) return 'BNB'
  else return 'ETH'
}

export const getItemByNetwork = (
  networkId: string
): { scanName: string; networkTag: string } => {
  let scanName
  let networkTag
  switch (networkId) {
    case Networks.arbitrum:
      scanName = 'ARBIScan'
      networkTag = 'Arbitrum'
      break
    case Networks.binance:
      scanName = 'BscScan'
      networkTag = 'BNB Chain'
      break
    case Networks.ethereum:
      scanName = 'Etherscan'
      networkTag = 'Ethereum'
      break
    case Networks.base:
      scanName = 'BaseScan'
      networkTag = 'Base'
      break
    case Networks.scroll:
      scanName = 'ScrollScan'
      networkTag = 'Scroll'
      break
    case Networks.linea:
      scanName = 'LineaScan'
      networkTag = 'Linea'
      break
    case Networks.optimism:
      scanName = 'OptimismScan'
      networkTag = 'Optimism'
      break
    case Networks.mode:
      scanName = 'ModeScan'
      networkTag = 'Mode'
      break
    default:
      scanName = 'Scan'
      break
  }
  return { scanName, networkTag }
}
