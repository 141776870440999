import { NetworkData, networkData } from 'src/data'
import { Networks } from './networkHelper'

const tokenAddressToName = (
  tokenAddress: string,
  chainId?: Networks
): string => {
  const networkInfo: NetworkData = networkData(chainId)
  const token = networkInfo.tokens?.find(
    (t: any) => t.address?.toLowerCase() === tokenAddress?.toLowerCase()
  )
  if (token) {
    return token?.name
  } else return ''
}

export default tokenAddressToName

export const tokenAddressToSymbol = (
  tokenAddress: string,
  chainId?: Networks
): string => {
  const networkInfo: NetworkData = networkData(chainId)
  const token = networkInfo.tokens?.find(
    (t: any) => t.address?.toLowerCase() === tokenAddress?.toLowerCase()
  )
  if (token) {
    return token?.id
  } else return ''
}
