import { FormattedMessage } from 'react-intl'
import SocialLinks from '../SocialLinks/SocialLinks'
import { useSelector } from 'react-redux'
import { WalletData } from 'src/types'

import './Footer.sass'

const Footer = ({ darkMode = false }: { darkMode?: boolean }) => {
  const wallet = useSelector((s: any) => s.wallet as WalletData)
  return (
    <footer
      id="colophon"
      className={`${wallet ? 'with-wallet' : ''} ${darkMode ? 'dark-theme' : 'light-theme'}`}
    >
      <div className="container">
        <section className="general-links">
          <a
            rel="noopener noreferrer"
            className="link"
            target="_blank"
            href="https://legacy.autolayer.io/fast-mode/products"
          >
            <FormattedMessage
              id="footer.legacy"
              defaultMessage="Legacy Access (Tortle Ninja)"
            />
          </a>
          <a
            rel="noopener noreferrer"
            className="link"
            target="_blank"
            href="https://docs.autolayer.io/autolayer/latest-app-infos/privacy-policy/terms-of-use"
          >
            <FormattedMessage
              id="footer.legal.terms"
              defaultMessage="Terms of Use"
            />
          </a>
          <a
            rel="noopener noreferrer"
            className="link"
            target="_blank"
            href="https://docs.autolayer.io/autolayer/latest-app-infos/privacy-policy"
          >
            <FormattedMessage id="footer.legal.privacy" />
          </a>
          <a
            rel="noopener noreferrer"
            className="link"
            target="_blank"
            href="https://docs.autolayer.io/"
          >
            <FormattedMessage id="footer.info.docs" />
          </a>
          <a
            rel="noopener noreferrer"
            className="link"
            target="_blank"
            href="https://docs.autolayer.io/autolayer/welcome/faqs-and-troubleshooting"
          >
            FAQs
          </a>
          <a
            rel="noopener noreferrer"
            className="link"
            target="_blank"
            href="http://discord.gg/autolayer-899973803239686174"
          >
            <FormattedMessage
              id="footer.help.feed-back"
              defaultMessage="Send Feedback"
            />
          </a>
        </section>
        <section className="social-links-wrapper">
          <SocialLinks />
        </section>
      </div>
    </footer>
  )
}

export default Footer
