import { SmallIcons } from 'src/static/images/iconsSmall'
import { FormattedMessage } from 'react-intl'

import './ConnectButton.sass'

interface ConnectButtonProps {
  darkMode?: boolean
  text?: string
}

export default function ConnectButton({
  darkMode = false,
  text = 'default-text'
}: ConnectButtonProps) {
  const clickButton = async () => {
    import(/* webpackChunkName: "wallet" */ 'src/utils/wallet').then((m) =>
      m.connect()
    )
  }

  return (
    <button
      className={`connect-wallet ${darkMode ? 'dark-theme' : 'light-theme'}`}
      onClick={clickButton}
    >
      <img
        src={darkMode ? SmallIcons.walletWhite : SmallIcons.walletBlack}
        alt="wallet"
      />
      <span>
        <FormattedMessage id={`connect-wallet.${text}`} />
      </span>
    </button>
  )
}
