import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import CreateCode from './CreateCode'
import InsertCode from './InsertCode'

import './ReferralsDialog.sass'

export interface ReferralsDialogProps {
  handleClose: () => void
  type: 'insert' | 'create'
  refresh?: () => void
}

const ReferralsDialog = ({
  handleClose,
  type,
  refresh
}: ReferralsDialogProps) => {
  return (
    <div id="referrals-dialog">
      <ErrorBoundary fallback={<div>Error</div>}>
        {type === 'create' ? (
          <CreateCode handleClose={handleClose} refresh={refresh} />
        ) : (
          <InsertCode handleClose={handleClose} refresh={refresh} />
        )}
      </ErrorBoundary>
    </div>
  )
}

export default ReferralsDialog
