import getWeb3 from '../web3'
import { stakingAbi } from '../abis/AutoLayerAbis/StakingAbi'
import { NetworkName } from '@utils/networkHelper'
import { StakingTotals } from 'src/types/staking'

export const getTotalAmountStaked = async (
  contractAddress: string,
  networkName: NetworkName = 'binance'
): Promise<number> => {
  const web3 = getWeb3(networkName)
  const contract = new web3.eth.Contract(stakingAbi, contractAddress)
  const totalAmountStaked: bigint = await contract.methods
    .totalAmountStaked()
    .call()
  const ethTotalAmountStaked = web3.utils.fromWei(
    totalAmountStaked.toString(),
    'ether'
  )
  return parseFloat(ethTotalAmountStaked)
}

export const getHistoricTotalAmountStaked = async (
  contractAddress: string,
  networkName: NetworkName = 'binance'
): Promise<number> => {
  const web3 = getWeb3(networkName)
  const contract = new web3.eth.Contract(stakingAbi, contractAddress)
  const historicTotalAmount: bigint = await contract.methods
    .historicTotalAmountStaked()
    .call()
  const ethhistoricTotalAmount = web3.utils.fromWei(
    historicTotalAmount.toString(),
    'ether'
  )
  return parseFloat(ethhistoricTotalAmount)
}

export const getHistoricTotalRewardsClaimed = async (
  contractAddress: string,
  networkName: NetworkName = 'binance'
): Promise<number> => {
  const web3 = getWeb3(networkName)
  const contract = new web3.eth.Contract(stakingAbi, contractAddress)
  const historicTotalRewardClaimed: bigint = await contract.methods
    .historicTotalRewardClaimed()
    .call()
  const etHistoricTotalRewardClaimed = web3.utils.fromWei(
    historicTotalRewardClaimed.toString(),
    'ether'
  )
  return parseFloat(etHistoricTotalRewardClaimed)
}

export const getStakingTotals = async (
  contractAddress: string,
  networkName: NetworkName = 'binance'
): Promise<StakingTotals> => {
  const totalAmountStaked = await getTotalAmountStaked(
    contractAddress,
    networkName
  )
  const historicTotalAmountStaked = await getHistoricTotalAmountStaked(
    contractAddress,
    networkName
  )
  const historicTotalRewardClaimed = await getHistoricTotalRewardsClaimed(
    contractAddress,
    networkName
  )
  return {
    totalAmountStaked,
    historicTotalAmountStaked,
    historicTotalRewardClaimed
  }
}

export const getAvailableCapacity = async (
  contractAddress: string,
  networkName: NetworkName = 'binance'
): Promise<number> => {
  const web3 = getWeb3(networkName)
  if (!contractAddress) return 0
  const contract = new web3.eth.Contract(stakingAbi, contractAddress)
  const resAvailableCapacity: bigint = await contract.methods
    .getAvailableStakeAmount()
    .call()
  console.log('resAvailableCapacity', resAvailableCapacity)
  const availableCapacity = web3.utils.fromWei(
    resAvailableCapacity.toString(),
    'ether'
  )
  return parseFloat(availableCapacity)
}
