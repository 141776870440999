import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

import './AuBnbBanner.sass'

export default function AuBnbBanner() {
  const location = useLocation()
  const isInfoPage = location.pathname.includes('/info')
  return (
    <div id="aubnb-banner">
      <h3>AuBNB</h3>
      <p>
        <FormattedMessage id="aubnb.banner.text" />
      </p>
      {!isInfoPage && (
        <Link className="button" to="/info/aubnb">
          <FormattedMessage id="more-info" />
        </Link>
      )}
      <div className="sphere" />
    </div>
  )
}
