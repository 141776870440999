import { GENERIC_ABIS } from './abis'
import { SWAPS_CONTRACT } from './addresses'
import getAllowance from './getAllowance'
import tokenNameToAddress from 'src/utils/tokenNameToAddress'
import { NetworkIds, Networks } from 'src/utils/networkHelper'

import web3 from '@utils/web3'

const approveToken = async (
  tokenInput: string,
  amountIn: bigint,
  userAddress: string,
  chainId: NetworkIds
) => {
  const input: string =
    tokenInput.length === 42
      ? tokenInput.toLowerCase()
      : tokenNameToAddress(tokenInput, chainId)
  const swapsContractAddress: string = SWAPS_CONTRACT[chainId].toLowerCase()
  const allowanceAmountWei: bigint = await getAllowance(
    input,
    swapsContractAddress,
    userAddress,
    chainId
  )

  if (allowanceAmountWei >= amountIn) return '0x' // amount is already approved

  const tokenContract = new web3.eth.Contract(
    GENERIC_ABIS.token as any[],
    input
  )
  const estimatedGas = await tokenContract.methods
    .approve(swapsContractAddress, amountIn.toString())
    .estimateGas({
      from: userAddress
    })
  const increasedGasPrice =
    chainId === Networks.arbitrum
      ? Number(estimatedGas) * 2
      : Math.ceil(Number(estimatedGas) * 1.4)
  const tx = await tokenContract.methods
    .approve(swapsContractAddress, amountIn.toString())
    .send({
      from: userAddress,
      gas: increasedGasPrice.toString(),
      maxPriorityFeePerGas: null,
      maxFeePerGas: null
    })
  return tx
}

export default approveToken
