import { TransactionParams } from '@paraswap/sdk'
import { SWAPS_CONTRACT } from '../addresses'
import { AUTOLAYER_ABIS } from '../abis'
import { NetworkIds } from 'src/utils/networkHelper'
import web3 from '@utils/web3'

const addLiquidityCamelot = async (
  ParamsTxToken0: TransactionParams,
  ParamsTxToken1: TransactionParams,
  pos: string,
  nftPoolAddress: string,
  ethRealWeiAmount: bigint,
  userAddress: string,
  chainId: NetworkIds
) => {
  const swapData0: string = ParamsTxToken0.data
  const swapData1: string = ParamsTxToken1.data
  const amountInMin: number[] = [0, 0, 0, 0]
  const lockDuration: number = 0
  const swapsContractAddress: string = SWAPS_CONTRACT[chainId.toLowerCase()]
  const swapsContract = new web3.eth.Contract(
    AUTOLAYER_ABIS[chainId.toLowerCase()].swapContract as any[],
    swapsContractAddress.toLowerCase()
  )

  //* if value different 0, tokenIn is native ETH and we must send to the contract
  const estimatedGas = await swapsContract.methods
    .addLiquidityToCamelot(
      swapData0,
      swapData1,
      pos,
      amountInMin,
      nftPoolAddress,
      lockDuration
    )
    .estimateGas({
      from: userAddress,
      value: ethRealWeiAmount.toString()
    })

  const increasedGasPrice: number = Number(estimatedGas) * 2

  const sendSwapTx = await swapsContract.methods
    .addLiquidityToCamelot(
      swapData0,
      swapData1,
      pos,
      amountInMin,
      nftPoolAddress,
      lockDuration
    )
    .send({
      from: userAddress,
      gasPrice: ParamsTxToken0.gasPrice,
      gas: increasedGasPrice.toString(),
      value: ethRealWeiAmount.toString()
    })

  return sendSwapTx
}

export default addLiquidityCamelot
