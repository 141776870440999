import usePromise from './usePromise'
import { TokenProps } from 'src/components/TokenSelector/TokenSelectorList/TokenSelectorList'
import { ArbitrumData } from 'src/data/Arbitrum'
import useCoingecko, { CoingeckoDataSummary } from './useCoingecko'
import usePools from './usePools'
import { useSelector } from 'react-redux'
import { WalletData } from 'src/types'
import { PortfolioStrategies } from 'src/types/portfolioTypes'
import useTokensLRTValueUsd from './useTokensLRTValueUsd'
import { Networks } from 'src/utils/networkHelper'
import { getPoolsBalanceListBatch } from 'src/contracts/getBalance/getBalanceListBatch'
import { useMemo } from 'react'
import calculateLpValues, {
  CamelotDataWithUserValues
} from 'src/contracts/Camelot/calculateLpValues'
import getFullCamelotData, {
  CamelotData
} from 'src/contracts/Camelot/getFullCamelotData'

// * This hook is used to get the TOTAL USD value of the user's deposit points for lst and lrst tokens.
// * The USD value is estimated using ether price.

const useDepositUsdPoints = (): number => {
  const wallet = useSelector((s: any) => s?.wallet as WalletData)
  const coingeckoData: CoingeckoDataSummary[] = useCoingecko()

  //* Tokens
  const { tokensUsdValue } = useTokensLRTValueUsd(Networks.arbitrum)

  //* Balancer pools
  const pools = usePools()
  const poolsList = useMemo(() => ArbitrumData.pools, [])
  const balanceBPT: TokenProps[] = usePromise(
    getPoolsBalanceListBatch,
    poolsList,
    wallet?.address
  )
  const poolsWithBalance: PortfolioStrategies[] = pools.map((pool) => {
    const bptBalance: number =
      balanceBPT.find(
        (b) => b.address.toLowerCase() === pool.address.toLowerCase()
      )?.balance || 0
    const bptPrice: number =
      parseFloat(pool.liquidity) / parseFloat(pool.totalShares)
    const bptUserUsdValue: number = bptPrice * bptBalance
    return {
      ...pool,
      bptBalance,
      bptPrice: bptPrice?.toFixed(2),
      bptUserUsdValue
    }
  })
  const balancerUsdValue: number =
    poolsWithBalance?.reduce((acc, p) => acc + p.bptUserUsdValue, 0) ?? 0

  //* Camelot pools
  const poolsFullData: CamelotData[] = usePromise(
    getFullCamelotData,
    wallet?.address
  )
  const camelotPoolsWithUserUsdValue: CamelotDataWithUserValues[] =
    calculateLpValues(poolsFullData, coingeckoData)
  const camelotUsdValue: number = camelotPoolsWithUserUsdValue?.reduce(
    (acc, p) => acc + p.usdUserValue,
    0
  )
  return tokensUsdValue + balancerUsdValue + camelotUsdValue
}

export default useDepositUsdPoints
