import { FormattedMessage } from 'react-intl'
import { unstake } from 'src/contracts/staking/stake'
import { StakeDialogsProps } from '../../StakeDialog'
import StakeDialogSteps from '../../Steps/StakeDialogSteps'
import TokenInput from '@components/TokenInput/TokenInput'
import { AutolayerLogos } from '@static/logos/autolayer'
import { useStakingPosition } from '@hooks/useStaking'
import { useWallet } from '@hooks/useWallet'
import { FormEvent, useState } from 'react'

export default function StakeDialogUnstake({
  poolAddress,
  positionId,
  updateRefreshKey,
  stepsData
}: StakeDialogsProps) {
  const address = useWallet()
  const positionData = useStakingPosition(address, positionId, poolAddress)
  const { currentAmount } = positionData

  const [enabled, setEnabled] = useState<boolean>(true)

  const { addStep, errorStep, resetSteps, setDone, steps, successStep } =
    stepsData

  const handleUnstake = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // Unstake selected
    try {
      addStep('stake.staking-dialog.stake.confirmation')
      const tx = await unstake(address, poolAddress, positionId)
      successStep()
      setDone('stake.staking-dialog.steps.done-unstaking', tx)
      updateRefreshKey()
    } catch (err) {
      console.error('Error unstaking', err)
      errorStep()
    }
  }
  return (
    <form onSubmit={handleUnstake}>
      <TokenInput
        className="stake"
        disabled
        initialValue={currentAmount}
        handleExternalChange={(value) => {
          resetSteps()
          if (value === 0) setEnabled(false)
        }}
      >
        <div>
          <img src={AutolayerLogos.autolayerIconWhite} alt="$LAY3R logo" />
          <span>$LAY3R</span>
        </div>
      </TokenInput>
      <StakeDialogSteps steps={steps}>
        <button className="continue" disabled={!enabled} type="submit">
          <FormattedMessage id="continue" />
        </button>
      </StakeDialogSteps>
    </form>
  )
}
