import { GENERIC_ABIS } from '../abis'
import getWeb3 from '../web3'

export interface GammaDepositAmount {
  amountStart: bigint
  amountEnd: bigint
}

interface DepositAmountResponse {
  amountStart: string
  amountEnd: string
}

//* This function returns the amount interval which can be used for second token in the pool.
const getDepositAmount = async (
  pos: string,
  token0Address: string,
  amountToken0Address: bigint
): Promise<GammaDepositAmount> => {
  const GAMMA_CONTRACT: string = '0x1f7792eD527A399159583730017cdB5003D57f4F'
  const web3 = getWeb3('arbitrum')
  const gammaContract = new web3.eth.Contract(
    GENERIC_ABIS.gammaContractAbi as any[],
    GAMMA_CONTRACT
  )
  const depositAmount: DepositAmountResponse = await gammaContract.methods
    .getDepositAmount(pos, token0Address, amountToken0Address.toString())
    .call()

  const { amountStart, amountEnd } = depositAmount
  return {
    amountStart: BigInt(amountStart),
    amountEnd: BigInt(amountEnd)
  }
}

export default getDepositAmount
