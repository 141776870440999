import { FormattedMessage } from 'react-intl'
import { PointsBoxProps } from './PointsBox'
import Spinner from '../../Loading/Spinner/Spinner'

import './PointsBox.sass'

const DefaultPointsBox = ({
  type = 'default'
}: {
  type: PointsBoxProps['type'] | 'default' | 'error'
}) => {
  return (
    <div className={`${type}-points-box points-box`}>
      <h4>
        {type === 'error' ? (
          <>
            <span className="icon icon-warning" />
            <span>
              {' '}
              <FormattedMessage id="error" />
            </span>
          </>
        ) : (
          <FormattedMessage id="loading" />
        )}
      </h4>
      <div className="autolayer-amount row">
        {type === 'error' ? (
          <span>
            <FormattedMessage id="error-loading-your-values" />
          </span>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default DefaultPointsBox
