import { useSelector } from 'react-redux'
import { WalletData } from 'src/types'
import usePromise from './usePromise'
import {
  getAmountOfPoolNftsForUser,
  getLpsForNfts,
  getNFTIds
} from 'src/contracts/Camelot/getNFTId'

export const useCamelotPoolNftsForUser = (
  refreshKey: number,
  nftAddress: string
): number => {
  const wallet = useSelector((s: any) => s.wallet as WalletData)
  const amountNfts = usePromise(
    getAmountOfPoolNftsForUser as (
      address: string,
      userAddress: string,
      s: number
    ) => Promise<number>,
    nftAddress,
    wallet?.address,
    refreshKey
  )
  return amountNfts
}

export const useGetNftIdsCamelot = (
  refreshKey: number,
  nftPoolsAddress: string
): number[] => {
  const wallet = useSelector((s: any) => s.wallet as WalletData)
  const nftsIds: number[] = usePromise(
    getNFTIds as (
      nftAddress: string,
      userAddress: string,
      key: number
    ) => Promise<number[]>,
    nftPoolsAddress,
    wallet?.address,
    refreshKey
  )
  return nftsIds
}

export const useGetLpAmountForNft = (
  refreshKey: number,
  nftPoolsAddress: string,
  nftsIds: number[]
): number[] => {
  const idsBalances: number[] = usePromise(
    getLpsForNfts as (
      nft: string,
      ids: number[],
      key: number
    ) => Promise<number[]>,
    nftPoolsAddress,
    nftsIds,
    refreshKey
  )
  return idsBalances
}
