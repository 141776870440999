import { FormattedMessage } from 'react-intl'
import { compactNumbers } from 'src/utils/compactNumbers'
import usePromise from 'src/hooks/usePromise'
import tokenNameToAddress from 'src/utils/tokenNameToAddress'
import { getSingleTokenBalance } from 'src/contracts/getBalance/getSingleTokenBalance'
import { getTokenImage } from 'src/utils/getTokenImage'
import { useCopy } from 'src/hooks/useCopy'
import { useTokenBalanceList } from 'src/hooks/useTokenBalanceList'
import { WalletData } from 'src/types'
import { useSelector } from 'react-redux'
import useNetworkFromRoute from 'src/hooks/useNetworkFromRoute'
import { NetworkData, networkData } from 'src/data'
import { useParams } from 'react-router-dom'
import { filterTokenInListToAvoidSwapSameToken } from './tokensSelectorHelper'

import './TokenSelectorList.sass'

export interface TokensList {
  address: string
  id: string
  name?: string
}

interface TokenListProps {
  outputCoin: string
  setCoin: (id: string) => void
  search?: string
  coins
  networkId?: string
  inputCoin?: string
}

interface TokenListItemProps {
  outputCoin: string
  setCoin: (id: string) => void
  token: TokenProps
}
export interface TokenProps extends TokensList {
  balance?: number
}

const TokenListItem = ({ outputCoin, setCoin, token }: TokenListItemProps) => {
  const { showCopied, copyClipBoard } = useCopy()

  return (
    <li
      className={`token-item ${outputCoin === token.id ? 'selected' : ''}`}
      onClick={() => setCoin(token.id)}
    >
      <section>
        <img title={token.id} src={getTokenImage(token.id)} />
        <section>
          <p>
            <strong>{token.name || token.id}</strong>
          </p>
          <p>
            <span>{token.id}</span>
            {token?.balance > 0 && (
              <span className="compact-number">{`(${compactNumbers(token?.balance, 5)})`}</span>
            )}
          </p>
        </section>
      </section>
      <div className="account-number">
        <span>
          {token.address.substr(0, 5) + '...' + token.address.slice(-3)}
        </span>
        {showCopied && (
          <div className="copied-bubble">
            <span>
              <FormattedMessage id="copied-bubble.text" />
            </span>
          </div>
        )}
        <button
          type="button"
          className="mobile-icon mobile-icon-copy"
          onClick={(e) => copyClipBoard(e, token.address)}
        />
      </div>
    </li>
  )
}

const TokenSelectorList = ({
  outputCoin,
  setCoin,
  search,
  coins,
  inputCoin
}: TokenListProps) => {
  const { chainId } = useNetworkFromRoute()
  const { productAddress } = useParams<{ productAddress: string }>()
  const networkInfo: NetworkData = networkData(chainId)
  const balanceCoins = useTokenBalanceList<TokenProps>(networkInfo.tokens)
  const wallet = useSelector((s: any) => s.wallet as WalletData)
  const balanceOutputCoin: string = usePromise(
    getSingleTokenBalance,
    outputCoin,
    wallet?.address,
    chainId
  )

  if (balanceOutputCoin) {
    const index: number = balanceCoins.findIndex(
      (token) => token?.id === outputCoin
    )
    if (index === -1) {
      // it means that token is not inside balanceList. We must add the value
      const newItem: TokenProps = {
        balance: parseFloat(balanceOutputCoin),
        id: outputCoin,
        address: tokenNameToAddress(outputCoin, chainId)
      }
      balanceCoins.push(newItem)
    }
  }
  const ids = new Set(balanceCoins.map((t) => t.id))
  const sortedBalanceCoins = [...balanceCoins].sort(
    (a, b) => +b.balance - +a.balance
  )
  const merged = [...sortedBalanceCoins, ...coins.filter((c) => !ids.has(c.id))]

  const filterCoins: TokenProps[] = merged.filter(
    (c) =>
      c.id.toLowerCase().includes(search.toLowerCase()) ||
      c.name?.toLowerCase().includes(search.toLowerCase())
  )
  const coinstWithInputCoin: TokenProps[] =
    filterTokenInListToAvoidSwapSameToken(
      filterCoins,
      networkInfo,
      productAddress,
      inputCoin
    )

  return (
    <>
      {coinstWithInputCoin.map((c) => (
        <TokenListItem
          key={c.id}
          token={c}
          outputCoin={outputCoin}
          setCoin={setCoin}
        />
      ))}
    </>
  )
}

export default TokenSelectorList
