import { useCallback, useState } from 'react'
import ChartTimeSelector from '../../ChartTimeSelector/ChartTimeSelector'
import RiskChart from '../RiskChartWrapper'
import { ChartTimeSpan } from 'src/types'
import { RiskType } from 'src/types/RiskTypes'

import './CompleteRiskChart.sass'

interface CompleteChartProps {
  productName: string
}

declare let __CLIENT__: boolean

export default function CompleteRiskChart({ productName }: CompleteChartProps) {
  const [time, setTime] = useState<ChartTimeSpan | null>('1m')

  const handleTime = useCallback(
    (time: ChartTimeSpan | null) => setTime(time),
    []
  )
  const [selectedRisks, setSelectedRisks] = useState<RiskType[]>([
    RiskType.Collateral,
    RiskType.Dexes
  ])

  const toggleRisk = (risk: RiskType) => {
    if (selectedRisks.includes(risk)) {
      if (selectedRisks.length > 1) {
        setSelectedRisks(selectedRisks.filter((r) => r !== risk))
      }
    } else {
      setSelectedRisks([...selectedRisks, risk])
    }
  }
  return (
    <section id="complete-risk-chart">
      <div className="row">
        <label id="dexes-check">
          <input
            type="checkbox"
            checked={selectedRisks.includes(RiskType.Dexes)}
            onChange={() => toggleRisk(RiskType.Dexes)}
            disabled={
              selectedRisks.length === 1 &&
              selectedRisks.includes(RiskType.Dexes)
            }
          />
          <span>Liquidity</span>
        </label>
        <label id="collateral-check">
          <input
            type="checkbox"
            checked={selectedRisks.includes(RiskType.Collateral)}
            onChange={() => toggleRisk(RiskType.Collateral)}
            disabled={
              selectedRisks.length === 1 &&
              selectedRisks.includes(RiskType.Collateral)
            }
          />
          <span>Collateral</span>
        </label>
      </div>
      <section className="chart-risk-item">
        {__CLIENT__ ? (
          <RiskChart
            chartTime={time}
            productName={productName}
            handleTime={handleTime}
            selectedRisks={selectedRisks}
          />
        ) : null}
        <ChartTimeSelector time={time} handleTime={handleTime} />
      </section>
    </section>
  )
}
