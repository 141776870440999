import { FormattedMessage } from 'react-intl'

import './AuBnbApy.sass'

interface AuBnbApyProps {
  title: string
  value: number
}

const AuBnbApy = ({ title, value }: AuBnbApyProps) => {
  const finalValue =
    value < 0 ? <FormattedMessage id="coming-soon" /> : value + '%'
  return (
    <div className={`apy-aubnb-box`}>
      <span>{title}</span>
      <strong>{finalValue}</strong>
    </div>
  )
}

export default AuBnbApy
