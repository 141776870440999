import { ChartInfo } from 'src/types/ChartTypes'

const APY = 52.1

export function generateAuBnbChart(): ChartInfo[] {
  const result: ChartInfo[] = []
  const startDate = new Date(2024, 10, 11, 0, 0, 0)

  const actual = new Date()
  actual.setHours(0, 0, 0, 0)

  const currentDate = startDate

  while (currentDate <= actual) {
    result.push({
      value: APY,
      timestamp: currentDate.toISOString()
    })

    currentDate.setDate(currentDate.getDate() + 1)
  }

  return result
}
