import DefaultPointsBox from 'src/components/Points/PointsBox/DefaultPointsBox'

const LeftProductInfoClient = ({ infoType }: { infoType: string }) => {
  const isLST: boolean = infoType === 'LST'
  const isDefi: boolean = infoType === 'Defi'
  return (
    <div className="left-product-info">
      <DefaultPointsBox type="default" />
      <DefaultPointsBox type="default" />
      {!isLST || (isDefi && <DefaultPointsBox type="default" />)}
      {!isLST || (isDefi && <DefaultPointsBox type="default" />)}
      {isDefi && <DefaultPointsBox type="default" />}
    </div>
  )
}

export default LeftProductInfoClient
