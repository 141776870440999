import MarkdownMessage from '@gluedigital/markdown-message'

import './BinaceNetworkNotice.sass'

const BinaceNetworkNotice = () => {
  return (
    <div id="binace-network-notice">
      <p>
        <MarkdownMessage id="binace-network-notice" />
      </p>
    </div>
  )
}

export default BinaceNetworkNotice
