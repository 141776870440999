import balancer from './balancer.png'
import gmx from './gmx.png'
import camelot from './camelot.png'
import reaper from './reaper.png'
import sushiswap from './sushiswap.png'
import uniswap from './uniswap.png'

export const protocolsIcons = {
  sushiswap,
  reaper,
  camelot,
  gmx,
  balancer,
  uniswap
}
