const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

function firstPhrase(text: string): string {
  const firstPoint = text?.indexOf('.')

  if (!firstPoint) return text

  return text?.substring(0, firstPoint + 1)
}

const textHelpers = {
  capitalize,
  firstPhrase
}

export default textHelpers
