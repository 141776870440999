import { useSelector } from 'react-redux'
import useAirdrops from 'src/hooks/useAirdrops'
import { WalletData } from 'src/types'
import RewardCard from './RewardCard/RewardCard'
import DefaultRewardsGrid from './DefaultRewardsGrid'

import './RewardsGrid.sass'

const RewardsGrid = () => {
  const rewards = useAirdrops() || []
  const wallet = useSelector((s: any) => s.wallet as WalletData)
  if (!wallet) return <DefaultRewardsGrid />

  return (
    <section id="rewards-grid" className="grid-container">
      {rewards?.map((r) => <RewardCard key={r.id} info={r} />)}
    </section>
  )
}

export default RewardsGrid
