import { SWAPS_CONTRACT } from '../addresses'
import { AUTOLAYER_ABIS } from '../abis'
import { NetworkIds } from 'src/utils/networkHelper'
import { AbiItem } from 'web3'
import web3 from '@utils/web3'

const withdrawFromCamelotPosition = async (
  nftPoolAddress: string,
  tokenId: string,
  amountToWithdraw: string,
  userAddress: string,
  chainId: NetworkIds
) => {
  const gasPrice = (await web3.eth.getGasPrice()).toString()
  const swapsContractAddress: string = SWAPS_CONTRACT[chainId.toLowerCase()]
  const swapsContract = new web3.eth.Contract(
    AUTOLAYER_ABIS[chainId.toLowerCase()].swapContract as AbiItem[],
    swapsContractAddress.toLowerCase()
  )
  const tx = await swapsContract.methods
    .withdrawFromCamelotPosition(nftPoolAddress, tokenId, amountToWithdraw)
    .send({ from: userAddress, gasPrice })
  return tx
}

export default withdrawFromCamelotPosition

export const unbindCamelotPosition = async (
  pos: string,
  amountToWithdraw: string,
  userAddress: string,
  chainId: NetworkIds
) => {
  const minAmounts: number[] = [0, 0, 0, 0]
  const gasPrice = (await web3.eth.getGasPrice()).toString()
  const swapsContractAddress: string = SWAPS_CONTRACT[chainId.toLowerCase()]
  const swapsContract = new web3.eth.Contract(
    AUTOLAYER_ABIS[chainId.toLowerCase()].swapContract as AbiItem[],
    swapsContractAddress.toLowerCase()
  )
  const tx = await swapsContract.methods
    .unbindCamelotPosition(pos, amountToWithdraw, minAmounts)
    .send({ from: userAddress, gasPrice })
  return tx
}
