import { FormattedMessage } from 'react-intl'

export default function SoonPoints() {
  return (
    <article className="points-item-more points-item-dashboard">
      <section className="inside-container">
        <FormattedMessage id="more-coming-soon" />
      </section>
      <footer />
    </article>
  )
}
