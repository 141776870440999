import { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { registerModal } from '@gluedigital/modal'
import Loading from 'src/components/Loading/Loading'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import AssetBodyTable from './AssetBodyTable/AssetBodyTable'
import PortfolioHeader from './PortfolioHeader/PortfolioHeader'
import DefiBodyTable from './DefiBodyTable/DefiBodyTable'
import { ProductIcons } from 'src/types'
import { productPoints } from 'src/components/Points'
import PointsPortfolio from 'src/components/Points/PointsPortfolio/PointsPortfolio'

import './PortfolioModal.sass'

const PortfolioModal = ({ darkMode = false }: { darkMode?: boolean }) => {
  return (
    <div
      className={`portfolio-modal modal-content ${darkMode ? 'dark-theme' : 'light-theme'}`}
    >
      <PortfolioHeader />
      <div className="points-container-wrapper">
        <div className="points-container">
          {Object.entries(productPoints).map(([type, fetchPoints]) => (
            <PointsPortfolio
              type={type as ProductIcons}
              useFetchPoints={fetchPoints}
              key={type}
            />
          ))}
        </div>
      </div>
      <div className="portfolio-content">
        <div className="portfolio-grid">
          <h2>
            <FormattedMessage id="assets" />
          </h2>
          <ErrorBoundary fallback={<div className="error">Error</div>}>
            <Suspense fallback={<Loading colored />}>
              <AssetBodyTable />
            </Suspense>
          </ErrorBoundary>
        </div>
        <div className="portfolio-grid">
          <h2>
            <FormattedMessage id="strategies" />
          </h2>
          <ErrorBoundary fallback={<div className="error">Error</div>}>
            <Suspense fallback={<Loading colored />}>
              <DefiBodyTable />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  )
}

registerModal('portfolio-modal', PortfolioModal)
