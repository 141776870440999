import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import shadow from 'src/static/images/error/error_shadow.png'
import cube from 'src/static/images/error/error_cube.png'
import logo from 'src/static/logos/autolayer/autolayerLogoWhite.svg'
import './NotFound.sass'

const NotFound = () => {
  return (
    <div id="not-found" className="page">
      <header>
        <div className="container">
          <img className="logo" src={logo} alt="tortle logo" />
        </div>
      </header>
      <div className="container">
        <div className="split">
          <div className="item left">
            <h1>404</h1>
            <h2 className="mono-space">
              <span>
                <FormattedMessage id="error.404-text" />
              </span>
            </h2>
            <Link to="/" className="button">
              <span>
                <FormattedMessage id="back-to-dashboard" />
              </span>
            </Link>
          </div>
          <div className="item right">
            <div className="cube-wrapper">
              <img className="cube" src={cube} alt="cube layers" />
              <img className="shadow" src={shadow} alt="shadow cube layers" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
