import oneClickRstBlack from './oneClickRST-black.svg'
import oneClickRsWhite from './oneClickRST-white.svg'
import strategiesRSTBlack from './strategiesRST-black.svg'
import strategiesRSTWhite from './strategiesRST-white.svg'
import walletWhite from './wallet-white.svg'
import walletBlack from './wallet-black.svg'
import whiteCheckIcon from './whiteCheckIcon.svg'
import whiteCrossIcon from './whiteCrossIcon.svg'
import linkGrey from './link-icon-grey.svg'
import share from './share.svg'
import gift from './gift.svg'

export const SmallIcons = {
  oneClickRstBlack,
  oneClickRsWhite,
  strategiesRSTBlack,
  strategiesRSTWhite,
  walletWhite,
  walletBlack,
  whiteCheckIcon,
  whiteCrossIcon,
  linkGrey,
  share,
  gift
}
