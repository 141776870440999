import { Suspense } from 'react'
import { Outlet, Route, Routes, useOutletContext } from 'react-router-dom'
import useTokensLst, { TokensInfo } from 'src/hooks/useTokensLst'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import { FormattedMessage } from 'react-intl'
import useTokensLrst from 'src/hooks/useTokensLrst'
import Loading from '../Loading/Loading'
import DefiGrid from './DefiGrid/DefiGrid'
import AssetsGrid from './AssetsGrid/AssetsGrid'
import usePools, { useCamelotPools } from 'src/hooks/usePools'
import { Network } from 'src/types'

import './ProductGrid.sass'

interface SelectNetwork {
  selectedNetwork: Network
}

const ComingSoonGrid = () => {
  return (
    <div className="strategies-grid">
      <div className="coming-soon">
        <div>
          <h2>
            <FormattedMessage id="coming-soon" />
          </h2>
        </div>
      </div>
    </div>
  )
}

export const LstStrategiesGrid = () => {
  const tokens: TokensInfo[] = useTokensLst()
  if (tokens.length === 0) return <ComingSoonGrid />
  return <AssetsGrid tokens={tokens} />
}

export const LrstStrategiesGrid = () => {
  const selectedNetwork = useOutletContext()

  const tokens: TokensInfo[] = useTokensLrst()
  if (tokens.length === 0) return <ComingSoonGrid />
  return (
    <AssetsGrid
      tokens={tokens}
      showNetwork
      selectedNetwork={selectedNetwork as Network}
    />
  )
}

export const DefiStrategiesGrid = () => {
  const strategies = usePools()
  const camelotPools = useCamelotPools()
  if (strategies.length === 0) return <ComingSoonGrid />
  return <DefiGrid strategies={strategies} camelotPools={camelotPools} />
}

const ProductGrid = ({ selectedNetwork }: SelectNetwork) => {
  return (
    <ErrorBoundary fallback={<div />}>
      <Suspense fallback={<Loading colored />}>
        <Outlet context={selectedNetwork} />
      </Suspense>
    </ErrorBoundary>
  )
}

export default ProductGrid
