import { FormattedMessage } from 'react-intl'

import './Loading.sass'

interface LoadingProps {
  withText?: boolean
  colored?: boolean
  mini?: boolean
}

const Loading = ({
  withText = false,
  colored = false,
  mini = false
}: LoadingProps) => {
  return (
    <div
      id="loading"
      className={`${colored ? 'colored' : ''} ${mini ? 'mini' : ''}`}
    >
      <div className="loader" />
      {withText && (
        <p>
          <FormattedMessage id="loading" />
        </p>
      )}
    </div>
  )
}

export default Loading
