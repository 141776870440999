import { FormattedMessage } from 'react-intl'
import { useCompactNumbers } from 'src/hooks/useCompactNumbers'

import './InfoBox.sass'

export interface InfoBoxProps {
  type: 'apr' | 'volume' | 'tvl'
  amount?: number
}

const InfoBox = ({ amount, type }: InfoBoxProps) => {
  const finalValue = useCompactNumbers()
  return (
    <div className={`${type}-info-box info-box`}>
      <h4>
        <FormattedMessage id={`info-box.${type}`} />
      </h4>
      <div title={amount?.toFixed(2)} className={`${type}-amount row`}>
        <strong className="amount">
          {type !== 'apr' ? <span className="currency">$</span> : ''}
          <span>{finalValue(+amount, 2)}</span>
          {type === 'apr' ? <span className="percentage">%</span> : ''}
        </strong>
      </div>
    </div>
  )
}

export default InfoBox
