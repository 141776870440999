import { JsonRpcOptionalRequest } from 'web3'
import { GENERIC_ABIS } from '../abis'
import getWeb3 from '../web3'
import {
  NetworkIds,
  NetworkName,
  getNetworkName
} from 'src/utils/networkHelper'
import { AbiItem } from 'web3/lib/commonjs'

export interface BalanceResult {
  balance: string
  balanceWei?: string
  id: string
  name?: string
  address: string
  networkName?: NetworkName
}

export const getTokensLRTBalanceListBatch = async (
  tokensList: any[],
  address: string,
  chainId: NetworkIds
) => {
  const userAddress: string = address
  if (!userAddress) return []
  const networkName = getNetworkName(chainId)
  const web3 = getWeb3(networkName)

  const batchRequest = new web3.BatchRequest()
  const weiBalance = tokensList.map((token, index) => {
    const tokenContract = new web3.eth.Contract(
      GENERIC_ABIS.token as AbiItem[],
      token?.address
    )
    const data = tokenContract.methods.balanceOf(userAddress).encodeABI()

    const jsonRpc: JsonRpcOptionalRequest = {
      jsonrpc: '2.0',
      method: 'eth_call',
      params: [
        {
          to: token?.address,
          data,
          from: userAddress
        },
        'latest'
      ],
      id: index
    }
    return batchRequest.add(jsonRpc)
  })
  let balancesWei = []
  try {
    await batchRequest.execute()
    balancesWei = await Promise.all(weiBalance)
  } catch (error) {
    console.error('Error getting balances in batch', error)
  }

  const balancesEth: string[] = balancesWei?.map((balance: string) =>
    web3.utils.fromWei(balance, 'ether')
  )
  const balancesResult: any[] = tokensList.map((token, i) => {
    const info: BalanceResult = {
      balance: parseFloat(balancesEth[i]).toString(),
      id: token.id,
      name: token.name,
      address: token.address
    }
    return info
  })
  return balancesResult
}

export const getPoolsBalanceListBatch = async (
  pools: any[],
  address: string
) => {
  const userAddress: string = address
  if (!userAddress) return []

  const web3 = getWeb3('arbitrum')
  const batchRequest = new web3.BatchRequest()
  const weiBalance = pools.map((token, index) => {
    const tokenContract = new web3.eth.Contract(
      GENERIC_ABIS.token as AbiItem[],
      token?.address
    )
    const data = tokenContract.methods.balanceOf(userAddress).encodeABI()
    const jsonRpc: JsonRpcOptionalRequest = {
      jsonrpc: '2.0',
      method: 'eth_call',
      params: [
        {
          to: token?.address,
          data
        }
      ],
      id: index
    }
    return batchRequest.add(jsonRpc)
  })

  await batchRequest.execute()
  const balancesWei = await Promise.all(weiBalance)
  const balancesEth: string[] = balancesWei.map((balance: string) =>
    web3.utils.fromWei(balance, 'ether')
  )
  const balancesResult: any[] = pools.map((token, i) => {
    const info: BalanceResult = {
      balance: balancesEth[i],
      id: token.id,
      name: token.name,
      address: token.address
    }
    return info
  })
  return balancesResult
}
