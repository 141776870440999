import DefiCard from 'src/components/ProductCard/DefiCard'
import { BalancerPool, CamelotPool } from 'src/types/pools'

import './DefiGrid.sass'

const DefiGrid = ({
  strategies,
  camelotPools
}: {
  strategies: BalancerPool[]
  camelotPools: CamelotPool[]
}) => {
  return (
    <div id="defi-grid" className="product-grid">
      {strategies?.map((s) => <DefiCard key={s?.id} strategy={s} />)}
      {camelotPools?.map((pool) => (
        <DefiCard key={pool?.address} camelotPools={pool} />
      ))}
    </div>
  )
}

export default DefiGrid
