import { Suspense } from 'react'
import { AllPoints, Points } from 'src/types'
import { TopPointsDashboard } from './TopPointsDashboard'
import PointsIcon from 'src/components/Points/Icons/PointsIcons'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import { PointsDashboardError } from './PointsDashboardError/PointsDashboardError'
import { PointsDashboardLoading } from './PointsDashboardFallback/PointsDashboardFallback'
import { ReducedPointsDashboard } from './ReducedPointsDashboard'

import './CommonPoints/CommonPoints.sass'
import './CommonPoints/ItemPoints.sass'

interface PointsDashboardProps {
  useFetchPoints: () => Points
  type: AllPoints
  top?: boolean
}

function PointsDashboard({ useFetchPoints, type, top }: PointsDashboardProps) {
  const { points } = useFetchPoints()

  return top ? (
    <TopPointsDashboard withInfo amount={+points} type={type}>
      <PointsIcon type={type} />
    </TopPointsDashboard>
  ) : (
    <ReducedPointsDashboard withInfo amount={+points} type={type}>
      <PointsIcon type={type} />
    </ReducedPointsDashboard>
  )
}

export default function PointsDashboardWrapper({
  type,
  useFetchPoints,
  top
}: PointsDashboardProps) {
  return (
    <ErrorBoundary fallback={<PointsDashboardError />}>
      <Suspense fallback={<PointsDashboardLoading type={type} />}>
        <PointsDashboard
          type={type}
          useFetchPoints={useFetchPoints}
          top={top}
        />
      </Suspense>
    </ErrorBoundary>
  )
}
