import {
  ARBITRUM,
  ETHEREUM,
  BINANCE,
  LINEA,
  MODE,
  OPTIMISM,
  SCROLL,
  BASE
} from 'src/constants'
import { NetworkName } from 'src/utils/networkHelper'
import Web3 from 'web3'

const web3Instances = new Map<NetworkName, Web3>()

const rpcSelector = (networkName: NetworkName): string => {
  switch (networkName) {
    case 'arbitrum':
      return ARBITRUM.rpcUrls[0]
    case 'binance':
      return BINANCE.rpcUrls[0]
    case 'ethereum':
      return ETHEREUM.rpcUrls[0]
    case 'linea':
      return LINEA.rpcUrls[0]
    case 'mode':
      return MODE.rpcUrls[0]
    case 'optimism':
      return OPTIMISM.rpcUrls[0]
    case 'scroll':
      return SCROLL.rpcUrls[0]
    case 'base':
      return BASE.rpcUrls[0]
    default:
      return ETHEREUM.rpcUrls[0]
  }
}
const getWeb3 = (networkName: NetworkName): Web3 => {
  if (!web3Instances.has(networkName)) {
    const rpc: string = rpcSelector(networkName)
    const web3 = new Web3(rpc)
    web3Instances.set(networkName, web3)
  }
  return web3Instances.get(networkName)
}

export default getWeb3
