import { useFetch } from '@gluedigital/ruse-fetch-extras'
import { dataMicroserviceUrl } from 'src/constants'
import { EtherFiPoints, KelpPointsRes } from 'src/types/KelpPointsTypes'
import {
  DataBreakdown,
  DataRenzoPoints,
  RenzoPointsHookData
} from 'src/types/RenzoPointsTypes'
import { useWallet } from './useWallet'
import { Points } from 'src/types'

interface PointsWithEigenLayer extends Points {
  eigenLayerPoints: string
}

const useReducedRenzoPoints = (): PointsWithEigenLayer => {
  const userAddress = useWallet()
  const renzoPointsInfo = useFetch<DataRenzoPoints>(
    `${dataMicroserviceUrl}/lrst/ezeth/points/${userAddress}`
  )

  const { totals } = renzoPointsInfo
  const { eigenLayerPoints, renzoPoints } = totals

  return {
    eigenLayerPoints: eigenLayerPoints.toString() || '0',
    points: renzoPoints.toString() || '0'
  }
}

export const useRenzoPoints = (): RenzoPointsHookData => {
  const userAddress = useWallet()
  const renzoPointsInfo = useFetch<DataRenzoPoints>(
    `${dataMicroserviceUrl}/lrst/ezeth/points/${userAddress}`
  )

  const { totals, breakdown } = renzoPointsInfo
  const { eigenLayerPoints, renzoPoints, symbioticPoints, mellowPoints } =
    totals

  const newTotals = {
    eigenLayerPoints: eigenLayerPoints.toString() || '0',
    renzoPoints: renzoPoints.toString() || '0',
    symbioticPoints: symbioticPoints.toString() || '0',
    mellowPoints: mellowPoints.toString() || '0'
  }

  const pointsBreakdown: Array<DataBreakdown> =
    breakdown?.materialView?.pointsBreakdown || []
  const balanceRenzoInfo: DataBreakdown = pointsBreakdown.find(
    (type: DataBreakdown) => type.id === 'balancer'
  )
  const camelotRenzoInfo: DataBreakdown = pointsBreakdown.find(
    (type: DataBreakdown) => type.id === 'camelot'
  )
  const walletRenzoInfo: DataBreakdown = pointsBreakdown.find(
    (type: DataBreakdown) => type.id === 'wallet'
  )

  const balancer = {
    eigenLayerPoints: balanceRenzoInfo?.eigenLayerPoints?.toString() || '0',
    renzoPoints: balanceRenzoInfo?.renzoPoints?.toString() || '0',
    symbioticPoints: balanceRenzoInfo?.symbioticPoints?.toString() || '0',
    mellowPoints: balanceRenzoInfo?.mellowPoints?.toString() || '0'
  }

  const camelot = {
    eigenLayerPoints: camelotRenzoInfo?.eigenLayerPoints?.toString() || '0',
    renzoPoints: camelotRenzoInfo?.renzoPoints?.toString() || '0',
    symbioticPoints: camelotRenzoInfo?.symbioticPoints?.toString() || '0',
    mellowPoints: camelotRenzoInfo?.mellowPoints?.toString() || '0'
  }

  const tokenHold = {
    eigenLayerPoints: walletRenzoInfo.eigenLayerPoints.toString() || '0',
    renzoPoints: walletRenzoInfo.renzoPoints.toString() || '0',
    symbioticPoints: walletRenzoInfo?.symbioticPoints?.toString() || '0',
    mellowPoints: walletRenzoInfo?.mellowPoints?.toString() || '0'
  }

  return { totals: newTotals, balancer, camelot, tokenHold }
}

export const useKelpPoints = (): PointsWithEigenLayer => {
  const userAddress = useWallet()
  const res = useFetch<KelpPointsRes>(
    `https://common.kelpdao.xyz/km-el-points/user/${userAddress}`
  )
  const eigenLayerPoints: string = parseFloat(res?.value?.elPoints)?.toString()
  const points: string = parseFloat(res?.value?.kelpMiles)?.toString()
  return { eigenLayerPoints, points }
}

const useReducedEtherFiPoints = (): PointsWithEigenLayer => {
  const wallet = useWallet()
  const result = useFetch<EtherFiPoints>(
    `${dataMicroserviceUrl}/lrst/etherfi/points/${wallet}`
  )
  const { totalEigenLayerPoints, totalEtherFiPoints: points } = result.total
  return { eigenLayerPoints: totalEigenLayerPoints, points }
}

export const useEtherFiPoints = (): EtherFiPoints => {
  const wallet = useWallet()
  const result = useFetch<EtherFiPoints>(
    `${dataMicroserviceUrl}/lrst/etherfi/points/${wallet}`
  )
  return result
}

export const usePufferPoints = (): PointsWithEigenLayer => {
  const wallet = useWallet()
  const result = useFetch<{ pufferPoints: string; eigenLayerPoints: string }>(
    dataMicroserviceUrl + '/lrst/puffer/points/' + wallet
  )
  const { pufferPoints: points, eigenLayerPoints } = result
  return { points, eigenLayerPoints }
}

export const useSwellPoints = (): Points => {
  const wallet = useWallet()
  const url = `https://v3-lst.svc.swellnetwork.io/swell.v3.VoyageService/VoyageUser?connect=v1&encoding=json&message=%7B%22address%22%3A%22${wallet}%22%7D`
  const res = useFetch<{ points: string }>(url)
  const points = isNaN(+res?.points) ? 0 : +res?.points
  return { points: points.toString() }
}

export const useBedrockPoints = (): PointsWithEigenLayer => {
  const wallet = useWallet()
  const result = useFetch<{ uniethPoints: number; eigenLayerPoints: number }>(
    `${dataMicroserviceUrl}/lrst/bedrock/points/${wallet}`
  )
  const { uniethPoints: points, eigenLayerPoints } = result

  if (!points || !eigenLayerPoints) {
    return { points: '0', eigenLayerPoints: '0' }
  }

  return {
    points: points.toString(),
    eigenLayerPoints: eigenLayerPoints.toString()
  }
}

export const useClaystackpoints = (): PointsWithEigenLayer => {
  const wallet = useWallet()
  const result = useFetch<{ clayPoints: string; eigenLayerPoints: string }>(
    `${dataMicroserviceUrl}/lrst/clay/points/${wallet}`
  )
  const { clayPoints: points, eigenLayerPoints } = result
  return { points, eigenLayerPoints }
}

export const usePrimestakedPoints = (): PointsWithEigenLayer => {
  const wallet = useWallet()
  const query = `query UserPoints($address: String) {
    lrtPointRecipients(
      limit: 1
      orderBy: pointsDate_DESC
      where: {id_containsInsensitive: $address}
    ) {
      id
      balance
      points
      pointsDate
      referralPoints
      elPoints
    }
    }`

  const url = 'https://squid.subsquid.io/prime-eth-squid/graphql'
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: { query, variables: { address: wallet } }
  }
  const res = useFetch<{
    data: { lrtPointRecipients: { points: string; elPoints: string }[] }
  }>(url, options)

  if (res?.data?.lrtPointRecipients?.length === 0) {
    return { points: '0', eigenLayerPoints: '0' }
  }

  const points = parseFloat(res?.data?.lrtPointRecipients[0]?.points) / 10 ** 18

  const eigenLayerPoints =
    parseFloat(res?.data?.lrtPointRecipients[0]?.elPoints) / 10 ** 18

  return {
    points: points.toString(),
    eigenLayerPoints: eigenLayerPoints.toString()
  }
}

export const useInceptionPoints = (): PointsWithEigenLayer => {
  let eigenLayerPoints: string = '0'
  const wallet = useWallet()
  const url = `https://api.missionshub.xyz/v1/users/user-pts/5472336f-fc0e-477f-9e60-670195792264/${wallet}`
  const resultTotems = useFetch<{ statusCode: string; data: number }>(url)
  const points: string = resultTotems?.data?.toFixed(2)
  try {
    const urlEigenLayer = `https://api.genesislrt.com/v1/balance?stakerAddress=${wallet}&symbol=ineth`
    const resultEigenLayer = useFetch<{ ElPoints: number }>(urlEigenLayer)
    eigenLayerPoints = resultEigenLayer?.ElPoints?.toFixed(2)
  } catch (error) {
    console.warn(
      'Not available EigenLayer points corresponding to inETH  for ',
      wallet
    )
  }
  return { points, eigenLayerPoints }
}

export const useSwBtcPoints = (): Points => {
  const wallet = useWallet()
  const userAddress: string = wallet
  const url = `https://v3-lst.svc.swellnetwork.io/swell.v3.WalletService/BtcLrtUser?connect=v1&encoding=json&message=%7B%22walletAddress%22%3A%22${userAddress}%22%7D`
  const res: { blackPearls: number; multiplier: number } = useFetch(url)
  const blackPearlsPoints: number = res.blackPearls ?? 0
  return { points: blackPearlsPoints.toString() }
}

export const useTotalEigenLayerPointsAndOtherPoints = (): {
  totalEigenLayerPoints: number
  totalOtherPoints: number
} => {
  let totalPoints: number = 0
  let totalEigenLayerPoints: number = 0

  const hooks: (() => PointsWithEigenLayer)[] = [
    useKelpPoints,
    useReducedRenzoPoints,
    useBedrockPoints,
    usePufferPoints,
    useReducedEtherFiPoints,
    usePrimestakedPoints,
    // useClaystackpoints,
    useInceptionPoints
    // useSwBtcPoints TODO Check what to do
  ]

  for (const useHook of hooks) {
    //* In this case we must include hooks inside try-catch blocks to assure returing 0 even when some of the hooks fails.
    //* When the "errror" is a Promise, it means that the hook is still fetching the data, so we throw the Promise to the parent Suspense component.
    try {
      const { eigenLayerPoints, points } = useHook()
      const isValidOtherPoints: boolean = !isNaN(parseFloat(points))
      const isValidEigenLayer: boolean = !isNaN(parseFloat(eigenLayerPoints))

      totalPoints += isValidOtherPoints ? parseFloat(points) : 0

      totalEigenLayerPoints += isValidEigenLayer
        ? parseFloat(eigenLayerPoints)
        : 0
    } catch (e) {
      if (e instanceof Promise) throw e
      console.error(e)
    }
  }
  const { mellowPoints, symbioticPoints } = useRenzoPoints().totals
  totalPoints += parseFloat(mellowPoints) + parseFloat(symbioticPoints)

  return { totalEigenLayerPoints, totalOtherPoints: totalPoints }
}

export const useTotalEigenLayerPoints = (): Points => {
  const { totalEigenLayerPoints } = useTotalEigenLayerPointsAndOtherPoints()
  return { points: totalEigenLayerPoints.toString() }
}

export const useOtherEigenLayerPoints = (): Points => {
  const { totalOtherPoints } = useTotalEigenLayerPointsAndOtherPoints()
  return { points: totalOtherPoints.toString() }
}

export const useTotalEtherFiPoints = (): Points => {
  const { total } = useEtherFiPoints()
  return { points: total.totalEtherFiPoints }
}

export const useMellowPoints = (): Points => {
  const { totals } = useRenzoPoints()
  return { points: totals.mellowPoints }
}

export const useTotalRenzoPoints = (): Points => {
  const { totals } = useRenzoPoints()
  return { points: totals.renzoPoints }
}

export const useSymbioticPoints = (): Points => {
  const { totals } = useRenzoPoints()
  return { points: totals.symbioticPoints }
}
