import { NetworkIds, getNetworkName } from 'src/utils/networkHelper'
import { GENERIC_ABIS } from './abis'
import getWeb3 from './web3'

const getAllowance = async (
  token: string,
  spender: string,
  owner: string,
  chainId: NetworkIds
): Promise<bigint> => {
  const networkName = getNetworkName(chainId)
  const web3 = getWeb3(networkName)
  const tokenContract = new web3.eth.Contract(
    GENERIC_ABIS.token as any[],
    token
  )
  const allowanceAmount: number = await tokenContract.methods
    .allowance(owner, spender)
    .call()
  return BigInt(allowanceAmount.toString())
}

export default getAllowance
