import { FormattedMessage } from 'react-intl'
import { AirdropTrackerInfo, ProductIcons } from 'src/types'
import { productsPointsWithoutAutoLayer } from 'src/components/Points'
import RewardPoints from 'src/components/Points/Airdrop/RewardPoints'

import './RewardCard.sass'

export const DefaultRewardCard = () => {
  return (
    <article className="reward-card default-reward-card">
      <h1 className="skeleton" />
      <div className="row">
        <span className="badge skeleton" />
      </div>
      <h2>
        <span>
          <FormattedMessage
            id="reward-card.points-gathered"
            values={{ n: '' }}
          />
        </span>
        <span className="skeleton" />
      </h2>
      <div className="row">
        <span>
          <FormattedMessage id="reward-card.stage" values={{ s: '' }} />
        </span>
        <span className="skeleton" />
      </div>
      <a target="_blank" rel="noopener noreferrer" className="button disabled">
        <FormattedMessage id="reward-card.claim" />
      </a>
    </article>
  )
}

const RewardCard = ({ info }: { info: AirdropTrackerInfo }) => {
  const { id, lrt, url, tag, project, announced } = info
  const lowerId = id.toLocaleLowerCase()

  const associations: Record<string, Exclude<ProductIcons, 'autoLayer'>> = {
    eigenlayer: 'eigenLayer',
    ezeth: 'renzo',
    rseth: 'kelp',
    pufeth: 'puffer',
    eeth: 'ether',
    rsweth: 'swell',
    unieth: 'bedrock',
    primeeth: 'primestaked',
    inETH: 'inception',
    symbiotic: 'symbiotic',
    mellow: 'mellow'
  }
  const Element = (
    <RewardPoints
      useFetchPoints={productsPointsWithoutAutoLayer[associations[id]]}
    />
  )
  return (
    <article
      style={{ backgroundImage: `url("/images/airdrop/${lowerId}.jpg")` }}
      id={`reward-card-${lowerId}`}
      className="reward-card"
    >
      <h1>{project}</h1>
      <div className="row">
        <span id={`badge-${id}`} className="badge">
          {lrt || tag}
        </span>
      </div>
      <h2>
        <span>
          <FormattedMessage
            id="reward-card.points-gathered"
            values={{ n: Element }}
          />
        </span>
      </h2>
      <div className="row">
        <span className="stage">
          <FormattedMessage id="reward-card.stage" values={{ s: announced }} />
        </span>
      </div>
      <a
        href={info.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`button ${!url ? 'disabled' : ''}`}
      >
        <FormattedMessage id="reward-card.claim" />
      </a>
    </article>
  )
}

export default RewardCard
