import { OptimalRate, TransactionParams } from '@paraswap/sdk'
import TokenSelector from '../TokenSelector/TokenSelector'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import tokenNameToAddress from 'src/utils/tokenNameToAddress'
import DepositStepMessage from './DepositStepMessage/DepositStepMessage'
import NotConnectedDeposit from './NotConnectedDeposit'
import { DepositProps } from './Deposit'
import { ExecutionSteps } from 'src/types/DepositTypes'
import useDeposit from 'src/hooks/useDeposit'
import { useSingleCamelotPool } from 'src/hooks/usePools'
import getDepositAmount from 'src/contracts/Camelot/getDepositAmount'
import addLiquidityCamelot from 'src/contracts/Camelot/addLiquidityCamelot'
import approveToken from 'src/contracts/approveToken'
import getParaswapRoute from 'src/contracts/Paraswap/getParaswapRoute'
import Slippage from '../Slippage/Slippage'

import './Deposit.sass'

const DepositCamelotPools = ({
  darkMode = false,
  refreshAutoLayerPoints
}: DepositProps) => {
  const { productAddress } = useParams<{ productAddress: string }>()
  const {
    searchMode,
    executionStep,
    failExecutionStep,
    txHash,
    txPoints,
    isConnected,
    wallet,
    realWeiAmount,
    depositData,
    hasEnoughBalance,
    chainId,
    setDepositData,
    finishTransaction,
    handleSendError,
    handleApproveError,
    retrieveTokenInAdress,
    retrieveTxParams,
    changeExecutionStep,
    handleRetrieveTxParamsError,
    setSearchMode,
    setRealWeiAmount,
    handleTokenChange,
    slippage,
    setSlippage
  } = useDeposit({ refreshAutoLayerPoints })
  const blocked = executionStep !== undefined && failExecutionStep === undefined

  const thisPool = useSingleCamelotPool(productAddress)

  const handleDeposit = async () => {
    setSearchMode(false) // to close the list
    const tokenInAddress = retrieveTokenInAdress()
    changeExecutionStep(ExecutionSteps.notStarted)

    const token0Address: string =
      thisPool?.filteredTokens[0].address.toLowerCase()
    const token1Address: string =
      thisPool?.filteredTokens[1].address.toLowerCase()

    let txParamsToken0: TransactionParams
    let txParamsToken1: TransactionParams
    let ethRealAmountInWei: bigint = BigInt(0)
    let inputTokenAmountForSecondPoolToken: bigint = BigInt(0)
    let halfRealWeiAmount: bigint = BigInt(0)
    try {
      // const halfRealWeiAmount: bigint = realWeiAmount / BigInt(2)
      const token0Weight: number = Math.floor(
        thisPool.filteredTokens[0].weight * 100
      )
      halfRealWeiAmount = (realWeiAmount * BigInt(token0Weight)) / BigInt(100)
      const rateWithInput: OptimalRate = await getParaswapRoute(
        tokenInAddress,
        token0Address,
        halfRealWeiAmount.toString(),
        chainId
      )
      const expectedAmountToken0: bigint = BigInt(rateWithInput.destAmount)
      const { amountStart: expectedAmountSecondPoolToken } =
        await getDepositAmount(
          productAddress,
          token0Address,
          expectedAmountToken0
        )
      const rateWithOutput: OptimalRate = await getParaswapRoute(
        token1Address,
        tokenInAddress,
        expectedAmountSecondPoolToken.toString(),
        chainId
      )

      inputTokenAmountForSecondPoolToken = BigInt(rateWithOutput.destAmount)

      if (tokenInAddress === tokenNameToAddress('ETH', chainId))
        ethRealAmountInWei =
          halfRealWeiAmount + BigInt(rateWithOutput.destAmount)

      const paraswapRes0 = await retrieveTxParams(
        tokenInAddress,
        token0Address,
        halfRealWeiAmount.toString(),
        slippage
      )
      const paraswapRes1 = await retrieveTxParams(
        tokenInAddress,
        token1Address,
        inputTokenAmountForSecondPoolToken.toString(),
        slippage
      )
      txParamsToken0 = paraswapRes0.txParams
      txParamsToken1 = paraswapRes1.txParams
      changeExecutionStep(
        tokenInAddress !== tokenNameToAddress('ETH', chainId)
          ? ExecutionSteps.gotRoute
          : ExecutionSteps.approvedToken
      )
    } catch (error) {
      handleRetrieveTxParamsError(error)
      return
    }

    try {
      if (tokenInAddress !== tokenNameToAddress('ETH', chainId)) {
        const totalRealAmount: bigint =
          halfRealWeiAmount + inputTokenAmountForSecondPoolToken
        await approveToken(
          tokenInAddress,
          totalRealAmount,
          wallet?.address,
          chainId
        )
        changeExecutionStep(ExecutionSteps.approvedToken)
      }
    } catch (error) {
      handleApproveError(error)
      return
    }

    let sendSwapTx

    try {
      sendSwapTx = await addLiquidityCamelot(
        txParamsToken0,
        txParamsToken1,
        productAddress,
        thisPool.nftAddress,
        ethRealAmountInWei,
        wallet?.address,
        chainId
      )
    } catch (error) {
      handleSendError(error)
      return
    }

    try {
      finishTransaction(sendSwapTx)
      changeExecutionStep(ExecutionSteps.sentToken)
    } catch (error) {
      console.error('Error getting transaction Points', error.message)
    }
  }

  return (
    <div id="deposit" className={darkMode ? 'dark-theme' : 'light-theme'}>
      {!isConnected ? (
        <NotConnectedDeposit
          darkMode={darkMode}
          searchMode={searchMode}
          depositData={depositData}
          setDepositData={setDepositData}
          setSearchMode={setSearchMode}
        />
      ) : (
        <>
          <div className="wrapper-selector">
            <TokenSelector
              darkMode={darkMode}
              searchMode={searchMode}
              previousData={depositData}
              blocked={blocked}
              onChange={handleTokenChange}
              setSearchMode={setSearchMode}
              setRealWeiAmount={setRealWeiAmount}
            />
            <Slippage
              blocked={blocked}
              slippage={slippage}
              setSlippage={setSlippage}
            />
          </div>
          {executionStep === undefined && (
            <div>
              <button
                className={
                  !darkMode
                    ? 'continue-deposit light-theme'
                    : 'continue-deposit dark-theme'
                }
                disabled={depositData?.amount <= 0 || !hasEnoughBalance}
                onClick={handleDeposit}
              >
                <FormattedMessage id="continue" />
              </button>
            </div>
          )}
          {executionStep !== undefined && (
            <DepositStepMessage
              executionStep={executionStep}
              failExecutionStep={failExecutionStep}
              txHash={txHash}
              txPoints={txPoints}
            />
          )}
        </>
      )}
    </div>
  )
}

export default DepositCamelotPools
