import { FormattedMessage } from 'react-intl'
import { getLpUSDPriceForNftPosition } from 'src/contracts/Camelot/getCamelotLpValue'
import { useGetLpAmountForNft, useGetNftIdsCamelot } from 'src/hooks/useCamelot'
import useCoingecko, { CoingeckoDataSummary } from 'src/hooks/useCoingecko'
import usePromise from 'src/hooks/usePromise'
import camelotUtils from 'src/utils/camelot'

interface CamelotLeftUsdValueProps {
  pos: string
  refreshKey?: number
}

export default function CamelotLeftUsdValue({
  pos,
  refreshKey
}: CamelotLeftUsdValueProps) {
  const nftAddress: string = camelotUtils.getNftPoolAddress(pos)
  const name: string = camelotUtils.getLpName(pos)
  const ids: number[] = useGetNftIdsCamelot(refreshKey, nftAddress)
  const lps: number[] = useGetLpAmountForNft(refreshKey, nftAddress, ids)
  const totalLpAmount: number = lps.reduce((acc, lp) => acc + lp, 0)
  const coingeckoData: CoingeckoDataSummary[] = useCoingecko()
  const unitaryPrice = usePromise(
    getLpUSDPriceForNftPosition,
    pos,
    coingeckoData
  )
  const finalUsdValue: number = totalLpAmount * unitaryPrice

  return (
    <div className="actual-value-points-box points-box">
      <h4>
        <FormattedMessage id="left-point.actual-value" />
      </h4>
      <div className="active-amount row">
        <strong className="amount">{totalLpAmount?.toFixed(5)}</strong>
        <span className="badge-name">{name}</span>
      </div>
      <span className="usd-value">({finalUsdValue.toFixed(2)} USD)</span>
    </div>
  )
}
