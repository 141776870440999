import { WalletData } from 'src/types'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { ReferralsEnum } from 'src/routes/Referrals/ReferralsButtons/ReferralsButtons'

import './GoToReferrals.sass'

const GoToReferrals = () => {
  const wallet = useSelector((s: any) => s.wallet as WalletData)
  const navigate = useNavigate()
  const clickButton = async () => {
    import(/* webpackChunkName: "wallet" */ 'src/utils/wallet')
      .then((m) => m.connect())
      .then(() => navigate(`/referrals/${ReferralsEnum.AFFILIATES}`))
  }
  const content = (
    <span>
      <FormattedMessage id="referrals" />
    </span>
  )

  if (!wallet) {
    return (
      <button onClick={clickButton} id="go-to-referrals" className="button">
        {content}
      </button>
    )
  }

  return (
    <Link
      to={`/referrals/${ReferralsEnum.AFFILIATES}`}
      id="go-to-referrals"
      className="button"
    >
      {content}
    </Link>
  )
}

export default GoToReferrals
