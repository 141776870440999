import { useMemo, useState } from 'react'
import { useParams, useLocation, Navigate } from 'react-router-dom'
import ProductInfoButtons from './ProductInfoButtons/ProductInfoButtons'
import DepositDialog from 'src/components/DepositDialog/DepositDialog'
import ProductMeta from 'src/components/Meta/ProductMeta'
import { TokensInfo } from 'src/hooks/useTokensLst'
import textUtils from 'src/utils/text'
import Footer from 'src/components/Footer/Footer'
import SwapDialog from 'src/components/SwapDialog/SwapDialog'
import HeaderProductInfo from './Header/HeaderProductInfo'
import LeftAssetInfo from './LeftInfo/LeftAssetInfo'
import MiddleAssetInfo from './MiddleInfo/MiddleAssetInfo'
import {
  NetworkIds,
  NetworkName,
  getNetworkIdByName
} from 'src/utils/networkHelper'
import { NetworkData, networkData } from 'src/data'
import DiscontinuedNotice from './DiscontinuedNotice/DiscontinuedNotice'

declare const __CLIENT__: boolean

//* isDeposit permits to know if the user is depositing more or swapping the lst/lrst token to normal token

const AssetInfo = () => {
  const [isSwapDialogOpen, setIsSwapDialogOpen] = useState<boolean>(false)
  const [isDepositDialogOpen, setIsDepositDialogOpen] = useState<boolean>(false)
  const [refreshKey, setRefreshKey] = useState<number>(0)
  const { productName, networkName } = useParams<{
    productName: string
    networkName: NetworkName
  }>()
  const networkId: NetworkIds = getNetworkIdByName(networkName)
  const networkInfo: NetworkData = networkData(networkId)
  const tokenInfo: TokensInfo = useMemo(
    () =>
      networkInfo.tokensLRS.find(
        (t) => t.id.toLowerCase() === productName?.toLowerCase()
      ) as any,
    [networkInfo.tokensLRS, productName]
  )
  const handleRefreshKey = () => setRefreshKey((s: number) => s + 1)

  const location = useLocation()

  return (
    <>
      <ProductMeta
        url={
          __CLIENT__
            ? window.location.href
            : `https://app.autolayer.io/${location.pathname}`
        }
        description={textUtils.firstPhrase(tokenInfo.opengraph)}
        image={tokenInfo.image}
        title={`${tokenInfo.name} | Autolayer`}
      />
      <div id="asset-info" className="product-info page">
        {isDepositDialogOpen && (
          <DepositDialog
            setIsDepositDialogOpen={setIsDepositDialogOpen}
            refreshAutoLayerPoints={handleRefreshKey}
          />
        )}
        {isSwapDialogOpen && (
          <SwapDialog
            setIsSwapDialogOpen={setIsSwapDialogOpen}
            refreshAutoLayerPoints={handleRefreshKey}
          />
        )}
        <HeaderProductInfo productInfo={tokenInfo} />
        <div className="container">
          <div className="grid-container">
            <LeftAssetInfo tokenInfo={tokenInfo} refreshKey={refreshKey} />
            <MiddleAssetInfo tokenInfo={tokenInfo} />
            <div>
              {productName === 'cseth' && <DiscontinuedNotice />}
              <ProductInfoButtons
                darkMode={false}
                setIsSwapDialogOpen={setIsSwapDialogOpen}
                setIsDepositDialogOpen={setIsDepositDialogOpen}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

const AssetInfoWrapper = () => {
  const { productName, networkName } = useParams<{
    productName: string
    networkName: NetworkName
  }>()
  const networkId: NetworkIds = getNetworkIdByName(networkName)
  const networkInfo: NetworkData = networkData(networkId)
  const isTokenLst: boolean = networkInfo.tokensLRS.some(
    (t) => t.id.toLowerCase() === productName?.toLowerCase()
  )
  if (!isTokenLst) return <Navigate to="/" />
  return <AssetInfo />
}

export default AssetInfoWrapper
