import { FormattedNumber } from 'react-intl'
import stakingUtils from '@utils/stake'
import { useStakingTotalStaked } from '@hooks/useStaking'

import './ProgressBar.sass'

interface ProgressBarProps {
  value: number
  maxValue?: number
  label: string
}

export function ProgressWrapper({
  maxCapacity,
  poolAddress
}: {
  maxCapacity: number
  poolAddress: string
}) {
  const progrees = useStakingTotalStaked(poolAddress)

  return (
    <ProgressBar
      value={(progrees / maxCapacity) * 100}
      label={stakingUtils.getAbbreviatedNumber(maxCapacity)}
    />
  )
}

const ProgressBar = ({ label, value, maxValue = 100 }: ProgressBarProps) => {
  return (
    <div className="progress-container">
      <span className="progress-label">{label}</span>
      <div
        className="progress-bar"
        role="progressbar"
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={value}
      >
        <div
          className="progress-fill"
          style={{
            clipPath: `polygon(0% 0%, ${value}% 0%, ${value}% 100%, 0% 100%)`
          }}
        ></div>
      </div>
      <div className="progress-text">
        <FormattedNumber value={value / 100} style="percent" /> /
        <FormattedNumber value={maxValue / 100} style="percent" />
      </div>
    </div>
  )
}

export default ProgressBar
