import useTokensWeightForPools from 'src/hooks/useTokensWeightForPools'
import { BalancerPool, CamelotPool, Pools } from 'src/types/pools'
import { PortfolioStrategies } from 'src/types/portfolioTypes'
import { getTokenImage } from 'src/utils/getTokenImage'

const TokensWeight = ({
  scanLink,
  info
}: {
  scanLink: string
  info: CamelotPool | PortfolioStrategies[]
}) => {
  const isCamelot: boolean = (info as CamelotPool).provider === 'Camelot'
  const tokens = isCamelot
    ? (info as CamelotPool).filteredTokens
    : (info as PortfolioStrategies[])[0].filteredTokens
  return (
    <div className="tokens">
      {tokens.map((t) => (
        <div className="token-card" key={t.address}>
          <img
            src={getTokenImage(t.symbol.toLowerCase())}
            alt={t.symbol}
            className="token-image"
          />
          <strong className="token-name">{t.symbol}</strong>
          <span className="token-weight">{(t.weight * 100).toFixed(2)}%</span>
        </div>
      ))}
      <a rel="noopener noreferrer" target="_blank" href={scanLink}>
        <span className="icon icon-external_window" />
      </a>
    </div>
  )
}

const BalancerTokensWeight = ({
  productInfo
}: {
  productInfo: BalancerPool
}) => {
  const scanLink: string = 'https://arbiscan.io/address/' + productInfo?.address
  const info = useTokensWeightForPools(productInfo)
  return <TokensWeight info={info} scanLink={scanLink} />
}

const TokensWeightWrapper = ({ productInfo }: { productInfo: Pools }) => {
  return productInfo.provider === 'Camelot' ? (
    <TokensWeight
      info={productInfo}
      scanLink={'https://arbiscan.io/address/' + productInfo.pos}
    />
  ) : (
    <BalancerTokensWeight productInfo={productInfo} />
  )
}

export default TokensWeightWrapper
