import { useState } from 'react'
import { useSelector } from 'react-redux'
import { WalletData } from 'src/types'
import useIsConnected from './useIsConnected'
import {
  NetworkIds,
  NetworkName,
  getNetworkIdByName
} from 'src/utils/networkHelper'
import { useParams } from 'react-router'

export function useCommonDeposit<T, K, E>(initialState: T) {
  const [data, setData] = useState<T>(initialState)
  const [realWeiAmount, setRealWeiAmount] = useState<bigint>(BigInt(0))
  const isConnected: boolean = useIsConnected()

  const [slippage, setSlippage] = useState<number>(100)
  const [searchMode, setSearchMode] = useState<boolean>(false)
  const [executionStep, setExecutionStep] = useState<K>(undefined)
  const [failExecutionStep, setFailExecutionStep] = useState<E>(undefined)
  const [txHash, setTxHash] = useState<string>(undefined)
  const [txPoints, setTxPoints] = useState<string>(undefined)
  const wallet = useSelector((s: any) => s.wallet as WalletData)
  const { networkName } = useParams<{ networkName: NetworkName }>()
  const chainId: NetworkIds = getNetworkIdByName(networkName as NetworkName)

  return {
    data,
    setData,
    realWeiAmount,
    setRealWeiAmount,
    searchMode,
    setSearchMode,
    executionStep,
    setExecutionStep,
    failExecutionStep,
    setFailExecutionStep,
    txHash,
    setTxHash,
    txPoints,
    setTxPoints,
    wallet,
    isConnected,
    chainId,
    slippage,
    setSlippage
  }
}
