import { ArbitrumData } from 'src/data/Arbitrum'

function isCamelot(productAddress: string) {
  const isCamelotPool = ArbitrumData.camelotPools.some(
    (pool) => pool.pos.toLowerCase() === productAddress?.toLowerCase()
  )

  return isCamelotPool
}

function getNftPoolAddress(productAddress: string) {
  const nftPool = ArbitrumData.camelotPools.find(
    (pool) => pool.pos.toLowerCase() === productAddress?.toLowerCase()
  )
  return nftPool?.nftAddress
}

function getLpName(productAddress: string) {
  const nftPool = ArbitrumData.camelotPools.find(
    (pool) => pool.pos.toLowerCase() === productAddress?.toLowerCase()
  )
  return nftPool?.bptName
}
const camelotUtils = {
  isCamelot,
  getNftPoolAddress,
  getLpName
}

export default camelotUtils
