import { NetworkData, networkData } from 'src/data'
import { NetworkIds } from './networkHelper'

const tokenNameToAddress = (tokenSymbol: string, chainId: NetworkIds) => {
  const networkInfo: NetworkData = networkData(chainId)
  const token = networkInfo.tokens.find(
    (t: any) => t.id?.toLowerCase() === tokenSymbol?.toLowerCase()
  )
  if (token) {
    return token?.address?.toLowerCase()
  } else return ''
}

export default tokenNameToAddress
