import { useParams } from 'react-router'
import { useEtherFiPoints, useRenzoPoints } from 'src/hooks/useEigenLayerPoints'
import { Points } from 'src/types'

export function useLeftEigenLayer(): Points {
  const { tokenHold } = useRenzoPoints()
  const eigenLayerPoints = tokenHold.eigenLayerPoints
  return { points: eigenLayerPoints }
}

export function useLeftEigenLayerEtherFiPoints(): Points {
  const result = useEtherFiPoints()
  const { networkName } = useParams<{ networkName: string }>()
  let eigenLayerPoints = result?.eethPoints.eigenlayerPoints
  if (networkName === 'arbitrum') {
    eigenLayerPoints = result?.weethArbPoints.eigenlayerPoints
  } else if (networkName === 'binance') {
    eigenLayerPoints = result?.bscPoints.eigenlayerPoints
  }
  return { points: eigenLayerPoints }
}
