import { useCallback, useState } from 'react'
import ChartTimeSelector from '../../ChartTimeSelector/ChartTimeSelector'
import { ChartTimeSpan } from 'src/types'
import AprChart from '../AprChartWrapper'

import './CompleteAprChart.sass'
import { ChartInfo } from 'src/types/ChartTypes'

interface CompleteChartProps {
  productName: string
  fallbackData?: ChartInfo[]
}

declare let __CLIENT__: boolean

export default function CompleteChart({
  productName,
  fallbackData
}: CompleteChartProps) {
  const [time, setTime] = useState<ChartTimeSpan | null>('1m')

  const handleTime = useCallback(
    (time: ChartTimeSpan | null) => setTime(time),
    []
  )

  return (
    <section className="chart-apr-item">
      {__CLIENT__ ? (
        <AprChart
          chartTime={time}
          productName={productName}
          handleTime={handleTime}
          fallbackData={fallbackData}
        />
      ) : null}
      <ChartTimeSelector time={time} handleTime={handleTime} />
    </section>
  )
}
