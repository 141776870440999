import { NetworkName } from '@utils/networkHelper'
import { stakingAbi } from '../abis/AutoLayerAbis/StakingAbi'
import getWeb3 from '../web3'
import { StakingPosition } from 'src/types/staking'

// returns the full position of a user in a staking contract
const getUserStakingPosition = async (
  userAddress: string,
  positionId: number,
  contractAddress: string,
  networkName: NetworkName = 'binance'
): Promise<StakingPosition> => {
  if (!userAddress) return null
  const web3 = getWeb3(networkName)
  const contract = new web3.eth.Contract(stakingAbi, contractAddress)
  const userStakingPosition: any = await contract.methods
    .getUserStakingPosition(userAddress, positionId)
    .call()

  const infoFormatted = {
    initialAmount: parseFloat(
      web3.utils.fromWei(userStakingPosition.initialAmount.toString(), 'ether')
    ),
    currentAmount: parseFloat(
      web3.utils.fromWei(userStakingPosition.amount.toString(), 'ether')
    ),
    startTime: +userStakingPosition.startTime.toString(),
    rewardAmountClaimed: parseFloat(
      web3.utils.fromWei(
        userStakingPosition.rewardAmountClaimed.toString(),
        'ether'
      )
    ),
    lastTimeClaimed: +userStakingPosition.lastTimeClaimed.toString(),
    active: userStakingPosition.active
  }
  return infoFormatted
}

export default getUserStakingPosition

export const getUserPositionPendingRewards = async (
  address: string,
  positionId: number,
  contractAddress: string,
  networkName: NetworkName = 'binance'
): Promise<number> => {
  if (!address) return 0
  const web3 = getWeb3(networkName)
  const contract = new web3.eth.Contract(stakingAbi, contractAddress)
  const pendingRewards: bigint = await contract.methods
    .getUserPositionPendingRewardAmount(address, positionId)
    .call()
  const rewardsToClaim = web3.utils.fromWei(pendingRewards.toString(), 'ether')

  return parseFloat(rewardsToClaim)
}
