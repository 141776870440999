import { WhiteIcons } from 'src/static/logos/whiteIcons'

export type Multiplier =
  | 'autoLayer'
  | 'eigenLayer'
  | 'symbiotic'
  | 'mellow'
  | 'renzo'
  | 'kelp'
  | 'bedrock'
  | 'swell'
  | 'etherfi'
  | 'puffer'
  | 'inception'
  | 'prime'
  | 'swellBlackPearl'

export interface MultiplierValue {
  value: string
  name: string
  icon: string
}

export const multipliersList: Record<Multiplier, MultiplierValue> = {
  autoLayer: {
    name: 'AutoLayer',
    value: 'autoLayerMultiplier',
    icon: WhiteIcons.autoLayer
  },
  bedrock: {
    name: 'Bedrock',
    value: 'bedrockMultiplier',
    icon: WhiteIcons.bedrock
  },
  eigenLayer: {
    name: 'EigenLayer',
    value: 'eigenLayerMultiplier',
    icon: WhiteIcons.eigenLayer
  },
  etherfi: {
    name: 'Etherfi',
    value: 'etherfiMultiplier',
    icon: WhiteIcons.ether
  },
  inception: {
    name: 'Inception',
    value: 'inceptionMultiplier',
    icon: WhiteIcons.inception
  },
  kelp: { name: 'Kelp', value: 'kelpMultiplier', icon: WhiteIcons.kelp },
  mellow: {
    name: 'Mellow',
    value: 'mellowMultiplier',
    icon: WhiteIcons.mellow
  },
  prime: {
    name: 'Prime',
    value: 'primeMultiplier',
    icon: WhiteIcons.primestaked
  },
  puffer: {
    name: 'Puffer',
    value: 'pufferMultiplier',
    icon: WhiteIcons.puffer
  },
  renzo: { name: 'Renzo', value: 'renzoMultiplier', icon: WhiteIcons.renzo },
  swell: { name: 'Swell', value: 'swellMultiplier', icon: WhiteIcons.swell },
  symbiotic: {
    name: 'Symbiotic',
    value: 'symbioticMultiplier',
    icon: WhiteIcons.symbiotic
  },
  swellBlackPearl: {
    name: 'Black Perls',
    value: 'swellBlackPearlMultiplier',
    icon: WhiteIcons.swbtc
  }
}
