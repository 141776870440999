import { Suspense } from 'react'
import { useParams } from 'react-router'
import ActualValueLeftPoint from './LeftPointsTypes/ActualValueLeftPoint'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import EigenLayerLeftPoint from './LeftPointsTypes/EigenLayer/EigenLayerLeftPoint'
import { NetworkName } from 'src/utils/networkHelper'
import DefaultPointsBox from 'src/components/Points/PointsBox/DefaultPointsBox'
import FallbackNoConnected from '../../../components/FallbackNoConnected/FallbackNoConnected'
import LeftProductInfoClient from './LeftProductInfoClient'
import BinaceNetworkNotice from './BinaceNetworkNotice/BinaceNetworkNotice'
import { TokensInfo } from 'src/hooks/useTokensLst'

import { useAutolayerPoints } from 'src/hooks/useAutolayerPoints'
import { useWallet } from 'src/hooks/useWallet'

import './LeftProductInfo.sass'
import LRTPoints from 'src/components/Points/AssetInfo/LRTPoints/LrtPoints'
import AssetInfoPoints from 'src/components/Points/AssetInfo/AssetInfoPoints'

declare const __CLIENT__: boolean

const LeftAssetInfo = ({
  refreshKey,
  tokenInfo
}: {
  refreshKey: number
  tokenInfo: TokensInfo
}) => {
  const { networkName } = useParams<{ networkName: NetworkName }>()
  const isBinaceNetwork = networkName === 'binance'
  const isLST: boolean = tokenInfo?.type === 'LST'

  const wallet = useWallet()

  if (!__CLIENT__) return <LeftProductInfoClient infoType={tokenInfo.type} />

  if (wallet === null) return <FallbackNoConnected />

  return (
    <div id="left-asset-info" className="left-product-info">
      <ErrorBoundary fallback={<DefaultPointsBox type="error" />}>
        <Suspense fallback={<DefaultPointsBox type="default" />}>
          <ActualValueLeftPoint refreshKey={refreshKey} />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary fallback={<DefaultPointsBox type="error" />}>
        <Suspense fallback={<DefaultPointsBox type="autoLayer" />}>
          <AssetInfoPoints
            type="autoLayer"
            useFetchPoints={useAutolayerPoints}
            refreshKey={refreshKey}
          />
        </Suspense>
      </ErrorBoundary>
      {isBinaceNetwork && <BinaceNetworkNotice />}
      {!isLST && (
        <>
          <ErrorBoundary fallback={<DefaultPointsBox type="error" />}>
            <Suspense fallback={<DefaultPointsBox type="eigenLayer" />}>
              <EigenLayerLeftPoint />
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary fallback={<DefaultPointsBox type="error" />}>
            <Suspense
              fallback={
                <DefaultPointsBox
                  type={
                    tokenInfo?.id.toLocaleLowerCase() === 'ezeth'
                      ? 'renzo'
                      : 'kelp'
                  }
                />
              }
            >
              <LRTPoints />
            </Suspense>
          </ErrorBoundary>
        </>
      )}
    </div>
  )
}

export default LeftAssetInfo
