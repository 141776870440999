import { CoingeckoDataSummary } from 'src/hooks/useCoingecko'

import { PortfolioStrategies } from 'src/types/portfolioTypes'

const addTokensWeightForPools = (
  poolsWithBalance: PortfolioStrategies[],
  coingeckoData: CoingeckoDataSummary[]
): PortfolioStrategies[] => {
  const poolsWithTokenWeight: PortfolioStrategies[] = poolsWithBalance.map(
    (pool: PortfolioStrategies) => {
      const tokens = pool.filteredTokens
      const totalLiquidity: number = Number(pool.liquidity)
      const updatedTokens = tokens.map((token) => {
        const tokenBalance: number = Number(token.balance)
        try {
          const {
            current_price: usdPrice,
            price_change_percentage_24h: priceChangePercentage24h
          } = coingeckoData.find(
            (c) => c.symbol?.toLowerCase() === token.symbol?.toLowerCase()
          )
          const usdValue: number = usdPrice ? tokenBalance * usdPrice : 0
          const tokenWeight: number = usdValue / totalLiquidity
          return {
            ...token,
            weight: tokenWeight,
            usdPrice,
            usdValue,
            priceVariation24h: priceChangePercentage24h
          }
        } catch (error) {
          return {
            ...token,
            weight: 0,
            usdPrice: 0,
            usdValue: 0,
            priceVariation24h: 0
          }
        }
      })

      // update lastTTokenWeight to have perfect total 1 weight
      const previousWeights: number = updatedTokens
        .slice(0, tokens.length - 1)
        .reduce((acc, t) => acc + t.weight, 0)
      const lastToken = updatedTokens[updatedTokens.length - 1]
      lastToken.weight = 1 - previousWeights

      const realTokens = updatedTokens.filter((t) => t.weight !== 0)
      const totalPriceVariation: number = realTokens.reduce(
        (acc, t) => acc + t.priceVariation24h * t.weight,
        0
      )
      const priceVariation: number = totalPriceVariation / realTokens.length
      return { ...pool, filteredTokens: updatedTokens, priceVariation }
    }
  )
  return poolsWithTokenWeight
}

export default addTokensWeightForPools
