import { useParams } from 'react-router'
import { useEtherFiPoints, useRenzoPoints } from 'src/hooks/useEigenLayerPoints'
import { Points } from 'src/types'

export function useLrtEtherPoints(): Points {
  const result = useEtherFiPoints()
  const { networkName } = useParams<{ networkName: string }>()
  let etherFiPoints = result?.eethPoints.loyaltyPoints
  if (networkName === 'arbitrum') {
    etherFiPoints = result?.weethArbPoints.loyaltyPoints
  } else if (networkName === 'binance') {
    etherFiPoints = result?.bscPoints.loyaltyPoints
  }
  return { points: etherFiPoints }
}

export function useLrtRenzoLeftPoints(): Points {
  const { tokenHold } = useRenzoPoints()
  const renzoPoints = tokenHold.renzoPoints
  return { points: renzoPoints }
}

export function useLrtMellowPoints(): Points {
  const { tokenHold } = useRenzoPoints()
  const mellowPoints = tokenHold.mellowPoints
  return { points: mellowPoints }
}

export function useLrtSymbioticPoints(): Points {
  const { tokenHold } = useRenzoPoints()
  const symbioticPoints = tokenHold.symbioticPoints
  return { points: symbioticPoints }
}
