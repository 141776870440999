import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'

export default function AuBNBMeta() {
  const intl = useIntl()
  const title = intl.formatMessage({ id: 'meta.aubnb.title' })
  const description = intl.formatMessage({ id: 'meta.aubnb.description' })
  const image = 'https://i.imgur.com/ZHxcJU7.png'
  return (
    <Helmet>
      <title>{title}</title>

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="https://app.autolayer.io/info/aubnb" />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:description" content={description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}
