import { useIntl } from 'react-intl'
import { compactNumbers } from 'src/utils/compactNumbers'

export const useCompactNumbers = () => {
  const { locale } = useIntl()

  const result = (amount = 0, digits = 1) =>
    compactNumbers(amount, digits, locale)

  return result
}
