import { networkVectors } from 'src/static/logos/networks'
import {
  DEFAULT_NETWORK_NAME,
  getNetworkName,
  NetworkIds
} from 'src/utils/networkHelper'
import networkChoice from 'src/components/common/Network/networkChoice.json'
import { useSelector } from 'react-redux'

import './NetworkWithLogo.sass'

export default function NetworkWithLogo() {
  const networkId = useSelector((s: { network: NetworkIds }) => s.network)

  const networkName = getNetworkName(networkId)

  return (
    <span id="network-with-logo">
      <img
        src={
          networkVectors[networkName] ?? networkVectors[DEFAULT_NETWORK_NAME]
        }
        alt={`${networkChoice[networkName]?.label ?? DEFAULT_NETWORK_NAME} Logo`}
      />
      <h4>{networkChoice[networkName]?.label ?? DEFAULT_NETWORK_NAME}</h4>
    </span>
  )
}
