import { Suspense } from 'react'
import Loading from 'src/components/Loading/Loading'
import ErrorPage from '../ErrorPage/ErrorPage'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import { Outlet } from 'react-router-dom'

import './ProductInfo.sass'

const ProductInfo = () => {
  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Suspense fallback={<Loading colored />}>
        <Outlet />
      </Suspense>
    </ErrorBoundary>
  )
}

export default ProductInfo
