import { useParams } from 'react-router-dom'
import AssetInfoPoints from 'src/components/Points/AssetInfo/AssetInfoPoints'
import { productsPointsEigenLayer } from 'src/components/Points'
import { ProductIcons } from 'src/types'

const productItems: Record<
  string,
  Exclude<
    ProductIcons,
    'autoLayer' | 'mellow' | 'renzo' | 'swbtc' | 'swell' | 'symbiotic'
  >
> = {
  ezeth: 'eigenLayer',
  rseth: 'kelp',
  pufeth: 'puffer',
  primeeth: 'primestaked',
  unieth: 'bedrock',
  ineth: 'inception',
  eeth: 'ether',
  weeth: 'ether'
}

const EigenLayerLeftPointsWrapper = () => {
  const { productName } = useParams<{ productName: string }>()
  const type = productItems[productName]
  if (!type) return null
  return (
    <AssetInfoPoints
      type="eigenLayer"
      useFetchPoints={productsPointsEigenLayer[type]}
    />
  )
}

export default EigenLayerLeftPointsWrapper
