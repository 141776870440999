import { FormattedMessage } from 'react-intl'
import Loading from 'src/components/Loading/Loading'
import useIsResponsive from 'src/hooks/useIsResponsive'
import { AllPoints } from 'src/types'

interface PointsDashboardFallbackProps {
  type?: AllPoints
}

export function PointsDashboardLoading({ type }: PointsDashboardFallbackProps) {
  const isResponsive = useIsResponsive()

  return (
    <article
      className={`points-item-dashboard points-item-${type || 'loading'}`}
    >
      <section className="inside-container">
        <h2>
          <span>
            <FormattedMessage id="loading" />
          </span>
        </h2>
        <Loading mini={isResponsive} />
      </section>
    </article>
  )
}
