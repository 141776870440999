import { useEffect, useState } from 'react'
import SwapSelector from '../TokenSelector/SwapSelector'
import { useParams } from 'react-router-dom'
import useEnoughBalance from 'src/hooks/useEnoughBalance'
import approveToken from 'src/contracts/approveToken'
import sendSwapTransaction from 'src/contracts/sendSwapTransaction'
import tokenNameToAddress from 'src/utils/tokenNameToAddress'
import getParaswapRoute from 'src/contracts/Paraswap/getParaswapRoute'
import { OptimalRate } from '@paraswap/sdk'
import buildParaswapTx from 'src/contracts/Paraswap/buildParaswapTx'
import { ExecutionSteps, FailExecutionSteps } from 'src/types/DepositTypes'
import { DepositProps } from '../Deposit/Deposit'
import { getAutolayerPointsAfterTx } from 'src/contracts/getAutoLayerPoints'
import { useCommonDeposit } from 'src/hooks/useCommonDeposit'
import NotConnectedDeposit from '../Deposit/NotConnectedDeposit'
import ContinueButton from '../Deposit/ContinueButton'
import DepositStepMessage from '../Deposit/DepositStepMessage/DepositStepMessage'
import type { SwapData } from 'src/types'
import Slippage from '../Slippage/Slippage'
import useNetworkFromRoute from 'src/hooks/useNetworkFromRoute'

import './Swap.sass'

const Swap = ({ darkMode = false, refreshAutoLayerPoints }: DepositProps) => {
  const { productName } = useParams<{ productName: string }>()
  const [priceRoute, setPriceRoute] = useState<OptimalRate>(undefined)
  const [swapOutputPreviewAmount, setSwapOutputPreviewAmount] =
    useState<string>(undefined)
  const { networkName: network } = useNetworkFromRoute()
  const initialTokenSymbol: string = network === 'binance' ? 'BNB' : 'ETH'
  const {
    searchMode,
    setSearchMode,
    executionStep,
    setExecutionStep,
    failExecutionStep,
    setFailExecutionStep,
    txHash,
    setTxHash,
    txPoints,
    setTxPoints,
    data: swapData,
    setData: setSwapData,
    realWeiAmount,
    setRealWeiAmount,
    wallet,
    isConnected,
    chainId,
    slippage,
    setSlippage
  } = useCommonDeposit<SwapData, ExecutionSteps, FailExecutionSteps>({
    outputCoin: initialTokenSymbol,
    amount: 0,
    inputCoin: productName
  })

  const hasEnoughBalance: boolean = useEnoughBalance(
    realWeiAmount,
    swapData?.inputCoin
  )

  const blocked = executionStep !== undefined && failExecutionStep === undefined
  useEffect(() => {
    setSwapOutputPreviewAmount(undefined)
    setPriceRoute(undefined)
    const estimateSwap = setTimeout(async () => {
      const priceRoute: OptimalRate = await getParaswapRoute(
        swapData.inputCoin,
        swapData.outputCoin,
        realWeiAmount.toString(),
        chainId
      )
      setPriceRoute(priceRoute)
      const outputEtherAmount = (
        parseFloat(priceRoute.destAmount) /
        10 ** priceRoute.destDecimals
      ).toFixed(6)
      setSwapOutputPreviewAmount(outputEtherAmount)
    }, 1200)
    return () => clearTimeout(estimateSwap)
  }, [chainId, realWeiAmount, swapData])

  const handleChange = (data: SwapData) => {
    if (executionStep || failExecutionStep) {
      setExecutionStep(undefined)
      setFailExecutionStep(undefined)
    }
    setSwapData(data)
  }

  const handleSwap = async () => {
    setSearchMode(false) // to close the list
    setExecutionStep(ExecutionSteps.notStarted)
    let txParams
    try {
      txParams = await buildParaswapTx(
        priceRoute,
        swapData.inputCoin,
        swapData.outputCoin,
        realWeiAmount.toString(),
        chainId,
        slippage
      )
      setExecutionStep(ExecutionSteps.gotRoute)
    } catch (error) {
      console.error('Error getting swap info', error.message)
      setFailExecutionStep(FailExecutionSteps.failGotRoute)
      return
    }

    try {
      await approveToken(
        swapData.inputCoin,
        realWeiAmount,
        wallet?.address,
        chainId
      )
      setExecutionStep(ExecutionSteps.approvedToken)
    } catch (error) {
      console.error('Error approving token', error.message)
      setFailExecutionStep(FailExecutionSteps.failApprovedToken)
      return
    }
    let sendSwapTx
    try {
      sendSwapTx = await sendSwapTransaction(
        txParams,
        swapData.outputCoin,
        realWeiAmount.toString(),
        wallet?.address,
        chainId
      )
    } catch (error) {
      console.error('Error sending swap transaction', error.message)
      setFailExecutionStep(FailExecutionSteps.failSentToken)
      return
    }

    try {
      const { points, transactionHash } = getAutolayerPointsAfterTx(
        sendSwapTx,
        wallet?.address,
        false,
        tokenNameToAddress(swapData.outputCoin, chainId)
      )
      setExecutionStep(ExecutionSteps.sentToken)
      setTxHash(transactionHash)
      setTxPoints(points)
      refreshAutoLayerPoints()
    } catch (error) {
      console.error('Error getting transaction Points', error.message)
    }
  }

  if (!isConnected) {
    return (
      <NotConnectedDeposit
        darkMode={darkMode}
        searchMode={searchMode}
        depositData={swapData}
        setDepositData={setSwapData}
        setSearchMode={setSearchMode}
      />
    )
  }

  return (
    <div id="swap" className={darkMode ? 'dark-theme' : 'light-theme'}>
      <div className="wrapper-selector">
        <SwapSelector
          darkMode={darkMode}
          searchMode={searchMode}
          previousData={swapData}
          blocked={blocked}
          onChange={handleChange}
          setSearchMode={setSearchMode}
          swapPreviewAmount={swapOutputPreviewAmount}
          setRealWeiAmount={setRealWeiAmount}
        />
        <Slippage
          blocked={blocked}
          slippage={slippage}
          setSlippage={setSlippage}
        />
      </div>
      {executionStep === undefined ? (
        <div>
          <ContinueButton
            disabled={swapData?.amount <= 0 || !hasEnoughBalance || !priceRoute}
            handleDeposit={handleSwap}
            darkMode={darkMode}
          />
        </div>
      ) : (
        <DepositStepMessage
          executionStep={executionStep}
          failExecutionStep={failExecutionStep}
          txHash={txHash}
          txPoints={txPoints}
        />
      )}
    </div>
  )
}

export default Swap
