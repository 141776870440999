import {
  useEigenLayerWithKelp,
  useEigenLayerWithKelpAndRenzo,
  useEigenLayerWithRenzo
} from './useDefiPoints'
import AssetInfoPoints from 'src/components/Points/AssetInfo/AssetInfoPoints'

interface EigenLayerDefiLeftPointsProps {
  isRenzo: boolean
  isKelp: boolean
}

const EigenLayerDefiLeftPoints = ({
  isKelp,
  isRenzo
}: EigenLayerDefiLeftPointsProps) => {
  if (isRenzo && isKelp)
    return (
      <AssetInfoPoints
        type="eigenLayer"
        useFetchPoints={useEigenLayerWithKelpAndRenzo}
      />
    )
  if (isKelp)
    return (
      <AssetInfoPoints
        type="eigenLayer"
        useFetchPoints={useEigenLayerWithKelp}
      />
    )
  return (
    <AssetInfoPoints
      type="eigenLayer"
      useFetchPoints={useEigenLayerWithRenzo}
    />
  )
}
export default EigenLayerDefiLeftPoints
