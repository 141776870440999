import TooltipItem from '../TooltipItem/TooltipItem'
import { GroupedRiskInfo } from 'src/utils/risk'
import { RiskType } from 'src/types/RiskTypes'

import './TvlTooltip.sass'

interface TvlTooltipProps {
  info: Record<string, GroupedRiskInfo>
  riskType: RiskType
}

export default function TvlTooltip({ info, riskType }: TvlTooltipProps) {
  return (
    <ul id="risk-box-tooltip">
      {Object.entries(info).map(
        ([protocol, data]) =>
          !!data?.percentageTvl && (
            <li key={protocol}>
              <TooltipItem
                data={data}
                protocol={protocol}
                riskType={riskType}
              />
            </li>
          )
      )}
    </ul>
  )
}
