import { useSelector } from 'react-redux'
import { WalletData } from 'src/types'
import usePromise from './usePromise'
import useCoingecko, { CoingeckoDataSummary } from './useCoingecko'
import { getNetworkName, NetworkIds } from 'src/utils/networkHelper'
import { BalanceResult } from 'src/contracts/getBalance/getBalanceListBatch'
import { getBalanceListFromTokenManager } from 'src/contracts/getBalance/getBalanceFromTokenManager'

const useTokensLRTValueUsd = (chainId: NetworkIds) => {
  const wallet = useSelector((s: any) => s.wallet as WalletData)
  const coingeckoData: CoingeckoDataSummary[] = useCoingecko()
  const address = wallet?.address ? wallet.address : null
  // const tokensList = useMemo(() => networkData(chainId).tokensLRS, [chainId])
  const networkName = getNetworkName(chainId)
  const balancesTokens: BalanceResult[] = usePromise(
    getBalanceListFromTokenManager,
    networkName,
    address
  )
  const tokensUsdValue: number =
    balancesTokens?.reduce((acc, b) => {
      const currentPrice = coingeckoData.find(
        (c) => c.symbol?.toLowerCase() === b.id?.toLowerCase()
      )?.current_price

      const balance = parseFloat(b.balance) || 0
      if (!currentPrice) return acc
      return acc + balance * currentPrice || 0
    }, 0) ?? 0
  return { tokensUsdValue, balancesTokens }
}

export default useTokensLRTValueUsd
