import BannerItem from '@components/Stake/BannerItem/BannerItem'
import { FormattedMessage } from 'react-intl'
import { useWallet } from '@hooks/useWallet'
import DashboardHeader from '@routes/Dashboard/DashboardHeader/DashboardHeader'
import StakingDataMobile from '@components/Stake/Data/Mobile'
import StakingDataDesktop from '@components/Stake/Data/Desktop'
import Footer from '@components/Footer/Footer'
import useWindowDimensions from '@hooks/useWindowDimensions'
import { useEffect, useRef, useState } from 'react'
import StakeDialog, {
  StakeDialogMethods
} from '@components/Stake/StakeDialog/StakeDialog'
import { binancePools } from 'src/data/Staking/Binance/contracts'
import useWalletChainId from '@hooks/useWalletChainId'
import { Networks } from '@utils/networkHelper'
import { changeChain } from 'src/utils/walletHelpers'
import binanceLogo from '@static/vectors/binance.svg'
import StakeMeta from '@components/Meta/StakeMeta'

import './Stake.sass'

const { pool1, pool2, pool3, pool4 } = binancePools

export default function Stake() {
  const address = useWallet()
  const { width } = useWindowDimensions()
  const userWalletChainId = useWalletChainId()

  const [mobile, setMobile] = useState<boolean>(true)
  const stakeDialogRef = useRef<StakeDialogMethods>(null)

  const [refreshKey, setRefreshKey] = useState<number>(0)

  const openDialog: StakeDialogMethods['open'] = (data) =>
    stakeDialogRef.current.open(data)

  const updateRefreshKey = () => setRefreshKey((prev) => ++prev)

  const isMobile: boolean = width < 820

  useEffect(() => {
    setMobile(isMobile)
  }, [isMobile])

  return (
    <>
      <StakeMeta />
      <div id="stake" className="page">
        <DashboardHeader />
        <div className="container">
          <div className="banner">
            <header>
              <h2>
                <FormattedMessage id="stake.banner.title" />
              </h2>
            </header>
            <div>
              <BannerItem
                address={address}
                stakeKey="totalStakedAmount"
                title="stake.banner.items.total"
              />
              <BannerItem
                address={address}
                stakeKey="usd"
                title="stake.banner.items.usd"
              />
              {userWalletChainId === Networks.binance ? (
                <>
                  <BannerItem
                    address={address}
                    stakeKey="userStakedAmount"
                    title="stake.banner.items.userStake"
                  />
                  <BannerItem
                    address={address}
                    stakeKey="rewards"
                    title="stake.banner.items.rewards"
                  />
                </>
              ) : (
                <div className="banner-item binance">
                  <span>
                    <FormattedMessage id="change-network-to" /> Stake
                  </span>
                  <button
                    className="change-network"
                    onClick={() => changeChain(Networks.binance)}
                  >
                    <img src={binanceLogo} alt="Binance Network logo" />
                    <FormattedMessage id="change-to-bsc" />
                  </button>
                </div>
              )}
            </div>
          </div>
          <h3>
            <FormattedMessage id="stake.staking-data.title" />
          </h3>
          {mobile ? (
            <section className="staking-data-container mobile" key={refreshKey}>
              <StakingDataMobile
                open={openDialog}
                apy={pool1.apy}
                image={pool1.logo}
                name={pool1.name}
                lockTime={pool1.lockTerm}
                maxCapacity={pool1.maxCapacity}
                poolAddress={pool1.address}
                refreshKey={refreshKey}
              />
              <StakingDataMobile
                open={openDialog}
                apy={pool3.apy}
                image={pool3.logo}
                name={pool3.name}
                lockTime={pool3.lockTerm}
                maxCapacity={pool3.maxCapacity}
                poolAddress={pool3.address}
                refreshKey={refreshKey}
              />
              <StakingDataMobile
                open={openDialog}
                apy={pool4.apy}
                image={pool4.logo}
                name={pool4.name}
                lockTime={pool4.lockTerm}
                maxCapacity={pool4.maxCapacity}
                poolAddress={pool4.address}
                refreshKey={refreshKey}
              />
              <StakingDataMobile
                open={openDialog}
                apy={pool2.apy}
                image={pool2.logo}
                name={pool2.name}
                lockTime={pool2.lockTerm}
                maxCapacity={pool2.maxCapacity}
                poolAddress={pool2.address}
                refreshKey={refreshKey}
              />
            </section>
          ) : (
            <StakingDataDesktop
              open={openDialog}
              key={refreshKey}
              refreshKey={refreshKey}
            />
          )}
        </div>
        <StakeDialog ref={stakeDialogRef} updateRefreshKey={updateRefreshKey} />
        <Footer />
      </div>
    </>
  )
}
