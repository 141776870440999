import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import Spinner from 'src/components/Loading/Spinner/Spinner'

import './ReferralsCard.sass'

interface ReferralsCardProps {
  type: string
  text: string
  amount?: ReactNode
  icon?: ReactNode
  status: 'done' | 'loading' | 'error'
}

const ReferralsCard = ({
  type,
  text,
  amount = 0,
  icon,
  status
}: ReferralsCardProps) => {
  return (
    <article className="referrals-info-card" id={`${type + '-' + text}-card`}>
      <h2>
        <FormattedMessage id={`referrals-info-card.title.${text}`} />
      </h2>
      <div className="row">
        <strong className="amount">
          {status === 'loading' ? (
            <Spinner />
          ) : status === 'error' ? (
            <span className="referrals-info-card-error">
              <FormattedMessage id="error-loading-your-values" />
            </span>
          ) : (
            amount
          )}
        </strong>
        {icon}
      </div>
    </article>
  )
}

export default ReferralsCard
