import Loading from 'src/components/Loading/Loading'

import './RiskSection.sass'

const RiskSectionLoading = () => {
  return (
    <section id="risk-section">
      <h2>
        <span>Risk Factor</span>
      </h2>
      <Loading />
    </section>
  )
}

export default RiskSectionLoading
