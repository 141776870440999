import { Suspense, useState } from 'react'
import { useParams } from 'react-router-dom'
import NotConnectedDeposit from '../Deposit/NotConnectedDeposit'
import {
  FailRemoveLiquiditySteps,
  RemoveLiquiditySteps
} from 'src/types/DepositTypes'
import { DepositProps } from '../Deposit/Deposit'
import { useSingleCamelotPool } from 'src/hooks/usePools'
import DepositStepMessageForPoolsWithdrawal from '../Deposit/DepositStepMessage/DepositStepMessageForPoolsWithdrawal'
import { useEnoughBalanceCamelotPools } from 'src/hooks/useEnoughBalance'
import { useCommonDeposit } from 'src/hooks/useCommonDeposit'
import useIsConnected from 'src/hooks/useIsConnected'
import ContinueButton from '../Deposit/ContinueButton'
import type { SwapData } from 'src/types'
import NftCamelotSelector from '../TokenSelector/NftCamelotSelector'
import withdrawFromCamelotPosition, {
  unbindCamelotPosition
} from 'src/contracts/Camelot/withdrawFromCamelotPosition'
import nftCamelotApprove, {
  lpCamelotApprove
} from 'src/contracts/Camelot/nftApprove'
import { FormattedMessage } from 'react-intl'
import CamelotPositionsList from '../TokenSelector/CamelotPositionsList/CamelotPositionsList'
import Loading from '../Loading/Loading'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import arrow from 'src/static/images/iconsSmall/arrow.svg'
import decodedWithdrawalAmount from 'src/contracts/Camelot/decodedWithdrawalAmount'

import './Swap.sass'

//* In camelotPools the product address is pos (lp token address)

const WithdrawCamelotPools = ({
  darkMode = false,
  refreshAutoLayerPoints,
  refreshKey
}: DepositProps) => {
  const { productAddress } = useParams<{ productAddress: string }>()
  // In this case we cannot get executionStep and failExecutionStep from useDeposit because we need different types.
  const isConnected: boolean = useIsConnected()
  const {
    searchMode,
    setSearchMode,
    txHash,
    txPoints,
    executionStep,
    failExecutionStep,
    setExecutionStep,
    setFailExecutionStep,
    setTxHash,
    realWeiAmount,
    setRealWeiAmount,
    data: swapData,
    setData: setSwapData,
    wallet,
    chainId
  } = useCommonDeposit<
    SwapData,
    RemoveLiquiditySteps,
    FailRemoveLiquiditySteps
  >({ outputCoin: '', amount: 0, inputCoin: productAddress })
  const [showPositions, setShowPositions] = useState<boolean>(false)
  const [selectedPositionData, setSelectedPositionData] = useState<{
    balance: number
    usdValue: number
  }>({ balance: 0, usdValue: 0 })
  const [withdralAmount, setWithdrawalAmount] = useState<{
    token0: string
    token1: string
  }>({ token0: undefined, token1: undefined })
  const thisPool = useSingleCamelotPool(productAddress)
  const hasEnoughBalance: boolean = useEnoughBalanceCamelotPools(
    realWeiAmount,
    swapData.outputCoin,
    thisPool?.nftAddress
  )

  const handleChange = (data: SwapData) => {
    if (executionStep || failExecutionStep) {
      setExecutionStep(undefined)
      setFailExecutionStep(undefined)
    }
    setSwapData(data)
  }

  const handleSwap = async () => {
    setExecutionStep(RemoveLiquiditySteps.notStarted)

    try {
      await nftCamelotApprove(
        thisPool.nftAddress,
        swapData?.outputCoin,
        wallet?.address,
        chainId
      )
      setExecutionStep(RemoveLiquiditySteps.approveBpt)
    } catch (error) {
      setFailExecutionStep(FailRemoveLiquiditySteps.failBptApprove)
      console.error('Error approving NFT', error)
      return
    }

    try {
      await withdrawFromCamelotPosition(
        thisPool.nftAddress,
        swapData?.outputCoin,
        realWeiAmount.toString(),
        wallet?.address,
        chainId
      )
      setExecutionStep(RemoveLiquiditySteps.sendBptLiquidity)
    } catch (error) {
      setFailExecutionStep(FailRemoveLiquiditySteps.failSendBptLiquidity)
      console.error('Error withdrawing from Camelot Position', error)
      return
    }

    try {
      await lpCamelotApprove(
        thisPool.pos,
        realWeiAmount.toString(),
        wallet?.address,
        chainId
      )
      setExecutionStep(RemoveLiquiditySteps.approveToken)
    } catch (error) {
      setFailExecutionStep(FailRemoveLiquiditySteps.failApproveToken)
      console.error('Error approving Camelot LP', error)
      return
    }

    try {
      const tx = await unbindCamelotPosition(
        thisPool.pos,
        realWeiAmount.toString(),
        wallet?.address,
        chainId
      )
      refreshAutoLayerPoints()
      const { token0Amount, token1Amount } = decodedWithdrawalAmount(tx)
      setWithdrawalAmount({ token0: token0Amount, token1: token1Amount })
      setTxHash(tx.transactionHash)
      setExecutionStep(RemoveLiquiditySteps.sendToken)
    } catch (error) {
      setFailExecutionStep(FailRemoveLiquiditySteps.failSendToken)
      console.error('Error unbinding Camelot LP', error)
    }
  }

  if (!isConnected) {
    return (
      <NotConnectedDeposit
        darkMode={darkMode}
        searchMode={searchMode}
        depositData={swapData}
        setDepositData={setSwapData}
        setSearchMode={setSearchMode}
      />
    )
  }

  return (
    <div id="swap" className={darkMode ? 'dark-theme' : 'light-theme'}>
      {swapData.outputCoin === '' ? (
        <section className="camelot-positions">
          <button
            onClick={() => setShowPositions(true)}
            className="select-position-btn"
          >
            <FormattedMessage id="select-position" />
            <img
              src={arrow}
              alt="arrow down"
              className={showPositions ? '' : 'rotate'}
            />
          </button>
          {showPositions && (
            <ErrorBoundary fallback={<div />}>
              <Suspense fallback={<Loading />}>
                <CamelotPositionsList
                  thisPool={thisPool}
                  onChange={handleChange}
                  setSeletedPositionData={setSelectedPositionData}
                  refreshKey={refreshKey}
                />
              </Suspense>
            </ErrorBoundary>
          )}
        </section>
      ) : (
        <>
          <NftCamelotSelector
            darkMode={darkMode}
            searchMode={searchMode}
            previousData={swapData}
            blocked={false}
            onChange={handleChange}
            setSearchMode={setSearchMode}
            setRealWeiAmount={setRealWeiAmount}
          />

          {executionStep === undefined ? (
            <div>
              <ContinueButton
                disabled={
                  swapData?.amount <= 0 ||
                  !hasEnoughBalance ||
                  realWeiAmount === BigInt(0)
                }
                handleDeposit={handleSwap}
                darkMode={darkMode}
                selectedPositionData={selectedPositionData}
                bptName={thisPool.bptName}
              />
            </div>
          ) : (
            <DepositStepMessageForPoolsWithdrawal
              executionStep={executionStep}
              failExecutionStep={failExecutionStep}
              txHash={txHash}
              txPoints={txPoints}
              avoidSwap={false}
              withdrawalAmount={withdralAmount}
              camelotLpName={thisPool.bptName}
            />
          )}
        </>
      )}
    </div>
  )
}

export default WithdrawCamelotPools
