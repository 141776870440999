import fire from 'src/static/vectors/fire.svg'
import './MultiplierInfo.sass'

const MultiplierInfo = ({ amount }: { amount: number }) => {
  const isOnFire = amount > 1
  return (
    <section className={`multiplier-info ${isOnFire ? 'on-fire' : ''}`}>
      <span className="multiplier-value">
        <span>x{amount}</span>
      </span>
      {isOnFire && <img loading="lazy" src={fire} />}
    </section>
  )
}

export default MultiplierInfo
