import { NetworkData, networkData } from 'src/data'
import { NetworkIds } from 'src/utils/networkHelper'
import tokenNameToAddress from 'src/utils/tokenNameToAddress'

const getTokenDecimals = (token: string, chainId?: NetworkIds): string => {
  const tokenAddress: string =
    token.length === 42
      ? token.toLowerCase()
      : tokenNameToAddress(token, chainId).toLowerCase()
  const networkInfo: NetworkData = networkData(chainId)
  const findToken = networkInfo.tokens?.find(
    (t) => t.address.toLowerCase() === tokenAddress.toLowerCase()
  )
  return findToken ? findToken.decimals : '18'
}
export default getTokenDecimals
