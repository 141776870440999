import { FormattedMessage } from 'react-intl'
import useEnoughBalance from 'src/hooks/useEnoughBalance'
import { useParams } from 'react-router-dom'
import { NetworkData, networkData } from 'src/data'
import { useCamelotPoolNftsForUser } from 'src/hooks/useCamelot'
import useNetworkFromRoute from 'src/hooks/useNetworkFromRoute'
// import useWalletChainId from 'src/hooks/useWalletChainId'
// import ChangeNetworkButton from './ChangeNetworkButton'

import './ProductInfoButtons.sass'
import ChangeNetworkButton from './ChangeNetworkButton'
import useWalletChainId from '@hooks/useWalletChainId'

interface ProductInfoButtonsProps {
  darkMode?: boolean
  setIsDepositDialogOpen: (value: boolean) => void
  setIsSwapDialogOpen: (value: boolean) => void
  disableWithdraw?: boolean
  refresher?: number
}

const ProductInfoButtons = ({
  darkMode = false,
  setIsDepositDialogOpen,
  setIsSwapDialogOpen,
  disableWithdraw
}: ProductInfoButtonsProps) => {
  const { productName } = useParams<{ productName: string }>()

  const handleSwap = () => {
    setIsSwapDialogOpen(true)
  }
  const handleDepositMore = () => {
    setIsDepositDialogOpen(true)
  }
  const isForToken: boolean = !!productName
  return (
    <div
      className={
        !darkMode ? 'product-info-buttons' : 'product-info-buttons dark-theme'
      }
    >
      <button className="deposit-more" onClick={handleDepositMore}>
        <span>Restake</span>
      </button>
      <button
        className={isForToken ? 'swap' : 'withdraw'}
        onClick={handleSwap}
        disabled={disableWithdraw}
      >
        <span>
          {' '}
          {isForToken ? (
            <FormattedMessage id="swap" />
          ) : (
            <FormattedMessage id="token-selector.withdraw" />
          )}
        </span>
      </button>
    </div>
  )
}

const CamelotProductInfoButtons = ({
  darkMode = false,
  setIsDepositDialogOpen,
  setIsSwapDialogOpen,
  refresher
}: ProductInfoButtonsProps) => {
  const { productAddress } = useParams<{ productAddress: string }>()
  const { chainId } = useNetworkFromRoute()
  const networkInfo: NetworkData = networkData(chainId)
  const nftAddress: string = networkInfo?.camelotPools?.find(
    (pool) => pool.pos.toLowerCase() === productAddress?.toLowerCase()
  )?.nftAddress
  const amountNfts: number = useCamelotPoolNftsForUser(refresher, nftAddress)
  const disableWithdraw: boolean = amountNfts === 0
  return (
    <ProductInfoButtons
      setIsDepositDialogOpen={setIsDepositDialogOpen}
      darkMode={darkMode}
      setIsSwapDialogOpen={setIsSwapDialogOpen}
      disableWithdraw={disableWithdraw}
    />
  )
}

const TokensProductInfoButtons = ({
  darkMode = false,
  setIsDepositDialogOpen,
  setIsSwapDialogOpen
}: ProductInfoButtonsProps) => {
  const { productName } = useParams<{ productName: string }>()
  const { productAddress } = useParams<{ productAddress: string }>()
  const tokenUsed: string = productName ?? productAddress
  const MIN_WEI_AMOUNT: bigint = BigInt(100)
  const enoughBalance: boolean = useEnoughBalance(MIN_WEI_AMOUNT, tokenUsed)
  const disableWithdraw: boolean = !enoughBalance
  if (!productName) {
    return (
      <div
        className={
          !darkMode ? 'product-info-buttons' : 'product-info-buttons dark-theme'
        }
      >
        <a
          href="https://autolayer.notion.site/The-AuBNB-Odyssey-Discovering-Next-Gen-LRT-12f62c8ce4be80d5b5e4e246f02fe04f"
          className="button"
          id="aubnb-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="how-get-whitelisted" />
        </a>
      </div>
    )
  }
  return (
    <ProductInfoButtons
      setIsDepositDialogOpen={setIsDepositDialogOpen}
      darkMode={darkMode}
      setIsSwapDialogOpen={setIsSwapDialogOpen}
      disableWithdraw={disableWithdraw}
    />
  )
}

const ProductInfoButtonsWrapper = ({
  darkMode = false,
  setIsDepositDialogOpen,
  setIsSwapDialogOpen,
  refresher
}: ProductInfoButtonsProps) => {
  const { productAddress } = useParams<{ productAddress: string }>()
  const userWalletChainId = useWalletChainId()
  const { chainId } = useNetworkFromRoute()
  const networkInfo: NetworkData = networkData(chainId)
  const isCamelot: boolean = networkInfo?.camelotPools?.some(
    (pool) => pool?.pos?.toLowerCase() === productAddress?.toLowerCase()
  )
  if (chainId !== userWalletChainId) {
    return <ChangeNetworkButton darkMode={darkMode} />
  }
  return (
    <>
      {isCamelot ? (
        <CamelotProductInfoButtons
          refresher={refresher}
          setIsDepositDialogOpen={setIsDepositDialogOpen}
          darkMode={darkMode}
          setIsSwapDialogOpen={setIsSwapDialogOpen}
        />
      ) : (
        <TokensProductInfoButtons
          setIsDepositDialogOpen={setIsDepositDialogOpen}
          darkMode={darkMode}
          setIsSwapDialogOpen={setIsSwapDialogOpen}
        />
      )}
    </>
  )
}

export default ProductInfoButtonsWrapper
