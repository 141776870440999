import { Points, ProductIcons } from 'src/types'
import PointsBox from '../PointsBox/PointsBox'

interface AssetInfoProps {
  useFetchPoints: (key?: number) => Points
  type: ProductIcons
  refreshKey?: number
}

export default function AssetInfoPoints({
  type,
  useFetchPoints,
  refreshKey
}: AssetInfoProps) {
  const { points } = useFetchPoints(refreshKey)
  return <PointsBox type={type} points={+points} />
}
