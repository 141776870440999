import { useParams } from 'react-router-dom'

import { ProductIcons } from 'src/types'
import { productPointsLrt } from 'src/components/Points'
import AssetInfoPoints from '../AssetInfoPoints'

const productItems: Record<
  string,
  Exclude<ProductIcons, 'eigenLayer' | 'autoLayer'>[]
> = {
  ezeth: ['renzo'],
  pzeth: ['renzo', 'mellow', 'symbiotic'],
  swbtc: ['swbtc'],
  rseth: ['kelp'],
  pufeth: ['puffer'],
  rsweth: ['swell'],
  primeeth: ['primestaked'],
  unieth: ['bedrock'],
  ineth: ['inception'],
  eeth: ['ether'],
  weeth: ['ether']
}

export default function LRTPoints() {
  const { productName } = useParams<{ productName: string }>()

  return (
    <>
      {productItems[productName].map((product) => (
        <AssetInfoPoints
          type={product}
          useFetchPoints={productPointsLrt[product]}
          key={product}
        />
      ))}
    </>
  )
}
