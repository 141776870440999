import { SmallIcons } from 'src/static/images/iconsSmall'
import MarkdownMessage from '@gluedigital/markdown-message'
import useNetworkFromRoute from 'src/hooks/useNetworkFromRoute'
import { getNetworkParams } from 'src/utils/walletHelpers'
import { getItemByNetwork } from 'src/utils/networkHelper'

const ScanLinkMessage = ({ txHash }: { txHash: string }) => {
  const { chainId } = useNetworkFromRoute()
  const networkParams = getNetworkParams(chainId)
  const networkScan = networkParams.blockExplorerUrls[0]
  const scanLink: string = networkScan + 'tx/' + txHash
  const { scanName } = getItemByNetwork(chainId)
  return (
    <a href={scanLink} target="_blank" className="ftm-link" rel="noreferrer">
      <img src={SmallIcons.linkGrey} alt="link icon" />
      <span>
        <MarkdownMessage
          id="deposit-step-scan.link"
          values={{ scan: scanName }}
        />
      </span>
    </a>
  )
}

export default ScanLinkMessage
