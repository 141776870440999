import { ChangeEvent, FormEvent, ReactNode, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Spinner from 'src/components/Loading/Spinner/Spinner'
import { SmallIcons } from 'src/static/images/iconsSmall'
import { getItemByNetwork, NetworkIds } from 'src/utils/networkHelper'
import MarkdownMessage from '@gluedigital/markdown-message'
import { getNetworkParams } from 'src/utils/walletHelpers'

interface IntroduceCodeProps {
  type: 'create' | 'insert'
  description: ReactNode | null
  error: string | null
  handleClose: () => void
  handleSubmit: (code: string) => void
  revertError: () => void
  loading?: boolean
  txHash: string | null
}

export default function IntroduceCode({
  type,
  description,
  error,
  txHash,
  handleClose,
  handleSubmit,
  revertError,
  loading
}: IntroduceCodeProps) {
  const [code, setCode] = useState<string>('')
  const networkId = useSelector((s: { network: NetworkIds }) => s.network)
  const intl = useIntl()
  const networkParams = getNetworkParams(networkId)
  const explorerBaseUrl: string = networkParams.blockExplorerUrls[0]
  const scanLink = `${explorerBaseUrl}tx/${txHash}`
  const { scanName } = getItemByNetwork(networkId)

  const commonInit = `referrals.dialog.${type}`

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit(code)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setCode(newValue)
    if (error) revertError()
  }

  return (
    <>
      <header>
        <h3>
          <FormattedMessage id={`${commonInit}.title`} />
        </h3>
        <button onClick={handleClose}>
          <span className="icon icon-close" />
        </button>
      </header>
      <main className={error ? 'has-errors' : txHash ? 'success' : ''}>
        {description ? <div className="description">{description}</div> : null}
        <form onSubmit={handleSubmitForm}>
          <div className="code">
            <label>
              <span>
                <FormattedMessage id={`${commonInit}.input.label`} />
              </span>
              <input
                placeholder={intl.formatMessage({
                  id: `${commonInit}.input.placeholder`
                })}
                type="text"
                required
                name="code"
                value={code}
                onChange={handleChange}
              />
            </label>
          </div>

          {error ? (
            <p className="error">
              <div className="circle failed" />
              {intl.formatMessage({ id: error })}
            </p>
          ) : null}
          {txHash ? (
            <div className="successful">
              <div>
                <div className="circle done" />
                <span>
                  <FormattedMessage id={`${commonInit}.success`} />
                </span>
              </div>

              <a
                href={scanLink}
                target="_blank"
                className="ftm-link"
                rel="noreferrer"
              >
                <img src={SmallIcons.linkGrey} alt="link icon" />
                <span>
                  <MarkdownMessage
                    id="deposit-step-scan.link"
                    values={{ scan: scanName }}
                  />
                </span>
              </a>
            </div>
          ) : null}

          <button type="submit">
            <FormattedMessage id={`${commonInit}.button`} />
          </button>

          {loading ? <Spinner /> : null}
        </form>
        {type === 'create' && (
          <p id="referrals-network-advice">
            <FormattedMessage id="referrals.advice" />
          </p>
        )}
      </main>
    </>
  )
}
