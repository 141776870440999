import { FormattedMessage } from 'react-intl'
import Tooltip from 'src/components/Tooltip/Tooltip'
import { RiskType } from 'src/types/RiskTypes'
import { GroupedRiskInfo } from 'src/utils/risk'
import TvlTooltip from './TvlTooltip/TvlTooltip'

import './RiskBox.sass'

interface RiskBoxProps {
  riskType: RiskType
  info: Record<string, GroupedRiskInfo>
}

export default function RiskBox({ info, riskType }: RiskBoxProps) {
  const totalPercentage = Object.values(info).reduce(
    (acc, obj) => acc + obj.percentageTvl,
    0
  )

  return (
    <article
      id={`risk-box-${riskType}`}
      className={`risk-box ${!totalPercentage ? 'disabled' : ''}`}
    >
      <div className="risk-box-left">
        <FormattedMessage id={`risk-box.${riskType}`} />
      </div>
      <div className="risk-box-right">
        <div className="percetage">
          <strong>
            {totalPercentage > 0
              ? Math.round(+totalPercentage * 100) / 100
              : '0'}
            %
          </strong>
        </div>
        <Tooltip>
          <TvlTooltip info={info} riskType={riskType} />
        </Tooltip>
      </div>
    </article>
  )
}
