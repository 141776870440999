import web3 from '@utils/web3'
import { stakingAbi } from '../abis/AutoLayerAbis/StakingAbi'
import { GENERIC_ABIS } from '../abis'

const autolayerTokenAddress = '0xF06ce11836851d71E74e4ffeFa7b73FCc8A27786'

export const approveForStake = async (
  userAddress: string,
  contractAddress: string,
  amount: bigint
) => {
  const tokenContract = new web3.eth.Contract(
    GENERIC_ABIS.token,
    autolayerTokenAddress
  )
  const allowance: bigint = await tokenContract.methods
    .allowance(userAddress, contractAddress)
    .call()

  let txHash = ''
  if (allowance < amount) {
    txHash = (
      await tokenContract.methods.approve(contractAddress, amount).send({
        from: userAddress
      })
    ).transactionHash
  }
  return txHash
}

export const stake = async (
  userAddress: string,
  contractAddress: string,
  amount: bigint
) => {
  const contract = new web3.eth.Contract(stakingAbi, contractAddress)

  const estimatedGas = await contract.methods.stake(amount).estimateGas({
    from: userAddress
  })
  const stakeTx = await contract.methods.stake(amount).send({
    from: userAddress,
    gas: estimatedGas.toString()
  })

  return stakeTx.transactionHash
}

export const unstake = async (
  userAddress: string,
  contractAddress: string,
  positionId: number
) => {
  const contract = new web3.eth.Contract(stakingAbi, contractAddress)
  const estimatedGas = await contract.methods.unstake(positionId).estimateGas({
    from: userAddress
  })
  const unstakeTx = await contract.methods.unstake(positionId).send({
    from: userAddress,
    gas: estimatedGas.toString()
  })

  return unstakeTx.transactionHash
}

export const claimReward = async (
  userAddress: string,
  contractAddress: string,
  positionId: number
) => {
  const contract = new web3.eth.Contract(stakingAbi, contractAddress)
  const estimatedGas = await contract.methods
    .claimReward(positionId)
    .estimateGas({
      from: userAddress
    })
  const claimRewardsTx = await contract.methods.claimReward(positionId).send({
    from: userAddress,
    gas: estimatedGas.toString()
  })

  return claimRewardsTx.transactionHash
}

export const stakeReward = async (
  userAddress: string,
  contractAddress: string,
  positionId: number
) => {
  const contract = new web3.eth.Contract(stakingAbi, contractAddress)
  const estimatedGas = await contract.methods
    .stakeReward(positionId)
    .estimateGas({
      from: userAddress
    })
  const stakeRewardTx = await contract.methods.stakeReward(positionId).send({
    from: userAddress,
    gas: estimatedGas.toString()
  })

  return stakeRewardTx.transactionHash
}
