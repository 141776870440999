import { FormattedMessage } from 'react-intl'
import { StakeDialogMethods } from '@components/Stake/StakeDialog/StakeDialog'
import useWalletChainId from '@hooks/useWalletChainId'

import './StakingButtons.sass'

interface StakingButtonsProps extends Pick<StakeDialogMethods, 'open'> {
  rewards: number
  poolAddress: string
  positionId: number
  pendingDate: number
}

export default function StakingButtons({
  open,
  rewards,
  poolAddress,
  positionId,
  pendingDate
}: StakingButtonsProps) {
  const userWalletChainId = useWalletChainId()

  return (
    <div className="staking-buttons">
      <button
        className="stake"
        disabled={userWalletChainId !== '0x38'}
        onClick={() =>
          open({
            type: 'rewards',
            poolAddress,
            positionId,
            initialValue: rewards
          })
        }
      >
        <FormattedMessage id="stake.staking-data.buttons.stake" />
      </button>
      <button
        className="claim"
        disabled={userWalletChainId !== '0x38' || pendingDate > 0}
        onClick={() =>
          open({
            type: 'claim',
            poolAddress,
            positionId,
            initialValue: rewards
          })
        }
      >
        <FormattedMessage id="stake.staking-data.buttons.claim" />
      </button>
      <button
        className="unstake"
        disabled={userWalletChainId !== '0x38' || pendingDate > 0}
        onClick={() => open({ type: 'unstake', poolAddress, positionId })}
      >
        <FormattedMessage id="stake.staking-data.buttons.unstake" />
      </button>
    </div>
  )
}
