import { NetworkIds } from 'src/utils/networkHelper'
import { GENERIC_ABIS } from '../abis'
import { SWAPS_CONTRACT } from '../addresses'
import web3 from '@utils/web3'
import { AbiItem } from 'web3'

const nftCamelotApprove = async (
  nftAddress: string,
  tokenId: string,
  userAddress: string,
  chainId: NetworkIds
) => {
  const swapsContractAddress: string = SWAPS_CONTRACT[chainId.toLowerCase()]
  const nftContract = new web3.eth.Contract(
    GENERIC_ABIS.nftPoolCamelotAbi as AbiItem[],
    nftAddress
  )
  const gasPrice = await web3.eth.getGasPrice()
  const tx = await nftContract.methods
    .approve(swapsContractAddress, tokenId)
    .send({ from: userAddress, gasPrice: gasPrice.toString() })
  return tx
}

export default nftCamelotApprove

export const lpCamelotApprove = async (
  pos: string,
  amount: string,
  userAddress: string,
  chainId: NetworkIds
) => {
  const swapsContractAddress: string = SWAPS_CONTRACT[chainId.toLowerCase()]
  const lpAddress = new web3.eth.Contract(
    GENERIC_ABIS.camelotLpAbi as AbiItem[],
    pos
  )
  const gasPrice = await web3.eth.getGasPrice()
  const tx = await lpAddress.methods
    .approve(swapsContractAddress, amount)
    .send({ from: userAddress, gasPrice: gasPrice.toString() })
  return tx
}
