import swapAbiArbitrumDEV from './AutoLayerAbis/swapAbiArbitrumDEV.json'
import swapAbiArbitrumPRO from './AutoLayerAbis/swapAbiArbitrumPRO.json'
import swapAbiEthereumDEV from './AutoLayerAbis/swapAbiEthereumDEV.json'
import swapAbiEthereumPRO from './AutoLayerAbis/swapAbiEthereumPRO.json'
import CamelotPoolsInfoAbi from './AutoLayerAbis/CamelotPoolsInfoAbi.json'
import pointContractAbi from './AutoLayerAbis/pointsAbi.json'
import tokenAbi from './tokenAbi.json'
import balancerVaultAbi from './BalancerVault.json'
import gammaContractAbi from './gammaContract.json'
import nftPoolCamelotAbi from './nftPoolCamelot.json'
import camelotLpAbi from './camelotLp.json'
import { IS_DEV } from 'src/constants'
import tokenBalanceManagerAbi from './AutoLayerAbis/TokenBalanceManager.json'

export const AUTOLAYER_ABIS = {
  '0xa4b1': {
    swapContract: IS_DEV ? swapAbiArbitrumDEV : swapAbiArbitrumPRO,
    pointsContract: pointContractAbi
  },
  '0x1': {
    swapContract: IS_DEV ? swapAbiEthereumDEV : swapAbiEthereumPRO,
    pointsContract: pointContractAbi
  },
  '0x38': {
    swapContract: IS_DEV ? swapAbiEthereumDEV : swapAbiEthereumPRO,
    pointsContract: pointContractAbi
  },
  '0x2105': {
    swapContract: IS_DEV ? swapAbiEthereumDEV : swapAbiEthereumPRO,
    pointsContract: pointContractAbi
  },
  '0xe708': {
    swapContract: IS_DEV ? swapAbiEthereumDEV : swapAbiEthereumPRO,
    pointsContract: pointContractAbi
  },
  '0xa': {
    swapContract: IS_DEV ? swapAbiEthereumDEV : swapAbiEthereumPRO,
    pointsContract: pointContractAbi
  }
}

export const GENERIC_ABIS = {
  gammaContractAbi,
  nftPoolCamelotAbi,
  camelotLpAbi,
  token: tokenAbi,
  balancerVault: balancerVaultAbi,
  CamelotPoolsInfoAbi,
  tokenBalanceManagerAbi
}
