import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCompactNumbers } from 'src/hooks/useCompactNumbers'
import { useSinglePool } from 'src/hooks/usePools'

interface DefiLeftUsdValueProps {
  ethBalance: string
  address: string
}

export default function DefiLeftUsdValue({
  ethBalance,
  address
}: DefiLeftUsdValueProps) {
  const pool = useSinglePool(address)
  const bptUsdPrice: number =
    parseFloat(pool.liquidity) / parseFloat(pool.totalShares)
  const usdValue: number = bptUsdPrice * Number(ethBalance)
  const finalValue = useCompactNumbers()
  const tokens = pool?.filteredTokens
  const [open, setOpen] = useState(false)

  return (
    <div className="actual-value-points-box points-box">
      <h4>
        <FormattedMessage id="left-point.actual-value" />
      </h4>
      <div className="active-amount row">
        <strong className="amount">{parseFloat(ethBalance).toFixed(5)}</strong>
        <div className="points-symbol" onClick={() => setOpen(!open)}>
          +{tokens.length}
          <ul className={`tooltip ${open ? 'open' : ''}`}>
            {tokens?.map((t) => <li key={t.id}>{t.symbol}</li>)}
          </ul>
        </div>
      </div>
      <span className="usd-value">({finalValue(usdValue, 2)} USD)</span>
    </div>
  )
}
