import { FormattedMessage } from 'react-intl'
import TokenSelector from '../TokenSelector/TokenSelector'

interface NotConnectedDepositProps {
  darkMode: boolean
  searchMode: boolean
  depositData: { amount: number; outputCoin: string }
  setDepositData: (s: any) => void
  setSearchMode: (s: boolean) => void
}
const NotConnectedDeposit = ({
  darkMode,
  searchMode,
  depositData,
  setDepositData,
  setSearchMode
}: NotConnectedDepositProps) => {
  const handleConnect = () => {
    import(/* webpackChunkName: "wallet" */ 'src/utils/wallet').then((m) =>
      m.connect()
    )
  }
  return (
    <div className="not-connected">
      <TokenSelector
        darkMode={darkMode}
        searchMode={searchMode}
        previousData={depositData}
        blocked={true}
        onChange={setDepositData}
        setSearchMode={setSearchMode}
      />
      <button
        onClick={handleConnect}
        className={
          !darkMode
            ? 'continue-deposit light-theme'
            : 'continue-deposit dark-theme'
        }
      >
        <FormattedMessage id="select-wallet-modal.connect-wallet" />
      </button>
    </div>
  )
}

export default NotConnectedDeposit
