import createMessageList from './messageList'
import MarkdownMessage from '@gluedigital/markdown-message'
import Spinner from 'src/components/Loading/Spinner/Spinner'
import ScanLinkMessage from './ScanLinkMessage'
import { FormattedMessage } from 'react-intl'
import { ExecutionSteps, FailExecutionSteps } from 'src/types/DepositTypes'

import './DepositStepMessage.sass'

interface DepositStepMessageProps {
  failExecutionStep: FailExecutionSteps
  executionStep: ExecutionSteps
  darkMode?: boolean
  txHash?: string
  txPoints?: string
}
const DepositStepMessage = ({
  failExecutionStep,
  executionStep,
  darkMode = false,
  txHash,
  txPoints
}: DepositStepMessageProps) => {
  //* messages has in the first element the step and in second the status
  const messages: string[][] = createMessageList(
    failExecutionStep,
    executionStep
  )

  return (
    <div
      className={`deposit-step-list ${darkMode ? 'dark-theme' : 'light-theme'}`}
    >
      {messages?.map((message, index) => {
        const [step, status] = message
        return (
          <div className="row" key={index}>
            {status === 'done' && <div className={'circle done'} />}
            {failExecutionStep && status === 'fail' && (
              <div className={'circle failed'} />
            )}
            {failExecutionStep && status === 'running' && (
              <div className={'circle failed'} />
            )}
            {!failExecutionStep && status === 'running' && <Spinner />}
            <span>
              <MarkdownMessage id={`deposit-step-${step}.${status}`} />
            </span>
          </div>
        )
      })}
      {txHash ? (
        <>
          <ScanLinkMessage txHash={txHash} />
          <p className="funds-wallet">
            <FormattedMessage id="funds-wallet" />
          </p>
        </>
      ) : null}
      {txHash && txPoints && parseFloat(txPoints) > 0 && (
        <>
          <p className="funds-wallet">
            <FormattedMessage id="deposit.tx-points-earned" />
          </p>
          <span className="tx-points">
            {parseFloat(txPoints).toFixed(4)}{' '}
            <FormattedMessage id="dashboard.autoLayer" />
          </span>
        </>
      )}
    </div>
  )
}

export default DepositStepMessage
