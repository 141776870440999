import { Suspense } from 'react'
import GenericPoints, { GenericPointsFallback } from './GenericPoints'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import useTokensLst, { TokensInfo } from 'src/hooks/useTokensLst'
import useTokensLrst from 'src/hooks/useTokensLrst'

import './GenericPoints.sass'

const RestakingRewards = ({ productName }: { productName?: string }) => {
  const tokensLst: TokensInfo[] = useTokensLst()
  const tokensLrst: TokensInfo[] = useTokensLrst()
  const tokens = [...tokensLst, ...tokensLrst]
  const stakingRewards: string = tokens?.find(
    (t) => t?.id?.toLowerCase() === productName?.toLowerCase()
  )?.apr

  return (
    <GenericPoints
      type="left-point.restaking-rewards"
      points={+stakingRewards}
    />
  )
}

const RestakingRewardsWrapper = ({ productName }: { productName?: string }) => {
  return (
    <ErrorBoundary
      fallback={
        <GenericPointsFallback
          type="error-loading-data"
          pointsType="left-point.restaking-rewards"
        />
      }
    >
      <Suspense
        fallback={
          <GenericPointsFallback pointsType="left-point.restaking-rewards" />
        }
      >
        <RestakingRewards productName={productName} />
      </Suspense>
    </ErrorBoundary>
  )
}

export default RestakingRewardsWrapper
