import { useFetch } from '@gluedigital/ruse-fetch-extras'
import { dataMicroserviceUrl } from 'src/constants'
import { getUserClaimAubnbRewardsInfo } from 'src/contracts/aubnb/aubnbMethods'
import usePromise from './usePromise'

const useAubnbPendingRewards = (
  userAddress: string
): {
  pendingRewards: number
  rewards: number
  ethPendingRewards: number
  updateBlock: number
} => {
  const url = `${dataMicroserviceUrl}/aubnb/rewards/${userAddress}`
  const res = useFetch<{
    pendingRewards: number
    rewards: number
    ethPendingRewards: number
    updateBlock: number
  }>(url)

  return res
}

export default useAubnbPendingRewards

export const useAubnbClaimInfo = (userAddress: string, refreshKey: number) => {
  const { lastBlockNumberClaimed, rewardsAmountClaimed } = usePromise(
    getUserClaimAubnbRewardsInfo as (
      address: string,
      s: number
    ) => Promise<{
      lastBlockNumberClaimed: bigint
      rewardsAmountClaimed: bigint
    }>,
    userAddress,
    refreshKey
  )
  return { lastBlockNumberClaimed, rewardsAmountClaimed }
}
