import tokenNameToAddress from 'src/utils/tokenNameToAddress'
import { getEtherNumberAmount } from '../tokenDecimals/getEtherNumberAmount'
import { GENERIC_ABIS } from '../abis'
import {
  NetworkIds,
  getNativeCurrencySymbol,
  getNetworkName
} from 'src/utils/networkHelper'
import getWeb3 from '../web3'
import { AbiItem } from 'web3'

export const getSingleTokenBalance = async (
  tokenSymbol: string,
  userAddress: string,
  chainId: NetworkIds,
  isMaxButton: boolean = false
): Promise<string | null> => {
  if (!userAddress) return null
  const networkName = getNetworkName(chainId)
  const web3 = getWeb3(networkName)
  const nativeCrypto: string = getNativeCurrencySymbol(chainId)
  if (tokenSymbol === nativeCrypto) {
    const balance: string = web3.utils.fromWei(
      await web3.eth.getBalance(userAddress),
      'ether'
    )
    return parseFloat(balance)?.toFixed(6)
  }

  const tokenAddress: string =
    tokenSymbol.length === 42
      ? tokenSymbol
      : tokenNameToAddress(tokenSymbol, chainId)
  const tokenContract = new web3.eth.Contract(
    GENERIC_ABIS.token as AbiItem[],
    tokenAddress
  )
  const weiBalance: string = await tokenContract.methods
    .balanceOf(userAddress)
    .call()
  const ethBalance: string = await getEtherNumberAmount(
    tokenAddress,
    weiBalance,
    chainId
  )
  return isMaxButton ? parseFloat(ethBalance).toFixed(6) : ethBalance
}

export const getRealWeiTokenBalance = async (
  tokenSymbol: string,
  userAddress: string,
  chainId: NetworkIds
): Promise<string | null> => {
  if (!userAddress) return null
  const networkName = getNetworkName(chainId)
  const web3 = getWeb3(networkName)
  if (tokenSymbol === 'ETH' || tokenSymbol === 'BNB') {
    const balance = await web3.eth.getBalance(userAddress)
    return balance.toString()
  }
  const tokenAddress: string =
    tokenSymbol.length === 42
      ? tokenSymbol
      : tokenNameToAddress(tokenSymbol.toLowerCase(), chainId)
  if (!tokenAddress) return '0'
  const tokenContract = new web3.eth.Contract(
    GENERIC_ABIS.token as AbiItem[],
    tokenAddress
  )
  const weiBalance: string = await tokenContract.methods
    .balanceOf(userAddress)
    .call()
  return weiBalance
}
