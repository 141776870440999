import { TokensInfoWithoutMultiplier } from 'src/hooks/useTokensLst'
import camelotPools from './camelotPools.json'
import pools from './pools.json'
import tokens from './tokens.json'
import tokensLRS from './tokensLRS.json'

const lrtTokens = tokensLRS.map((t) => ({
  address: t.address,
  id: t.id,
  name: t.name,
  decimals: t.decimals
}))
const newTokens = tokens.slice(0, 3).concat(lrtTokens).concat(tokens.slice(3))
export const ArbitrumData = {
  pools,
  camelotPools,
  tokens: newTokens,
  tokensLRS: tokensLRS as TokensInfoWithoutMultiplier[]
}
