import MultiplierInfo from '../MultiplierInfo'
import { Multiplier, multipliersList } from '../multipliersList'
import IconMultiplier from '../IconMultiplier/IconMultiplier'
import { Multipliers } from 'src/types'

import './ListMultiplier.sass'

interface ListMultiplierProps {
  mini?: boolean
  multipliers: Multipliers
}

const ListMultiplier = ({ multipliers, mini }: ListMultiplierProps) => {
  return (
    <section className={`list-multipliers ${mini ? 'mini' : ''}`}>
      {Object.entries(multipliersList).map(([key, value]) => {
        const multiplierValue = multipliers[key as Multiplier]
        if (!multiplierValue) return null
        const { icon, name } = value
        return (
          <div
            key={name}
            className={`product-multiplier product-multiplier-${name.toLowerCase()}`}
          >
            <h3>{name}</h3>
            <div className="product-multiplier-wrapper">
              <IconMultiplier name={name.toLowerCase()} icon={icon} />
              <MultiplierInfo amount={multiplierValue} />
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default ListMultiplier
