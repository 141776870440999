import { FormattedMessage } from 'react-intl'
import { useCompactNumbers } from 'src/hooks/useCompactNumbers'
import { ProductIcons } from 'src/types'
import PointsIcon from '../Icons/PointsIcons'

import './PointsBox.sass'

export interface PointsBoxProps {
  type: ProductIcons
  points?: number
}

const PointsBox = ({ points, type }: PointsBoxProps) => {
  const finalValue = useCompactNumbers()
  return (
    <div className={`${type}-points-box points-box`}>
      <h4>
        <FormattedMessage id={`dashboard.${type}`} />
      </h4>
      <div className={`${type}-amount row`}>
        <strong className="staking-rewards"> {finalValue(+points, 2)}</strong>
        <PointsIcon type={type} />
      </div>
    </div>
  )
}

export default PointsBox
