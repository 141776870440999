import { useParams } from 'react-router-dom'
import useDeposit from 'src/hooks/useDeposit'
import sendSwapTransaction from 'src/contracts/sendSwapTransaction'
import TokenSelector from '../TokenSelector/TokenSelector'
import NotConnectedDeposit from './NotConnectedDeposit'
import ContinueButton from './ContinueButton'
import DepositStepMessage from './DepositStepMessage/DepositStepMessage'
import tokenNameToAddress from 'src/utils/tokenNameToAddress'
import { ExecutionSteps } from 'src/types/DepositTypes'
import { TransactionParams } from '@paraswap/sdk'
import Slippage from '../Slippage/Slippage'

import './Deposit.sass'

export interface DepositProps {
  darkMode: boolean
  refreshAutoLayerPoints?: () => void
  refreshKey?: number
}

const Deposit = ({
  darkMode = false,
  refreshAutoLayerPoints
}: DepositProps) => {
  const { productName } = useParams<{ productName: string }>()
  const {
    searchMode,
    setSearchMode,
    executionStep,
    failExecutionStep,
    txHash,
    txPoints,
    setDepositData,
    setRealWeiAmount,
    handleTokenChange,
    depositData,
    hasEnoughBalance,
    realWeiAmount,
    wallet,
    finishTransaction,
    tokenApprove,
    handleApproveError,
    handleRetrieveTxParamsError,
    handleSendError,
    retrieveTokenInAdress,
    retrieveTxParams,
    isConnected,
    chainId,
    changeExecutionStep,
    slippage,
    setSlippage
  } = useDeposit({ refreshAutoLayerPoints })

  const blocked = executionStep !== undefined && failExecutionStep === undefined

  const handleDeposit = async () => {
    setSearchMode(false) // to close the list
    const tokenInAddress = retrieveTokenInAdress()
    changeExecutionStep(ExecutionSteps.notStarted)

    let txParams: TransactionParams

    try {
      const paraswapRes = await retrieveTxParams(
        tokenInAddress,
        productName,
        realWeiAmount.toString(),
        slippage
      )
      txParams = paraswapRes.txParams
      changeExecutionStep(
        tokenInAddress !== tokenNameToAddress('ETH', chainId)
          ? ExecutionSteps.gotRoute
          : ExecutionSteps.approvedToken
      )
    } catch (err) {
      handleRetrieveTxParamsError(err)
      return
    }

    try {
      await tokenApprove(tokenInAddress)
      changeExecutionStep(ExecutionSteps.approvedToken)
    } catch (err) {
      handleApproveError(err)
      return
    }

    let sendSwapTx

    try {
      sendSwapTx = await sendSwapTransaction(
        txParams,
        productName,
        realWeiAmount.toString(),
        wallet?.address,
        chainId
      )
    } catch (error) {
      handleSendError(error)
      return
    }

    try {
      finishTransaction(sendSwapTx)
      changeExecutionStep(ExecutionSteps.sentToken)
    } catch (error) {
      console.error('Error getting transaction Points', error.message)
    }
  }

  if (!isConnected) {
    return (
      <NotConnectedDeposit
        darkMode={darkMode}
        searchMode={searchMode}
        depositData={depositData}
        setDepositData={setDepositData}
        setSearchMode={setSearchMode}
      />
    )
  }

  return (
    <div id="deposit" className={darkMode ? 'dark-theme' : 'light-theme'}>
      <div className="wrapper-selector">
        <TokenSelector
          darkMode={darkMode}
          searchMode={searchMode}
          previousData={depositData}
          blocked={blocked}
          onChange={handleTokenChange}
          setSearchMode={setSearchMode}
          setRealWeiAmount={setRealWeiAmount}
        />
        <Slippage
          blocked={blocked}
          slippage={slippage}
          setSlippage={setSlippage}
        />
      </div>
      {executionStep === undefined ? (
        <div>
          <ContinueButton
            disabled={depositData?.amount <= 0 || !hasEnoughBalance}
            handleDeposit={handleDeposit}
            darkMode={darkMode}
          />
        </div>
      ) : (
        <DepositStepMessage
          executionStep={executionStep}
          failExecutionStep={failExecutionStep}
          txHash={txHash}
          txPoints={txPoints}
        />
      )}
    </div>
  )
}

export default Deposit
