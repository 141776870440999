import { useState } from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { getRealWeiTokenBalance } from 'src/contracts/getBalance/getSingleTokenBalance'
import { WalletData } from 'src/types'
import { getEtherNumberAmount } from 'src/contracts/tokenDecimals/getEtherNumberAmount'
import swapUtils from 'src/utils/swap'
import {
  LpInfoForCamelotNft,
  getLpEquivalentForNft
} from 'src/contracts/Camelot/getNFTId'
import { useParams } from 'react-router-dom'
import { NetworkData, networkData } from 'src/data'
import web3 from 'src/utils/web3'
import useNetworkFromRoute from 'src/hooks/useNetworkFromRoute'

import './BalanceButtons.sass'

interface BalanceButtonsProps {
  previousData: { outputCoin: string; inputCoin?: string; amount: number }
  onChange?: ({
    amount,
    outputCoin
  }: {
    outputCoin: string
    amount: number
  }) => void
  disabled?: boolean
  isSwap?: boolean
  setRealWeiAmount?: (value: bigint) => void
}

const BalanceButtons = ({
  previousData,
  onChange,
  disabled = true,
  isSwap = false,
  setRealWeiAmount
}: BalanceButtonsProps) => {
  const [open, setOpen] = useState(false)
  const wallet = useSelector((s: any) => s.wallet as WalletData)
  const { productAddress } = useParams<{ productAddress: string }>()
  const { chainId } = useNetworkFromRoute()
  const networkInfo: NetworkData = networkData(chainId)
  const nftPoolsAddress: string = networkInfo?.camelotPools?.find(
    (pool) => pool.pos.toLowerCase() === productAddress?.toLowerCase()
  )?.nftAddress

  const handleBalance = async (value: number) => {
    const token = isSwap ? previousData.inputCoin : previousData.outputCoin
    const tokenAddress = swapUtils.getTokenAddress(token, chainId)
    try {
      const res = await getRealWeiTokenBalance(token, wallet?.address, chainId)
      const ethAmount: string = await getEtherNumberAmount(
        tokenAddress,
        res,
        chainId
      )

      if (value === 1) {
        setRealWeiAmount(BigInt(res))
        onChange({ ...previousData, amount: +parseFloat(ethAmount).toFixed(6) })
      } else {
        const exactEthAmount: number = parseFloat(ethAmount) * value
        onChange({ ...previousData, amount: +exactEthAmount?.toFixed(6) })
        if (value === 0.25) setRealWeiAmount(BigInt(res) / BigInt(4))
        else if (value === 0.5) setRealWeiAmount(BigInt(res) / BigInt(2))
        else if (value === 0.75) {
          setRealWeiAmount((BigInt(res) / BigInt(4)) * BigInt(3))
        }
      }
    } catch (err) {
      console.error('Error in percentage buttons:', err.message)
    }
  }

  const handleCamelotBalance = async (value: number) => {
    try {
      const info: LpInfoForCamelotNft = await getLpEquivalentForNft(
        nftPoolsAddress,
        Number(previousData.outputCoin)
      )
      const lpAmountWei: number = info.amount
      if (value === 1) {
        setRealWeiAmount(BigInt(lpAmountWei))
        const ethAmount: number = +web3.utils.fromWei(
          lpAmountWei.toString(),
          'ether'
        )
        onChange({ ...previousData, amount: +ethAmount?.toFixed(6) })
      } else {
        const ethAmount: number = +web3.utils.fromWei(
          lpAmountWei.toString(),
          'ether'
        )
        const exactEthAmount: number = ethAmount * value
        onChange({ ...previousData, amount: +exactEthAmount?.toFixed(6) })
        if (value === 0.25) setRealWeiAmount(BigInt(lpAmountWei) / BigInt(4))
        else if (value === 0.5) {
          setRealWeiAmount(BigInt(lpAmountWei) / BigInt(2))
        } else if (value === 0.75) {
          setRealWeiAmount((BigInt(lpAmountWei) / BigInt(4)) * BigInt(3))
        }
      }
    } catch (error) {
      console.error('Error in percentage buttons for Camelot:', error.message)
    }
  }

  const handleFunction =
    nftPoolsAddress && isSwap ? handleCamelotBalance : handleBalance
  return (
    <div className="balance-buttons">
      <button
        disabled={disabled}
        className="max-balance-button"
        onClick={() => handleFunction(1)}
      >
        <FormattedMessage id="max" />
      </button>
      <button
        disabled={disabled}
        className="percentage-balance-button"
        onClick={() => setOpen(!open)}
      >
        %
      </button>
      <div className={`percentages ${open && !disabled ? 'expanded' : ''}`}>
        <button className="balance-button" onClick={() => handleFunction(0.75)}>
          75%
        </button>
        <button className="balance-button" onClick={() => handleFunction(0.5)}>
          50%
        </button>
        <button className="balance-button" onClick={() => handleFunction(0.25)}>
          25%
        </button>
      </div>
    </div>
  )
}

export default BalanceButtons
