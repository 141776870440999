import { useFetch } from '@gluedigital/ruse-fetch-extras'
import { dataMicroserviceUrl } from 'src/constants'
import { AvsForTokens } from 'src/types/AvsTypes'

// Returns the id of the corresponding LRT token and the avsInfo (points, staked, etc)
const useAvsInfoForLRT = (): AvsForTokens[] => {
  const route = '/avs-for-tokens'
  const useAvsInfoForLRT: AvsForTokens[] = useFetch(dataMicroserviceUrl + route)
  return useAvsInfoForLRT
}

export default useAvsInfoForLRT
