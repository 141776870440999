import TokenInput from '@components/TokenInput/TokenInput'
import { AutolayerLogos } from '@static/logos/autolayer'
import { FormattedMessage } from 'react-intl'
import { claimReward } from 'src/contracts/staking/stake'
import { StakeDialogsProps } from '../../StakeDialog'
import StakeDialogSteps from '../../Steps/StakeDialogSteps'
import { useWallet } from '@hooks/useWallet'
import { FormEvent } from 'react'

export default function StakeDialogClaimReward({
  initialValue,
  poolAddress,
  positionId,
  updateRefreshKey,
  stepsData
}: StakeDialogsProps) {
  const address = useWallet()

  const { addStep, successStep, errorStep, steps, setDone } = stepsData

  const handleClaimRewards = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      addStep('stake.staking-dialog.stake.confirmation')
      const tx = await claimReward(address, poolAddress, positionId)
      successStep()
      setDone('stake.staking-dialog.steps.done-claim', tx)
      updateRefreshKey()
    } catch (err) {
      console.error('Error claiming rewards', err)
      errorStep()
    }
  }

  return (
    <form onSubmit={handleClaimRewards}>
      <TokenInput className="stake" disabled initialValue={initialValue}>
        <div>
          <img src={AutolayerLogos.autolayerIconWhite} alt="$LAY3R logo" />
          <span>$LAY3R</span>
        </div>
      </TokenInput>
      <StakeDialogSteps steps={steps}>
        <button
          className="continue"
          disabled={initialValue === 0}
          type="submit"
        >
          <FormattedMessage id="continue" />
        </button>
      </StakeDialogSteps>
    </form>
  )
}
