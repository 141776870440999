import tokensLRS from './tokensLRS.json'
import tokens from './tokens.json'
import auBnb from './auBnb.json'
import { TokensInfo, TokensInfoWithoutMultiplier } from 'src/hooks/useTokensLst'

const lrtTokens = tokensLRS.map((t) => ({
  address: t.address,
  id: t.id,
  name: t.name,
  decimals: t.decimals
}))
const aubnbToken = {
  address: auBnb.address,
  id: auBnb.id,
  name: auBnb.name,
  decimals: auBnb.decimals
}
const newTokens = tokens
  .slice(0, 3)
  .concat(lrtTokens)
  .concat(tokens.slice(3))
  .concat([aubnbToken])
export const BinanceData = {
  tokensLRS: tokensLRS as TokensInfoWithoutMultiplier[],
  tokens: newTokens,
  auBnb: auBnb as TokensInfo
}
