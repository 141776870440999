import { useCallback, useEffect, useMemo, useState } from 'react'
import ReferralsCard from './ReferralsCard/ReferralsCard'
import ReferralsDialog from '../ReferralsDialog/ReferralsDialog'
import AffiliatesData from './AffiliatesData'
import referalUtils from 'src/utils/referals'
import { useSelector } from 'react-redux'
import { ReferalStats, WalletData } from 'src/types'
import { getNetworkName, NetworkIds } from 'src/utils/networkHelper'
import getWeb3 from 'src/contracts/web3'
import { FormattedNumber } from 'react-intl'

const Affiliates = () => {
  const wallet = useSelector((s: { wallet: WalletData }) => s.wallet)
  const networkId = useSelector((s: { network: NetworkIds }) => s.network)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [allCodes, setAllCodes] = useState<ReferalStats[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const [refreshKey, setRefreshKey] = useState<number>(0)

  const handleClose = () => setShowDialog(false)

  const handleOpen = () => setShowDialog(true)

  const refresh = useCallback(() => setRefreshKey((prev) => prev + 1), [])

  useEffect(() => {
    if (!wallet?.address) return

    setLoading(true)

    const web3 = getWeb3(getNetworkName(networkId))

    const getAllCodes = async () => {
      const codeData = await referalUtils.getCreatedCodesData(
        wallet?.address,
        web3,
        networkId
      )

      const newCodeData = codeData.map(
        (entry): ReferalStats => ({
          ...entry,
          pointsObtained: referalUtils.getValue(
            entry.pointsObtained,
            networkId
          ),
          volumeUsd: referalUtils.getValue(entry.volumeUsd, networkId)
        })
      )
      setAllCodes(newCodeData)
    }

    getAllCodes()
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }, [networkId, wallet?.address, refreshKey])

  const totalValues = useMemo(
    () => ({
      totalUsers: referalUtils.getTotalReferal(allCodes, 'users'),
      totalVolume: referalUtils.getTotalReferal(allCodes, 'volumeUsd'),
      totalPoints: referalUtils.getTotalReferal(allCodes, 'pointsObtained')
    }),
    [allCodes]
  )

  return (
    <>
      <div id="affiliates" className="referrals-grid">
        <ReferralsCard
          text="referrals-traders"
          type="affiliates"
          amount={totalValues.totalUsers}
          status={!error ? (loading ? 'loading' : 'done') : 'error'}
        />
        <ReferralsCard
          text="trading-volume"
          type="affiliates"
          amount={
            <FormattedNumber
              value={totalValues.totalVolume}
              currency="USD"
              currencyDisplay="narrowSymbol"
              style="currency"
            />
          }
          status={!error ? (loading ? 'loading' : 'done') : 'error'}
        />
        <ReferralsCard
          text="points-earned"
          type="affiliates"
          amount={totalValues.totalPoints}
          status={!error ? (loading ? 'loading' : 'done') : 'error'}
        />
      </div>
      <AffiliatesData
        handleOpen={handleOpen}
        allCodes={allCodes}
        loading={loading}
      />

      {showDialog && (
        <ReferralsDialog
          handleClose={handleClose}
          type="create"
          refresh={refresh}
        />
      )}
    </>
  )
}

export default Affiliates
