import { useParams } from 'react-router'
import {
  NetworkName,
  NetworkIds,
  getNetworkIdByName
} from 'src/utils/networkHelper'

const useNetworkFromRoute = (): {
  chainId: NetworkIds
  networkName: NetworkName
} => {
  const { networkName } = useParams<{
    productName: string
    networkName: NetworkName
  }>()
  const chainId: NetworkIds = getNetworkIdByName(networkName)
  return { chainId, networkName }
}

export default useNetworkFromRoute
