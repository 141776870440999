import { useSelector } from 'react-redux'
import AccountDataWallet from './AccountDataWallet/AccountDataWallet'
import { WalletData } from 'src/types'
import ConnectButton from './ConnectButton/ConnectButton'

interface ConnectWalletProps {
  text?: string
  darkMode?: boolean
}

const ConnectWallet = ({
  text = 'default-text',
  darkMode = false
}: ConnectWalletProps) => {
  const wallet = useSelector((s: any) => s.wallet as WalletData)

  return !wallet ? (
    <ConnectButton darkMode={darkMode} text={text} />
  ) : (
    <AccountDataWallet wallet={wallet} />
  )
}

export default ConnectWallet
