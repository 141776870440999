import { useSelector } from 'react-redux'
import { NetworkIds } from 'src/utils/networkHelper'

// It returns the chainId of the network connected to the wallet.
// In case there is no wallet connected to the application it must return null
const useWalletChainId = (): NetworkIds => {
  const chainId: NetworkIds = useSelector((state: any) => state.network)
  return chainId
}

export default useWalletChainId
