import { useState } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Network, WalletData } from 'src/types'
import ButtonsChooseRST from 'src/components/ButtonsChooseRST/ButtonChooseRST'
import ProductGrid from 'src/components/ProductGrid/ProductGrid'
import DashboardPointsGrid from './DashboardPointsGrid/DashboardPointsGrid'
import Welcome from 'src/components/Welcome/Welcome'
import Footer from 'src/components/Footer/Footer'
import SelectNetwork from 'src/components/common/Network/SelectNewtork/SelectNetwork'
import DashboardHeader from './DashboardHeader/DashboardHeader'
import AuBnbBanner from '@components/AuBnbBanner/AuBnbBanner'
import './Dashboard.sass'

const Dashboard = () => {
  const location = useLocation()

  const wallet = useSelector((s: { wallet: WalletData }) => s.wallet)

  const [selectedNetwork, setSelectedNetwork] =
    useState<Network>('all networks')

  const isLrt = location.pathname.replace('/', '') === 'lrt'

  const handleNetworkChange = (newValue: Network) =>
    setSelectedNetwork(newValue)

  // useEffect(() => {
  //   if (!productType) {
  //     navigate('/lrt')
  //   }
  // }, [navigate, productType])

  if (location.pathname === '/') {
    return <Navigate to="/lrt" />
  }
  return (
    <div id="dashboard" className="page">
      <DashboardHeader />
      <div className="container">
        <section className="points-section">
          <AuBnbBanner />
          {wallet ? <DashboardPointsGrid /> : <Welcome />}
        </section>
        <div
          className="filters"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <ButtonsChooseRST />
          {isLrt ? (
            <SelectNetwork
              handleNetworkChange={handleNetworkChange}
              selectedNetwork={selectedNetwork}
            />
          ) : null}
        </div>
        <section className="strategy-section">
          <ProductGrid selectedNetwork={selectedNetwork} />
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default Dashboard
