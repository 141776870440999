import { getTokenImage } from 'src/utils/getTokenImage'
import { PoolsTokens } from 'src/types/pools'

import './TokenImageList.sass'

interface TokenImageListProps {
  tokens: PoolsTokens[]
  mini?: boolean
}

const TokenImageList = ({ tokens = [], mini = false }: TokenImageListProps) => {
  const max = mini ? 2 : 4
  return (
    <div
      className={`token-image-list tokens-${tokens?.length} ${mini ? 'mini' : ''}`}
    >
      {tokens
        ?.map((t) => (
          <img
            key={t.address}
            src={getTokenImage(t.symbol.toLowerCase())}
            title={t.symbol}
            alt={t.symbol}
            className="token-image"
          />
        ))
        .slice(0, max)}
      {tokens?.length > max && (
        <div className="rest-tokens">
          <span>{`+${tokens?.length - max}`}</span>
        </div>
      )}
    </div>
  )
}

export default TokenImageList
