import CardEntry from './CardEntry/CardEntry'
import { ReferalStats } from 'src/types'
import CardEntryFallback from './CardEntry/CardEntryFallback'
import { FormattedMessage } from 'react-intl'
import NetworkWithLogo from 'src/components/NetworkWithLogo/NetworkWithLogo'

import './AffiliatesCards.sass'

interface AffiliatesCardsProps {
  codes: ReferalStats[]
  openDialog: () => void
  loading: boolean
}

export default function AffiliatesCards({
  codes,
  openDialog,
  loading
}: AffiliatesCardsProps) {
  return (
    <section id="affiliates-cards">
      <button onClick={openDialog}>
        <span className="icon icon-plus" />
        <FormattedMessage id="start-inviting" />
      </button>
      <div>
        <NetworkWithLogo />
      </div>
      {loading ? (
        <>
          <CardEntryFallback />
          <CardEntryFallback />
          <CardEntryFallback />
        </>
      ) : (
        codes.map((codeData, i) => (
          <CardEntry
            key={i}
            code={codeData.name}
            points={codeData.pointsObtained}
            traders={codeData.usageCount}
            volume={codeData.volumeUsd}
          />
        ))
      )}
    </section>
  )
}
