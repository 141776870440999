import { useState } from 'react'
import IntroduceCode from './IntroduceCode/IntroduceCode'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { WalletData } from 'src/types'
import referalUtils from 'src/utils/referals'
import web3 from 'src/utils/web3'
import { NetworkIds } from 'src/utils/networkHelper'

interface InsertCodeProps {
  handleClose: () => void
  refresh: () => void
}

export default function InsertCode({ handleClose, refresh }: InsertCodeProps) {
  const wallet = useSelector((s: { wallet: WalletData }) => s.wallet)
  const networkId = useSelector((s: { network: NetworkIds }) => s.network)
  const [txHash, setTxHash] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const intl = useIntl()

  const startError = 'referrals.dialog.insert.error'

  const handleClaim = async (code: string) => {
    setTxHash(null)
    setLoading(true)

    try {
      const userReferal = await referalUtils.getUserReferal(
        wallet?.address,
        web3,
        networkId
      )

      const codeData = await referalUtils.getReferalCode(code, web3, networkId)
      // User already claimed a code
      if (userReferal.referalCode.length !== 0) {
        setError(`${startError}.claimed`)
        return
      }
      // Code doesnt exists
      else if (parseInt(codeData.owner, 16) === 0) {
        setError(`${startError}.not-exists`)
        return
      }
      // Owner try to claim own code
      else if (codeData.owner.toLowerCase() === wallet?.address) {
        setError(`${startError}.same-owner`)
        return
      }
      const txHash: string = await referalUtils.claimCode(
        wallet.address,
        code,
        networkId
      )
      setTxHash(txHash)
      refresh()
    } catch (err) {
      console.warn('Code not claimed', err)
      setError(intl.formatMessage({ id: 'referrals.dialog.error' }))
    } finally {
      setLoading(false)
    }
  }

  const commonInit = `referrals.dialog.insert`

  return (
    <IntroduceCode
      type="insert"
      error={error}
      loading={loading}
      handleClose={handleClose}
      handleSubmit={handleClaim}
      revertError={() => setError(null)}
      txHash={txHash}
      description={
        <p>
          <FormattedMessage id={`${commonInit}.description`} />
        </p>
      }
    />
  )
}
