import { NetworkName } from '@utils/networkHelper'
import {
  getAvailableCapacity,
  getStakingTotals,
  getTotalAmountStaked
} from 'src/contracts/staking/getTotalData'
import { StakingPosition, StakingTotals } from 'src/types/staking'
import usePromise from './usePromise'
import getUserStakingPosition, {
  getUserPositionPendingRewards
} from 'src/contracts/staking/getUserStakingPosition'
import getUserNumStakingPositions from 'src/contracts/staking/getUserNumStakingPositions'
import {
  getUserTotalPendingRewardAmount,
  getUserTotalStakedAmount
} from 'src/contracts/staking/getUserStakingTotalData'

export const useStakingTotalStaked = (
  contractAddress: string,
  networkName: NetworkName = 'binance'
): number => {
  const totalStaked: number = usePromise(
    getTotalAmountStaked,
    contractAddress,
    networkName
  )
  return totalStaked
}
export const useStakingTotals = (
  contractAddress: string,
  networkName: NetworkName = 'binance'
): StakingTotals => {
  const totals: StakingTotals = usePromise(
    getStakingTotals,
    contractAddress,
    networkName
  )
  return totals
}

export const useStakingPosition = (
  address: string,
  positionId: number,
  contractAddress: string,
  networkName: NetworkName = 'binance'
): StakingPosition => {
  const position: StakingPosition = usePromise(
    getUserStakingPosition,
    address,
    positionId,
    contractAddress,
    networkName
  )
  return position
}

export const useStakingPositionNumber = (
  address: string,
  contractAddress: string,
  refreshKey: number,
  networkName: NetworkName = 'binance'
): number => {
  const positionsNumber: number = usePromise(
    getUserNumStakingPositions as (
      address: string,
      contract: string,
      networkName: string,
      refreshKey: number
    ) => Promise<number>,
    address,
    contractAddress,
    networkName,
    refreshKey
  )
  return positionsNumber
}

export const useGetTotalUserStakedAmount = (
  userAddress: string,
  contractAddress: string,
  networkName: NetworkName = 'binance'
): number => {
  const totalStaked: number = usePromise(
    getUserTotalStakedAmount,
    userAddress,
    contractAddress,
    networkName
  )
  return totalStaked
}

export const useGetTotalUserRewards = (
  userAddress: string,
  contractAddress: string,
  networkName: NetworkName = 'binance'
): number => {
  const totalRewards: number = usePromise(
    getUserTotalPendingRewardAmount,
    userAddress,
    contractAddress,
    networkName
  )
  return totalRewards
}

export const useGetPendingRewardsInPosition = (
  userAddress: string,
  positionId: number,
  contractAddress: string,
  networkName: NetworkName = 'binance'
): number => {
  const rewards: number = usePromise(
    getUserPositionPendingRewards,
    userAddress,
    positionId,
    contractAddress,
    networkName
  )
  return rewards
}

export const useGetAvailableCapacity = (
  contractAddress: string,
  networkName: NetworkName = 'binance'
): number => {
  const rewards: number = usePromise(
    getAvailableCapacity,
    contractAddress,
    networkName
  )
  return rewards
}
