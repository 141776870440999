import { FormattedMessage } from 'react-intl'
import { AuBnbDialogProps } from '../../AuBnbDialog'
import AuBnbDialogSteps from '../../Steps/AuBnbDialogSteps'
import TokenInput from '@components/TokenInput/TokenInput'
import { useWallet } from '@hooks/useWallet'
import { FormEvent, useState } from 'react'
import { getTokenImage } from '@utils/getTokenImage'
import {
  approveAuBnb,
  withdrawAuBnbToBnb
} from 'src/contracts/aubnb/aubnbMethods'

export default function AuBnbDialogUnstake({
  updateRefreshKey,
  stepsData
}: AuBnbDialogProps) {
  const address = useWallet()
  console.log('Address: ', address)
  const [enabled, setEnabled] = useState<boolean>(true)
  const { addStep, errorStep, resetSteps, setDone, steps, successStep } =
    stepsData

  const handleUnstake = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // Unstake selected
    const aubnbWeiAmount = e.target[5].dataset.wei as string
    try {
      addStep('aubnb-dialog.stake.approved')
      await approveAuBnb(address, BigInt(aubnbWeiAmount))
      successStep()
    } catch (error) {
      console.error('Error approving AuBNB to unstake', error)
      errorStep()
    }

    try {
      addStep('stake.staking-dialog.stake.confirmation')

      const tx = await withdrawAuBnbToBnb(
        address,
        BigInt(aubnbWeiAmount),
        BigInt(aubnbWeiAmount)
      )
      successStep({ txHash: tx })
      setDone('aubnb-dialog.steps-done-unstaking', tx)
      updateRefreshKey()
    } catch (err) {
      console.error('Error unstaking auBNB', err)
      errorStep()
    }
  }
  return (
    <form onSubmit={handleUnstake}>
      <TokenInput
        className="stake"
        defaultCoin="aubnb"
        initialValue={0}
        handleExternalChange={(value) => {
          resetSteps()
          if (value === 0) setEnabled(false)
        }}
      >
        <div>
          <img src={getTokenImage('aubnb')} alt="AuBNB logo" id="token-logo" />
          <span>AuBNB</span>
        </div>
      </TokenInput>
      <AuBnbDialogSteps steps={steps}>
        <button className="continue" disabled={!enabled} type="submit">
          <FormattedMessage id="continue" />
        </button>
      </AuBnbDialogSteps>
    </form>
  )
}
