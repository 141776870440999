import { Suspense } from 'react'
import ActualValueLeftPoint from './LeftPointsTypes/ActualValueLeftPoint'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import DefaultPointsBox from 'src/components/Points/PointsBox/DefaultPointsBox'
import FallbackNoConnected from '../../../components/FallbackNoConnected/FallbackNoConnected'
import LeftProductInfoClient from './LeftProductInfoClient'
import EigenLayerDefiLeftPoints from './LeftPointsTypes/Defi/EigenLayerDefiLeftPoints'
import { useWallet } from 'src/hooks/useWallet'
import { useKelpPoints } from 'src/hooks/useEigenLayerPoints'
import { useEigenLayerWithRenzo } from './LeftPointsTypes/Defi/useDefiPoints'

import './LeftProductInfo.sass'
import { PoolsTokens } from 'src/types/pools'
import { useAutolayerPoints } from 'src/hooks/useAutolayerPoints'
import AssetInfoPoints from 'src/components/Points/AssetInfo/AssetInfoPoints'

declare const __CLIENT__: boolean

const LeftDefiInfo = ({
  refreshKey,
  filteredTokens
}: {
  refreshKey: number
  filteredTokens: PoolsTokens[]
}) => {
  const renzoAddress = '0x2416092f143378750bb29b79eD961ab195CcEea5'
  const kelpAddress = '0x4186BFC76E2E237523CBC30FD220FE055156b41F'

  const isRenzoToken: boolean = filteredTokens?.some(
    (t) => t.address.toLowerCase() === renzoAddress.toLowerCase()
  )
  const isKelpToken: boolean = filteredTokens?.some(
    (t) => t.address.toLowerCase() === kelpAddress.toLowerCase()
  )

  const wallet = useWallet()
  console.log('wallet', wallet)

  if (!__CLIENT__) return <LeftProductInfoClient infoType="isDefi" />

  if (wallet === null) return <FallbackNoConnected />

  return (
    <div id="left-defi-info" className="left-product-info">
      <ErrorBoundary fallback={<DefaultPointsBox type="error" />}>
        <Suspense fallback={<DefaultPointsBox type="default" />}>
          <ActualValueLeftPoint refreshKey={refreshKey} />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary fallback={<DefaultPointsBox type="error" />}>
        <Suspense fallback={<DefaultPointsBox type="autoLayer" />}>
          <AssetInfoPoints
            type="autoLayer"
            useFetchPoints={useAutolayerPoints}
            refreshKey={refreshKey}
          />
        </Suspense>
      </ErrorBoundary>
      {(isRenzoToken || isKelpToken) && (
        <ErrorBoundary fallback={<DefaultPointsBox type="error" />}>
          <Suspense fallback={<DefaultPointsBox type="eigenLayer" />}>
            <EigenLayerDefiLeftPoints
              isKelp={isKelpToken}
              isRenzo={isRenzoToken}
            />
          </Suspense>
        </ErrorBoundary>
      )}
      {isRenzoToken && (
        <ErrorBoundary fallback={<DefaultPointsBox type="error" />}>
          <Suspense fallback={<DefaultPointsBox type="renzo" />}>
            <AssetInfoPoints
              type="renzo"
              useFetchPoints={useEigenLayerWithRenzo}
            />
          </Suspense>
        </ErrorBoundary>
      )}

      {isKelpToken && (
        <ErrorBoundary fallback={<DefaultPointsBox type="error" />}>
          <Suspense fallback={<DefaultPointsBox type="kelp" />}>
            <AssetInfoPoints type="kelp" useFetchPoints={useKelpPoints} />
          </Suspense>
        </ErrorBoundary>
      )}
    </div>
  )
}

export default LeftDefiInfo
