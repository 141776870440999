import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'

import './ReferralsButtons.sass'

export enum ReferralsEnum {
  TRADERS = 'traders',
  AFFILIATES = 'affiliates'
}

const ReferralsButtons = () => {
  return (
    <div id="refferrals-tab-buttons">
      {Object.values(ReferralsEnum).map((r) => (
        <NavLink
          key={r}
          className={({ isActive }) =>
            isActive ? `button button-${r} choosen` : `button button-${r}`
          }
          to={`/referrals/${r}`}
        >
          <FormattedMessage id={`refferrals-tab-buttons-${r}`} />
        </NavLink>
      ))}
    </div>
  )
}

export default ReferralsButtons
