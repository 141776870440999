import getAutolayerPoints from 'src/contracts/getAutoLayerPoints'
import usePromise from './usePromise'
import { Points } from 'src/types'
import { useWallet } from './useWallet'

export function useAutolayerPoints(refreshKey?: number): Points {
  const wallet = useWallet()
  const points: string = usePromise(
    getAutolayerPoints as (address: string, s: number) => Promise<string>,
    wallet,
    refreshKey
  )

  return { points }
}
