import { useState } from 'react'
import { getProtocolImage } from 'src/utils/getTokenImage'
import { GroupedRiskInfo } from 'src/utils/risk'
import { networkVectorsWhite } from 'src/static/logos/networks'
import textHelpers from 'src/utils/text'
import { RiskType } from 'src/types/RiskTypes'
import { protocolsIcons } from 'src/static/logos/protocols'

import './TooltipItem.sass'

interface TooltipItemProps {
  protocol: string
  riskType: RiskType
  data: GroupedRiskInfo
}

export default function TooltipItem({
  protocol,
  data,
  riskType
}: TooltipItemProps) {
  const [expand, setExpand] = useState<boolean>(false)

  return (
    <div className="toltip-item">
      <div className="top">
        <span>
          <img
            src={
              riskType === RiskType.Collateral
                ? getProtocolImage(protocol)
                : protocolsIcons[protocol]
            }
            alt={`icon ${protocol}`}
          />
          <strong>{protocol}</strong>
          {data.splitedTvl.length > 0 ? (
            <div className="icon-wrapper">
              <span
                className={`icon icon-angle_${expand ? 'right' : 'down'}`}
                role="button"
                onClick={() => setExpand(!expand)}
              />
            </div>
          ) : null}
        </span>

        <span className="percentage">
          {Math.round(+data.percentageTvl * 100) / 100}%
        </span>
      </div>
      {expand ? (
        <div className="bottom">
          <ul>
            {data.splitedTvl.map(({ network, percentageTvl }) => (
              <li key={network}>
                <div>
                  <img
                    loading="lazy"
                    src={networkVectorsWhite[network]}
                    alt={network}
                  />
                  <span>{textHelpers.capitalize(network)}</span>
                </div>
                <span className="percentage">{percentageTvl}%</span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  )
}
