import { ReactNode, Suspense, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import usePromise from '@hooks/usePromise'
import { getRealWeiTokenBalance } from 'src/contracts/getBalance/getSingleTokenBalance'
import { useWallet } from '@hooks/useWallet'

import './TokenInput.sass'

interface TokenInputProps {
  children: ReactNode
  className: string
  disabled?: boolean
  initialValue?: number
  handleExternalChange?: (value: number) => void
  defaultCoin?: 'lay3r' | 'bnb' | 'aubnb'
}

interface BalanceButtonsProps {
  handleValue: (value: bigint) => void
  defaultCoin: 'lay3r' | 'bnb' | 'aubnb'
}

function BalanceButtonsSkeleton() {
  return (
    <div className="balance-buttons">
      <button className="max-balance-button" type="button">
        <FormattedMessage id="max" />
      </button>
      <button type="button" className="percentage-balance-button">
        %
      </button>
      <div className={`percentages`}>
        <button className="balance-button" type="button">
          75%
        </button>
        <button className="balance-button" type="button">
          50%
        </button>
        <button className="balance-button" type="button">
          25%
        </button>
      </div>
    </div>
  )
}

const BalanceButtons = ({ handleValue, defaultCoin }: BalanceButtonsProps) => {
  const address = useWallet()
  const balance = usePromise(
    getRealWeiTokenBalance,
    defaultCoin.toUpperCase(),
    address,
    '0x38'
  ) as unknown as bigint
  const [openPorcentages, setOpenPorcentages] = useState<boolean>(false)

  return (
    <div className="balance-buttons">
      <button
        className="max-balance-button"
        type="button"
        onClick={() => handleValue(balance)}
      >
        <FormattedMessage id="max" />
      </button>
      <button
        type="button"
        className="percentage-balance-button"
        onClick={() => setOpenPorcentages((prev) => !prev)}
      >
        %
      </button>
      <div className={`percentages ${openPorcentages ? 'expanded' : ''}`}>
        <button
          className="balance-button"
          type="button"
          onClick={() => handleValue((BigInt(balance) / BigInt(4)) * BigInt(3))}
        >
          75%
        </button>
        <button
          className="balance-button"
          type="button"
          onClick={() => handleValue(BigInt(balance) / BigInt(2))}
        >
          50%
        </button>
        <button
          className="balance-button"
          type="button"
          onClick={() => handleValue(BigInt(balance) / BigInt(4))}
        >
          25%
        </button>
      </div>
    </div>
  )
}

export default function TokenInput({
  children,
  className,
  handleExternalChange,
  disabled = false,
  initialValue = 0,
  defaultCoin = 'lay3r'
}: TokenInputProps) {
  const [value, setValue] = useState<number>(initialValue)
  const weiInitialValue = initialValue * Math.pow(10, Number(18))
  const [realAmount, setRealAmount] = useState<bigint>(BigInt(weiInitialValue))
  console.log('value', value)
  return (
    <section className={`token-input ${className}`}>
      <header>
        <label htmlFor="quantity">Amount</label>
        {!disabled ? (
          <Suspense fallback={<BalanceButtonsSkeleton />}>
            <BalanceButtons
              handleValue={(value) => {
                handleExternalChange(+value.toString())
                setRealAmount(value)
                setValue(+value.toString() / Math.pow(10, Number(18)))
              }}
              defaultCoin={defaultCoin}
            />
          </Suspense>
        ) : null}
      </header>
      <div>
        <input
          name="quantity"
          type="number"
          required
          disabled={disabled}
          value={value}
          data-wei={realAmount}
          onChange={({ target: { value } }) => {
            const decimals: string = value.split(',')[1]
            const factor: number = Math.pow(10, Number(18))
            const valueAndFactor = +value * factor
            setRealAmount(BigInt(valueAndFactor))
            if (decimals && decimals.length > 6) return
            setValue(+value)
            handleExternalChange(+value)
          }}
        />
        {children}
      </div>
    </section>
  )
}
