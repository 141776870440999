import menu from 'src/static/vectors/menu.svg'
import NonModalLayout from '@components/NonModalLayout/NonModalLayout'
import GoToReferrals from '@components/GoToReferrals/GoToReferrals'
import GoToAirdrops from '@components/GoToAirdrops/GoToAirdrops'

import './NavigationMenu.sass'
import NavigateStake from '@components/StakeBanner/NavigateStake/NavigateStake'

const NavigationMenu = () => {
  return (
    <>
      <NonModalLayout image={{ src: menu, alt: 'menu icon' }}>
        <div id="navigation-menu-bubble">
          <NavigateStake />
          <GoToReferrals />
          <GoToAirdrops />
        </div>
      </NonModalLayout>
    </>
  )
}

export default NavigationMenu
