import { DefaultRewardCard } from './RewardCard/RewardCard'

import './RewardsGrid.sass'

const DefaultRewardsGrid = () => {
  return (
    <section id="rewards-grid" className="grid-container">
      {[...Array(5)].map((_, i) => (
        <DefaultRewardCard key={i} />
      ))}
    </section>
  )
}

export default DefaultRewardsGrid
