import {
  FC,
  forwardRef,
  Suspense,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import StakeDialogStake from './Types/StakeDialogStake/StakeDialogStake'
import StakeDialogUnstake from './Types/StakeDialogUntstake/StakeDialogUnstake'
import stakeUtils from '@utils/stake'
import StakeDialogClaimReward from './Types/StakeDialogClaimReward/StakeDialogClaimReward'
import StakeDialogStakeReward from './Types/StakeDialogStakeReward/StakeDialogStakeReward'
import { useDialogSteps } from '@hooks/useDialogSteps'
import Spinner from '@components/Loading/Spinner/Spinner'

import './StakeDialog.sass'
import '@components/TokenSelector/BalanceButtons/BalanceButtons.sass'

export type DialogTypes = 'stake' | 'unstake' | 'rewards' | 'claim'

export interface StakeDialogState {
  type: DialogTypes
  initialValue?: number
  poolAddress: string
  positionId?: number
}

export interface StakeDialogMethods {
  open: (data: StakeDialogState) => void
  close: () => void
}

type StepsReturn = ReturnType<typeof useDialogSteps>

export type StakeDialogsProps = Omit<StakeDialogState, 'type'> & {
  updateRefreshKey: () => void
  stepsData: StepsReturn
}

const typesRecord: Record<DialogTypes, FC<StakeDialogsProps>> = {
  stake: StakeDialogStake,
  unstake: StakeDialogUnstake,
  claim: StakeDialogClaimReward,
  rewards: StakeDialogStakeReward
}

interface StakeDialogProps {
  updateRefreshKey: () => void
}

const StakeDialog = forwardRef<StakeDialogMethods, StakeDialogProps>(
  ({ updateRefreshKey }, ref) => {
    const dialogRef = useRef<HTMLDialogElement>()
    const [data, setData] = useState<StakeDialogState>({
      poolAddress: '',
      type: 'stake',
      initialValue: 0,
      positionId: 0
    })

    const steps = useDialogSteps()

    const Component = typesRecord[data.type]

    const close = () => {
      dialogRef.current?.close()
      steps.resetSteps()
    }

    useImperativeHandle(ref, () => ({
      open: (data: StakeDialogState) => {
        setData(data)
        dialogRef.current?.showModal()
      },
      close
    }))

    return (
      <dialog id="stake-dialog" ref={dialogRef}>
        <header>
          <h3>{stakeUtils.chooseDialogLabel(data.type)}</h3>
          <button title="Close dialog" onClick={close} autoFocus>
            <span className="icon icon-close" />
          </button>
        </header>
        <Suspense
          fallback={
            <div className="spinner-fallback">
              <Spinner />
            </div>
          }
        >
          <Component
            initialValue={data.initialValue}
            poolAddress={data.poolAddress}
            positionId={data.positionId}
            updateRefreshKey={updateRefreshKey}
            stepsData={steps}
          />
        </Suspense>
      </dialog>
    )
  }
)

StakeDialog.displayName = 'Stake Dialog'

export default StakeDialog
