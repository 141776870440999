import { useEffect, useState } from 'react'
import IntroduceCode from './IntroduceCode/IntroduceCode'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import getWeb3 from 'src/contracts/web3'
import { getNetworkName, NetworkIds } from 'src/utils/networkHelper'
import referalUtils from 'src/utils/referals'
import { WalletData } from 'src/types'
import web3 from 'src/utils/web3'

interface CreateCodeProps {
  handleClose: () => void
  refresh: () => void
}

export default function CreateCode({ handleClose, refresh }: CreateCodeProps) {
  const networkId = useSelector((s: { network: NetworkIds }) => s.network)
  const wallet = useSelector((s: { wallet: WalletData }) => s.wallet)
  const [firstCode, setFirstCode] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string | null>(null)

  const intl = useIntl()

  useEffect(() => {
    const web3 = getWeb3(getNetworkName(networkId))
    referalUtils
      .getCreatedReferalCodes(wallet?.address, web3, networkId)
      .then((codes) => setFirstCode(codes.length === 0))
  }, [networkId, wallet?.address])

  const handleCreate = async (code: string) => {
    try {
      setTxHash(null)
      setLoading(true)
      const codeData = await referalUtils.getReferalCode(code, web3, networkId)
      if (parseInt(codeData.owner) !== 0) {
        setError(
          intl.formatMessage({ id: 'referrals.dialog.create.error.claimed' })
        )
        return
      }
      const tx = await referalUtils.createReferalCode(
        code,
        wallet?.address,
        networkId
      )
      setTxHash(tx)
      refresh()
    } catch (err) {
      setError(intl.formatMessage({ id: 'referrals.dialog.error' }))
    } finally {
      setLoading(false)
    }
  }

  const commonInit = `referrals.dialog.create`

  return (
    <IntroduceCode
      type="create"
      error={error}
      handleClose={handleClose}
      handleSubmit={handleCreate}
      revertError={() => setError(null)}
      loading={loading}
      txHash={txHash}
      description={
        firstCode ? (
          <>
            <p>
              <FormattedMessage id={`${commonInit}.description-1`} />
            </p>
            <p>
              <FormattedMessage id={`${commonInit}.description-2`} />
            </p>
          </>
        ) : null
      }
    />
  )
}
