import { NetworkName } from '@utils/networkHelper'
import { stakingAbi } from '../abis/AutoLayerAbis/StakingAbi'
import getWeb3 from '../web3'

const getUserNumStakingPositions = async (
  userAddress: string,
  contractAddress: string,
  networkName: NetworkName = 'binance'
): Promise<number> => {
  if (!userAddress) return 0
  const web3 = getWeb3(networkName)
  const contract = new web3.eth.Contract(stakingAbi, contractAddress)
  const numPositions: bigint = await contract.methods
    .getUserNumStakingPositions(userAddress)
    .call()
  return +numPositions?.toString()
}

export default getUserNumStakingPositions
