import {
  getNetworkIdByName,
  NetworkIds,
  NetworkName
} from 'src/utils/networkHelper'
import getWeb3 from '../web3'
import { GENERIC_ABIS } from '../abis'
import { networkData } from 'src/data'
import { BalanceResult } from './getBalanceListBatch'

const TOKEN_BALANCE_MANAGER = {
  '0x1': '0x9825A225ceB7E759bB117D2a19e2FC88ECD535A5',
  '0xa4b1': '0x28a8e8d4aD238dB7c61b0677bAbdB4FBcDa2b16f',
  '0x38': '0xf31bC28d0dAc6Bec888206eC1B062b588C1da507',
  '0xa': '0xbefA54f940101476998D546553993f434E1Df7Fe',
  '0x2105': '0x98998257bDe09006D366405245C6b8d4226A99e3',
  '0xe708': '0x98998257bDe09006D366405245C6b8d4226A99e3',
  '0x868b': '0x98998257bDe09006D366405245C6b8d4226A99e3',
  '0x82750': '0x98998257bDe09006D366405245C6b8d4226A99e3'
}

const getManagerInstance = (networkName: NetworkName) => {
  const networkId: NetworkIds = getNetworkIdByName(networkName)
  const tokenManagerAddress = TOKEN_BALANCE_MANAGER[networkId]
  const dataNetwork = networkData(networkId)
  const abi: any[] = GENERIC_ABIS.tokenBalanceManagerAbi
  const web3 = getWeb3(networkName)
  const tokenManagerContract = new web3.eth.Contract(abi, tokenManagerAddress)
  return { tokenManagerContract, web3, dataNetwork }
}

export const getSingleBalanceFromTokenManager = async (
  networkName: NetworkName,
  userAddress: string,
  tokenAddress: string
): Promise<BalanceResult> => {
  const { tokenManagerContract, web3, dataNetwork } =
    getManagerInstance(networkName)
  const balanceWei: string = await tokenManagerContract.methods
    .getSingleBalance(userAddress.toLowerCase(), tokenAddress.toLowerCase())
    .call()
  const balance: string = web3.utils.fromWei(balanceWei, 'ether')
  const id = dataNetwork.tokensLRS.find(
    (t) => t.address.toLowerCase() === tokenAddress.toLowerCase()
  )?.id
  return { balance, id, balanceWei, address: tokenAddress, networkName }
}

export const getBalanceListFromTokenManager = async (
  networkName: NetworkName,
  userAddress: string,
  allList: boolean = true,
  tokensList: string[] = []
): Promise<BalanceResult[]> => {
  const { tokenManagerContract, web3, dataNetwork } =
    getManagerInstance(networkName)
  const tokens: string[] = allList
    ? dataNetwork.tokensLRS.map((t) => t.address.toLowerCase())
    : tokensList
  const balancesWei = await tokenManagerContract.methods
    .getBalanceForAddressesList(userAddress, tokens)
    .call()
  const result: BalanceResult[] = tokens.map((token, i) => {
    const balance: string = web3.utils.fromWei(balancesWei[i], 'ether')
    const id = dataNetwork.tokensLRS.find(
      (t) => t.address.toLowerCase() === token.toLowerCase()
    )?.id
    return { balance, id, balanceWei: balancesWei[i], address: token }
  })
  return result
}
